import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import useDismissBanner, {
  BannerTypes,
} from '~publish/legacy/profile-page/hooks/useDismissBanner'
import { RemindersIcon } from '~publish/legacy/reminders/components/RemindersIcon'

import { Button, Link, PromotionalBanner } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAccount } from '~publish/legacy/accountContext'
import { CLIENT_NAMES, SEGMENT_NAMES } from '~publish/legacy/constants'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'

const SetupInstagramRemindersBanner = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization
  const [dismissed, setDismissed] = useState(false)

  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.remindersSetup,
  })

  useEffect(() => {
    if (currentOrganization?.id) {
      BufferTracker.remindersSetupCTAViewed({
        organizationId: currentOrganization?.id || null,
        clientName: CLIENT_NAMES.web,
        cta: SEGMENT_NAMES.REMINDERS_BANNER,
        placement: 'setupRemindersBanner',
      })
    }
  }, [currentOrganization])

  const onRemindersSetupButtonClick = (): void => {
    BufferTracker.remindersSetupCTAClicked({
      organizationId: currentOrganization?.id || null,
      clientName: CLIENT_NAMES.web,
      cta: SEGMENT_NAMES.REMINDERS_BANNER,
      placement: 'setupRemindersBanner',
    })

    const { MODALS, actions } = window.appshell || {}

    actions?.openModal(MODALS?.setupNotifications, {
      // @ts-expect-error - FIXME: Object literal may only specify known properties, and 'placement' does not exist in type 'ModalData'
      placement: 'setupRemindersBanner',
    })
  }

  const onDismiss = (): void => {
    onDismissBanner()
    setDismissed(true)
  }

  if (dismissed) return null

  return (
    <PromotionalBanner onDismiss={onDismiss}>
      <PromotionalBanner.Content>
        <PromotionalBanner.Heading>
          <RemindersIcon size="large" />
          {t('remindersBanner.headline')}
        </PromotionalBanner.Heading>
        <PromotionalBanner.Description>
          {t('remindersBanner.description')}&nbsp;
          <Link
            href={`https://support.buffer.com/article/658-using-notification-publishing?${HC_UTM_PARAMS}`}
            external
          >
            {t('remindersBanner.linkText')}
          </Link>
        </PromotionalBanner.Description>
        <PromotionalBanner.Actions>
          <Button variant="primary" onClick={onRemindersSetupButtonClick}>
            {t('remindersSetupWizard.setUpNotificationsButton')}
          </Button>
        </PromotionalBanner.Actions>
      </PromotionalBanner.Content>
      <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/reminders-banner.png" />
    </PromotionalBanner>
  )
}

export { SetupInstagramRemindersBanner }
