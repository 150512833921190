import React from 'react'
import styled from 'styled-components'
import { Tooltip } from '@bufferapp/ui'
import { Canva } from '@bufferapp/ui/Icon'
import { white } from '@bufferapp/ui/style/colors'
import { openCanvaModal } from '~publish/legacy/canva/handlers'
import { OmniCanvaDesignConfig } from '~publish/legacy/canva/constants'
import { Upload, UploadSource } from '@buffer-mono/uploader'
import type { Uploader } from '@buffer-mono/uploader'

const CanvaTag = styled(Canva)`
  position: absolute;
  cursor: pointer;
  fill: ${white};
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4px;
  bottom: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
`

export const CanvaEditTag = ({
  upload,
  uploader,
}: {
  upload: Upload
  uploader: Uploader
}): JSX.Element | null => {
  const source = Upload.getSource(upload)

  if (!UploadSource.isCanva(source)) return null

  const onClick = () => {
    openCanvaModal({
      uploader,
      designConfig: OmniCanvaDesignConfig,
      designId: source.id,
    })
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message
    <Tooltip label="Edit Canva Design">
      <CanvaTag onClick={onClick} role="button" tabIndex={0} />
    </Tooltip>
  )
}
