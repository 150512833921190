// Progress.tsx
import React from 'react'
import styles from './Progress.module.css'

interface ProgressProps {
  /** Current progress value */
  value: number
  /** Maximum value (defaults to 100) */
  max?: number
  /** Optional class name for additional styling */
  className?: string
}

export const Progress = ({
  value,
  max = 100,
  className = '',
}: ProgressProps): JSX.Element => {
  const percentage = Math.min(100, Math.max(0, (value / max) * 100))

  return (
    <div className={`${styles.progressBar} ${className}`}>
      <div
        className={styles.progressFill}
        style={{ width: `${percentage}%` }}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={max}
      />
    </div>
  )
}
