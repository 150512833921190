import React from 'react'
import { CreatePageLayout } from '../components/Layout/CreatePageLayout'

import { Feeds } from '../Feed/Feeds'

export function FeedsPage(): JSX.Element {
  return (
    <CreatePageLayout>
      <Feeds />
    </CreatePageLayout>
  )
}
