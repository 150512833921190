import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChannelFieldCharacterCount, Input } from '../../channel-fields/styles'
import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import DateOptionalTimePicker from '../Common/DateOptionalTimePicker'
import { Label, RightFloatWrapper, Textarea, Wrapper } from '../styles'
import { googleBusinessDataPropType } from '../GoogleBusinessPropTypes'
import type { GoogleBusinessChannelData } from '../../../entities/Draft/types'
import { Switch, Label as PopcornLabel } from '@buffer-mono/popcorn'

const OfferBar = ({
  data,
  uses24hTime,
}: {
  data: GoogleBusinessChannelData
  uses24hTime: boolean
}): JSX.Element => {
  const [hasDetails, setHasDetails] = useState(
    !!data.code || !!data.link || !!data.terms,
  )

  const onInputChange = (
    val: string,
    attr: keyof GoogleBusinessChannelData,
  ): void => {
    ComposerActionCreators.updateDraftGoogleBusinessData({
      ...data,
      [attr]: val,
    })
  }

  return (
    <>
      <Wrapper>
        <Label>Offer Title</Label>
        <Input
          onChange={(e): void => onInputChange(e.target.value, 'title')}
          value={data.title ?? ''}
        />
        {data.title && data.title.length > 50 && (
          <ChannelFieldCharacterCount count={data.title.length} maxCount={58} />
        )}
      </Wrapper>
      <DateOptionalTimePicker data={data} uses24hTime={uses24hTime} hideTime />
      <RightFloatWrapper withBottomMargin={hasDetails}>
        <Switch
          size="small"
          id="offer_details"
          onCheckedChange={(): void => {
            if (hasDetails === true) {
              ComposerActionCreators.updateDraftGoogleBusinessData({
                ...data,
                code: null,
                link: null,
                terms: null,
              })
            }
            setHasDetails(!hasDetails)
          }}
          checked={hasDetails}
        />
        <PopcornLabel htmlFor="offer_details" size="small">
          Add More Details
        </PopcornLabel>
      </RightFloatWrapper>
      {hasDetails && (
        <>
          <Wrapper>
            <Label>Coupon Code</Label>
            <Input
              onChange={(e): void => onInputChange(e.target.value, 'code')}
              value={data.code ?? ''}
            />
            {data.code && data.code.length > 58 && (
              <ChannelFieldCharacterCount
                count={data.code.length}
                maxCount={58}
              />
            )}
          </Wrapper>
          <Wrapper>
            <Label>Offer Link</Label>
            <Input
              onChange={(e): void => onInputChange(e.target.value, 'link')}
              value={data.link ?? ''}
              placeholder="Example: https://buffer.com"
            />
          </Wrapper>
          <Wrapper>
            <Label>Terms & Conditions</Label>
            <Input
              as={Textarea}
              onChange={(e: { target: { value: string } }): void =>
                onInputChange(e.target.value, 'terms')
              }
              value={data.terms ?? ''}
            />
            {data.terms && data.terms.length > 4900 && (
              <ChannelFieldCharacterCount
                count={data.terms.length}
                maxCount={5000}
              />
            )}
          </Wrapper>
        </>
      )}
    </>
  )
}

OfferBar.propTypes = {
  data: googleBusinessDataPropType.isRequired,
  uses24hTime: PropTypes.bool.isRequired,
}

export default OfferBar
