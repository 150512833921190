import React, { useEffect, useState } from 'react'

import {
  AlertDialog,
  Button,
  Flex,
  Heading,
  Paragraph,
  Strong,
  toast,
} from '@buffer-mono/popcorn'

import {
  selectCurrentProfile,
  selectCurrentProfileTimezone,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { TimeZoneSelect } from '~publish/components/TimeZoneSelect'

import { updateTimezone } from '../../thunks/updateTimezone'

import commonStyles from '../../PostingSchedule.module.css'

const TimezoneInputForm = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const profileTimezone = useAppSelector(selectCurrentProfileTimezone)

  const [loading, setLoading] = useState(false)
  const [selectedTimezone, setSelectedTimezone] = useState(profileTimezone)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  useEffect(() => {
    setSelectedTimezone(profileTimezone)
  }, [profileTimezone])

  if (!profile) return <></>

  const profileId = profile.id

  const handleTimezoneChange = (timezone: string): void => {
    if (timezone !== profileTimezone) {
      setSelectedTimezone(timezone)
      setIsConfirmationOpen(true)
    }
  }

  const handleTimezoneConfirm = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault()

    if (!selectedTimezone) return

    setLoading(true)
    const result = await dispatch(
      updateTimezone({
        profileId,
        timezone: selectedTimezone,
        city: selectedTimezone,
      }),
    )

    if (updateTimezone.fulfilled.match(result)) {
      toast.success('Channel time zone updated and all posts are rescheduled')
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your time zone: ${result.error.message}. Would you be up for trying again?`,
      )
    }

    setLoading(false)
    setIsConfirmationOpen(false)
  }

  return (
    <Flex
      justify="between"
      align="center"
      style={{ width: '100%' }}
      data-testid="timezone-form-wrapper"
    >
      <Flex direction="column">
        <Heading size="small" as="h3">
          Time zone
        </Heading>
        <Paragraph className={commonStyles.postingScheduleParagraph}>
          Time zone used for scheduling queue for this channel
        </Paragraph>
      </Flex>
      <Flex direction="row" align="center" gap="xs">
        <TimeZoneSelect
          value={selectedTimezone}
          disabled={!profile.isManager}
          size="large"
          variant="secondary"
          onChange={handleTimezoneChange}
        />
        {/* Using AlertDialog without trigger is not ideal, but we're 
        considering to sunset channel-specific time zone in general, 
        so did not invest time here to change its behavior  */}
        <AlertDialog
          open={isConfirmationOpen}
          onOpenChange={(open): void => {
            if (!open) {
              setIsConfirmationOpen(open)
              setSelectedTimezone(profileTimezone)
            }
          }}
        >
          <AlertDialog.Content>
            <AlertDialog.Title>
              Change channel time zone and reschedule posts?
            </AlertDialog.Title>
            <AlertDialog.Description>
              Changing the channel time zone to{' '}
              <Strong>{selectedTimezone}</Strong> will reschedule posts in
              channel&quot;s queue to match the new time zone.
            </AlertDialog.Description>
            <AlertDialog.Actions>
              <AlertDialog.Cancel>
                <Button variant="secondary" size="large">
                  Cancel
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <Button
                  variant="primary"
                  size="large"
                  loading={loading}
                  onClick={handleTimezoneConfirm}
                >
                  Change Time Zone
                </Button>
              </AlertDialog.Action>
            </AlertDialog.Actions>
          </AlertDialog.Content>
        </AlertDialog>
      </Flex>
    </Flex>
  )
}

export { TimezoneInputForm }
