import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export const usePostNavigation = (postId: string): (() => void) => {
  const history = useHistory()

  const expandPostCard = useCallback((): void => {
    const currentPath = location.pathname
    const newPath = `${currentPath}/posts/${postId}`
    history.push(newPath)
  }, [history, postId])

  return expandPostCard
}
