import React from 'react'
import { Sidebar, Flex, Heading, IdeasIcon } from '@buffer-mono/popcorn'
import { useLocation } from 'react-router-dom'
import styles from './CreatePageLayout.module.css'

import { FeedGroupsNav } from '../../Feed/components/FeedGroupsNav/FeedGroupsNav'
import { SidebarLink } from '../SidebarLink/SidebarLink'

type Props = {
  children: React.ReactNode
}

export function CreatePageLayout(props: Props): JSX.Element {
  const location = useLocation()
  const isFeed = location.pathname.startsWith('/create/feeds')
  const isIdeas = location.pathname.startsWith('/create') && !isFeed

  return (
    <Sidebar.Provider className={styles.sidebarContainer}>
      <Sidebar collapsible="icon" className={styles.sidebar}>
        <Sidebar.Header data-nohover className={styles.sidebarHeader}>
          <Heading
            as="h3"
            size="small"
            align="center"
            className={styles.createHeadingTitle}
          >
            Create
          </Heading>
          <Sidebar.Trigger />
        </Sidebar.Header>
        <Sidebar.Content>
          <Sidebar.Group>
            <Sidebar.GroupContent>
              <Sidebar.List>
                <SidebarLink
                  icon={<IdeasIcon />}
                  selected={isIdeas}
                  to="/create"
                >
                  Ideas
                </SidebarLink>
              </Sidebar.List>
            </Sidebar.GroupContent>
          </Sidebar.Group>
          <Sidebar.Group>
            <Sidebar.GroupLabel>Feeds</Sidebar.GroupLabel>
            <Sidebar.GroupContent>
              <Sidebar.List>
                <FeedGroupsNav />
              </Sidebar.List>
            </Sidebar.GroupContent>
          </Sidebar.Group>
        </Sidebar.Content>
        <Sidebar.Rail />
      </Sidebar>
      <Flex direction="column" gap="md" className={styles.createPageContent}>
        {props.children}
      </Flex>
    </Sidebar.Provider>
  )
}
