import React, { useState } from 'react'
import { Button, Heading, Dialog } from '@buffer-mono/popcorn'
import { useRecommendationList } from '../../hooks/useFeedRecommendations'
import { OPMLViewer } from './OPMLViewer'
import styles from './FeedGroupSettings.module.css'

const formatName = (name: string): string => name.replace('.opml', '')

type Props = {
  onAddFeed: (url: string) => void
}

type Recommendation = NonNullable<
  ReturnType<typeof useRecommendationList>['data']
>[number]

export const FeedRecommender = (props: Props): JSX.Element => {
  const { onAddFeed } = props
  const recommendations = useRecommendationList()
  const [selected, setSelected] = useState<Recommendation | null>(null)

  return (
    <div>
      <Heading size="small">Recommended Feeds</Heading>
      {selected && (
        <Dialog open={!!selected} onOpenChange={(): void => setSelected(null)}>
          <Dialog.Content size="xlarge">
            <Dialog.Header>
              <Dialog.Title>{formatName(selected.name)}</Dialog.Title>
              <Dialog.Description>
                Follow more feeds inspire your content creation
              </Dialog.Description>
            </Dialog.Header>
            <Dialog.Body>
              <OPMLViewer
                onAddFeed={(url): void => {
                  setSelected(null)
                  onAddFeed(url)
                }}
                opmlUrl={selected.download_url}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                variant="secondary"
                onClick={(): void => setSelected(null)}
              >
                Close
              </Button>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog>
      )}
      <ul className={styles.recommendationListContainer}>
        {recommendations?.data?.map((recommendation) => (
          <li key={recommendation.name} className={styles.recommendationList}>
            <Button
              variant="secondary"
              size="large"
              onClick={(): void => setSelected(recommendation)}
            >
              {formatName(recommendation.name)}
            </Button>
          </li>
        ))}
      </ul>
    </div>
  )
}
