import React, { useMemo } from 'react'

import {
  Avatar,
  Badge,
  ChannelIcon,
  Combobox,
  Flex,
  MapPinIcon,
  Text,
  Tooltip,
} from '@buffer-mono/popcorn'

import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

import { useChannels } from '../PageSidebar/useChannels'
import type { TimezoneInfo } from './timezone-filter'

import styles from './TimeZoneSelectItem.module.css'

export function TimeZoneSelectItem({
  timezone,
}: {
  timezone: TimezoneInfo
}): React.ReactElement {
  const { channels } = useChannels()
  const { id, longOffset, city } = timezone

  const channelsInTimezone = useMemo(
    () => channels?.filter(({ timezone }) => timezone === id),
    [channels, id],
  ).reverse()

  const isBrowserTimezone = id === getCurrentTimeZone()

  const tooltipContent = (
    <Flex direction="column" gap="xs">
      {isBrowserTimezone && (
        <Flex gap="xs" align="center">
          <MapPinIcon size="xsmall" />
          <Text>Browser detected timezone</Text>
        </Flex>
      )}
      {channelsInTimezone.slice(0, 5).map((channel) => (
        <Flex gap="xs" key={channel.id} align="center">
          <ChannelIcon
            type={channel.service}
            size="xsmall"
            style={{ color: 'currentColor' }}
          />
          {channel.name}
        </Flex>
      ))}
      {channelsInTimezone.length > 5 && (
        <Text size="sm">+{channelsInTimezone.length - 5} more...</Text>
      )}
    </Flex>
  )

  const TooltipWrapper = ({
    children,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Tooltip>): React.ReactElement => {
    if (!isBrowserTimezone && channelsInTimezone.length === 0) {
      return <>{children}</>
    }
    return (
      <Tooltip {...props} side="left" sideOffset={50}>
        {children}
      </Tooltip>
    )
  }

  // Remove leading zero from offset
  const offset = longOffset.replace(/([+-])0+/, '$1')

  return (
    <Combobox.Item key={id} value={id}>
      <TooltipWrapper content={tooltipContent}>
        <Flex
          justify="between"
          align="center"
          gap="2xs"
          className={styles.fullWidth}
        >
          {city}{' '}
          <Text color="subtle" size="sm">
            ({offset})
          </Text>
          <Flex className={styles.badgesList} gap="sm" justify="end">
            {isBrowserTimezone && (
              <Badge size="xsmall" className={styles.browserBadge}>
                <MapPinIcon />
              </Badge>
            )}
            {channelsInTimezone.length > 0 && (
              <Avatar.Stack
                max={2}
                size="xsmall"
                className={styles.avatarStack}
              >
                {channelsInTimezone.map((channel) => (
                  <Avatar
                    alt={channel.service}
                    src={channel.avatar}
                    size="xsmall"
                    key={channel.id}
                  />
                ))}
              </Avatar.Stack>
            )}
          </Flex>
        </Flex>
      </TooltipWrapper>
    </Combobox.Item>
  )
}
