import React, { useEffect } from 'react'

import type { Profile } from '~publish/legacy/profile-sidebar/types'

import { Text } from '@bufferapp/ui'
import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import FacebookIcon from '@bufferapp/ui/Icon/Icons/Facebook'
import LinkedInIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'
import PinterestIcon from '@bufferapp/ui/Icon/Icons/Pinterest'
import StartPageIcon from '@bufferapp/ui/Icon/Icons/StartPage'
import TiktokIcon from '@bufferapp/ui/Icon/Icons/Tiktok'
import GbpIcon from '@bufferapp/ui/Icon/Icons/Gbp'
import MastodonIcon from '@bufferapp/ui/Icon/Icons/Mastodon'
import YoutubeIcon from '@bufferapp/ui/Icon/Icons/Youtube'
import BlueskyIcon from '@bufferapp/ui/Icon/Icons/Bluesky'
import XIcon from '@bufferapp/ui/Icon/Icons/X'
import CaretDownIcon from '@bufferapp/ui/Icon/Icons/CaretDown'
import CaretUpIcon from '@bufferapp/ui/Icon/Icons/CaretUp'
import * as color from '@bufferapp/ui/style/colors'

import { getChannelConnectURL } from '~publish/legacy/utils/formatters/getURL'
import { useAccount } from '~publish/legacy/accountContext'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { isFreeUser } from '~publish/helpers/user'
import type { Service } from '~publish/gql/graphql'

import * as Styled from './styles'
import { ThreadsIcon } from '@buffer-mono/popcorn'

type ChannelOption = {
  name: string
  service: Service
  icon: JSX.Element
  showFirst: boolean
  color: string
  url: string
}
/**
 * show first Facebook, Instagram, Twitter/X, LinkedIn and Threads
 */
const connectableChannels: ChannelOption[] = [
  {
    name: 'Connect Facebook',
    service: 'facebook',
    icon: <FacebookIcon size={'medium'} />,
    showFirst: true,
    color: color.facebook,
    url: `${getChannelConnectURL()}?startConnectionService=facebook`,
  },
  {
    name: 'Connect Instagram',
    service: 'instagram',
    icon: <InstagramIcon size={'medium'} />,
    showFirst: true,
    color: color.instagram,
    url: `${getChannelConnectURL()}?startConnectionService=instagram`,
  },
  {
    name: 'Connect Twitter/X',
    service: 'twitter',
    icon: <XIcon size={'medium'} />,
    showFirst: true,
    color: color.twitter,
    url: `${getChannelConnectURL()}?startConnectionService=twitter`,
  },
  {
    name: 'Connect LinkedIn',
    service: 'linkedin',
    icon: <LinkedInIcon size={'medium'} />,
    showFirst: true,
    color: color.linkedin,
    url: `${getChannelConnectURL()}?startConnectionService=linkedin`,
  },
  {
    name: 'Connect Threads',
    service: 'threads',
    icon: <ThreadsIcon size={'small'} />,
    showFirst: true,
    color: color.threads,
    url: `${getChannelConnectURL()}?startConnectionService=threads`,
  },
  {
    name: 'Connect TikTok',
    service: 'tiktok',
    icon: <TiktokIcon size={'medium'} />,
    showFirst: true,
    color: color.tiktok,
    url: `${getChannelConnectURL()}?startConnectionService=tiktok`,
  },
  {
    name: 'Connect Pinterest',
    service: 'pinterest',
    icon: <PinterestIcon size={'medium'} />,
    showFirst: false,
    color: color.pinterest,
    url: `${getChannelConnectURL()}?startConnectionService=pinterest`,
  },
  {
    name: 'Connect Google Business',
    service: 'googlebusiness',
    icon: <GbpIcon size={'medium'} />,
    showFirst: false,
    color: color.googlebusiness,
    url: `${getChannelConnectURL()}?startConnectionService=googlebusiness`,
  },
  {
    name: 'Connect Bluesky',
    service: 'bluesky',
    icon: <BlueskyIcon size={'medium'} />,
    showFirst: false,
    color: color.bluesky,
    url: `${getChannelConnectURL()}?startConnectionService=bluesky`,
  },
  {
    name: 'Connect Mastodon',
    service: 'mastodon',
    icon: <MastodonIcon size={'medium'} />,
    showFirst: false,
    color: color.mastodon,
    url: `${getChannelConnectURL()}?startConnectionService=mastodon`,
  },
  {
    name: 'Connect YouTube',
    service: 'youtube',
    icon: <YoutubeIcon size={'medium'} />,
    showFirst: false,
    color: color.youtube,
    url: `${getChannelConnectURL()}?startConnectionService=youtube`,
  },
  {
    name: 'Create Start Page',
    service: 'startPage',
    icon: <StartPageIcon size={'medium'} />,
    showFirst: false,
    color: color.startpage,
    url: 'https://start-page.buffer.com/onboarding',
  },
]

function handleLegacyConnection(channel: ChannelOption): void {
  setTimeout(() => {
    window.location.assign(channel.url)
  }, 200)
}

const LEGACY_CONNECTION_SERVICES: Service[] = ['shopify', 'startPage']

const EmptyProfileList = (): JSX.Element => (
  <Text color="gray" type="p">
    Connect a channel to get started 👇
  </Text>
)

const useTracking = ({
  reachedChannelLimit,
}: {
  reachedChannelLimit: boolean
}): {
  trackChannelSuggestionViewed: () => void
  trackChannelSuggestionClicked: () => void
} => {
  const { account } = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const organizationId = account?.currentOrganization?.id || null
  const isFree = isFreeUser(account)

  const trackChannelSuggestionClicked = (): void => {
    if (organizationId && reachedChannelLimit && isFree) {
      BufferTracker.cTAClicked({
        organizationId,
        clientName: 'publishWeb',
        cta: 'publish-sidebar-channels-addChannelSuggestions-1',
        product: 'publish',
        upgradePathName: 'addChannel-upgrade',
        atEventBillingState:
          commonTrackingProperties?.atEventBillingState || null,
        atEventBillingPlan:
          commonTrackingProperties?.atEventBillingPlan || null,
        atEventBillingCycle:
          commonTrackingProperties?.atEventBillingCycle || null,
        atEventBillingGateway:
          commonTrackingProperties?.atEventBillingGateway || null,
        atEventIsNewBuffer:
          commonTrackingProperties?.atEventIsNewBuffer || null,
        atEventChannelQty: commonTrackingProperties?.atEventChannelQty || null,
      })
    }
  }
  const trackChannelSuggestionViewed = (): void => {
    // Disable CTA viewed tracking for now - see https://buffer.atlassian.net/browse/GROWTH-1081
    // if (organizationId && reachedChannelLimit && isFree) {
    //   BufferTracker.cTAViewed({
    //     organizationId,
    //     upgradePathName: 'addChannel-upgrade',
    //     cta: 'publish-sidebar-channels-addChannelSuggestions-1',
    //     product: 'publish',
    //     atEventBillingState:
    //       commonTrackingProperties?.atEventBillingState || null,
    //     atEventBillingPlan:
    //       commonTrackingProperties?.atEventBillingPlan || null,
    //     atEventBillingCycle:
    //       commonTrackingProperties?.atEventBillingCycle || null,
    //     atEventBillingGateway:
    //       commonTrackingProperties?.atEventBillingGateway || null,
    //     atEventIsNewBuffer:
    //       commonTrackingProperties?.atEventIsNewBuffer || null,
    //     atEventChannelQty: commonTrackingProperties?.atEventChannelQty || null,
    //   })
    // }
  }
  return { trackChannelSuggestionViewed, trackChannelSuggestionClicked }
}

export const ChannelSuggestions = ({
  filteredProfiles,
  profiles,
  shouldShowChannelSuggestions,
  reachedChannelLimit,
}: {
  filteredProfiles: Profile[]
  profiles: Profile[]
  shouldShowChannelSuggestions: boolean
  reachedChannelLimit?: boolean
}): JSX.Element => {
  const [showAll, setShowAll] = React.useState(false)
  let connectableChannelsToShow = connectableChannels.filter((channel) => {
    return !profiles.some((profile) => profile.service === channel.service)
  })

  if (!showAll) {
    connectableChannelsToShow = connectableChannels.slice(
      0,
      5 - profiles.length,
    )
  }

  // Tracking
  const { trackChannelSuggestionViewed, trackChannelSuggestionClicked } =
    useTracking({ reachedChannelLimit: !!reachedChannelLimit })

  function onChannelClick(channelOption: ChannelOption): void {
    trackChannelSuggestionClicked()

    // Shopify and startPages cannpt currently use the new channel connection functionality
    if (LEGACY_CONNECTION_SERVICES.includes(channelOption.service)) {
      handleLegacyConnection(channelOption)
    } else {
      window.appshell.actions.connectChannel({
        selectedService: channelOption.service as Parameters<
          typeof window.appshell.actions.connectChannel
        >[0]['selectedService'],
        cta: `publish-sidebar-channels-addChannelSuggestions-1`,
      })
    }
  }

  useEffect(() => {
    if (shouldShowChannelSuggestions) {
      trackChannelSuggestionViewed()
    }
  }, [shouldShowChannelSuggestions, trackChannelSuggestionViewed])

  return shouldShowChannelSuggestions ? (
    <Styled.Container>
      {connectableChannelsToShow.map((channel): JSX.Element | null => {
        return (
          <Styled.ConnectableChannelButton
            key={channel.service}
            hoverIconColor={channel.color}
            href={channel.url}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => {
              e.preventDefault()
              onChannelClick(channel)
            }}
          >
            <Styled.IconWrapper>{channel.icon}</Styled.IconWrapper>
            <Styled.Label>{channel.name}</Styled.Label>
          </Styled.ConnectableChannelButton>
        )
      })}
      {showAll ? (
        <Styled.ShowAllChannelsButton
          onClick={(): void => setShowAll(false)}
          data-testid="showAll"
        >
          <CaretUpIcon size="medium" /> Show less
        </Styled.ShowAllChannelsButton>
      ) : (
        <Styled.ShowAllChannelsButton
          onClick={(): void => setShowAll(true)}
          data-testid="showAll"
        >
          <CaretDownIcon size="medium" /> Show all channels
        </Styled.ShowAllChannelsButton>
      )}
    </Styled.Container>
  ) : (
    <>{!filteredProfiles.length && !profiles.length && EmptyProfileList()}</>
  )
}
