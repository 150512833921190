import { white } from '@buffer-mono/legacy-bufferapp-components'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import PencilIcon from '@bufferapp/ui/Icon/Icons/Pencil'
import PlayIconBDS from '@bufferapp/ui/Icon/Icons/Play'
import { grayDarker } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const DeleteIcon = styled(CrossIcon)`
  position: absolute;
  cursor: pointer;
  fill: ${white};
  background-color: ${grayDarker};
  padding: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: -6px;
  right: -4px;
  border: 2px solid #fff !important;
`

export const EditIcon = styled(PencilIcon)`
  position: absolute;
  cursor: pointer;
  fill: ${white};
  background-color: ${grayDarker};
  padding: 2px;
  bottom: 8px;
  left: 8px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
`

export const PlayIcon = styled(PlayIconBDS)`
  position: absolute;
  cursor: pointer;
  fill: ${white};
  background-color: rgb(245 245 245 / 38%);
  padding: 6px;
  bottom: 8px;
  left: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

export const ThumbnailOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0.239, 0.239, 0.239, 0.15);
  border-radius: 4px;
  pointer-events: none;
  top: 0;
  left: 0;
`
