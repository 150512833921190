import { graphql } from '~publish/gql'
import { useQuery, type ApolloError } from '@apollo/client'
import type {
  GetFeedDetailsPageQuery,
  GetFeedDetailsPageQueryVariables,
} from '~publish/gql/graphql'
import { useFeedUpdates } from './useFeedUpdates'

export type FeedGroupForDetails = GetFeedDetailsPageQuery['feedGroup']

export const GetFeedDetailsPage = graphql(/* GraphQL */ `
  query GetFeedDetailsPage($feedGroupId: FeedGroupId!) {
    feedGroup(input: { id: $feedGroupId }) {
      id
      name
      feeds {
        id
        name
        connectionMetadata {
          ... on RSSFeedConnectionMetadata {
            url
          }
        }
      }
    }
  }
`)

type UseFeedGroupResult = {
  loading: boolean
  error?: ApolloError
  feedGroup: FeedGroupForDetails | null
}

export const useFeedGroup = (id: string): UseFeedGroupResult => {
  const { data, loading, error, refetch } = useQuery<
    GetFeedDetailsPageQuery,
    GetFeedDetailsPageQueryVariables
  >(GetFeedDetailsPage, {
    variables: {
      feedGroupId: id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  useFeedUpdates(
    'feedGroupUpdated',
    ({ feedGroup }) => {
      if (feedGroup.id === id) {
        refetch()
      }
    },
    [id, refetch],
  )

  return {
    loading,
    error,
    feedGroup: data?.feedGroup ?? null,
  }
}
