import React from 'react'

import {
  Badge,
  CommentSquare1StIcon,
  Flex,
  MapPinIcon,
  ThreadIcon,
  Tooltip,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import {
  useMetadataFirstComment,
  useMetadataGeolocation,
  useMetadataThread,
  useMetadataThreadCount,
  usePostData,
} from '~publish/components/PostCard/PostCardContext'

import styles from './PostCardAddons.module.css'

export const PostCardAddons = (): JSX.Element | null => {
  const { metadata } = usePostData()

  const threadCount = useMetadataThreadCount(metadata)
  const thread = useMetadataThread(metadata)
  const firstComment = useMetadataFirstComment(metadata)
  const geolocation = useMetadataGeolocation(metadata)

  return (
    <Flex direction="row" gap="xs">
      {threadCount > 1 && thread?.[1]?.text && (
        <Tooltip
          content={
            <span
              className={styles.tooltip}
            >{`Thread (${threadCount} posts) ${thread[1].text}`}</span>
          }
        >
          <Flex data-testid="addon-icons-x" gap="2xs">
            <ThreadIcon />
            <Badge size="xsmall">
              {threadCount}
              <VisuallyHidden>thread posts</VisuallyHidden>
            </Badge>
          </Flex>
        </Tooltip>
      )}
      {firstComment && (
        <Tooltip content={`First comment: ${firstComment}`}>
          <CommentSquare1StIcon
            data-testid="first-comment-icon"
            aria-label={`First comment: ${firstComment}`}
          />
        </Tooltip>
      )}
      {geolocation && (
        <Tooltip content={`Location: ${geolocation?.text}`}>
          <MapPinIcon
            data-testid="map-icon"
            aria-label={`Location: ${geolocation?.text}`}
          />
        </Tooltip>
      )}
    </Flex>
  )
}
