import React from 'react'
import { Button } from '@buffer-mono/popcorn'
import { type FeedGroupForDetails, useUrlOrigin } from '../../hooks'
import styles from './ContentFeed.module.css'

type FeedNameProps = {
  feed: FeedGroupForDetails['feeds'][number]
}

export const FeedName = (props: FeedNameProps): JSX.Element => {
  const { feed } = props

  // Use root domain rather than RSS feed, sicne it is typically xml
  // and therefore does not contain common metadata
  const [origin] = useUrlOrigin(feed.connectionMetadata.url)
  return (
    <Button
      key={feed.id}
      as="a"
      variant="secondary"
      size="small"
      href={feed.connectionMetadata.url}
      target="_blank"
      rel="noreferrer noopener"
    >
      {origin && (
        <img
          src={`http://www.google.com/s2/favicons?domain=${origin}`}
          alt={`${feed.name} icon`}
          className={styles.feedNameFavicon}
        />
      )}
      {feed.name}
    </Button>
  )
}
