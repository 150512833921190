import {
  useOrganizationBilling,
  useOrganizationLimits,
} from '~publish/legacy/accountContext'
import { useAppSelector } from '~publish/legacy/store'

const START_SHOWING_AT = 3

function useQueueLimitBar(): {
  postLimit: number
  numberOfScheduledPosts: number
  shouldShow: boolean
} {
  const numberOfScheduledPosts = useAppSelector(
    (state) =>
      state.profileNav.pendingCount + state.profileNav.draftsScheduledCount,
  )

  const { scheduledPosts: postLimit } = useOrganizationLimits()
  const { subscription } = useOrganizationBilling()
  const isFree = subscription?.plan?.id === 'free'
  const shouldShow = isFree && numberOfScheduledPosts >= START_SHOWING_AT

  return {
    numberOfScheduledPosts,
    postLimit: postLimit ?? 10,
    shouldShow,
  }
}

export default useQueueLimitBar
