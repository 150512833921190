import { useQueryParam } from '~publish/hooks/useQueryParam'
import { useTags, type UseTagsQueryReturn } from './useTags'

export function useSelectedTags(
  queryParamName = 'tags',
): UseTagsQueryReturn['tags'] {
  const [selectedTagIds] = useQueryParam<string[]>(queryParamName)
  const { tags } = useTags()

  return tags.filter((tag) => selectedTagIds?.includes(tag.id))
}
