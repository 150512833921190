import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { push } from 'redux-first-history'
import ArrowRightIcon from '@bufferapp/ui/Icon/Icons/ArrowRight'
import CaretLeftIcon from '@bufferapp/ui/Icon/Icons/CaretLeft'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'
import { ButtonWithSkeleton } from '~publish/legacy/shared-components'
import { useTranslation } from 'react-i18next'
import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'

import {
  ButtonWrapper,
  CampaignDetails,
  CampaignDetailsWrapper,
  Breadcrumb,
  BreadcrumbLink,
  Color,
  Container,
  Title,
  Name,
} from './style'
import { SEGMENT_NAMES, TAGS_REPORT_PAYWALL_DATA } from '../../../../constants'
import { useAccount, useOrganizationBilling } from '../../../../accountContext'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import type { Campaign, LeanTag } from '~publish/legacy/campaign/types'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectCanViewTagReport } from '~publish/legacy/campaign/selectors'
import { PlusIcon } from '@buffer-mono/popcorn'

const ButtonWithSkeletonStyled = styled(ButtonWithSkeleton)`
  margin-left: 10px;
`

type SelectedItemProps = {
  title: string
  selectedItemClick: (campaign: Campaign) => void
}

type HeaderProps = {
  campaignDetails: Campaign
  hideAnalyzeReport: boolean
  showCampaignActions: boolean
  onCreatePostClick: (campaignId: string) => void
  onDeleteCampaignClick: (campaign: Campaign) => void
  onEditCampaignClick: (tag: LeanTag) => void
  goToAnalyzeReport: (campaign: Campaign) => void
  isLoading: boolean
}

const Header = ({
  campaignDetails,
  hideAnalyzeReport,
  showCampaignActions,
  onCreatePostClick,
  onDeleteCampaignClick,
  onEditCampaignClick,
  goToAnalyzeReport,
  isLoading,
}: HeaderProps): JSX.Element => {
  const { t } = useTranslation()
  const { account } = useAccount()
  const canViewTagReport = useAppSelector((state) =>
    selectCanViewTagReport(state),
  )
  const dispatch = useAppDispatch()
  const currentOrganization = account?.currentOrganization
  const billing = useOrganizationBilling()
  const isFree = billing?.subscription?.plan?.id === 'free'
  const commonTrackingProps =
    currentOrganization?.commonTrackingProperties || null

  const canStartTrial = billing?.canStartTrial
  const upgradePathName = canStartTrial
    ? 'tagsReport-trial'
    : 'tagsReport-upgrade'

  const cta = canStartTrial
    ? SEGMENT_NAMES.TAGS_REPORT_PRO_TRIAL
    : SEGMENT_NAMES.TAGS_REPORT_PRO_UPGRADE

  // Disable CTA viewed tracking for now - see https://buffer.atlassian.net/browse/GROWTH-1081
  // const trackCTAViewed = (): void => {
  //   BufferTracker.cTAViewed({
  //     organizationId: currentOrganization?.id || null,
  //     cta,
  //     upgradePathName,
  //     product: 'publish',
  //     ...commonTrackingProps,
  //   })
  // }

  // useEffect(() => {
  //   if (currentOrganization && isFree) {
  //     trackCTAViewed()
  //   }
  // }, [currentOrganization])

  useEffect(() => {
    if (campaignDetails && campaignDetails.id) {
      dispatch(
        dataFetchActions.fetch({
          name: 'canViewReport',
          args: {
            tagId: campaignDetails.id,
          },
        }),
      )
    }
  }, [campaignDetails.id])

  const trackCTAClicked = (): void => {
    BufferTracker.cTAClicked({
      organizationId: currentOrganization?.id || undefined,
      clientName: 'publishWeb',
      cta,
      upgradePathName,
      product: 'publish',
      ...commonTrackingProps,
    })
  }

  const onViewReportClick = (): void => {
    if (isFree) {
      const { MODALS, actions } = window?.appshell || {}
      if (actions && MODALS) {
        actions.openModal(MODALS.featurePaywall, TAGS_REPORT_PAYWALL_DATA)
        trackCTAClicked()
      }
    } else {
      goToAnalyzeReport(campaignDetails)
    }
  }

  return (
    <Container aria-label="Campaign Details" $tagsLayout>
      <Breadcrumb aria-label="Breadcrumb">
        <ol>
          <li>
            <BreadcrumbLink
              type="text"
              size="small"
              icon={<CaretLeftIcon />}
              onClick={(): void => {
                dispatch(push('/tags'))
              }}
              label={t('campaigns.viewCampaign.backToTags')}
            />
          </li>
        </ol>
      </Breadcrumb>
      <CampaignDetailsWrapper>
        <CampaignDetails>
          <Title>
            <Color color={campaignDetails?.color} displaySkeleton={isLoading} />
            <Name type="h2" displaySkeleton={isLoading} aria-busy={isLoading}>
              {campaignDetails?.name || 'Tag name'}
            </Name>
          </Title>
        </CampaignDetails>
        <ButtonWrapper>
          <ButtonWithSkeletonStyled
            onClick={(): void => onCreatePostClick(campaignDetails?.id)}
            type="secondary"
            isSplit={showCampaignActions}
            icon={<PlusIcon />}
            label={t('campaigns.viewCampaign.createPost')}
            disabled={isLoading}
            displaySkeleton={isLoading}
            onSelectClick={(selectedItem: SelectedItemProps): void =>
              selectedItem.selectedItemClick(campaignDetails)
            }
            items={[
              {
                title: t('campaigns.viewCampaign.createPost'),
                selectedItemClick: onCreatePostClick,
              },
              {
                title: t('campaigns.viewCampaign.editTag'),
                selectedItemClick: (campaign: Campaign): void =>
                  onEditCampaignClick({
                    id: campaign.id,
                    name: campaign.name,
                    color: campaign.color,
                  }),
              },
              {
                title: t('campaigns.viewCampaign.deleteTag'),
                selectedItemClick: (campaign: Campaign): void =>
                  onDeleteCampaignClick(campaign),
              },
            ]}
          />
          {!hideAnalyzeReport && (
            <ButtonWithSkeletonStyled
              type="primary"
              icon={isFree ? <FlashIcon /> : <ArrowRightIcon />}
              iconEnd={!isFree}
              onClick={(): void => {
                onViewReportClick()
              }}
              displaySkeleton={isLoading}
              disabled={isLoading || !canViewTagReport}
              label={t('campaigns.viewCampaign.viewReport')}
            />
          )}
        </ButtonWrapper>
      </CampaignDetailsWrapper>
    </Container>
  )
}

Header.propTypes = {
  campaignDetails: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    dateRange: PropTypes.string,
    scheduled: PropTypes.number,
    sent: PropTypes.number,
    lastUpdated: PropTypes.string,
  }),
  hideAnalyzeReport: PropTypes.bool.isRequired,
  showCampaignActions: PropTypes.bool.isRequired,
  onCreatePostClick: PropTypes.func.isRequired,
  onDeleteCampaignClick: PropTypes.func.isRequired,
  onEditCampaignClick: PropTypes.func.isRequired,
  goToAnalyzeReport: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

Header.defaultProps = {
  isLoading: false,
  campaignDetails: {},
}

export default Header
