import React, { type PropsWithChildren } from 'react'
import { useAppDispatch } from '~publish/legacy/store'
import { getQueueResizedImageUrl } from '~publish/legacy/utils/bufferImages'
import { escapeParens } from '~publish/legacy/composer/composer/utils/StringUtils'

import {
  Text,
  Tooltip,
  UnstyledButton,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import { openUploadcareModal } from '~publish/legacy/uploadcare/openUploadcareModal'
import { DeleteIcon, EditIcon, PlayIcon, ThumbnailOverlay } from './styles'

import { addPermanentNotification } from '~publish/legacy/notifications-provider/notificationsSlice'
import {
  UploadcareIntegrations,
  UploadcareSources,
} from '~publish/legacy/uploadcare/constants'
import { useIsIntegrationUploading } from '~publish/legacy/integrations-bar/state/useIsIntegrationUploading'
import { Upload } from '@buffer-mono/uploader'
import type { Uploader } from '@buffer-mono/uploader'
import { CanvaEditTag } from './CanvaEditTag'
import { white } from '@bufferapp/ui/style/colors'
import styles from './Thumbnail.module.css'
import clsx from 'clsx'
import { uploadRemoved } from '../state/slice'

// TODO: before enabling this we need to validate:
// - that it's OK to keep the image in the uploads (we could replace it instead)
// - review the crop presets provied for ideas in getUploadCareEditOptions
// - that 'ideas' is allowed in the tracking plan (and update openUploadcareModal)
const IS_EDIT_ENABLED = false

const ThumbnailTooltip = ({
  children,
  text,
}: PropsWithChildren<{
  text: string
}>): JSX.Element => {
  const tooltipContent = (
    <Text size="sm" color="inverted">
      {text}
    </Text>
  )

  return (
    <>
      <Tooltip content={tooltipContent} arrow={true}>
        {children}
      </Tooltip>
    </>
  )
}
export const Thumbnail = ({
  upload,
  openPreview,
  uploader,
}: {
  upload: Upload
  openPreview: (upload: Upload | null) => void
  uploader: Uploader
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const { onUploadStarted, onUploadFinished } = useIsIntegrationUploading()

  const tooltipText = Upload.canAddDescription(upload)
    ? 'Preview and add description'
    : 'Preview'

  const onError = ({ message }: { message: string }): void => {
    dispatch(addPermanentNotification(message))
  }

  const onThumbnailClick = (): void => {
    openPreview(upload)
  }

  const onDeleteClick = (): void => {
    uploader.removeUpload(upload)

    // REVIEW: this should be handled by the uploader
    dispatch(uploadRemoved(upload.id))
  }

  const onEditClick = (): void => {
    openUploadcareModal(
      'ideas',
      uploader,
      onError,
      UploadcareIntegrations.EDIT,
      onUploadStarted,
      onUploadFinished,
      UploadcareSources.EDIT,
      {
        url: upload.url,
      },
      undefined,
      'ideasEditor',
    )
  }

  const onKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      openPreview(upload)
    }
  }

  return (
    <ThumbnailTooltip text={tooltipText}>
      <div
        data-testid="media-manager-thumbnail"
        className={clsx(styles.thumbnail)}
      >
        <UnstyledButton onClick={onDeleteClick}>
          <DeleteIcon color={white} />
        </UnstyledButton>
        {Upload.isVideo(upload) && <ThumbnailOverlay />}
        {Upload.isDocumentUpload(upload) ? (
          <img
            alt={upload.alt || upload.name}
            src={getQueueResizedImageUrl(escapeParens(upload.thumbnailUrl))}
            crossOrigin={'anonymous'}
            onClick={onThumbnailClick}
            role="presentation"
          />
        ) : (
          <img
            src={Upload.getThumbnailUrl(upload)}
            alt={upload.alt || upload.name}
            onClick={onThumbnailClick}
            role="presentation"
          />
        )}
        <CanvaEditTag upload={upload} uploader={uploader} />
        {IS_EDIT_ENABLED && Upload.isImage(upload) && (
          <EditIcon onClick={onEditClick} />
        )}
        {Upload.isVideo(upload) && <PlayIcon />}
        <VisuallyHidden
          as="button"
          onClick={onThumbnailClick}
          onKeyDown={onKeyDown}
        />
      </div>
    </ThumbnailTooltip>
  )
}
