import { graphql } from '~publish/gql'
import { type ApolloError, useQuery } from '@apollo/client'
import { useOrganizationId } from '~publish/legacy/accountContext'
import type {
  GetFeedGroupsListForSidebarQueryVariables,
  GetFeedGroupsListForSidebarQuery,
} from '~publish/gql/graphql'
import { useFeedUpdates } from './useFeedUpdates'

export type Query = GetFeedGroupsListForSidebarQuery
export type QueryVariables = GetFeedGroupsListForSidebarQueryVariables

export const GetFeedGroupsListForSidebar = graphql(/* GraphQL */ `
  query GetFeedGroupsListForSidebar(
    $feedGroupLimit: Int!
    $feedGroupCursor: String
    $organizationId: OrganizationId!
  ) {
    feedGroups(
      first: $feedGroupLimit
      after: $feedGroupCursor
      input: { organizationId: $organizationId }
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

export type FeedGroup = NonNullable<
  GetFeedGroupsListForSidebarQuery['feedGroups']['edges']
>[number]['node']

type UseFeedGroupsResult = {
  items: FeedGroup[]
  loading: boolean
  error?: ApolloError
}

export function useFeedGroups(): UseFeedGroupsResult {
  const organizationId = useOrganizationId()

  const variables = {
    feedGroupLimit: 100,
    organizationId,
  }

  const { data, loading, error, refetch } = useQuery<Query, QueryVariables>(
    GetFeedGroupsListForSidebar,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  )

  useFeedUpdates('feedGroupCreated', () => refetch(), [refetch])
  useFeedUpdates('feedGroupDeleted', () => refetch(), [refetch])

  const items = data?.feedGroups?.edges?.map((edge) => edge.node) ?? []

  return {
    items,
    loading,
    error,
  }
}
