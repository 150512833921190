import React from 'react'

import {
  Avatar,
  Button,
  Flex,
  Heading,
  Input,
  Label,
  Link,
  Paragraph,
  Separator,
  Switch,
  Text,
} from '@buffer-mono/popcorn'

import SettingsPageLayout from './components/SettingsPageLayout'
import style from './ProfileSettings.module.css'

const Photo = (): JSX.Element => {
  return (
    <Flex gap="sm" align="center" className={style.fullWidth}>
      <Avatar
        size="medium"
        alt="Account photo"
        // src
      />
      <div className={style.photoDescription}>
        <Heading size="small" as="h2">
          Photo
        </Heading>
        <Paragraph color="subtle">
          At least 180x180 pixels, less than 4 MB
        </Paragraph>
      </div>
      <div>
        <Button size="medium" variant="secondary">
          Upload Photo
        </Button>
      </div>
    </Flex>
  )
}

const Name = (): JSX.Element => {
  return (
    <div className={style.fullWidth}>
      <Label htmlFor="name">Name</Label>
      <Flex gap="xs" align="center" className={style.fullWidth}>
        <Input id="name" placeholder="Your name" className={style.flexInput} />
        <Button size="medium" variant="secondary" disabled>
          Save Changes
        </Button>
      </Flex>
    </div>
  )
}

const Email = (): JSX.Element => {
  return (
    <div className={style.fullWidth}>
      <Label htmlFor="email">Email</Label>
      <Flex gap="xs" align="center" className={style.fullWidth}>
        <Input id="email" className={style.flexInput} />
        <Button size="medium" variant="secondary" disabled>
          Save Changes
        </Button>
      </Flex>
    </div>
  )
}

const Password = (): JSX.Element => {
  return (
    <div className={style.fullWidth}>
      <Label htmlFor="password">Password</Label>
      <Flex gap="xs" align="center" className={style.fullWidth}>
        <Input id="password" className={style.flexInput} type="password" />
        <Button size="medium" variant="secondary" disabled>
          Change Password
        </Button>
      </Flex>
    </div>
  )
}

const TwoFactor = (): JSX.Element => {
  return (
    <Flex as="section" gap="xs" align="start" className={style.fullWidth}>
      <div>
        <Heading
          id="two-factor-heading"
          as="h2"
          size="small"
          style={{
            marginBottom: 'var(--space-xs)',
          }}
        >
          Two Factor Authentication
        </Heading>
        <Paragraph color="subtle">
          Two factor authentication adds an extra layer of security for your
          Buffer account. Whenever you log in to your account, after entering
          your username and password, you will be asked for a second
          authentication code that was sent to your mobile phone via text or a
          free mobile app.{' '}
          <Link
            external
            href="https://support.buffer.com/article/503-enabling-two-factor-authentication?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
          >
            Learn more
          </Link>
        </Paragraph>
      </div>
      <div>
        <Switch disabled id="two-factor" aria-labelledby="two-factor-heading" />
      </div>
    </Flex>
  )
}

const Delete = (): JSX.Element => {
  return (
    <Flex
      as="section"
      gap="xs"
      align="start"
      className={style.fullWidth}
      justify="between"
    >
      <div>
        <Heading
          as="h2"
          size="small"
          style={{
            marginBottom: 'var(--space-xs)',
          }}
        >
          Delete your account
        </Heading>
        <Text color="subtle">
          When you delete your account, you lose access to Buffer account
          services, and we permanently delete your personal data.
        </Text>
      </div>
      <div className={style.deleteButtonWrapper}>
        <Button size="medium" variant="critical">
          Delete Account
        </Button>
      </div>
    </Flex>
  )
}

export const ProfileSettings = (): JSX.Element => {
  return (
    <SettingsPageLayout>
      <section>
        <Flex gap="xl" direction="column">
          <Heading as="h1" size="large">
            Profile
          </Heading>
          <Photo />
          <Name />
          <Email />
          <Password />
        </Flex>
      </section>
      <Separator />
      <TwoFactor />
      <Separator />
      <Delete />
    </SettingsPageLayout>
  )
}

export default ProfileSettings
