import { useEffect } from 'react'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store/index'
import { selectUserId } from '~publish/legacy/user/selectors'
import { useUploader } from '@buffer-mono/uploader'
import { bindUploaderToReduxSlice } from '../state/bindUploaderToReduxSlice'
import BufferUploadsUppyPlugin from '../uppy/BufferUploadsUppyPlugin'
import GenerateThumbnailsUppyPlugin from '../uppy/GenerateThumbnailsUppyPlugin'

export default function usePublishUploader(
  props: Omit<Parameters<typeof useUploader>[0], 'organizationId' | 'userId'>,
): ReturnType<typeof useUploader> {
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const organizationId = useAppSelector(selectCurrentOrganizationId)

  const uploader = useUploader({
    organizationId,
    userId,
    ...props,
  })

  useEffect(() => {
    bindUploaderToReduxSlice(uploader, { dispatch })
  }, [uploader, dispatch])

  useEffect(() => {
    uploader.uppyInstance
      .use(BufferUploadsUppyPlugin, {
        onComplete: (files) => {
          uploader.emit('upload-finished', files)
        },
        onError: (file, error) => {
          uploader.emit('upload-failed', file, error)
        },
      })
      .use(GenerateThumbnailsUppyPlugin, {
        onError: (file, error) => {
          uploader.emit('upload-failed', file, error)
        },
      })
  }, [uploader])

  return uploader
}
