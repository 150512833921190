import { useCallback, useMemo, useState } from 'react'
import { type Uploader, UploadSource } from '@buffer-mono/uploader'

type DropTargetProps = {
  isDragging: boolean
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  handleDragOver: (event) => void
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  handleDragLeave: (event) => void
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  handleDrop: (event) => void
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  handleClick: (event) => void
}

// @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
const isFileTransfer = (event): boolean =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  event.dataTransfer.types?.some((type) => type === 'Files') ?? false

// TODO: Remove as part of https://buffer.atlassian.net/browse/CT-501
/** @deprecated */
export const useDropTarget = ({
  uploader,
}: {
  uploader: Uploader
}): DropTargetProps => {
  const [isDragging, setIsDragging] = useState(false)
  const handleDragOver = useCallback((event: React.DragEvent) => {
    if (!isFileTransfer(event)) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    // Add a small (+) icon on drop
    // (and prevent browsers from interpreting this as files being _moved_ into the browser,
    // https://github.com/transloadit/uppy/issues/1978)
    event.dataTransfer.dropEffect = 'copy' // eslint-disable-line no-param-reassign

    setIsDragging(true)
  }, [])

  const handleDragLeave = useCallback((event: React.DragEvent) => {
    if (!isFileTransfer(event)) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    setIsDragging(false)
  }, [])

  const addFiles = useCallback(
    (files: File[], source: UploadSource) => {
      if (files.length > 0) {
        uploader.addFiles(files, {
          source,
        })
      }
    },
    [uploader],
  )

  const handleDrop = useCallback(
    (event: React.DragEvent) => {
      if (!isFileTransfer(event)) {
        return
      }

      event.preventDefault()
      event.stopPropagation()

      // Remove dragover class
      setIsDragging(false)

      // @TODO: consider using getDroppedFiles approach
      // @see https://github.com/transloadit/uppy/tree/main/packages/%40uppy/utils/src/getDroppedFiles
      const files: File[] = Array.from(event.dataTransfer.files)
      addFiles(files, UploadSource.dragAndDrop())
    },
    [addFiles],
  )

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      // @TODO: consider using getDroppedFiles approach
      // @see https://github.com/transloadit/uppy/tree/main/packages/%40uppy/utils/src/getDroppedFiles
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error TS(2339) FIXME: Property 'files' does not exist on type 'EventTarget'
      const files: File[] = Array.from(event.target.files)
      addFiles(files, UploadSource.filePicker())
    },
    [addFiles],
  )

  return useMemo(() => {
    return {
      isDragging,
      handleDragOver,
      handleDragLeave,
      handleDrop,
      handleClick,
    }
  }, [isDragging, handleDragOver, handleDragLeave, handleDrop, handleClick])
}
