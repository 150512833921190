import React, { useEffect, useState } from 'react'

import {
  Button,
  Dialog,
  Flex,
  Input,
  Label,
  Link,
  Notice,
  SwatchGroup,
  Text,
  useControllableState,
} from '@buffer-mono/popcorn'

import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import type { Tag } from '~publish/gql/graphql'
import { useTags } from '~publish/hooks/useTags'

import { colors, selectColor } from './colors'

import styles from './TagEditDialog.module.css'

export interface TagEditDialogProps {
  children?: React.ReactNode
  tag?: Tag | { name: string; color?: string }
  onSubmit: (tag: Tag) => void | Promise<void>
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

interface FormState {
  name: string
  color: string
  errorMessage: string | null
  saving: boolean
}

export function TagEditDialog({
  tag,
  onSubmit,
  onOpenChange: onOpenChangeProp,
  open: openProp,
  children,
}: TagEditDialogProps): JSX.Element {
  // State
  const [open, onOpenChange] = useControllableState({
    prop: openProp,
    onChange: onOpenChangeProp,
  })
  const { tags } = useTags()
  const editMode = !!(tag as Tag)?.id

  const [formState, setFormState] = useState<FormState>({
    name: tag?.name ?? '',
    color: tag?.color ?? selectColor(tags.map((t) => t.color)),
    errorMessage: null,
    saving: false,
  })

  // Effects
  useEffect(() => {
    if (tag) {
      setFormState((prev) => ({
        ...prev,
        name: tag.name ?? '',
        color: tag.color ?? prev.color,
      }))
    }
  }, [tag])

  useEffect(() => {
    if (!open) {
      setFormState((prev) => ({
        ...prev,
        name: '',
        errorMessage: null,
        color: tag?.color ?? selectColor(tags.map((t) => t.color)),
      }))
    }
  }, [open, tags, tag])

  useEffect(() => {
    const error = validateTagName(formState.name, tags, editMode)
    setFormState((prev) => ({ ...prev, errorMessage: error }))
  }, [formState.name, tags, editMode])

  // Handlers

  const handleSubmit = async (
    evt: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    evt.preventDefault()

    const error = validateTagName(formState.name, tags, editMode, {
      submitting: true,
    })
    setFormState((prev) => ({ ...prev, errorMessage: error }))
    if (formState.errorMessage || error) return

    const isUnchanged =
      tag?.name === formState.name && tag?.color === formState.color && editMode

    if (isUnchanged) {
      onOpenChange(false)
      return
    }

    setFormState((prev) => ({ ...prev, saving: true }))

    try {
      await onSubmit({
        ...tag,
        name: formState.name,
        color: formState.color,
      } as Tag)
      onOpenChange(false)
    } finally {
      setFormState((prev) => ({ ...prev, saving: false }))
    }
  }

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      {children && <Dialog.Trigger>{children}</Dialog.Trigger>}
      <Dialog.Content size="medium">
        <Dialog.Header>
          <Dialog.Title id="tag-form-title">
            {editMode ? 'Edit Tag' : 'New Tag'}
          </Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <form onSubmit={handleSubmit}>
            <Notice>
              Tags are visible to everyone in your organization.{' '}
              <Link
                href={`https://support.buffer.com/article/585-creating-and-managing-campaigns?${HC_UTM_PARAMS}`}
              >
                Learn more
              </Link>
            </Notice>

            {/* Name Input */}
            <Label htmlFor="tagName" className={styles.label}>
              Name
            </Label>
            <Input
              id="tagName"
              value={formState.name}
              aria-required={true}
              onChange={(evt): void =>
                setFormState((prev) => ({ ...prev, name: evt.target.value }))
              }
              aria-invalid={!!formState.errorMessage}
              aria-describedby={
                formState.errorMessage ? 'tagNameError' : undefined
              }
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            {formState.errorMessage && (
              <Text
                id="tagNameError"
                color="critical"
                role="alert"
                className={styles.errorLabel}
              >
                {formState.errorMessage}
              </Text>
            )}

            {/* Color Picker */}
            <Label htmlFor="colorPicker" className={styles.label}>
              Color
            </Label>
            <SwatchGroup
              id="colorPicker"
              onChange={(evt): void =>
                setFormState((prev) => ({ ...prev, color: evt.target.value }))
              }
              className={styles.swatch}
              value={formState.color}
            >
              {colors.map(({ color, name }) => (
                <SwatchGroup.Swatch
                  key={color}
                  color={color}
                  name={name}
                  aria-label={`color ${color}`}
                />
              ))}
            </SwatchGroup>

            {/* Action Buttons */}
            <Flex
              gap="xs"
              justify="start"
              direction="row-reverse"
              className={styles.formButtons}
            >
              <Button
                variant="primary"
                size="large"
                type="submit"
                loading={formState.saving}
              >
                Save Tag
              </Button>
              <Button
                variant="tertiary"
                size="large"
                type="button"
                onClick={(): void => onOpenChange(false)}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog>
  )
}

function validateTagName(
  name: string,
  existingTags: Tag[],
  editMode: boolean,
  { submitting }: { submitting: boolean } = { submitting: false },
): string | null {
  if (!name.trim() && submitting) {
    return 'Name is required.'
  }
  if (name.length > 100) {
    return 'Name must be less than 100 characters.'
  }
  if (
    !editMode &&
    !isUnique(
      name,
      existingTags.map((tag) => tag.name),
    )
  ) {
    return 'This tag already exists.'
  }
  return null
}

function isUnique(name: string, list: string[]): boolean {
  return list.every((item) => {
    return item.toLowerCase() !== name.toLowerCase()
  })
}
