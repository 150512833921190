/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { transform24hrTimeToString } from '../../../utils/TimeStringTransformer'
import TimePicker from './TimePicker'
import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import DatePicker from './DatePicker'
import {
  Label,
  TimeLabel,
  RightFloatWrapper,
  Wrapper,
  FieldWrapper,
} from '../styles'
import { Switch, Label as PopcornLabel } from '@buffer-mono/popcorn'
import type { GoogleBusinessChannelData } from '../../post-preview/previews/types'

dayjs.extend(utc)
dayjs.extend(timezone)

const DateOptionalTimePicker = ({
  data,
  uses24hTime,
  hideTime = false,
}: {
  data: GoogleBusinessChannelData
  uses24hTime: boolean
  hideTime?: boolean
}): JSX.Element | null => {
  const [hasTime, setHasTime] = React.useState(!!data.start_time)

  const shouldRemoveTime = hideTime && hasTime
  const shouldTransformTime = hasTime && !uses24hTime && !hideTime

  React.useEffect(() => {
    if (!hasTime) {
      ComposerActionCreators.updateDraftGoogleBusinessData({
        ...data,
        start_time: null,
        end_time: null,
      })
    }
  }, [hasTime])

  React.useEffect(() => {
    if (shouldRemoveTime) {
      ComposerActionCreators.updateDraftGoogleBusinessData({
        ...data,
        start_time: null,
        end_time: null,
      })
    }
  }, [shouldRemoveTime])

  React.useEffect(() => {
    if (shouldTransformTime && data.start_time && data.end_time) {
      ComposerActionCreators.updateDraftGoogleBusinessData({
        ...data,
        start_time: transform24hrTimeToString(data.start_time, false),
        end_time: transform24hrTimeToString(data.end_time, false),
      })
    }
  }, [shouldTransformTime, data.start_time, data.end_time])

  if (!data.start_date || !data.end_date) {
    return null
  }

  const endDateToRender = dayjs.unix(data.end_date).utc()
  const startDateToRender = dayjs.unix(data.start_date).utc()
  const endTimeToRender = data.end_time
  const startTimeToRender = data.start_time

  const onDateChange = (
    selectedDateTime: dayjs.Dayjs,
    attr: 'start_date' | 'end_date',
  ): void => {
    const updatedData = { ...data }
    updatedData[attr] = selectedDateTime.unix()

    ComposerActionCreators.updateDraftGoogleBusinessData(updatedData)
  }

  const onStartTimeChange = (val: string): void => {
    const updatedData = { ...data }
    updatedData.start_time = val

    ComposerActionCreators.updateDraftGoogleBusinessData(updatedData)
  }

  const onEndTimeChange = (val: string): void => {
    const updatedData = { ...data }
    updatedData.end_time = val

    ComposerActionCreators.updateDraftGoogleBusinessData(updatedData)
  }

  const shouldShowTimePicker = hideTime ? false : hasTime

  return (
    <>
      <RightFloatWrapper
        withBottomMargin={!hideTime}
        removeTopMargin={hideTime}
      >
        {!hideTime && (
          <>
            <Switch
              size="small"
              id="optional_datetime"
              onCheckedChange={(checked): void => {
                setHasTime(checked)

                if (checked === false) {
                  ComposerActionCreators.updateDraftGoogleBusinessData({
                    ...data,
                    start_time: null,
                    end_time: null,
                  })
                }
              }}
              checked={hasTime}
            />
            <PopcornLabel htmlFor="optional_datetime" size="small">
              Add Time
            </PopcornLabel>
          </>
        )}
      </RightFloatWrapper>
      <FieldWrapper>
        <Wrapper className="date-wrapper">
          <Label>Start Date</Label>
          <DatePicker
            inputClassName="date-input"
            dateToRender={startDateToRender}
            onDateChange={(date): void => onDateChange(date, 'start_date')}
            uses24hTime={uses24hTime}
          />
        </Wrapper>
        {shouldShowTimePicker && (
          <Wrapper>
            <TimeLabel>Start Time</TimeLabel>
            <TimePicker
              timeToRender={startTimeToRender}
              onTimeChange={(time): void => onStartTimeChange(time)}
              uses24hTime={uses24hTime}
              placeholder="Start Time"
            />
          </Wrapper>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <Wrapper className="date-wrapper">
          <Label>End Date</Label>
          <DatePicker
            inputClassName="date-input"
            dateToRender={endDateToRender}
            onDateChange={(date): void => onDateChange(date, 'end_date')}
            uses24hTime={uses24hTime}
          />
        </Wrapper>
        {shouldShowTimePicker && (
          <Wrapper>
            <TimeLabel>End Time</TimeLabel>
            <TimePicker
              timeToRender={endTimeToRender}
              onTimeChange={(time): void => onEndTimeChange(time)}
              uses24hTime={uses24hTime}
              placeholder="End Time"
            />
          </Wrapper>
        )}
      </FieldWrapper>
    </>
  )
}

export default DateOptionalTimePicker
