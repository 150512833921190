import type { Service } from '~publish/gql/graphql'
import { getStartPageUrl } from '~publish/legacy/utils/formatters/getURL'

export const useConnectChannel = ({
  cta,
}: {
  cta: string
}): ((service: Service) => void) => {
  const onChannelClick = (service: Service): void => {
    if (service === 'startPage') {
      setTimeout(() => {
        window.location.assign(getStartPageUrl())
      }, 200)
    } else {
      window.appshell.actions.connectChannel({
        selectedService: service as Parameters<
          typeof window.appshell.actions.connectChannel
        >[0]['selectedService'],
        cta,
      })
    }
  }

  return onChannelClick
}
