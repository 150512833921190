import React, { useEffect, useId, useState } from 'react'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import {
  Button,
  Flex,
  Text,
  Paragraph,
  Popover,
  Textarea,
  VisuallyHidden,
  CheckIcon,
  type PopoverContentProps,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'

import { useAccount, useOrganizationId } from '~publish/legacy/accountContext'

import styles from './FeedbackWidget.module.css'

const ANIMATION_TIMEOUT = 3_000

type FeedbackWidgetProps = {
  id: string
  source: string
  children: React.ReactNode
  align?: PopoverContentProps['align']
  animate?: boolean
}

const FeedbackWidget = ({
  id,
  source,
  children,
  align,
  animate = false,
}: FeedbackWidgetProps): JSX.Element | null => {
  const accId = useId()
  const organizationId = useOrganizationId()
  const { account } = useAccount()
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [feedback, setFeedback] = useState<string>('')
  const [error, setError] = useState<string | null>(null)

  const reset = (): void => {
    setFeedback('')
    setError(null)
    setIsSubmitted(false)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    if (!feedback) {
      setError('Feedback is empty, please enter something')
      return
    }

    BufferTracker.feedbackFormSubmitted({
      clientName: 'publishWeb',
      organizationId: organizationId ?? '',
      userId: account.id ?? '',
      widgetId: id,
      source,
      text: feedback,
    })

    setIsSubmitted(true)
  }

  const handleOpen = (open: boolean): void => {
    setIsOpen(open)

    if (!open) {
      reset()
    }
  }

  // upon submitted automatically reset and close the dialog after a timeout
  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        reset()
        setIsOpen(false)
      }, ANIMATION_TIMEOUT)

      return () => {
        reset()
        clearTimeout(timer)
      }
    }
  }, [isSubmitted])

  return (
    <Popover open={isOpen} onOpenChange={handleOpen}>
      <Popover.Trigger
        className={clsx(styles.trigger, animate && styles.triggerAnimated)}
      >
        {children}
      </Popover.Trigger>
      <Popover.Content className={styles.content} align={align}>
        {isSubmitted && (
          <div role="alert" className={styles.overlay}>
            <div className={styles.check} aria-hidden>
              <CheckIcon />
            </div>
            <Paragraph align="center" className={styles.message}>
              Your feedback has been received. <br />
              Thank you!
            </Paragraph>
          </div>
        )}
        <Paragraph id={`${accId}:description`}>
          Thanks for checking out our new feature. We&apos;d love to know what
          you think.
        </Paragraph>
        <form
          className={styles.form}
          aria-labelledby={`${accId}:heading`}
          aria-describedby={`${accId}:description`}
          onSubmit={handleSubmit}
        >
          <div>
            <VisuallyHidden as="label" htmlFor={id}>
              Feedback
            </VisuallyHidden>
            <Textarea
              id={id}
              rows={3}
              resize="none"
              placeholder="Share your feedback"
              maxLength={500}
              aria-required
              aria-describedby={error ? `${accId}:error` : undefined}
              aria-invalid={!!error}
              value={feedback}
              onChange={(event): void => setFeedback(event.target.value)}
            />
            {error && (
              <Text
                color="critical"
                size="sm"
                role="alert"
                id={`${accId}:error`}
              >
                {error}
              </Text>
            )}
          </div>
          <Flex justify="end" gap="xs">
            <Button variant="tertiary" onClick={(): void => handleOpen(false)}>
              Close
            </Button>
            <Button type="submit">Submit</Button>
          </Flex>
        </form>
      </Popover.Content>
    </Popover>
  )
}

export { FeedbackWidget }
export type { FeedbackWidgetProps }
