import { Button, channelColors, ChannelIcon } from '@buffer-mono/popcorn'
import React, { useEffect } from 'react'

import { isFreeUser } from '~publish/helpers/user'
import { useCta } from '~publish/hooks/useCta'
import { useIsAtPlanLimit } from '~publish/hooks/useIsAtPlanLimit'
import { useAccount } from '~publish/legacy/accountContext'

import { SideNavItem } from './SideNavItem'

import type { Service } from '~publish/gql/graphql'
import UserEntity from '~publish/legacy/user/UserEntity'
import { getStartPageUrl } from '~publish/legacy/utils/formatters/getURL'
import styles from './PageSidebar.module.css'
import type { ChannelSuggestion } from './constants'

interface ChannelSuggestionsProps {
  channelSuggestions: ChannelSuggestion[]
}

export const ChannelSuggestions = ({
  channelSuggestions,
}: ChannelSuggestionsProps): JSX.Element => {
  const { account } = useAccount()

  const isAdmin = UserEntity.isAdmin({ account })
  const isFree = isFreeUser(account)
  const isAtPlanLimit = useIsAtPlanLimit()
  const { viewed: ctaViewed, clicked: ctaClicked } = useCta(
    'publish-sidebar-channels-addChannelSuggestions-1',
    {
      upgradePathName: 'addChannel-upgrade',
    },
  )

  useEffect(() => {
    if (isAdmin && channelSuggestions.length > 0 && isAtPlanLimit && isFree) {
      ctaViewed()
    }
  }, [channelSuggestions, ctaViewed, isAdmin, isAtPlanLimit, isFree])

  const onChannelClick = (channelService: Service): void => {
    ctaClicked()

    if (channelService === 'startPage') {
      setTimeout(() => {
        window.location.assign(getStartPageUrl())
      }, 200)
    } else {
      window.appshell.actions.connectChannel({
        selectedService: channelService as Parameters<
          typeof window.appshell.actions.connectChannel
        >[0]['selectedService'],
        cta: `publish-sidebar-channels-addChannelSuggestions-1`,
      })
    }
  }

  if (!isAdmin) {
    return <></>
  }

  return (
    <>
      {channelSuggestions.map(({ name, service }) => (
        <SideNavItem className={styles.suggestion} key={service}>
          <Button
            variant="tertiary"
            size="large"
            // href={`${getAccountURL()}/channels/connect?startConnectionService=${service}`}
            onClick={(): void => onChannelClick(service)}
          >
            <ChannelIcon
              style={
                {
                  '--channel-icon-background-color': channelColors[service],
                } as React.CSSProperties
              }
              className={styles.icon}
              color="branded"
              type={service as React.ComponentProps<typeof ChannelIcon>['type']}
            />
            {name}
          </Button>
        </SideNavItem>
      ))}
    </>
  )
}
