import React, { useEffect, useRef, useState } from 'react'
import { Text, Tooltip, VisuallyHidden } from '@buffer-mono/popcorn'
import { formatDistanceToNowStrict } from 'date-fns'

import { useAccountId } from '~publish/legacy/accountContext'

import { usePostData } from '../PostCard/PostCardContext'

import styles from './PostCardFooter.module.css'

const MAX_EMAIL_LENGTH = 25

const AuthorEmail = ({ email }: { email: string }): JSX.Element => {
  if (!email) {
    return <Text weight="medium">Deleted user</Text>
  }

  if (email.length > MAX_EMAIL_LENGTH) {
    return (
      <>
        <Text weight="medium" aria-hidden>
          {email.slice(0, MAX_EMAIL_LENGTH)}...
        </Text>
        <VisuallyHidden>{email}</VisuallyHidden>
      </>
    )
  }

  return <Text weight="medium">{email}</Text>
}

export function PostCardAuthor(): JSX.Element | null {
  const accountId = useAccountId()
  const ref = useRef<HTMLDivElement>(null)
  const { author, createdAt } = usePostData()
  const [isOverflown, setIsOverflown] = useState(false)

  const isAuthor = author?.id === accountId

  useEffect(() => {
    if (!ref.current) return
    const element = ref.current
    // Check if the element is overflown (aka: has ellipsis)
    setIsOverflown(element.scrollWidth > element.clientWidth)
  }, [])

  const tooltipContent = (
    <>
      <b>{isAuthor ? 'You' : author?.email}</b>
      {createdAt && <> created this {formatDistanceToNowStrict(createdAt)}</>}
    </>
  )

  return (
    <Tooltip content={tooltipContent} delay={1000}>
      <div className={styles.footerCreator} ref={ref}>
        {author && (
          <Text>
            {isAuthor && <Text weight="medium">You</Text>}
            {!isAuthor && !isOverflown && <AuthorEmail email={author.email} />}
            {!isAuthor && isOverflown && (
              <Text weight="medium">{author.email}</Text>
            )}
            {createdAt && (
              <> created this {formatDistanceToNowStrict(createdAt)} ago</>
            )}
          </Text>
        )}
      </div>
    </Tooltip>
  )
}
