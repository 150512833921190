import { ArrowLeftIcon, IconButton, Tabs } from '@buffer-mono/popcorn'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { PageLayout } from '~publish/components/PageLayout'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { GeneralSettings } from '~publish/legacy/general-settings'
import { PostingSchedule } from '~publish/legacy/posting-schedule/'
import { actions as profileSidebarActions } from '~publish/legacy/profile-sidebar/reducer'
import { selectProfileById } from '~publish/legacy/profile-sidebar/selectors'
import { actions as profileActions } from '~publish/legacy/profiles/reducer'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'

type SettingsTab = 'general' | 'posting-schedule'

/**
 * This page integrates legacy components as a temporary solution. We should rewrite this page using GraphQL API and Popcorn
 */
export const ChannelSettings = (): JSX.Element => {
  const { id: channelId } = useParams<{
    id: string
  }>()
  const [tab = 'posting-schedule', setTab] = useQueryParam<SettingsTab>('tab')
  const [loading, setLoading] = React.useState(true)

  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => selectProfileById(state, channelId))

  // TODO: refactor this to use GraphQL API
  // If no profile in Redux for the current channel, fetch it
  React.useEffect(() => {
    if (loading && !profile) {
      dispatch(
        profileActions.fetchSingleProfile({
          profileId: channelId,
          message: '',
        }),
      )
    }
  }, [loading, profile, dispatch, channelId])

  // Once the profile is fetched, trigger profile selection to kick off
  // everything needed for the settings page
  React.useEffect(() => {
    if (loading && profile) {
      dispatch(profileSidebarActions.selectProfile({ profile }))
      setLoading(false)
    }
  }, [dispatch, channelId, profile, loading])

  return (
    <Tabs
      asChild
      value={tab}
      onChange={(selectedTab): void => setTab(selectedTab as SettingsTab)}
    >
      <PageLayout>
        <PageLayout.Header>
          <PageLayout.HeaderRow>
            <PageLayout.Title>
              <IconButton
                as={Link}
                to={`/channels/${channelId}`}
                label="Back to Channel"
                tooltip="Back to Channel"
                variant="tertiary"
              >
                <ArrowLeftIcon />
              </IconButton>
              Settings
            </PageLayout.Title>
          </PageLayout.HeaderRow>
          <PageLayout.HeaderRow>
            <Tabs.TabList>
              <Tabs.Tab value="posting-schedule">Posting Schedule</Tabs.Tab>
              <Tabs.Tab value="general">General</Tabs.Tab>
            </Tabs.TabList>
          </PageLayout.HeaderRow>
        </PageLayout.Header>
        <PageLayout.Container>
          <Tabs.Panel value="posting-schedule">
            <PostingSchedule />
          </Tabs.Panel>
          <Tabs.Panel value="general">
            <GeneralSettings />
          </Tabs.Panel>
        </PageLayout.Container>
      </PageLayout>
    </Tabs>
  )
}
