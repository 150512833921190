import React from 'react'

import { Button, Notice, Paragraph, SparklesIcon } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import { CtaButtonWrapper } from '~publish/components/CtaButtonWrapper'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'

export const PublishRevampBanner = (): JSX.Element | null => {
  const bannerId = 'publishRevampOptInBanner-dec-5'
  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })
  const [isNewPublishEnabled, { setPreference }] = usePublishRevamp()
  const { isEnabled: isBannerSplitEnabled } = useSplitEnabled(
    'publish-revamp-opt-in-banner',
  )

  const handleEnableClick = (): void => {
    setPreference('enabled')
    banner.dismiss()
  }

  const canRenderBanner =
    isBannerSplitEnabled && banner.isActive && !isNewPublishEnabled

  if (!canRenderBanner) {
    return null
  }

  return (
    <Notice variant="info" onDismiss={banner.dismiss} icon={<SparklesIcon />}>
      <Notice.Heading>
        The Publish tab will be getting a new look in January!
      </Notice.Heading>
      <Notice.Text>
        <Paragraph>
          Get early access now and let us know what you think. You can switch
          versions at any time by navigating to your account preferences.
        </Paragraph>
      </Notice.Text>
      <Notice.Actions>
        <CtaButtonWrapper cta="publish-revamp-banner-enable-1">
          <Button onClick={handleEnableClick}>Enable Now</Button>
        </CtaButtonWrapper>
      </Notice.Actions>
    </Notice>
  )
}
