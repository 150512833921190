import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import React from 'react'
import { isOfType } from '~publish/helpers/typeGuards'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { CtaButtonWrapper } from '../../../components/CtaButtonWrapper'
import { RegisteredBanner } from '../../../components/RegisteredBanner'

export const RefreshLinkedInForAnalyticsPromotionalBanner =
  (): JSX.Element | null => {
    const bannerId = 'RefreshLinkedInForAnalyticsPromotionalBanner'
    const selectedChannel = useSelectedChannel()
    // TODO: Channel does not currently support checking for missing scopes
    // Replace this boolean once the feature is available
    const shouldShowLinkedinAnalyticsRefreshBanner =
      isOfType(selectedChannel?.metadata, 'LinkedInMetadata') &&
      selectedChannel.metadata.shouldShowLinkedinAnalyticsRefreshBanner

    const onButtonClick = (): void => {
      window.appshell.actions.connectChannel({
        selectedService: 'linkedin' as Parameters<
          typeof window.appshell.actions.connectChannel
        >[0]['selectedService'],
        cta: `publish-queue-banner-refreshLinkedin-1`,
        isRefreshingConnection: true,
      })
    }

    const canRenderBanner =
      !!selectedChannel &&
      selectedChannel.service === 'linkedin' &&
      selectedChannel.type === 'profile' &&
      shouldShowLinkedinAnalyticsRefreshBanner

    return (
      <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
        <PromotionalBanner>
          <PromotionalBanner.Content>
            <PromotionalBanner.Heading>
              LinkedIn Profile Sent Post Analytics are now available in Buffer
            </PromotionalBanner.Heading>
            <PromotionalBanner.Description>
              Refresh your channel connection to get access to LinkedIn Profile
              Sent Posts Analytics
            </PromotionalBanner.Description>
            <PromotionalBanner.Actions>
              <CtaButtonWrapper
                cta="publish-queue-banner-refreshLinkedin-1"
                options={{ upgradePathName: 'accountChannels-upgrade' }}
              >
                <Button variant="primary" onClick={onButtonClick}>
                  Refresh connection
                </Button>
              </CtaButtonWrapper>
            </PromotionalBanner.Actions>
          </PromotionalBanner.Content>
        </PromotionalBanner>
      </RegisteredBanner>
    )
  }
