import { useState, useEffect, useCallback } from 'react'
import type { Link } from '~publish/legacy/composer/composer/entities/factories'
import { isValidHttpUrl } from '~publish/legacy/composer/composer/utils/StringUtils'

import { extractUrl, hasOnlyUrl } from '../utils'
import { useLinkPreview } from './useLinkPreview'

const isValidUrl = (url: string | null, checkValidUrl: boolean) => {
  if (!url) {
    return false
  }

  return (checkValidUrl && isValidHttpUrl(url)) || !checkValidUrl
}

function useLinkPreviewEffect({
  linkFromPost,
  text,
  checkValidUrl = false,
  compareWithScraper = false,
  useScraperData = true,
  returnFirstUrl = false,
}: {
  linkFromPost?: Link | null
  text?: string
  checkValidUrl?: boolean
  compareWithScraper?: boolean
  useScraperData?: boolean
  returnFirstUrl?: boolean
}): [Link | null, string | null, Link | null] {
  const [
    linkPreview,
    scrapeUrl,
    setLinkPreview,
    setIsDataLoaded,
    isDataLoaded,
    scrapedData,
  ] = useLinkPreview(false)
  const [linkText, setLinkText] = useState<string | null>(null)

  const processLink = useCallback(() => {
    let link: string | null = null

    if (linkFromPost) {
      link = linkFromPost.url
      setLinkPreview(linkFromPost)
    } else if (text) {
      const onlyUrl = hasOnlyUrl(text)
      const url =
        !onlyUrl || useScraperData ? extractUrl(text, returnFirstUrl) : null
      link = url
      if (!url || !useScraperData) {
        setLinkPreview(null)
      }
    } else {
      link = null
      setLinkPreview(null)
    }

    setLinkText(link)

    const validUrl = isValidUrl(link, checkValidUrl)
    const linkInText = !linkFromPost && useScraperData
    const linkInPost = linkFromPost && compareWithScraper
    const isLinkValid = validUrl && !isDataLoaded

    if ((linkInPost || linkInText) && isLinkValid) {
      // @todo: use the orinal url here to avoid importing ComposerStore to getCanonicalUrl
      scrapeUrl(link)
    }
  }, [
    linkFromPost,
    text,
    isDataLoaded,
    setLinkPreview,
    checkValidUrl,
    scrapeUrl,
    compareWithScraper,
    useScraperData,
    returnFirstUrl,
  ])

  useEffect(() => {
    processLink()
  }, [processLink])

  useEffect(() => {
    setIsDataLoaded(false)
  }, [linkText, setIsDataLoaded])

  return [linkPreview, linkText, scrapedData] as const
}

export default useLinkPreviewEffect
