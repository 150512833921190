/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client'
import {
  BoardIcon,
  Button,
  Flex,
  GalleryIcon,
  Heading,
  MessageCircleHeartIcon,
  PlusIcon,
  ToggleGroup,
} from '@buffer-mono/popcorn'
import React from 'react'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { FilterByTag } from '~publish/components/FilterByTag'
import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'
import { graphql } from '~publish/gql'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { usePageTitle } from '~publish/hooks/usePageTitle'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { GenerateIdeasButton } from '~publish/legacy/ai/components/GenerateIdeasButton'
import { selectHasContentFeature } from '~publish/legacy/organizations/selectors'
import { useAppSelector } from '~publish/legacy/store'
import { UploadSource } from '@buffer-mono/uploader'
import styles from './IdeasPage.module.css'
import { Board } from './components/Board'
import { IdeasGallery } from './components/IdeasGallery'
import { UpgradePathForCreate } from './components/UpgradePathForCreate'
import { IdeasMultiSelectProvider } from '~publish/components/IdeasMultiSelect'
import useIdeasUploader from '~publish/hooks/useIdeasUploader'

const LOCAL_STORAGE_VIEW_KEY = 'contentPlannerLastViewed'

export const VIEWS = {
  GALLERY: 'gallery',
  BOARD: 'board',
}

export const GetCreateInfo = graphql(/* GraphQL */ `
  query GetCreateInfo($organizationId: OrganizationId!) {
    tags(input: { organizationId: $organizationId }) {
      id
      ...FilterByTag_Tag
    }
  }
`)

type IdeasPageProps = {
  pageTitle?: string
}

function IdeasPage(props: IdeasPageProps): JSX.Element {
  const { pageTitle } = props
  usePageTitle(pageTitle ?? 'Create')
  const organizationId = useOrganizationId()
  const { createIdeaWithComposer } = useIdeaComposer()

  const DEFAULT_VIEW = VIEWS.BOARD

  const [selectedTagIds = [], setSelectedTagIds] =
    useQueryParam<string[]>('tagIds')

  const { data } = useQuery(GetCreateInfo, {
    variables: { organizationId },
  })

  const [view, setView] = useQueryParam('view')
  React.useEffect(() => {
    if (!view || Object.values(VIEWS).includes(view) === false) {
      const initialView =
        localStorage.getItem(LOCAL_STORAGE_VIEW_KEY) || DEFAULT_VIEW
      setView(initialView, { replace: true })
    }
  }, [view])

  const hasContentFeature = useAppSelector(selectHasContentFeature)

  const uploader = useIdeasUploader()

  const handleDropIfPossible = React.useCallback(
    (files: File[], groupId?: string) => {
      if (files.length > 0) {
        uploader.addFiles(files, {
          source: UploadSource.dragAndDrop(),
        })
        const viewSource = view === VIEWS.GALLERY ? 'gallery' : 'board'
        createIdeaWithComposer({
          source: `create-${viewSource}-dragAndDrop-newIdea-1`,
          groupId,
        })
      }
    },
    [uploader],
  )

  const handleViewSelect = (selectedValue: string): void => {
    if (!selectedValue) {
      return
    }
    setView(selectedValue)
    localStorage.setItem(LOCAL_STORAGE_VIEW_KEY, selectedValue)
  }

  if (!hasContentFeature) {
    return <UpgradePathForCreate />
  }

  return (
    <IdeasMultiSelectProvider>
      <div className={styles.page} data-testid="create-page">
        <Flex justify="between" as="header" className={styles.header}>
          <>
            <Flex gap="lg" align="center">
              <Heading as="h2" size="large">
                {pageTitle ?? 'Create'}
              </Heading>
              <GenerateIdeasButton />
            </Flex>
            <Flex gap="xs" align="center">
              <FeedbackWidget id="create-1" source="create">
                <Button variant="tertiary" size="large">
                  <MessageCircleHeartIcon />
                  Share Feedback
                </Button>
              </FeedbackWidget>
              <FilterByTag
                tags={sanitizeNullableArray(data?.tags)}
                value={selectedTagIds}
                onSelect={setSelectedTagIds}
              />
              <ToggleGroup
                size="large"
                value={view}
                onChange={handleViewSelect}
              >
                <ToggleGroup.Item value={VIEWS.BOARD}>
                  <BoardIcon />
                  Board
                </ToggleGroup.Item>
                <ToggleGroup.Item value={VIEWS.GALLERY}>
                  <GalleryIcon />
                  Gallery
                </ToggleGroup.Item>
              </ToggleGroup>
              <Button
                id="create-idea-button"
                variant="secondary"
                size="large"
                onClick={(): void => {
                  createIdeaWithComposer({
                    source:
                      view === VIEWS.GALLERY
                        ? 'create-gallery-toolbar-newIdea-1'
                        : 'create-board-toolbar-newIdea-1',
                  })
                }}
              >
                <PlusIcon />
                New Idea
              </Button>
            </Flex>
          </>
        </Flex>
        {view === VIEWS.GALLERY && (
          <IdeasGallery onFileDrop={handleDropIfPossible} />
        )}
        {view === VIEWS.BOARD && <Board onFileDrop={handleDropIfPossible} />}
      </div>
    </IdeasMultiSelectProvider>
  )
}

export { IdeasPage }
