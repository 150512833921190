import React from 'react'
import { Flex } from '@buffer-mono/popcorn'

import { type FragmentType, getFragmentData } from '~publish/gql'
import { PostCardProvider, PostCard_Post } from '~publish/components/PostCard'
import { PostCardHeader } from '~publish/components/PostCardHeader'
import { PostCardFooter } from '~publish/components/PostCardFooter'
import { PostCardTags } from '~publish/components/PostCardTags'
import { PostCardAddons } from '~publish/components/PostCardAddons'
import { PostCardContent } from '~publish/components/PostCardContent'
import { usePostNavigation } from '~publish/hooks/usePostNavigation'
import { PostMediaBentoBoxPreview } from '~publish/components/PostCard/PostMedia'
import { PostCardAttachments } from '~publish/components/PostCard/PostCardAttachments'
import { PostCardMetrics } from '~publish/components/PostCard/PostCardMetrics'

import { CalendarPostCardHeader } from './CalendarPostCardHeader'
import styles from './CalendarPostCard.module.css'
import { PostCardErrorNotice } from '~publish/components/PostCard/PostCardErrorNotice'

type CalendarPostCardProps = {
  post: FragmentType<typeof PostCard_Post>
}

const TagsAndAddons = (): JSX.Element => (
  <Flex direction="column" gap="xs" className={styles.tagsAddonsContainer}>
    <PostCardAddons />
    <PostCardTags maxVisibleTags={4} />
  </Flex>
)

export const CalendarPostCard = ({
  post,
}: CalendarPostCardProps): JSX.Element => {
  const postData = getFragmentData(PostCard_Post, post)
  const expandPostCard = usePostNavigation(postData.id)

  return (
    <PostCardProvider post={post}>
      <article className={styles.cardContainer}>
        <PostCardErrorNotice />
        <CalendarPostCardHeader />
        <section className={styles.body}>
          <div className={styles.bodyWrapper}>
            <div className={styles.contentWrapper}>
              <PostCardHeader condensed />
              <PostCardContent onSeeMoreClick={expandPostCard} />
              <PostCardAttachments condensed />
            </div>
            <div>
              <PostMediaBentoBoxPreview
                assets={postData.assets}
                className={styles.bentoBox}
              />
            </div>
          </div>
          <TagsAndAddons />
        </section>
        <PostCardMetrics condensed />
        <PostCardFooter condensed />
      </article>
    </PostCardProvider>
  )
}
