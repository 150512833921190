import { useEffect, useCallback, type DependencyList } from 'react'

type FeedGroupCreated = {
  kind: 'feedGroupCreated'
  feedGroup: {
    id: string
  }
}

type FeedGroupUpdated = {
  kind: 'feedGroupUpdated'
  feedGroup: {
    id: string
  }
}

type FeedGroupDeleted = {
  kind: 'feedGroupDeleted'
  feedGroup: {
    id: string
  }
}

type FeedUpdate = FeedGroupCreated | FeedGroupUpdated | FeedGroupDeleted

type FeedUpdateMap = {
  [K in FeedUpdate as K['kind']]: K
}

const listeners: {
  [K in keyof FeedUpdateMap]: ((update: FeedUpdateMap[K]) => void)[]
} = {
  feedGroupCreated: [],
  feedGroupUpdated: [],
  feedGroupDeleted: [],
}

export const useTriggerFeedUpdate = <Key extends FeedUpdate['kind']>(
  kind: Key,
): ((update: FeedUpdateMap[Key]) => void) => {
  return (update: FeedUpdateMap[Key]) => {
    listeners[kind].forEach((listener) => listener(update))
  }
}

export const useFeedUpdates = <Key extends FeedUpdate['kind']>(
  kind: Key,
  callback: (update: FeedUpdateMap[Key]) => void,
  deps: DependencyList,
): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackRef = useCallback(callback, deps)
  useEffect(() => {
    const keyListeners = listeners[kind]
    keyListeners.push(callbackRef)
    return () => {
      keyListeners.splice(keyListeners.indexOf(callbackRef), 1)
    }
  }, [callbackRef, kind])
}
