import type { AppDispatch } from '~publish/legacy/store'
import type { Uploader } from '@buffer-mono/uploader'
import {
  resetUploads,
  uploadAdded,
  uploadCompleted,
  uploadFailed,
  uploadProgress,
  uploadRemoved,
  uploadToS3Succeeded,
} from './slice'
import { getDebugLogger } from '~publish/legacy/utils/getDebugLogger'

const logger = getDebugLogger('uploads')

export function bindUploaderToReduxSlice(
  uploader: Uploader,
  { dispatch }: { dispatch: AppDispatch },
): void {
  logger('Binding uploader to redux slice: %s', uploader.getId())

  uploader.on('upload-started', (upload) => {
    dispatch(uploadAdded(upload))
  })

  uploader.on('upload-failed', ({ id }, error) => {
    dispatch(uploadFailed({ id, error: error.message }))
  })

  uploader.on('upload-removed', (upload) => {
    dispatch(uploadRemoved(upload))
  })

  uploader.on('upload-to-s3-finished', ({ id, url, key, bucket }) => {
    dispatch(uploadToS3Succeeded({ id, url, key, bucket }))
  })

  uploader.on('upload-finished', (upload) => {
    dispatch(uploadCompleted(upload))
  })

  uploader.on('upload-progress', ({ id }, progress) => {
    dispatch(uploadProgress({ id, progress }))
  })

  uploader.on('uploader-closed', (uploaderId) => {
    dispatch(resetUploads({ uploaderId }))
  })
}
