/**
 * Scrape urls to retrieve information about them.
 *
 * Returns a Promise that resolves with the JSON response from the appropriate
 * Scraper.urls entry as an object literal.
 *
 * Results are cached, and a same request done simultaneously will be
 * queued to return the same info as the already-running request.
 */

import Bugsnag from '@bugsnag/browser'
import qs from 'query-string'
import { removeUrlProtocol } from './StringUtils'

export type ResultType = {
  title: string
  description: string
  canonicalUrl: string
  images: {
    url: string
    width: number
    height: number
    type: string
    opengraph?: boolean
  }[]
  success?: boolean
  message?: string
}

const urls = new Map([
  ['local', 'https://scraper.local.buffer.com'],
  ['production', 'https://scraper.buffer.com'],
])

const pendingRequests = new Map<string, Promise<ResultType>>() // Stores pending scraper promises

const cache = new Map<string, ResultType>() // Stores results

function scrape(
  url: string,
  environment = 'production',
  isTwitterLinkPreview = false,
): Promise<ResultType> {
  const urlWithoutProtocol = removeUrlProtocol(url)
  // If the result is cached, return it right away
  const cacheKey = isTwitterLinkPreview
    ? `${urlWithoutProtocol}twitter`
    : urlWithoutProtocol

  if (cache.has(cacheKey)) {
    return Promise.resolve(cache.get(cacheKey) as ResultType)
  }
  // If a request about the same url is already running, return a
  // Promise that'll resolve with that request's data
  if (pendingRequests.has(cacheKey)) {
    return Promise.resolve(pendingRequests.get(cacheKey) as Promise<ResultType>)
  }

  const request = fetch(
    `${urls.get(environment)}?${qs.stringify({
      url,
      // if Twitter, add flag
      ...(isTwitterLinkPreview && { is_twitter_preview: true }),
    })}`,
  )
    .then((response) => {
      if (response.status >= 500 && environment === 'production') {
        Bugsnag.notify(
          new Error('Scraper returned 5xx error code'),
          (event) => {
            event.addMetadata('Scraper', {
              url,
              responseCode: response.status,
            })
          },
        )
      }

      return response
    })
    .then((response) => response.json())
    .then((urlData: ResultType) => {
      cache.set(cacheKey, urlData)
      pendingRequests.delete(cacheKey)

      return urlData
    })

  pendingRequests.set(cacheKey, request)

  return request
}

export default {
  scrape,
}
