import React from 'react'
import { Badge, ApprovalsIcon, type BadgeProps } from '@buffer-mono/popcorn'
import styles from './PostStatusBadge.module.css'

export const ApprovalBadge = (props: Pick<BadgeProps, 'size'>): JSX.Element => {
  return (
    <Badge className={styles.approval} size="medium" {...props}>
      <ApprovalsIcon />
      Approval
    </Badge>
  )
}
