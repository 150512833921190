import React from 'react'
import PropTypes from 'prop-types'
import Text from '@bufferapp/ui/Text'

const style = {
  padding: '0.1rem 0.4rem 0.1rem 0.4rem',
  backgroundColor: '#000',
  borderRadius: '0.126rem',
  opacity: '0.65',
  color: '#fff',
}

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const IdTag = ({ children, className }) => (
  <span style={style} className={className}>
    <Text>{children}</Text>
  </span>
)

IdTag.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
}

IdTag.defaultProps = {
  className: '',
}

export default IdTag
