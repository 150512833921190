import { useMemo } from 'react'

export const useUrlOrigin = (url: string): [string | null, Error | null] => {
  return useMemo(() => {
    try {
      return [new URL(url).origin, null]
    } catch (e) {
      return [null, e as Error]
    }
  }, [url])
}
