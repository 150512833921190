import clsx from 'clsx'
import React, { useEffect } from 'react'
import { Button, PlusIcon, UpgradeBadge } from '@buffer-mono/popcorn'

import {
  useAccount,
  useCurrentOrganization,
} from '~publish/legacy/accountContext'
import { getAccountURL } from '~publish/legacy/utils/formatters/getURL'
import { useIsAtPlanLimit } from '~publish/hooks/useIsAtPlanLimit'
import { isFreeUser } from '~publish/helpers/user'
import { useCta } from '~publish/hooks/useCta'
import UserEntity from '~publish/legacy/user/UserEntity'

import { SideNavItem } from './SideNavItem'

import styles from './PageSidebar.module.css'
import { useSplitEnabled } from '@buffer-mono/features'

export function NewChannelButton(): JSX.Element {
  const isAtPlanLimit = useIsAtPlanLimit()
  const { account } = useAccount()
  const isFree = isFreeUser(account)
  const isAdmin = UserEntity.isAdmin({ account })
  const currentOrganization = useCurrentOrganization()
  const hasPaymentDetails =
    currentOrganization.billing?.paymentDetails?.hasPaymentDetails ?? null
  const currentChannelQuantity = currentOrganization?.channels?.length ?? 0
  const { viewed: ctaViewed, clicked: ctaClicked } = useCta(
    'publish-sidebar-channels-addChannels-1',
    {
      upgradePathName: 'addChannel-upgrade',
      extraProperties: {
        currentChannelQuantity,
        isAtPlanLimit,
        hasPaymentDetails,
      },
    },
  )

  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  const openChannelStorefront = (): void => {
    const { actions } = window.appshell || {}
    actions?.openChannelStorefront({
      cta: 'publish-sidebar-channels-newChannel-1',
    })
  }

  useEffect(() => {
    if (isAdmin && isFree && isAtPlanLimit) {
      ctaViewed()
    }
  }, [isFree, isAtPlanLimit, ctaViewed, isAdmin])

  if (!isAdmin) {
    return <></>
  }

  return (
    <>
      <SideNavItem>
        {isChannelStorefrontEnabled ? (
          <Button
            variant="tertiary"
            size="large"
            onClick={openChannelStorefront}
            className={styles.newChannelButton}
          >
            <div className={clsx(styles.icon, styles.newChannelIcon)}>
              <PlusIcon size={'small'} />
            </div>
            New Channel
            {isAtPlanLimit && <UpgradeBadge className={styles.upgradeBadge} />}
          </Button>
        ) : (
          <a href={`${getAccountURL()}/channels/connect`} onClick={ctaClicked}>
            <div className={clsx(styles.icon, styles.newChannelIcon)}>
              <PlusIcon size={'small'} />
            </div>
            New Channel
            {isAtPlanLimit && <UpgradeBadge className={styles.upgradeBadge} />}
          </a>
        )}
      </SideNavItem>
    </>
  )
}
