import React from 'react'
import {
  Flex,
  Text,
  IconButton,
  CommentRoundIcon,
  ArrowUpRightIcon,
  ClockIcon,
} from '@buffer-mono/popcorn'
import { useDateTimeFormatter } from '~publish/hooks/useDateTimeFormatter'

import styles from './CalendarPostCardHeader.module.css'
import { PostStatusBadge } from '~publish/components/PostStatusBadge'
import { usePostData } from '~publish/components/PostCard/PostCardContext'
import { PostNotifyBadge } from '~publish/components/PostNotifyBadge'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { postDetailsRoute } from '~publish/legacy/routes'

// TODO: we have this same component in PostCardHeader, we should move it to a shared component
const TextSeparator = (): JSX.Element => (
  <Text color="subtle" aria-hidden={true}>
    {' • '}
  </Text>
)

const CustomScheduleLabel = (): JSX.Element => {
  return (
    <Text size="sm" color="subtle" className={styles.customLabel}>
      <TextSeparator />
      <Flex gap="2xs" align="center">
        <ClockIcon size="xsmall" />
        <span>Custom</span>
      </Flex>
    </Text>
  )
}

export const CalendarPostCardHeader = (): JSX.Element => {
  const { id, dueAt, isCustomScheduled } = usePostData()
  const formatDateTime = useDateTimeFormatter()
  const match = useRouteMatch()
  const { search } = useLocation()

  const url = `${match.url}${postDetailsRoute.getRoute({
    postId: id,
  })}${search}`

  return (
    <header className={styles.header}>
      <Flex gap="sm" align="center">
        <Flex gap="2xs" align="center">
          <Text size="md" weight="medium">
            {dueAt
              ? formatDateTime(dueAt, { includeTimeZone: false })
              : 'No date'}
          </Text>
          {isCustomScheduled && <CustomScheduleLabel />}
        </Flex>
        <PostStatusBadge />
        <PostNotifyBadge />
      </Flex>

      <Flex gap="xs">
        <IconButton
          as={Link}
          to={url}
          label="Notes"
          size="small"
          variant="tertiary"
        >
          <CommentRoundIcon />
        </IconButton>
        <IconButton
          as={Link}
          to={url}
          label="Expand"
          size="small"
          variant="tertiary"
        >
          {/* TODO: replace with ExpandIcon */}
          <ArrowUpRightIcon />
        </IconButton>
      </Flex>
    </header>
  )
}
