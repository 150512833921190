import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import SearchIcon from '@bufferapp/ui/Icon/Icons/Search'
import Text from '@bufferapp/ui/Text'
import Loader from '@bufferapp/ui/Loader'

import { useLazyQuery } from '@apollo/client'
import { UNSPLASH_SERVICE } from '~publish/legacy/integrations-bar/constants'
import {
  SearchButtonContainer,
  SpanWithMarginLeft,
  LoaderWrapper,
  ButtonWihMarginLeft,
  InputSearchWrapper,
  FooterForm,
  CleanIcon,
  InputSearch,
} from './styles'

import styles from './SearchImages.module.css'

import { useSearchImagesReducer, actions } from './searchImagesReducer'

import { SearchImagesResults } from './SearchImagesResults'
import { SEARCH_IMAGES_QUERY } from '../../queries'

import { QUERY_RUN, sendIntegrationsEvent } from '../../tracking'

import type { IntegrationsService, IntegrationsContext } from '../../types'
import { OrientationDropdownMenu } from './OrientationDropdownMenu'
import { Notice } from '@bufferapp/ui'

/**
 *  COMPONENT: SEARCH IMAGES FORM
 */
export const SearchImagesForm = ({
  // todo: rename editor to integrationsContext
  editor,
  service,
}: {
  editor: IntegrationsContext
  service: IntegrationsService
}) => {
  const { t } = useTranslation()
  const isMounted = useRef(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [state, dispatch] = useSearchImagesReducer()
  const [searchImages, { loading }] = useLazyQuery(SEARCH_IMAGES_QUERY)

  useEffect(() => {
    isMounted.current = true
    if (inputRef.current) {
      inputRef.current.focus()
    }
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (state.query && state.query !== '') {
      sendIntegrationsEvent(QUERY_RUN, editor, service.id, state.query)
    }
    searchImages({
      variables: {
        searchTerm: state.query,
        page: state.currentPage,
        orientation: state.orientation,
        source: service?.id,
      },
    }).then((result) => {
      if (isMounted.current) {
        const { error, data } = result

        if (error || data?.searchImages?.userFriendlyMessage) {
          const msg = error?.message || data?.searchImages?.userFriendlyMessage
          dispatch(actions.searchError(msg))
        } else {
          dispatch(actions.searchSucccess(data?.searchImages?.images))
        }
      }
    })
  }, [
    dispatch,
    searchImages,
    state.currentPage,
    state.query,
    editor,
    service,
    state.orientation,
  ])

  const handleOrientationChange = (newOrientation: string): void => {
    dispatch(actions.changeOrientation(newOrientation))
  }

  if (service === null) return null

  const setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setInputValue(value)
  }

  return (
    <>
      {state.error && (
        <Notice type="alert" disableAnimation className={styles.notification}>
          <Text type="p">{state.error}</Text>
        </Notice>
      )}
      <Text type="h3">
        {service?.icon?.({}) ?? null}
        <SpanWithMarginLeft>{service?.title}</SpanWithMarginLeft>
      </Text>
      <SearchButtonContainer>
        <InputSearchWrapper>
          <InputSearch
            type="text"
            onChange={setSearchTerm}
            name="search-terms"
            placeholder={service?.placeholder}
            icon={<SearchIcon size="medium" />}
            size="tall"
            value={inputValue}
            ref={inputRef}
          />
          {inputValue && (
            <CleanIcon
              title="Clean"
              data-testid="clean-icon"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                setInputValue('')
                // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
                dispatch(actions.cleanQuery())
              }}
            />
          )}
        </InputSearchWrapper>
        <ButtonWihMarginLeft
          type="primary"
          aria-label="search"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            dispatch(actions.search(inputValue))
          }}
          disable={loading}
          label={t('content.modals.integrations.searchButon')}
        />
      </SearchButtonContainer>
      {service.id === UNSPLASH_SERVICE && state.query && state.query !== '' && (
        <OrientationDropdownMenu
          onChangeOrientation={handleOrientationChange}
        />
      )}
      <SearchImagesResults
        images={state.images}
        // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
        getMore={() => dispatch(actions.getMore())}
        loading={loading}
        service={service}
        loader={
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        }
      />
      <FooterForm> {service?.footer && service.footer}</FooterForm>
    </>
  )
}
