import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { useSplitEnabled } from '@buffer-mono/features'

import { Avatar, Text, Tooltip } from '@bufferapp/ui'
import { Phone, Star, Thread, Warning } from '@bufferapp/ui/Icon'
import { grayDark, white } from '@bufferapp/ui/style/colors'
import DraftsIcon from '@bufferapp/ui/Icon/Icons/Drafts'
import CopyIcon from '@bufferapp/ui/Icon/Icons/Copy'
import { useTwentyFourHourTimeFormat } from '~publish/pages/Calendar/hooks/useTwentyFourHourTimeFormat'

import dayjs from 'dayjs'

import { usePostPermissions } from '~publish/legacy/post/hooks'
import { transform24hrTimeToString } from '../../../composer/composer/utils/TimeStringTransformer'
import PendingTiktokBanner from './PendingTiktokBanner'
import MediaThumbnail, { toUpdateType } from '../MediaThumbnail'
import RetweetItem from './RetweetItem'
import MetadataList from './MetadataList'
import {
  AttachmentCard,
  BlueprintWrapper,
  ChannelDescriptionWrapper,
  ChannelName,
  ChannelSubtitle,
  ClockIconStyled,
  Content,
  DraftWrapper,
  DraftWrapperText,
  ErrorWrapper,
  FlexWrapper,
  IconWrapper,
  MediaWrapper,
  ParagraphErrorText,
  PhoneIconWrapper,
  PostLink,
  PostPreviewHeader,
  PostPreviewWrapper,
  PostText,
  PostTitle,
  StyledLink,
  TextWrapper,
  ThreadCounter,
  ThreadIconWrapper,
  TooltipWrapper,
  PostTagsWrapper,
} from './styles'
import { PostEntity } from '../../../post/PostEntity'
import { Footer } from './Footer'
import { RemindersIcon } from '~publish/legacy/reminders/components/RemindersIcon'
import type {
  PublishingPost,
  PublishingPostAttachment,
  PublishingPostRetweet,
  PublishingPostFirstComment,
  PublishingPostDestinationUrl,
  PublishingPostLink,
} from '~publish/gql/graphql'
import { PostCardTags } from '~publish/components/PostCardTags'

const getAttachment = <ReturnType extends PublishingPostAttachment>(
  attachments: PublishingPostAttachment[],
  type: ReturnType['__typename'],
): ReturnType | null => {
  if (!attachments) return null

  for (const attachment of attachments) {
    if (attachment.__typename === type) return attachment as ReturnType
  }
  return null
}

function filterNonNullable<T>(
  input: (T | undefined | null)[] | undefined | null,
): T[] {
  if (!input) return []
  return input.filter((item) => item !== null) as T[]
}

const getRetweet = (
  attachments: PublishingPostAttachment[],
): PublishingPostRetweet | null =>
  getAttachment(attachments, 'PublishingPostRetweet')

const getFirstComment = (
  attachments: PublishingPostAttachment[],
): PublishingPostFirstComment | null =>
  getAttachment(attachments, 'PublishingPostFirstComment')

const getDestinationUrl = (
  attachments: PublishingPostAttachment[],
): PublishingPostDestinationUrl | null =>
  getAttachment(attachments, 'PublishingPostDestinationUrl')

const getLink = (
  attachments: PublishingPostAttachment[],
): PublishingPostLink | null => getAttachment(attachments, 'PublishingPostLink')

export type PostPreviewProps = {
  post: PublishingPost
  inLastTwoRows: boolean
  isEndOfWeek: boolean
  isLastThirdDayOfWeek: boolean
  setOpenModal:
    | null
    | ((openModal: {
        open: boolean
        ctaString: string
        service: string
      }) => void)
}

const PostPreview = ({
  post,
  inLastTwoRows,
  isEndOfWeek,
  isLastThirdDayOfWeek,
  setOpenModal,
}: PostPreviewProps): JSX.Element => {
  const {
    media,
    text,
    status,
    error,
    channel,
    isReminder,
    isCustomScheduled,
    isPinned,
    isRetweet,
    formattedTime,
    title,
    statistics,
    attachments,
    subprofile,
    channelData,
    isBlueprint,
    updateType,
    threadCount,
  } = post || {}

  const use24HourTimeFormat = useTwentyFourHourTimeFormat()

  const { t } = useTranslation()
  const postMedia = media || []
  const hasMedia = postMedia.length > 0
  const hasError = status === 'error'
  const isSent = PostEntity.isSent(post)
  const isDraft = PostEntity.isDraft(post)
  const isPendingTiktok = PostEntity.isPendingTiktok(post)
  const needsApproval = PostEntity.needsApproval(post)
  const attachmentsNonNull = filterNonNullable(attachments)
  const retweet = isRetweet ? getRetweet(attachmentsNonNull) : null
  const commentText = getFirstComment(attachmentsNonNull)?.text ?? null
  const destinationUrl = getDestinationUrl(attachmentsNonNull)?.url ?? null
  const linkAttachment = getLink(attachmentsNonNull) ?? null
  const hasThread = updateType === 'thread' && threadCount && threadCount > 1
  const geoLocation = PostEntity.getLocation(post)

  const {
    title: googleBusinessPostTitle = '',
    start_date: startDate = '',
    end_date: endDate = '',
    start_time: startTime = '',
    end_time: endTime = '',
    code = '',
    link = '',
    terms = '',
  } = channelData?.googlebusiness || {}
  const youtubePostTitle = channelData?.youtube?.title
  const spLink = channelData?.startPage?.link ?? channelData?.instagram?.link

  const hasMetadata =
    commentText ||
    geoLocation ||
    title ||
    destinationUrl ||
    startDate ||
    endDate ||
    startTime ||
    endTime ||
    code ||
    link ||
    terms ||
    spLink

  const getStartEndDate = () => {
    if (
      updateType !== 'offer' &&
      startTime &&
      endTime &&
      startDate &&
      endDate
    ) {
      return `${dayjs.unix(startDate).utc().format('MMM Do, YYYY')}
      ${transform24hrTimeToString(startTime, use24HourTimeFormat)} to
      ${dayjs.unix(endDate).utc().format('MMM Do, YYYY')}
      ${transform24hrTimeToString(endTime, use24HourTimeFormat)}`
    }

    if (startDate && endDate) {
      return `${dayjs.unix(startDate).utc().format('MMM Do, YYYY')}
      to ${dayjs.unix(endDate).utc().format('MMM Do, YYYY')}`
    }

    return null
  }

  const { isEnabled: isBlueprintsEnabled } = useSplitEnabled(
    'build-week-blueprints',
  )
  const shouldShowBluePrint = isBlueprintsEnabled && isBlueprint
  const { isManager, isPostOwner } = usePostPermissions(post)
  const draftHeaderText = useMemo(() => {
    if (shouldShowBluePrint) return 'Recipe'
    if (!needsApproval) return 'Draft'
    if (isManager) return 'Awaiting Approval'
    if (!isManager && isPostOwner) return 'Pending Approval'
  }, [needsApproval, isManager, isPostOwner, shouldShowBluePrint])

  const shouldShowLinkInError = error && error.link && error.actionType

  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  return (
    <PostPreviewWrapper
      inLastTwoRows={inLastTwoRows}
      isEndOfWeek={isEndOfWeek}
      isLastThirdDayOfWeek={isLastThirdDayOfWeek}
      hasSlap={isDraft || hasError}
    >
      {isDraft && !shouldShowBluePrint && (
        <DraftWrapper needsApproval={needsApproval}>
          <DraftsIcon size="medium" />
          <DraftWrapperText>{draftHeaderText}</DraftWrapperText>
        </DraftWrapper>
      )}
      {isDraft && shouldShowBluePrint && (
        <BlueprintWrapper>
          <CopyIcon size="medium" />
          <DraftWrapperText>{draftHeaderText}</DraftWrapperText>
        </BlueprintWrapper>
      )}
      {/* Error Banner */}
      {hasError && (
        <ErrorWrapper>
          <Warning color={white} />
          <ParagraphErrorText type="p" color="white">
            {error?.message}
          </ParagraphErrorText>
          {shouldShowLinkInError && (
            <StyledLink newTab href={error.link}>
              {error.actionType}
            </StyledLink>
          )}
        </ErrorWrapper>
      )}
      {isPendingTiktok && <PendingTiktokBanner />}
      {/* Header */}
      <PostPreviewHeader>
        <FlexWrapper>
          <Avatar
            src={channel.avatar ?? ''}
            fallbackUrl="https://s3.amazonaws.com/buffer-ui/Default+Avatar.png"
            alt={channel.username}
            size="small"
            type="social"
            network={channel.service}
          />
          <ChannelDescriptionWrapper>
            <ChannelName
              type="label"
              color="grayDarker"
              title={channel.username}
            >
              {channel.username}
            </ChannelName>
            {channel.serverUrl && (
              <ChannelSubtitle
                type="label"
                color="grayDark"
                title={channel.serverUrl}
              >
                {channel.serverUrl}
              </ChannelSubtitle>
            )}
            {channel.locationData?.location && (
              <ChannelSubtitle
                type="label"
                color="grayDark"
                title={channel.locationData.location}
              >
                {channel.locationData.location}
              </ChannelSubtitle>
            )}
          </ChannelDescriptionWrapper>
        </FlexWrapper>
        <FlexWrapper>
          {hasThread && (
            <ThreadIconWrapper>
              <Thread
                color={grayDark}
                size="small"
                role="img"
                title="This is a Thread post"
              />
              <ThreadCounter>{threadCount}</ThreadCounter>
            </ThreadIconWrapper>
          )}
          {isReminder &&
            (isRemindersEnabled ? (
              <TooltipWrapper>
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message */}
                <Tooltip label={t('remindersGeneral.tooltip')}>
                  <RemindersIcon />
                </Tooltip>
              </TooltipWrapper>
            ) : (
              <PhoneIconWrapper small>
                <Phone
                  color={white}
                  size="small"
                  role="img"
                  title="You'll receive a notification in your Buffer app"
                />
              </PhoneIconWrapper>
            ))}
          {isCustomScheduled && (
            <IconWrapper>
              <ClockIconStyled
                color={grayDark}
                role="img"
                title="This post is a custom schedule post"
              />
            </IconWrapper>
          )}
          {isPinned && (
            <IconWrapper>
              <Star
                color={grayDark}
                role="img"
                title="This post is a pinned post"
              />
            </IconWrapper>
          )}
          <Text type="label" as="span" color="grayDark">
            {formattedTime}
          </Text>
        </FlexWrapper>
      </PostPreviewHeader>
      {/* Content */}
      <Content>
        <MediaWrapper>
          <TextWrapper>
            <PostTitle type="p">
              {title || googleBusinessPostTitle || youtubePostTitle}
            </PostTitle>
            <PostText type="p">{text}</PostText>
          </TextWrapper>
          {hasMedia && media && (
            <MediaThumbnail
              media={media}
              size="large"
              updateType={toUpdateType(updateType)}
            />
          )}
        </MediaWrapper>
        {linkAttachment && (
          <AttachmentCard>
            <MediaWrapper>
              <TextWrapper>
                <PostTitle type="p">{linkAttachment?.title}</PostTitle>
                <PostLink type="p" color="grayDark">
                  {linkAttachment?.url}
                </PostLink>
                <PostText type="p" color="grayDark">
                  {linkAttachment?.description}
                </PostText>
              </TextWrapper>
              {linkAttachment?.thumbnail && (
                <MediaThumbnail
                  media={[{ thumbnail: linkAttachment?.thumbnail }] || null}
                  size="medium"
                />
              )}
            </MediaWrapper>
          </AttachmentCard>
        )}
        {isRetweet && retweet && <RetweetItem retweet={retweet} />}
      </Content>
      {hasMetadata && (
        <MetadataList
          commentText={commentText ?? ''}
          geoLocationText={geoLocation}
          destinationUrl={destinationUrl ?? ''}
          boardName={subprofile?.name}
          startEndDate={getStartEndDate()}
          code={code ?? ''}
          link={link ?? ''}
          terms={terms ?? ''}
          spLink={spLink ?? ''}
          isReminder={isReminder}
        />
      )}
      <PostTagsWrapper>
        <PostCardTags />
      </PostTagsWrapper>
      <Footer
        post={post}
        isSent={isSent}
        isRetweet={isRetweet}
        statistics={statistics ?? undefined}
        channelName={channel.service}
        setOpenModal={setOpenModal ?? ((): void => undefined)}
      />
    </PostPreviewWrapper>
  )
}

export default PostPreview
