import { useEffect, useRef } from 'react'
import { useTheme } from '@buffer-mono/popcorn'
import { env } from '~publish/env'
import { useAccount } from '~publish/legacy/accountContext'

type WidgetOptions = {
  show?: 'changelog' | 'feature_requests' | 'all'
  enableIndicator?: boolean
  showOnlySubmission?: boolean
}

type Widget = {
  open: () => void
  close: () => void
}

export const useFeatureOsWidget = (
  options: WidgetOptions = {},
): Widget | null => {
  const widgetRef = useRef<Widget | null>(null)
  const { theme } = useTheme()
  const { account } = useAccount()

  useEffect(() => {
    if (!window.HellonextWidget) {
      return
    }

    try {
      // FeatureOS script is defined in /html-scripts/featureOS.ts
      // it does not support typescript, so we need to cast it to any
      // all the options are defined here based on the widget documentation
      // https://developers.featureos.app/docs/widgets
      const widget = new window.HellonextWidget({
        type: 'modal',
        theme: theme === 'dark' ? 'dark' : 'light',
        openFrom: 'center',
        ssoToken: account.hellonextSsoToken,
        token: env.VITE_FEATUREOS_TOKEN,
        modules:
          !options.show || options.show === 'all'
            ? ['changelog', 'feature_requests']
            : [options.show],
        enableIndicator: options.enableIndicator ?? true,
        noDefaultTrigger: true,
        postFilters: {
          bucket_id: [13248, 13444],
          status: ['under_review', 'planned', 'in_progress', 'completed'],
        },
        changelogFilters: {
          per_page: 20,
        },
        showOnlySubmission: options.showOnlySubmission ?? false,
      })
      widget.init()

      widgetRef.current = widget
    } catch (error) {
      console.error('Failed to create FeatureOS widget', error)
    }
  }, [
    options.show,
    options.enableIndicator,
    options.showOnlySubmission,
    theme,
    account.hellonextSsoToken,
  ])

  if (!widgetRef.current) {
    return null
  }

  return {
    open: (): void => {
      widgetRef.current?.open?.()
    },
    close: (): void => {
      widgetRef.current?.close?.()
    },
  }
}
