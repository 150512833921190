import React from 'react'
import { useParams, Redirect } from 'react-router-dom'

import { EditFeedGroup } from '../Feed/components/EditFeedGroup/EditFeedGroup'
import { CreatePageLayout } from '../components/Layout/CreatePageLayout'

export function FeedSettingsPage(): JSX.Element {
  const { feedGroupId } = useParams<{ feedGroupId?: string }>()
  if (!feedGroupId) {
    return <Redirect to="/create/feeds/new" />
  }
  return (
    <CreatePageLayout>
      <EditFeedGroup feedGroupId={feedGroupId} />
    </CreatePageLayout>
  )
}
