import React from 'react'
import events from './Events'
import { toast, Link } from '@buffer-mono/popcorn'
import { Link as RRLink } from 'react-router-dom'

const AppHooks = {
  handleAppLoaded: () => {
    events.emit('loaded')
  },

  handleSavedDrafts: (args: {
    message: string
    fromIdea: boolean
    deepLink?: string
  }): void => {
    const { message, fromIdea, deepLink } = args
    if (deepLink) {
      toast.success(
        <div>
          {message}{' '}
          <Link as={RRLink} to={deepLink}>
            View post
          </Link>
        </div>,
      )
    } else {
      toast.success(message)
    }
    events.emit('saved-drafts', { message, fromIdea })
  },

  handleBackdropClicked: () => {
    events.emit('backdrop-clicked')
  },

  // after a user starts a trial, send message with updated organizationsData
  // @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
  handleStartTrial: ({ message, removeScope }) => {
    events.emit('start-trial', { message, removeScope })
  },

  handleActionTaken: (message = {}) => {
    events.emit('action-taken', message)
  },
}

export default AppHooks
