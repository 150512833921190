/*
 * THIS FILE IS INTENDED TO CONTAIN HELPER FUNCTIONS USED TO DETERMINE USER CHARACTERISTICS
 */

import type { Account } from '~publish/legacy/accountContext/types'

export const isFreeUser = (user: Account): boolean => {
  return user?.currentOrganization?.billing?.subscription?.plan?.id === 'free'
}

export function isTrialUser(user: Account): boolean {
  return (
    user?.currentOrganization?.billing?.subscription?.trial?.isActive || false
  )
}

/*
 * We use this function to get the signup date of a user in milliseconds.
 * It is used over all in experiments to determine the user's signup date.
 */
export const getSignUpDateInEpochsMs = (user: Account): number => {
  const currentOrganizationCreationDate = user?.currentOrganization?.createdAt

  return currentOrganizationCreationDate
    ? new Date(currentOrganizationCreationDate).getTime()
    : 0
}
