/**
 * Timezone Filter Scoring System
 * -----------------------------
 * The scoring system is designed to rank timezone search results based on the
 * NN Group research findings (https://www.nngroup.com/articles/time-zone-selectors/)
 * about how users typically search for timezones. The system assigns higher
 * scores to matches that align with common user search patterns, with city
 * searches receiving the highest priority as they were found to be the most
 * frequent search strategy (44% of users).
 *
 * Base Field Scores (divided by 1000 in final calculation):
 * - City: 5000 points (Highest priority - most common search strategy)
 * - Region/Country: 4000 points (Second priority - important for users not in major cities)
 * - Long Name: 3000 points (Third priority - timezone names were 24% of search attempts)
 * - Short Name: 1000 points (Fourth priority)
 * - Long Generic: 500 points (Fifth priority)
 * - Short Generic: 250 points (Lowest priority)
 *
 * Exact Start Bonuses (divided by 1000 in final calculation):
 * - City: +2000 points
 * - Region/Country: +1500 points
 * - Long Name: +1000 points
 * - Short Name: +500 points
 * - Long Generic: +250 points
 * - Short Generic: +100 points
 *
 * Special Cases:
 * - Offset Queries (e.g., GMT+1, UTC-7): Receives 1000 points for exact offset matches
 * - Cities starting with "St.": Checks both "St" and "Saint" variations
 *
 * Command Score Multipliers (from command-score.ts):
 * - Continuous Match: 1.0 * 0.1 (best case - adjacent matching characters)
 * - Space Word Jump: 0.9 * 0.1 (matching after space)
 * - Non-Space Word Jump: 0.8 * 0.1 (matching after /, _, +, etc.)
 * - Character Jump: 0.17 * 0.1 (any other match)
 * - Transposition: 0.1 * 0.1 (swapped characters)
 *
 * Additional Penalties:
 * - Skipped Characters: 0.999^ per character skipped
 * - Case Mismatch: 0.9999^ per mismatch
 * - Incomplete Match: 0.99 (when search is prefix of result)
 *
 * String Normalization:
 * - Convert to lowercase
 * - Remove diacritical marks
 * - Trim whitespace
 * - Normalize spaces/hyphens in command scoring
 *
 * Example Scoring Scenarios:
 * 1. Exact city match "london" for "London":
 *    Base: 5000/1000 + Exact Start: 2000/1000 + Command Score: 0.1
 *    Total ≈ 7.1
 *
 * 2. GMT offset search "GMT-8":
 *    Exact offset match = 1000 points
 *
 * 3. Partial region match "amer" for "America":
 *    Base: 4000/1000 + Exact Start: 1500/1000 + Command Score: ~0.08
 *    Total ≈ 5.58
 *
 * The final score for a timezone is the highest score among all matching field scores,
 * with higher scores indicating better matches. A score of 0 indicates
 * no match was found. The scoring system ensures that longer, more specific matches
 * (like full timezone names) are preferred over shorter matches (like abbreviations),
 * while maintaining the priority order based on user search patterns.
 */
import { memoize } from '~publish/helpers/memoize'
import { commandScore } from './command-score'

export interface TimezoneInfo {
  id: string // e.g. "America/Los_Angeles"
  readableId: string // e.g. "America/Los Angeles"
  longName: string // e.g. "Pacific Time"
  shortName: string // e.g. "PT"
  longGeneric: string // e.g. "Pacific Time (PT)"
  shortGeneric: string // e.g. "PT"
  longOffset: string // e.g. "GMT-07:00"
  shortOffset: string // e.g. "GMT-7"
  gmtOffset: string // e.g. "-07:00"
  rawOffset: number // offset in minutes
  city: string // e.g. "Los Angeles"
}

export type TimezoneMap = {
  [id: string]: TimezoneInfo
}

export async function getOrFetchTimezonesMap(): Promise<TimezoneMap> {
  let timezoneIds: string[] = []
  try {
    // Get all available timezone IDs
    timezoneIds = Intl.supportedValuesOf('timeZone')
  } catch (error) {
    // Intl.supportedValuesOf is not supported in all browsers, fetch the timezones from the file
    return await import('./timezones').then((module) => module.TIMEZONES)
  }

  const currentDate = new Date()

  // Create the timezone map
  const timezones: TimezoneMap = {}

  timezoneIds.forEach((zoneId) => {
    // Get various timezone name formats
    const formats = {
      long: new Intl.DateTimeFormat('en', {
        timeZone: zoneId,
        timeZoneName: 'long',
      }),
      short: new Intl.DateTimeFormat('en', {
        timeZone: zoneId,
        timeZoneName: 'short',
      }),
      longGeneric: new Intl.DateTimeFormat('en', {
        timeZone: zoneId,
        timeZoneName: 'longGeneric',
      }),
      shortGeneric: new Intl.DateTimeFormat('en', {
        timeZone: zoneId,
        timeZoneName: 'shortGeneric',
      }),
      longOffset: new Intl.DateTimeFormat('en', {
        timeZone: zoneId,
        timeZoneName: 'longOffset',
      }),
      shortOffset: new Intl.DateTimeFormat('en', {
        timeZone: zoneId,
        timeZoneName: 'shortOffset',
      }),
      time: new Intl.DateTimeFormat('en', {
        timeZone: zoneId,
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
    }

    // Extract the timezone names from the formatted parts
    const getPart = (
      formatter: Intl.DateTimeFormat,
      type = 'timeZoneName',
    ): string => {
      const parts = formatter.formatToParts(currentDate)
      return parts.find((part) => part.type === type)?.value || ''
    }

    // Calculate raw offset in minutes
    const localDate = new Date(
      currentDate.toLocaleString('en-US', { timeZone: zoneId }),
    )
    const utcDate = new Date(
      currentDate.toLocaleString('en-US', { timeZone: 'UTC' }),
    )
    const offsetInMinutes =
      (localDate.getTime() - utcDate.getTime()) / (1000 * 60)

    // Format GMT offset
    const formatGMTOffset = (minutes: number): string => {
      const sign = minutes >= 0 ? '+' : '-'
      const absMinutes = Math.abs(minutes)
      const hours = Math.floor(absMinutes / 60)
      const mins = absMinutes % 60
      return `${sign}${String(hours).padStart(2, '0')}:${String(mins).padStart(
        2,
        '0',
      )}`
    }

    const city = zoneId.split('/').at(-1)?.replace(/_/g, ' ') ?? zoneId

    // Add timezone info to the map
    timezones[zoneId] = {
      id: zoneId,
      readableId: zoneId.replace(/_/g, ' '),
      longName: getPart(formats.long),
      shortName: getPart(formats.short),
      longGeneric: getPart(formats.longGeneric),
      shortGeneric: getPart(formats.shortGeneric),
      longOffset: getPart(formats.longOffset),
      shortOffset: getPart(formats.shortOffset),
      gmtOffset: formatGMTOffset(offsetInMinutes),
      rawOffset: offsetInMinutes,
      city,
    }
  })

  return timezones
}

export const getTimezonesMap = memoize(getOrFetchTimezonesMap)
/**
 * Normalizes strings for consistent comparison by:
 * - Converting to lowercase
 * - Removing diacritical marks
 * - Trimming whitespace
 */
const normalize = (str: string): string => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
}

/**
 * Checks if the search query matches GMT/UTC offset pattern
 * Valid formats: GMT+1, UTC-7, +1, -7, etc.
 */
const isOffsetQuery = (query: string): boolean => {
  return /^(gmt|utc)?[+-]?\d{1,2}(:?\d{2})?$/i.test(query)
}

/**
 * Normalizes offset string for comparison by converting to HHMM format
 * Examples:
 * - "UTC-8" -> "-0800"
 * - "GMT+9:30" -> "+0930"
 * - "-7" -> "-0700"
 * - "+5:45" -> "+0545"
 */

/**
 * Normalizes offset string for comparison by converting to HHMM format
 */
const normalizeOffset = (offset: string): string => {
  // Remove all non-numeric characters except +/-
  const stripped = offset.replace(/[^0-9+-]/g, '')

  // Extract the sign and number
  const match = stripped.match(/([+-])?(\d+)/)
  if (!match) return ''

  const [, sign = '+', num] = match

  // Convert to hours and minutes
  if (num.length <= 2) {
    // Simple hour offset (e.g., "-7" or "+5")
    return `${sign}${num.padStart(2, '0')}00`
  } else if (num.length === 3) {
    // Handle cases like "+545" for 5:45
    return `${sign}0${num}`
  } else if (num.length === 4) {
    // Already in HHMM format
    return `${sign}${num}`
  }

  return ''
}

const SCORE_CONFIG = {
  city: {
    base: 5000, // Highest priority
    exactStart: 2000,
  },
  region: {
    base: 4000, // Second priority
    exactStart: 1500,
  },
  longName: {
    base: 3000, // Third priority
    exactStart: 1000,
  },
  shortName: {
    base: 1000, // Fourth priority
    exactStart: 500,
  },
  longGeneric: {
    base: 500, // Fifth priority
    exactStart: 250,
  },
  shortGeneric: {
    base: 250, // Lowest priority
    exactStart: 100,
  },
}

/**
 * Calculate field score with fuzzy matching
 */
const calculateFieldScore = (
  fieldValue: string,
  normalizedSearch: string,
  baseScore: number,
  exactStartBonus: number,
): number => {
  const cmdScore = commandScore(fieldValue, normalizedSearch, [])

  if (cmdScore === 0) return 0

  // Make base scores dominate over command scores by multiplying cmdScore
  const matchQuality = cmdScore * 0.1 // Reduce impact of command score
  const bonus = fieldValue.startsWith(normalizedSearch) ? exactStartBonus : 0
  return matchQuality + (baseScore + bonus) / 1000
}

/**
 * Enhanced filter function for Command Menu (cmdk) that ranks timezone search results
 * based on NN Group research findings and includes fuzzy matching for typos and
 * character transpositions. Implements the cmdk filter function signature while
 * maintaining the timezone-specific scoring system.
 *
 * @param value - The value of the command item (timezone ID)
 * @param search - The search query from the command input
 * @param keywords - Optional array of keywords for additional matching
 * @returns A number indicating the rank/relevance (0 means no match)
 */
export const filterTimezones = (
  TIMEZONES: TimezoneMap,
  value: string,
  search: string,
): number => {
  const timezone = TIMEZONES[value]
  if (!timezone) return 0

  const normalizedSearch = normalize(search)
  if (!normalizedSearch) return 1

  // Handle offset searches (e.g., GMT+1, UTC-7)
  if (isOffsetQuery(normalizedSearch)) {
    const searchOffset = normalizeOffset(normalizedSearch)
    const tzOffset = normalizeOffset(timezone.gmtOffset)

    return searchOffset === tzOffset ? 1000 : 0
  }

  let score = 0
  const searchableFields = {
    city: normalize(timezone.city),
    region: normalize(timezone.id.split('/')[0]),
    longName: normalize(timezone.longName),
    shortName: normalize(timezone.shortName),
    longGeneric: normalize(timezone.longGeneric),
    shortGeneric: normalize(timezone.shortGeneric),
  }

  // City match scoring
  if (searchableFields.city.startsWith('st ')) {
    const stScore = calculateFieldScore(
      searchableFields.city,
      normalizedSearch,
      SCORE_CONFIG.city.base,
      SCORE_CONFIG.city.exactStart,
    )
    const saintScore = calculateFieldScore(
      `saint ${searchableFields.city.slice(3)}`,
      normalizedSearch,
      SCORE_CONFIG.city.base,
      SCORE_CONFIG.city.exactStart,
    )
    score = Math.max(stScore, saintScore)
  } else {
    score = calculateFieldScore(
      searchableFields.city,
      normalizedSearch,
      SCORE_CONFIG.city.base,
      SCORE_CONFIG.city.exactStart,
    )
  }

  // Region match scoring
  const regionScore = calculateFieldScore(
    searchableFields.region,
    normalizedSearch,
    SCORE_CONFIG.region.base,
    SCORE_CONFIG.region.exactStart,
  )
  if (regionScore > score) score = regionScore

  // Long name match scoring
  const longNameScore = calculateFieldScore(
    searchableFields.longName,
    normalizedSearch,
    SCORE_CONFIG.longName.base,
    SCORE_CONFIG.longName.exactStart,
  )
  if (longNameScore > score) score = longNameScore

  // Short name match scoring
  const shortNameScore = calculateFieldScore(
    searchableFields.shortName,
    normalizedSearch,
    SCORE_CONFIG.shortName.base,
    SCORE_CONFIG.shortName.exactStart,
  )
  if (shortNameScore > score) score = shortNameScore

  // Long generic match scoring
  const longGenericScore = calculateFieldScore(
    searchableFields.longGeneric,
    normalizedSearch,
    SCORE_CONFIG.longGeneric.base,
    SCORE_CONFIG.longGeneric.exactStart,
  )
  if (longGenericScore > score) score = longGenericScore

  // Short generic match scoring
  const shortGenericScore = calculateFieldScore(
    searchableFields.shortGeneric,
    normalizedSearch,
    SCORE_CONFIG.shortGeneric.base,
    SCORE_CONFIG.shortGeneric.exactStart,
  )
  if (shortGenericScore > score) score = shortGenericScore

  return score
}
