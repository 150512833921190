import { graphql } from '~publish/gql'
import { toast } from '@buffer-mono/popcorn'
import { useQuery, NetworkStatus, type ApolloError } from '@apollo/client'
import type {
  GetGroupFeedItemsQuery,
  GetGroupFeedItemsQueryVariables,
} from '~publish/gql/graphql'
import { useInfiniteScrollPagination } from '~publish/hooks/useInfiniteScrollPagination'
import { useFeedUpdates } from './useFeedUpdates'

export type FeedItem = NonNullable<
  GetGroupFeedItemsQuery['feedItems']['edges']
>[number]['node']

export const GetGroupFeedItems = graphql(/* GraphQL */ `
  query GetGroupFeedItems(
    $feedGroupId: FeedGroupId!
    $organizationId: OrganizationId!
    $after: String
  ) {
    feedItems(
      first: 20
      after: $after
      input: {
        organizationId: $organizationId
        filter: { feedGroupIds: [$feedGroupId] }
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          content {
            ... on RSSFeedItemContent {
              title
              contentTextPreview
              articleUrl
              thumbnail
            }
          }
          feed {
            id
            name
          }
        }
      }
    }
  }
`)

type UseFeedGroupItemResult = {
  loading: boolean
  error?: ApolloError
  items: FeedItem[] | null
  fetchingMore: boolean
  lastElementRef: (node: HTMLElement | null) => void
}

export const useFeedItems = (
  feedGroupId: string,
  organizationId: string,
): UseFeedGroupItemResult => {
  const { data, error, refetch, fetchMore, networkStatus } = useQuery<
    GetGroupFeedItemsQuery,
    GetGroupFeedItemsQueryVariables
  >(GetGroupFeedItems, {
    variables: {
      feedGroupId,
      organizationId,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const loading = networkStatus === NetworkStatus.loading
  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  useFeedUpdates('feedGroupUpdated', () => refetch(), [refetch])
  const items = data?.feedItems?.edges?.map((edge) => edge.node) ?? null

  const [lastElementRef] = useInfiniteScrollPagination({
    loading: fetchingMore,
    hasNextPage: data?.feedItems.pageInfo.hasNextPage,
    fetchMore: async () => {
      try {
        await fetchMore({
          variables: {
            after: data?.feedItems.pageInfo.endCursor,
          },
        })
      } catch (error) {
        toast.error('Error loading more feed items')
        throw new Error(
          'Failed to fetch more feed items:' + (error as Error).message,
        )
      }
    },
  })

  return {
    loading,
    lastElementRef,
    fetchingMore,
    error,
    items,
  }
}
