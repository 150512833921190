import React from 'react'
import { useFeedOPML } from '../../hooks/useFeedRecommendations'
import { FeedTile } from './FeedTile'
import styles from './FeedGroupSettings.module.css'

type Props = {
  onAddFeed: (url: string) => void
  opmlUrl: string
}

export const OPMLViewer = (props: Props): JSX.Element => {
  const { onAddFeed, opmlUrl } = props
  const opml = useFeedOPML(opmlUrl)

  return (
    <div>
      <div className={styles.feedsRecommendationListContainer}>
        {opml.data?.feeds.map((feed) => (
          <FeedTile
            key={feed.url}
            name={feed.title}
            url={feed.url}
            isSubscribed={false}
            onSubscribe={onAddFeed}
          />
        ))}
      </div>
      {opml.error && <p>{opml.error.message}</p>}
      {opml.loading && <p>Loading...</p>}
      {opml.data?.feeds.length === 0 && <p>No feeds found</p>}
    </div>
  )
}
