import React, { useState, useCallback, useMemo } from 'react'

import { PageLayout } from '~publish/components/PageLayout'
import {
  Button,
  Flex,
  Text,
  Input,
  Separator,
  Heading,
  IconButton,
  Dialog,
  Label,
  ArrowLeftIcon,
} from '@buffer-mono/popcorn'
import { Link as RRLink, useHistory } from 'react-router-dom'
import { feedGroupPage, createPage } from '~publish/legacy/routes'
import { SubscribeToFeed } from './SubscribeToFeed'
import {
  useUpdateFeedGroup,
  useDeleteFeedGroups,
  type FeedGroupForDetails,
} from '../../hooks'
import { FeedTile } from './FeedTile'
import styles from './FeedGroupSettings.module.css'
import { FeedRecommender } from './FeedRecommender'

type EditFeedGroupProps = {
  feedGroup: FeedGroupForDetails
}

const extractUrls = (feeds: FeedGroupForDetails['feeds']): string[] => {
  const urls: string[] = []
  feeds.forEach((feed) => {
    if (feed.connectionMetadata.url) {
      urls.push(feed.connectionMetadata.url)
    }
  })
  return urls
}

const limit = 5
export const FeedGroupSettings = (props: EditFeedGroupProps): JSX.Element => {
  const { feedGroup } = props
  const [isFeedNameOpen, setIsFeedNameOpen] = useState(false)

  const updateFeedGroup = useUpdateFeedGroup()
  const deleteFeedGroups = useDeleteFeedGroups()

  const [name, setName] = useState(feedGroup.name ?? '')
  const feedUrls = useMemo(
    () => extractUrls(feedGroup.feeds),
    [feedGroup.feeds],
  )
  const history = useHistory()

  const handleDelete = useCallback(async (): Promise<void> => {
    if (feedGroup) {
      await deleteFeedGroups([feedGroup.id])
      history.push(createPage.route)
    }
  }, [feedGroup, deleteFeedGroups, history])

  const handleAddFeed = useCallback(
    (feedUrl: string): void => {
      updateFeedGroup({
        name,
        feedGroupId: feedGroup.id,
        feedUrls: [...feedUrls, feedUrl],
      })
    },
    [name, feedUrls, feedGroup, updateFeedGroup],
  )

  const handleUnsubscribe = useCallback(
    async (feedId: string): Promise<void> => {
      const feedsWithoutMatch = feedGroup.feeds.filter(
        (feed) => feed.id !== feedId,
      )

      await updateFeedGroup({
        name,
        feedGroupId: feedGroup.id,
        feedUrls: extractUrls(feedsWithoutMatch),
      })
    },
    [name, feedGroup, updateFeedGroup],
  )

  const updateName = useCallback(async (): Promise<void> => {
    await updateFeedGroup({
      name,
      feedGroupId: feedGroup.id,
    })
    setIsFeedNameOpen(false)
  }, [feedGroup.id, name, updateFeedGroup])

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.HeaderRow>
          <PageLayout.Title>
            <IconButton
              as={RRLink}
              to={feedGroupPage.linkTo(feedGroup.id)}
              label="Back to Feed"
              tooltip="Back to Feed"
              variant="tertiary"
            >
              <ArrowLeftIcon />
            </IconButton>
            Settings
          </PageLayout.Title>
        </PageLayout.HeaderRow>
      </PageLayout.Header>
      <PageLayout.Container>
        <Heading>General</Heading>
        {isFeedNameOpen && (
          <Dialog onOpenChange={setIsFeedNameOpen} open={isFeedNameOpen}>
            <Dialog.Content size="small">
              <Dialog.Header>
                <Dialog.Title>Rename Feed Group</Dialog.Title>
              </Dialog.Header>
              <Dialog.Body>
                <Label htmlFor="feed-group-name">Name</Label>
                <Input
                  id="feed-group-name"
                  size="large"
                  value={name}
                  onChange={(e): void => setName(e.target.value)}
                />
              </Dialog.Body>
              <Dialog.Footer>
                <Button variant="primary" disabled={!name} onClick={updateName}>
                  Save
                </Button>
              </Dialog.Footer>
            </Dialog.Content>
          </Dialog>
        )}
        <Flex direction="row" justify="between">
          <div>
            <Heading size="xsmall">Name</Heading>
            <Text>Assign a name to your feed group</Text>
          </div>
          <Button
            variant="secondary"
            onClick={(): void => setIsFeedNameOpen(true)}
          >
            Edit
          </Button>
        </Flex>
        <Separator />
        <div>
          <Heading>Feeds</Heading>
          <Text>Subscribe to feeds to add content to your feed group</Text>
        </div>
        <div className={styles.feedsListContainer}>
          {feedGroup.feeds.map((feed) => (
            <FeedTile
              key={feed.id}
              id={feed.id}
              name={feed.name}
              url={feed.connectionMetadata.url}
              isSubscribed
              onUnsubscribe={handleUnsubscribe}
            />
          ))}
          <SubscribeToFeed
            onAdd={handleAddFeed}
            disabledReason={
              feedGroup.feeds.length < limit ? null : 'limitReached'
            }
          />
        </div>
        <Separator />
        <FeedRecommender onAddFeed={handleAddFeed} />
        <Separator />
        <Heading>Advanced</Heading>
        <Flex direction="row" justify="between">
          <div>
            <Heading size="xsmall">Delete Feed Group</Heading>
            <Text>
              Remove feed group from your account. This action cannot be undone.
            </Text>
          </div>
          <Button variant="critical" onClick={handleDelete}>
            Delete Feed Group
          </Button>
        </Flex>
      </PageLayout.Container>
    </PageLayout>
  )
}
