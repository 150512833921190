import React, { useMemo, useState } from 'react'

import {
  Button,
  Input,
  Label,
  Text,
  Dialog,
  PlusIcon,
  BookmarkIcon,
  Flex,
} from '@buffer-mono/popcorn'

import styles from './FeedGroupSettings.module.css'

type SubscribeToFeedProps = {
  onAdd: (feedUrl: string) => void
  disabledReason: 'limitReached' | null
}

export const SubscribeToFeed = (props: SubscribeToFeedProps): JSX.Element => {
  const { onAdd, disabledReason } = props
  const [isOpen, setIsOpen] = useState(false)
  const [feedUrl, setFeedUrl] = useState('')
  const [isValid, message] = useMemo(() => {
    try {
      if (!feedUrl || feedUrl.length === 0) {
        return [false, 'Feed URL is required']
      }
      if (!feedUrl.match(/^https?:\/\//)) {
        return [false, 'Feed URL must start with http:// or https://']
      }
      return [new URL(feedUrl) !== null, null]
    } catch (e) {
      const message = e instanceof Error ? e.message : String(e)
      return [false, message]
    }
  }, [feedUrl])

  return (
    <>
      {isOpen && (
        <Dialog onOpenChange={setIsOpen} open={isOpen}>
          <Dialog.Content size="small">
            <Dialog.Header>
              <Dialog.Title>Subscribe to Feed</Dialog.Title>
            </Dialog.Header>
            <Dialog.Body>
              <Label htmlFor="new-feed-url">Feed URL</Label>
              <Input
                id="new-feed-url"
                size="large"
                aria-invalid={!isValid}
                value={feedUrl}
                onChange={(e): void => setFeedUrl(e.target.value)}
              />
              {!isValid && (
                <Text size="sm" color="critical">
                  {message}
                </Text>
              )}
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                variant="primary"
                disabled={!isValid}
                onClick={(): void => onAdd(feedUrl)}
              >
                <BookmarkIcon />
                Subscribe
              </Button>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog>
      )}
      <Button
        variant="secondary"
        onClick={(): void => setIsOpen(true)}
        className={styles.subscribeToFeedTrigger}
        disabled={disabledReason !== null}
      >
        <Flex gap="xs" align="center" direction="column" justify="center">
          <Flex gap="xs" align="center" direction="row" justify="center">
            <PlusIcon />
            <span>Add Subscription</span>
          </Flex>
          {disabledReason === 'limitReached' && (
            <Text size="sm">You have reached the limit of 5 feeds</Text>
          )}
        </Flex>
      </Button>
    </>
  )
}
