import { RPCClient } from '@buffer-mono/legacy-rpc-utils'
import {
  Button,
  CheckCircleIcon,
  CopyPlusIcon,
  CriticalIcon,
  Dialog,
  Flex,
  Heading,
  Paragraph,
  SimpleSpinner,
  Text,
  Tooltip,
  UnstyledButton,
} from '@buffer-mono/popcorn'
import { createNextState } from '@reduxjs/toolkit'
import clsx from 'clsx'
import Papa from 'papaparse'
import React from 'react'
import * as UploadDropzone from '~publish/components/UploadDropzone'
import { selectCurrentProfileId } from '~publish/legacy/profile-sidebar/selectors'
import { useAppSelector } from '~publish/legacy/store'
import styles from './BulkPostUpload.module.css'
import { ConfirmDialog } from './ConfirmDialog'
import { Progress } from './Progress'
import { csvLineSchema, type CSVLine } from './validators'

const isError = (item: ListItem): boolean => item.status === 'error'
const isSuccess = (item: ListItem): boolean => item.status === 'success'

type Post = {
  profile_ids: string[]
  text: string
  media: { photo?: string }
}

type ListItem = {
  id: string
  status: 'valid' | 'loading' | 'success' | 'error'
  errors?: string[]
  data: Post
}

const validateAndConstructItem = (
  row: CSVLine,
  profileId: string,
): ListItem => {
  const validationResult = csvLineSchema.safeParse(row)
  const errorMessages =
    validationResult.success === false
      ? validationResult.error.issues.map((issue) => issue.message)
      : []

  return {
    id: crypto.randomUUID(),
    data: {
      profile_ids: [profileId],
      text: row.text,
      media: { photo: row.image ?? undefined },
    },
    status: validationResult.success ? 'valid' : 'error',
    errors: errorMessages,
  }
}

const rpc = new RPCClient({
  url: '/rpc',
  sendCredentials: 'same-origin',
})

type CreatePostResponse = {
  success: boolean
  message?: string
  code?: number
}

const createPost = async (post: Post): Promise<CreatePostResponse> => {
  return rpc.call('composerApiProxy', {
    url: '/1/updates/create.json',
    args: post,
    HTTPMethod: 'POST',
  }) as Promise<CreatePostResponse>
}

const simulateRequest = (): Promise<CreatePostResponse> => {
  return new Promise((resolve, reject) => {
    // Random timeout between 1-3 seconds
    const timeout = 1000 + Math.random() * 2000

    // 20% chance of failure
    const shouldFail = Math.random() < 0.2

    setTimeout(() => {
      if (shouldFail) {
        reject(new Error('Random test failure'))
      } else {
        resolve({ success: true })
      }
    }, timeout)
  })
}

const ListItem = ({ item }: { item: ListItem }): JSX.Element => {
  return (
    <Flex
      className={styles.listItem}
      align="center"
      aria-invalid={item.status === 'error'}
      data-status={item.status}
    >
      <Flex className={styles.itemStatus}>
        {item.status === 'loading' ? (
          <SimpleSpinner />
        ) : item.status === 'success' ? (
          <CheckCircleIcon color="success" size="small" />
        ) : item.status === 'error' ? (
          <Tooltip content={item.errors}>
            <UnstyledButton className={styles.errorIconButton}>
              <CriticalIcon
                className={styles.itemStatus}
                color="critical"
                size="small"
              />
            </UnstyledButton>
          </Tooltip>
        ) : null}
      </Flex>
      <Text className={styles.itemText} truncate>
        {item.data.text}
      </Text>
      <Text className={styles.itemImage} truncate>
        {item.data?.media?.photo}
      </Text>
    </Flex>
  )
}

const updateItemStatus = (
  items: ListItem[],
  id: string,
  status: ListItem['status'],
  errors?: string[],
): ListItem[] => {
  return createNextState(items, (draft) => {
    const item = draft.find((item) => item.id === id)
    if (item) {
      item.status = status
      if (errors) item.errors = errors
    }
    return draft
  })
}

type ItemPromises = Array<[string, Promise<CreatePostResponse>]>

const CsvUploader = ({
  onUploadComplete,
}: {
  onUploadComplete: (items: ListItem[]) => void
}): JSX.Element => {
  const profileId = useAppSelector(selectCurrentProfileId)

  const parseCsv = (file: File): void => {
    Papa.parse<CSVLine>(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const items = result.data.map((row) =>
          validateAndConstructItem(row, profileId),
        )
        onUploadComplete(items)
      },
    })
  }
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event.target.files?.[0]
    if (file) {
      parseCsv(file)
    }
  }

  const onDrop = (files: File[]): void => {
    const file = files[0]
    if (file) {
      parseCsv(file)
    }
  }

  return (
    <>
      <Dialog.Body className={styles.body}>
        <UploadDropzone.Root onDrop={onDrop}>
          <UploadDropzone.Overlay className={styles.dropzoneOverlay}>
            <Text size="lg" color="subtle" weight="bold">
              Drop a CSV file here
            </Text>
          </UploadDropzone.Overlay>
          <Flex
            className={clsx(styles.fullWidth, styles.fullHeight)}
            direction="column"
            justify="center"
            align="center"
            gap="sm"
          >
            <CopyPlusIcon size="large" />
            <Text size="md">
              Drag and drop or{' '}
              <UnstyledButton
                as="label"
                htmlFor="csv-upload"
                className={styles.uploadCsvButton}
              >
                upload a CSV file
              </UnstyledButton>
              <input
                type="file"
                id="csv-upload"
                hidden
                // TODO: Doesn't upload file
                onChange={handleFileChange}
              />
            </Text>
          </Flex>
        </UploadDropzone.Root>
      </Dialog.Body>
    </>
  )
}

const ListItemView = ({
  items,
  setItems,
}: {
  items: ListItem[]
  setItems: React.Dispatch<React.SetStateAction<ListItem[]>>
}): JSX.Element => {
  // const [isCreatingPosts, setIsCreatingPosts] = React.useState(false)
  const [postPromises, setPostPromises] = React.useState<ItemPromises>([])
  const [progress, setProgress] = React.useState(0)

  console.log('postPromises.length: ', postPromises.length)
  const isCreatingPosts = postPromises.length > 0
  const isPostCreationComplete = items.every(
    (item) => item.status === 'error' || item.status === 'success',
  )

  React.useEffect(() => {
    if (postPromises.length === 0) return

    let completed = 0
    const total = postPromises.length

    postPromises.forEach(([id, promise]) => {
      promise
        .then((result) => {
          if (!result.success) {
            throw new Error(result.message)
          }
          setItems((items) => updateItemStatus(items, id, 'success'))
        })
        .catch((error) => {
          setItems((items) =>
            updateItemStatus(items, id, 'error', [error.message]),
          )
        })
        .finally(() => {
          completed++
          if (completed === total) {
            setPostPromises([])
            setProgress(0)
          } else {
            setProgress((completed / total) * 100)
          }
        })
    })
  }, [postPromises, setItems])

  const onCreatePosts = async (): Promise<void> => {
    const promises: ItemPromises = items
      .filter((row) => row.status === 'valid')
      .map((row) => {
        setItems((items) => updateItemStatus(items, row.id, 'loading'))
        // const promise = simulateRequest()
        const promise = createPost(row.data)
        return [row.id, promise]
      })
    setPostPromises(promises ?? [])
  }

  const onUploadeMore = (): void => {
    setItems([])
  }

  const sortedItems = React.useMemo(() => {
    return items.sort((a, b) =>
      a.status === 'error' ? -1 : b.status === 'error' ? 1 : 0,
    )
  }, [items])

  const errorItemCount = sortedItems.filter(isError).length
  const successItemCount = sortedItems.filter(isSuccess).length

  return (
    <>
      <Dialog.Header>
        {isPostCreationComplete ? (
          <>
            <Heading size="small">Post Creation Complete</Heading>
            {successItemCount > 0 && (
              <Text color="success">
                {successItemCount} posts successfully created
              </Text>
            )}
            {errorItemCount > 0 && (
              <Text color="critical">
                {sortedItems.filter(isError).length} items failed
              </Text>
            )}
          </>
        ) : isCreatingPosts ? (
          <>
            <Heading size="small">Creating posts...</Heading>
            <Flex direction="column" gap="xs">
              <Text>Creating posts... {Math.round(progress)}%</Text>
              <Progress value={progress} max={100} />
            </Flex>
          </>
        ) : (
          <>
            <Heading size="small">Upload Complete</Heading>
            <Text>{sortedItems.length} items</Text>
            {errorItemCount > 0 && (
              <Text color="critical">
                {sortedItems.filter(isError).length} items failed
              </Text>
            )}
          </>
        )}
      </Dialog.Header>
      <Dialog.Body className={styles.body}>
        <Flex direction="column" gap="sm">
          {sortedItems.map((item) => (
            <ListItem key={item.id} item={item} />
          ))}
        </Flex>
      </Dialog.Body>
      <Dialog.Separator />
      <Dialog.Footer>
        <Dialog.Close>
          <Button variant="secondary" size="large">
            {isPostCreationComplete ? 'Close' : 'Cancel'}
          </Button>
        </Dialog.Close>
        {isPostCreationComplete ? (
          <Button variant="primary" size="large" onClick={onUploadeMore}>
            Upload More
          </Button>
        ) : (
          <ConfirmDialog onConfirm={onCreatePosts}>
            <Button size="large">Create Posts</Button>
          </ConfirmDialog>
        )}
      </Dialog.Footer>
    </>
  )
}

const PostUploader = (): JSX.Element => {
  const [items, setItems] = React.useState<ListItem[]>([])

  return items?.length ? (
    <ListItemView items={items} setItems={setItems} />
  ) : (
    <CsvUploader onUploadComplete={setItems} />
  )
}

/**
 * Bulk Post Upload Dialog
 * Separate the Dialog from the Page component
 * But also contain the Dialog.Content component as whatever renders it,
 * also renders Dialog.Portal which prevents local state from resetting
 */
const BulkPostUploadDialog = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    <Dialog>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      {/* Dialog.Content is not rendered inside the CsvUploader component */}
      {/* because it contains the Dialog.Portal component which prevents */}
      {/* state from resetting between renders */}
      {/* https://github.com/shadcn-ui/ui/issues/2584 */}
      <Dialog.Content className={styles.content}>
        <Dialog.Header>
          <Dialog.Title>Bulk Post Uploader</Dialog.Title>
          {/* <Dialog.Description>
            Upload a CSV file to schedule multiple posts in bulk.
          </Dialog.Description> */}
        </Dialog.Header>
        <Dialog.Separator />
        <PostUploader />
      </Dialog.Content>
    </Dialog>
  )
}

export const BulkPostUpload = (): JSX.Element => {
  return (
    <Flex direction="column" className={styles.fullWidth}>
      <Heading size="small" as="h3">
        Bulk Post Upload
      </Heading>
      <Flex as="section" justify="between" className={styles.fullWidth}>
        <Paragraph color="subtle">
          Schedule multiple posts in bulk by uploading a CSV file.
        </Paragraph>
        <BulkPostUploadDialog key="csv-uploader">
          <Button
            variant="secondary"
            size="large"
            className={styles.openDialogButton}
          >
            Upload CSV
          </Button>
        </BulkPostUploadDialog>
      </Flex>
    </Flex>
  )
}
