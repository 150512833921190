import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import { CreatePageLayout } from './components/Layout/CreatePageLayout'

import { IdeasPage } from './IdeasPage'

export function CreatePage(): JSX.Element {
  const { isEnabled: isContentFeedEnabled } =
    useSplitEnabled('content_feed_alpha')

  if (isContentFeedEnabled) {
    return (
      <CreatePageLayout>
        <IdeasPage pageTitle="Ideas" />
      </CreatePageLayout>
    )
  }

  return <IdeasPage />
}
