import { BufferUploadsApi } from '../clients/BufferUploadsApi'
import {
  BasePlugin,
  type Uppy,
  type PluginOptions,
  type UploadedUppyFile,
  UppyFile,
  type BufferUpload,
  type Upload,
} from '@buffer-mono/uploader'

export interface BufferUploadsUppyPluginOptions extends PluginOptions {
  onComplete: (files: BufferUpload) => void
  onError: (file: Upload, error: Error) => void
}

export default class BufferUploadsUppyPlugin extends BasePlugin<BufferUploadsUppyPluginOptions> {
  private onComplete: ((file: BufferUpload) => void) | undefined
  private onError: ((file: Upload, error: Error) => void) | undefined

  constructor(uppy: Uppy, opts?: BufferUploadsUppyPluginOptions) {
    super(uppy, opts)

    this.id = 'BufferUploadsUppyPlugin'
    this.type = 'postprocess'
    this.onComplete = opts?.onComplete
    this.onError = opts?.onError

    this.createBufferUploads = this.createBufferUploads.bind(this)
  }

  async createBufferUploads(fileIds: string[]): Promise<void> {
    const promises = fileIds.map((fileId) => {
      const file = this.uppy.getFile(fileId) as UploadedUppyFile
      const upload = UppyFile.toS3Upload(file)

      if (!file.meta.parentUploadId) {
        return BufferUploadsApi.create(upload)
          .then((bufferUpload) => {
            this.onComplete?.(bufferUpload)
          })
          .catch((error) => {
            error.message = `Upload failed for ${file.name}. ${error.message}`

            this.onError?.(upload, error)
          })
      }

      return Promise.resolve()
    })

    await Promise.all(promises)
  }

  install(): void {
    this.uppy.addPostProcessor(this.createBufferUploads)
  }

  uninstall(): void {
    this.uppy.removePostProcessor(this.createBufferUploads)
  }
}
