import React from 'react'
import { Text } from '@bufferapp/ui'
import { Switch } from '@buffer-mono/popcorn'

import { Row } from '~publish/legacy/shared-components'

type NotificationProps = {
  title: string
  description?: string
  onToggleClick: (toggleIsEnabled: boolean, type: string) => void
  toggleIsEnabled: boolean
  type: string
}

const Notification = ({
  title,
  description,
  onToggleClick,
  toggleIsEnabled,
  type,
}: NotificationProps): JSX.Element => {
  return (
    <Row>
      <div>
        <Text type="h3">{title}</Text>
        {description && <Text type="p">{description}</Text>}
      </div>
      <Switch
        checked={toggleIsEnabled}
        onCheckedChange={(checked): void => {
          onToggleClick(checked, type)
        }}
      >
        {toggleIsEnabled ? 'Enabled' : 'Disabled'}
      </Switch>
    </Row>
  )
}

export default Notification
