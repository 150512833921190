import React, { useCallback, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Button, Tooltip } from '@bufferapp/ui'
import {
  CopyPlusIcon,
  DropdownMenu,
  IconButton,
  toast,
} from '@buffer-mono/popcorn'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useAccount } from '~publish/legacy/accountContext'
import type { ExecutePostActionById } from '~publish/legacy/post/hooks/usePostActions'
import type { PostAction, RpcUpdate } from '~publish/legacy/post/types'
import { ThreadsFreeOBPaywall } from '~publish/legacy/composer/composer/components/ThreadsFreeOBPaywall'
import { MoreActionsDropdown } from '~publish/legacy/shared-components/MoreActionsDropdown/MoreActionsDropdown'
import type { PublishingPost } from '~publish/gql/graphql'
import { omitFields } from '~publish/helpers/object'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import ConfirmDeleteButton from '~publish/legacy/shared-components/ConfirmDeleteButton'
import callRpc from '~publish/legacy/utils/call-rpc'
import { calendarPage, tagsPage } from '~publish/legacy/routes'

import { PostEntity } from '../../PostEntity'
import {
  ButtonWrapper,
  SingleActionButton,
  StackedActionButton,
} from './styles'
import { getThreadedUpdatesUpgradePath } from '~publish/legacy/composer/composer/utils/upgradePaths'

const omittedFields: (keyof RpcUpdate)[] = [
  'id',
  'due_at',
  'day',
  'scheduled_at',
  'scheduledAt',
  'serviceUpdateId',
  'createdAt',
  'statistics',
  'status',
  'serviceLink',
]

type OmittedField = (typeof omittedFields)[number]

type RpcUpdateWithoutOmittedFields = Omit<RpcUpdate, OmittedField>

const omitDuplicatedFields = (
  post: RpcUpdate,
): RpcUpdateWithoutOmittedFields => {
  const newPost = omitFields(post, omittedFields)

  return {
    ...newPost,
    isSent: false,
    isPastDue: false,
    isDraft: false,
  }
}

const DuplicateIconMenu = ({
  post,
}: {
  post: RpcUpdate | PublishingPost
}): JSX.Element => {
  const { duplicatePostInComposer } = usePostComposer()
  const cta = 'publish-calendar-postCard-duplicatePost-1'

  const handleDuplicatePost = useCallback(async () => {
    let rpcPost: RpcUpdate = post as RpcUpdate
    try {
      if (PostEntity.isPublishingPost(post)) {
        rpcPost = await callRpc('getPost', { updateId: post.id })
      }

      const prefillPostData = omitDuplicatedFields(rpcPost) as RpcUpdate
      duplicatePostInComposer({
        cta,
        channels: [rpcPost.profileId],
        prefillPostData,
        duplicatedFrom: post.id,
      })
    } catch (err: unknown) {
      const error = err as Error
      toast.error(`Failed to duplicate post: ${error.message}`)
    }
  }, [duplicatePostInComposer, post])

  return (
    <IconButton
      label="Duplicate"
      tooltip="Duplicate post"
      onClick={handleDuplicatePost}
      aria-controls="composer-root"
    >
      <CopyPlusIcon />
    </IconButton>
  )
}

const DuplicateMenuItem = ({
  post,
}: {
  post: RpcUpdate | PublishingPost
}): JSX.Element | null => {
  const [loading, setLoading] = useState(false)
  const { duplicatePostInComposer } = usePostComposer()
  const cta = 'publish-queue-postCard-duplicatePost-1'

  const handleDuplicatePost = useCallback(async () => {
    setLoading(true)

    let rpcPost: RpcUpdate = post as RpcUpdate
    try {
      if (PostEntity.isPublishingPost(post)) {
        rpcPost = await callRpc('getPost', { updateId: post.id })
      }

      const prefillPostData = omitDuplicatedFields(rpcPost) as RpcUpdate
      duplicatePostInComposer({
        cta,
        channels: [rpcPost.profileId],
        prefillPostData,
        duplicatedFrom: post.id,
      })
    } catch (err: unknown) {
      const error = err as Error
      toast.error(`Failed to duplicate post: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }, [duplicatePostInComposer, post])

  return (
    <DropdownMenu.Item
      loading={loading}
      onClick={handleDuplicatePost}
      aria-controls="composer-root"
    >
      Duplicate
    </DropdownMenu.Item>
  )
}

export type PostFooterButtonsProps = {
  isLoading?: boolean
  singleActions: PostAction[]
  stackedActions: PostAction[]
  executePostActionById: ExecutePostActionById
  onDeleteClick?: () => void
  onEditClick?: () => void
  shouldShowThreadsMigrationModal?: boolean
  threadsMigrationModalService?: string
  setOpenModal?: (args: {
    open: boolean
    ctaString: string
    service: string
  }) => void
  post: RpcUpdate | PublishingPost
}

const PostFooterButtons = ({
  isLoading,
  singleActions,
  stackedActions,
  executePostActionById,
  onDeleteClick,
  onEditClick,
  shouldShowThreadsMigrationModal,
  setOpenModal,
  threadsMigrationModalService,
  post,
}: PostFooterButtonsProps): JSX.Element => {
  const { account } = useAccount()
  const isOnTagsPage = Boolean(useRouteMatch(tagsPage.route))
  const isOnCalendarPage = Boolean(useRouteMatch(calendarPage.route))
  const commonTrackingProps =
    account?.currentOrganization?.commonTrackingProperties || null

  const [openInnerModal, setOpenInnerModal] = useState(false)
  const [firstAction, ...restStackedActions] = stackedActions

  const closeInnerModal = (): void => {
    setOpenInnerModal(false)
  }
  const threadsUpgradePath = getThreadedUpdatesUpgradePath(
    threadsMigrationModalService ?? 'omni',
  )

  return (
    <ButtonWrapper>
      {onDeleteClick && (
        <ConfirmDeleteButton
          onDeleteClick={onDeleteClick}
          isLoading={isLoading}
        />
      )}
      {onEditClick && (
        // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; size: string;... Remove this comment to see the full error message
        <Button
          type="text"
          label="Edit"
          size="small"
          aria-controls="composer-root"
          disabled={isLoading}
          onClick={onEditClick}
        />
      )}

      {!!singleActions &&
        !shouldShowThreadsMigrationModal &&
        singleActions.map((action) => (
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message
          <Tooltip label={action.tooltip} position="bottom" key={action.id}>
            <SingleActionButton
              type="secondary"
              label={action.title}
              size="small"
              aria-controls="composer-root"
              onClick={action.callback}
              disabled={isLoading || action.disabled}
              data-testid="single-action-button"
            />
          </Tooltip>
        ))}
      {shouldShowThreadsMigrationModal && (
        // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; size:... Remove this comment to see the full error message
        <Button
          data-testid="threads-free-paywall-button"
          type="primary"
          size="small"
          icon={<FlashIcon color="white" />}
          label="Upgrade to Schedule Thread"
          onClick={
            setOpenModal
              ? (): void => {
                  setOpenModal({
                    open: true,
                    ctaString:
                      'calendarDraft-upgradeButton-upgradeToScheduleThread-1',
                    service: threadsMigrationModalService ?? 'omni',
                  })
                  BufferTracker.cTAClicked({
                    organizationId: account?.currentOrganization?.id || '',
                    cta: `publish-calendar-post-upgradeToScheduleThread-1`,
                    upgradePathName: threadsUpgradePath,
                    product: 'publish',
                    clientName: 'publishWeb',
                    ...commonTrackingProps,
                  })
                }
              : (): void => {
                  setOpenInnerModal(true)
                  BufferTracker.cTAClicked({
                    organizationId: account?.currentOrganization?.id || '',
                    cta: `publish-draft-post-upgradeToScheduleThread-1`,
                    upgradePathName: threadsUpgradePath,
                    product: 'publish',
                    clientName: 'publishWeb',
                    ...commonTrackingProps,
                  })
                }
          }
          onOpen={(): void => {
            // Disable CTA viewed tracking for now - see https://buffer.atlassian.net/browse/GROWTH-1081
            // BufferTracker.cTAViewed({
            //   organizationId: account?.currentOrganization?.id || '',
            //   cta: `publish-draft-post-upgradeToScheduleThread-1`,
            //   upgradePathName: threadsUpgradePath,
            //   product: 'publish',
            //   ...commonTrackingProps,
            // })
          }}
        />
      )}
      {firstAction && (
        <StackedActionButton
          size="small"
          type="secondary"
          isSplit={!!restStackedActions}
          items={restStackedActions}
          label={firstAction.title}
          onClick={firstAction.callback}
          onSelectClick={executePostActionById}
          disabled={isLoading}
        />
      )}
      {openInnerModal && (
        <ThreadsFreeOBPaywall
          closeFirstModal={closeInnerModal}
          ctaString="drafts-upgradeButton-upgradeToScheduleThread-1"
          service={threadsMigrationModalService ?? 'omni'}
        />
      )}
      {isOnCalendarPage && <DuplicateIconMenu post={post} />}
      {!isOnTagsPage && !isOnCalendarPage && (
        <MoreActionsDropdown>
          <MoreActionsDropdown.Group>
            <DuplicateMenuItem post={post} />
          </MoreActionsDropdown.Group>
        </MoreActionsDropdown>
      )}
    </ButtonWrapper>
  )
}

PostFooterButtons.propTypes = {
  isLoading: PropTypes.bool,
  shouldShowThreadsMigrationModal: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  setOpenModal: PropTypes.func,
}

PostFooterButtons.defaultProps = {
  isLoading: false,
  shouldShowThreadsMigrationModal: false,
  onDeleteClick: null,
  onEditClick: null,
  setOpenModal: null,
}

export default PostFooterButtons
