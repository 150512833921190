import React, { useState, useCallback } from 'react'

import { Button, Input, Dialog, Label } from '@buffer-mono/popcorn'
import { useHistory } from 'react-router-dom'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useCreateNewFeedGroup } from '../../hooks'
import { feedGroupSettingsPage } from '~publish/legacy/routes'

type Props = {
  onOpenChange: (open: boolean) => void
  open?: boolean
}
export const NewFeedName = (props: Props): JSX.Element => {
  const { onOpenChange, open } = props

  const organizationId = useOrganizationId()

  const createFeedGroup = useCreateNewFeedGroup()

  const [name, setName] = useState('')
  const history = useHistory()

  const handleCreateFeed = useCallback(async (): Promise<void> => {
    const feed = await createFeedGroup({
      name,
      organizationId,
      feedUrls: [],
    })
    if (feed?.__typename === 'RSSFeedGroupActionSuccess') {
      history.push(feedGroupSettingsPage.linkTo(feed.feedGroup.id))
      onOpenChange(false)
    }
  }, [name, createFeedGroup, organizationId, history, onOpenChange])

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <Dialog.Content size="small">
        <Dialog.Header>
          <Dialog.Title>Create Feed Group</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Label htmlFor="feed-group-name">Name</Label>
          <Input
            id="feed-group-name"
            size="large"
            value={name}
            onChange={(e): void => setName(e.target.value)}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <Button variant="primary" disabled={!name} onClick={handleCreateFeed}>
            Save
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
