import React from 'react'
import styled from 'styled-components'
import SimpleModal from '@bufferapp/ui/SimpleModal'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import {
  BlueCheckIcon,
  ContentWrapper,
  Item,
  Left,
  List,
  StyledImage,
} from './ThreadsFreeOBPaywall'

const ContentWrapperTall = styled(ContentWrapper)`
  height: 490px;
`

export const ThreadsMPPaywall = () => {
  const MIGRATION_HUB_URL =
    'https://account.buffer.com/new-plans?cta=threadsModalBtn-migration-cta'
  return (
    <SimpleModal
      closeAction={() => {
        // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
        ModalActionCreators.closeModal()
      }}
    >
      <ContentWrapperTall>
        <Left>
          <Text type="h2">Unlock Threads</Text>
          <Text type="p">
            You&apos;re on a <strong>legacy Buffer plan</strong>. Switch over to
            the newest version to unlock Threads. Plus you&apos;ll receive:
          </Text>
          <List>
            <Item>
              <BlueCheckIcon />
              Only pay for active channels{' '}
            </Item>
            <Item>
              <BlueCheckIcon />
              Unlimited team members (available on our team plan){' '}
            </Item>
            <Item>
              <BlueCheckIcon />
              Access to Analytics included on all plans{' '}
            </Item>
            <Item>
              <BlueCheckIcon />
              Access to all new features guaranteed{' '}
            </Item>
            <Item>
              <BlueCheckIcon />
              Access to our beta program{' '}
            </Item>
            <Item>
              <BlueCheckIcon />
              Access to Ideas{' '}
            </Item>
          </List>
          <Button
            data-testid="threads-mp-paywall-button"
            type="primary"
            label="Find Out More"
            onClick={() => {
              // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
              ModalActionCreators.closeModal()
              window.open(MIGRATION_HUB_URL, '_blank')
            }}
          />
        </Left>
        <StyledImage src="https://buffer-publish.s3.amazonaws.com/images/threads-migration-path%402x.png" />
      </ContentWrapperTall>
    </SimpleModal>
  )
}

ThreadsMPPaywall.propTypes = {}

ThreadsMPPaywall.defaultProps = {}
