import React from 'react'
import PropTypes from 'prop-types'
import { Cross, OpenNew } from '@bufferapp/ui/Icon'
import Tooltip from '@bufferapp/ui/Tooltip'

import { UserName, CloseButton, Link } from './style'

// @ts-expect-error TS(7006) FIXME: Parameter 'username' implicitly has an 'any' type.
const getUrl = (username) => `https://www.instagram.com/${username}`

// @ts-expect-error TS(7031) FIXME: Binding element 'tag' implicitly has an 'any' type... Remove this comment to see the full error message
const TagListItem = ({ tag, index, lastItem, removeTag, translations }) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <UserName key={index} lastItem={lastItem}>
    <CloseButton
      type="button"
      onClick={() => {
        removeTag(tag)
      }}
      tabIndex={0}
    >
      <Cross size="small" />
    </CloseButton>
    {`@${tag.username}`}
    <Link target="_blank" href={getUrl(tag.username)}>
      <Tooltip label={translations.tooltip} position="top">
        <OpenNew size="medium" />
      </Tooltip>
    </Link>
  </UserName>
)

TagListItem.propTypes = {
  tag: PropTypes.shape({
    username: PropTypes.string,
    x: PropTypes.string,
    y: PropTypes.string,
  }).isRequired,
  translations: PropTypes.shape({
    tooltip: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  removeTag: PropTypes.func.isRequired,
  lastItem: PropTypes.bool.isRequired,
}

export default TagListItem
