import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Input from '@bufferapp/ui/Input'
import ColorSwatches from '../ColorSwatches'

import {
  ColorPopup,
  ColorContainer,
  ColorInputWrapper,
  ColorInput,
  InputWrapper,
  DEFAULT_COLOR,
} from '../../styles'

import { isHexValid, getValidHex } from '../../utils/HexValidations'

// @ts-expect-error TS(7006) FIXME: Parameter 'ref' implicitly has an 'any' type.
const useOutsideClick = (ref, callback) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  })
}

const ColorSelectorPopup = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'left' implicitly has an 'any' typ... Remove this comment to see the full error message
  left,
  // @ts-expect-error TS(7031) FIXME: Binding element 'colorSelected' implicitly has an ... Remove this comment to see the full error message
  colorSelected,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isValidHex' implicitly has an 'an... Remove this comment to see the full error message
  isValidHex,
  // @ts-expect-error TS(7031) FIXME: Binding element 'setIsValidHex' implicitly has an ... Remove this comment to see the full error message
  setIsValidHex,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onBlur' implicitly has an 'any' t... Remove this comment to see the full error message
  onBlur,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onChange' implicitly has an 'any'... Remove this comment to see the full error message
  onChange,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onColorChange' implicitly has an ... Remove this comment to see the full error message
  onColorChange,
}) => {
  const containerEl = useRef(null)
  const ref = useRef()
  const [lastValidColor, setLastValidColor] = useState(
    colorSelected || DEFAULT_COLOR,
  )

  useOutsideClick(ref, () => {
    onBlur()
  })

  // @ts-expect-error TS(7006) FIXME: Parameter 'newColor' implicitly has an 'any' type.
  const onColorSelectionChange = (newColor) => {
    onColorChange(newColor, onChange)
    setLastValidColor(newColor)
  }

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <ColorPopup ref={ref} left={left}>
      <ColorSwatches
        onChange={onChange}
        onColorChange={(color) => {
          onColorSelectionChange(color)
        }}
        colorSelected={colorSelected}
      />
      <ColorContainer>
        <ColorInputWrapper color={colorSelected}>
          <ColorInput
            ref={containerEl}
            type="color"
            value={colorSelected}
            id="colorWheel"
            onChange={(e) => {
              onColorSelectionChange(e.target.value)
            }}
          />
        </ColorInputWrapper>
        <InputWrapper>
          <Input
            type="input"
            prefix={{ text: '#', paddingLeft: '18px' }}
            // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
            onChange={(e) => {
              const color = `#${e.target.value}`
              const isValidColor = isHexValid(color)
              setIsValidHex(isValidColor)
              onColorChange(color, onChange)
            }}
            value={colorSelected.replace('#', '')}
            name="colorInput"
            disabled={false}
            hasError={!isValidHex}
            onBlur={() => {
              if (!isValidHex) {
                const selectedHex = getValidHex(colorSelected, lastValidColor)
                onColorSelectionChange(selectedHex)
                setIsValidHex(true)
              }
            }}
            // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
            onKeyUp={(e) => {
              // handle return key
              if (e.keyCode === 13) {
                e.preventDefault()
                const color = `#${e.target.value}`
                const isValidColor = isHexValid(color)
                if (isValidColor) {
                  onBlur()
                }
              }
            }}
            maxLength="6"
          />
        </InputWrapper>
      </ColorContainer>
    </ColorPopup>
  )
}

ColorSelectorPopup.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onColorChange: PropTypes.func,
  setIsValidHex: PropTypes.func,
  colorSelected: PropTypes.string,
  isValidHex: PropTypes.bool,
  left: PropTypes.number,
}

ColorSelectorPopup.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  setIsValidHex: () => {},
  onColorChange: () => {},
  colorSelected: '',
  isValidHex: true,
  left: 0,
}

export default ColorSelectorPopup
