import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { store } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { UploadSource } from '@buffer-mono/uploader'
import {
  type CanvaDesignConfig,
  CanvaDesignConfigValues,
  CanvaLiterals,
} from './constants'
import type { Uploader, UploadResult } from '@buffer-mono/uploader'

export const getCanvaDesignConfig = (serviceId: string): CanvaDesignConfig => {
  const config = CanvaDesignConfigValues.find((s) => s.service === serviceId)

  if (!config) throw new Error('Missing design option for canva')

  return config
}

// @ts-expect-error TS(7006) FIXME: Parameter 'serviceId' implicitly has an 'any' type... Remove this comment to see the full error message
const getCanvaChannel = (serviceId): string => {
  return serviceId === 'omni'
    ? 'multiple'
    : getCanvaDesignConfig(serviceId).service
}

// @ts-expect-error TS(7006) FIXME: Parameter 'serviceId' implicitly has an 'any' type... Remove this comment to see the full error message
const getTrackingMetadata = (serviceId, designId?: string) => {
  const organizationId = selectCurrentOrganizationId(store.getState())
  return {
    organizationId,
    clientName: 'publishWeb',
    product: 'publish',
    channel: getCanvaChannel(serviceId),
    designId,
  }
}

type CanvaPublishedOpts = {
  designId: string
  exportUrl: string
}

const uploadCanvaDesign = (
  opts: CanvaPublishedOpts,
  uploader: Uploader,
): Promise<UploadResult> => {
  return uploader.addFilesFromUrls(
    [
      {
        remoteUrl: opts.exportUrl,
        name: CanvaLiterals.CanvaFilename,
      },
    ],
    UploadSource.canva(opts.designId),
  )
}

const editTracking = {
  clicked: BufferTracker.canvaButtonEditClicked,
  published: BufferTracker.canvaDesignRepublished,
  attached: BufferTracker.canvaImageEditAttached,
  designOpen: BufferTracker.canvaDesignEditOpened,
  designClose: BufferTracker.canvaDesignEditClosed,
}

const createTracking = {
  clicked: BufferTracker.canvaButtonClicked,
  published: BufferTracker.canvaDesignPublished,
  attached: BufferTracker.canvaImageAttached,
  designOpen: BufferTracker.canvaDesignOpened,
  designClose: BufferTracker.canvaDesignClosed,
}

export const openCanvaModal = (options: {
  uploader: Uploader
  designConfig: CanvaDesignConfig
  designId?: string
  onClose?: () => void
}): void => {
  const { uploader, designConfig, designId, onClose } = options

  const metadata = getTrackingMetadata(designConfig.service, designId)

  let canvaFunc
  let design
  let track
  if (designId) {
    // when we have a designId it's an editing action
    canvaFunc = window?.canvaApi?.editDesign
    design = { id: designId }
    track = editTracking
  } else {
    // otherwise we create a new design with specific dimensions
    canvaFunc = window?.canvaApi?.createDesign
    design = { title: designConfig.title, dimensions: designConfig.dimensions }
    track = createTracking
  }

  track.clicked(metadata)

  canvaFunc?.({
    design,
    editor: {
      publishLabel: CanvaLiterals.PublishLabel,
      fileType: CanvaLiterals.FileType,
    },
    onDesignPublish: (opts: CanvaPublishedOpts) => {
      track.published({ ...metadata, designId: opts.designId })
      onClose?.()
      uploadCanvaDesign(opts, uploader).then(() => {
        track.attached({ ...metadata, designId: opts.designId })
      })
    },
    onDesignOpen: () => {
      track.designOpen(metadata)
    },
    onDesignClose: () => {
      onClose?.()
      track.designClose(metadata)
    },
  })
}
