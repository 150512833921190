import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Media } from '~publish/pages/Create/types'
import { Upload } from '@buffer-mono/uploader'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { uploadsPreloaded } from '../slice'

export const preloadMedia = createAsyncThunk<
  void,
  { media: Media[]; uploaderId: string }
>('uploads/preloadMedia', ({ media, uploaderId }, { getState, dispatch }) => {
  const organizationId = selectCurrentOrganizationId(getState())

  const uploads = media.map((item) =>
    Upload.newFromMedia(item, {
      trackingId: `media-${item.id}`,
      uploaderId,
      organizationId,
    }),
  )

  dispatch(uploadsPreloaded(uploads))
})
