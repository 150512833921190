import React from 'react'
import clsx from 'clsx'
import {
  BarChartIcon,
  ClockIcon,
  IconButton,
  Text,
  Collapsible,
  Flex,
  Button,
} from '@buffer-mono/popcorn'
import { formatDistanceToNowStrict } from 'date-fns'

import { SplitProtected } from '~publish/components/SplitProtected'
import type { PostMetric as Metric } from '~publish/gql/graphql'

import { getAnalyzeUrl, getEngageUrl } from '../helpers'
import { usePostData, useTrackActionClicked } from '../PostCardContext'
import { PostMetric } from './PostMetric'
import styles from './PostCardMetrics.module.css'

type MoreAnalyticsButtonProps = {
  analyzeUrl: string
  useIconButton?: boolean
}

const MoreAnalyticsButton = ({
  analyzeUrl,
  useIconButton = false,
}: MoreAnalyticsButtonProps): JSX.Element | null => {
  const trackPostAction = useTrackActionClicked()

  if (useIconButton) {
    return (
      <IconButton
        label="See More Analytics"
        tooltip="See more analytics in Analyze"
        size="medium"
        as="a"
        href={analyzeUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(): void => trackPostAction('seeMoreAnalytics-2')}
        className={styles.analyzeCta}
      >
        <BarChartIcon />
      </IconButton>
    )
  }

  return (
    <Button
      variant="secondary"
      as="a"
      href={analyzeUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(): void => trackPostAction('seeMoreAnalytics-2')}
      className={styles.analyzeCtaCollapsible}
    >
      <BarChartIcon />
      More Analytics...
    </Button>
  )
}

const Metrics = ({
  metrics,
  engageUrl,
}: {
  metrics: Metric[]
  engageUrl: string | null
}): JSX.Element => (
  <>
    {metrics.map((metric) => (
      <PostMetric
        key={metric.name}
        type={metric.type}
        name={metric.displayName}
        value={metric.nullableValue}
        unit={metric.unit}
        engageUrl={engageUrl}
      />
    ))}
  </>
)

const CollapsibleMetrics = ({
  visibleMetrics,
  collapsedMetrics,
  engageUrl,
  analyzeUrl,
}: {
  visibleMetrics: Metric[]
  collapsedMetrics: Metric[]
  engageUrl: string | null
  analyzeUrl: string | null
}): JSX.Element => {
  return (
    <Collapsible className={styles.metricsCollapse}>
      <Collapsible.Trigger className={styles.metricsTrigger}>
        <Flex
          justify="start"
          gap="2xl"
          className={styles.metricsCollapsibleTrigger}
        >
          <Metrics metrics={visibleMetrics} engageUrl={engageUrl} />
        </Flex>
      </Collapsible.Trigger>
      <Collapsible.Content>
        <Flex direction="column" gap="md">
          <Flex justify="start" gap="2xl">
            <Metrics metrics={collapsedMetrics} engageUrl={engageUrl} />
          </Flex>
          <div className={styles.analyzeCtaWrapper}>
            {analyzeUrl && <MoreAnalyticsButton analyzeUrl={analyzeUrl} />}
          </div>
        </Flex>
      </Collapsible.Content>
    </Collapsible>
  )
}

export const PostCardMetrics = ({
  condensed,
}: {
  condensed?: boolean
}): JSX.Element | null => {
  const { schedulingType, status, metrics, channel, metricsUpdatedAt } =
    usePostData()

  // Notifications don't have metrics
  // TODO: metrics array should be null for notifications
  // see: https://buffer.atlassian.net/browse/CT-389
  if (schedulingType === 'notification') {
    return null
  }

  if (status !== 'sent' || !metrics) {
    return null
  }

  const hasNoData = metrics.every(
    (metric) => typeof metric.nullableValue !== 'number',
  )

  const engageUrl = getEngageUrl(channel)
  const analyzeUrl = getAnalyzeUrl(channel)

  const collapsibleView = condensed && metrics.length > 3
  const visibleMetrics = collapsibleView ? metrics.slice(0, 3) : metrics
  const collapsedMetrics = collapsibleView ? metrics.slice(3) : []

  return (
    <section
      className={clsx(
        styles.metrics,
        collapsibleView && styles.metricsCondensed,
      )}
      aria-label="Analytics"
    >
      {collapsibleView ? (
        <CollapsibleMetrics
          visibleMetrics={visibleMetrics}
          collapsedMetrics={collapsedMetrics}
          engageUrl={engageUrl}
          analyzeUrl={analyzeUrl}
        />
      ) : (
        <Metrics metrics={visibleMetrics} engageUrl={engageUrl} />
      )}
      {!collapsibleView && analyzeUrl && (
        <MoreAnalyticsButton analyzeUrl={analyzeUrl} useIconButton />
      )}
      <SplitProtected name="CT-show-metric-refresh">
        {metricsUpdatedAt ? (
          <div className={styles.lastUpdated}>
            <ClockIcon size="xsmall" color="subtle" />
            <Text size="sm" color="subtle">
              Refreshed{' '}
              {formatDistanceToNowStrict(metricsUpdatedAt, { addSuffix: true })}
            </Text>
          </div>
        ) : null}
        {!metricsUpdatedAt && hasNoData ? (
          <div className={styles.lastUpdated}>
            <ClockIcon size="xsmall" color="subtle" />
            <Text size="sm" color="subtle">
              Metrics not available yet
            </Text>
          </div>
        ) : null}
      </SplitProtected>
    </section>
  )
}

PostCardMetrics.displayName = 'PostCardMetrics'
