import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { actions as analyticsActions } from '~publish/legacy/analytics-middleware/actions'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { actions } from '~publish/legacy/calendar/reducer'
import { toast } from '@buffer-mono/popcorn'

type CalendarAnalytics = {
  onCalendarChangeTrack: (previousView: string, changeType: string) => void
  onWeekClick: (isWeekly: boolean) => void
  onMonthClick: (isWeekly: boolean) => void
  onDropPostSuccess: (args: { dueAt: string; isDraft: boolean }) => void
  onDropPostError: (error?: { message: string } | Error | null) => void
}

type CalendarAnalyticsProps = {
  granularity: 'week' | 'month'
  channelsCount: number
  organizationId: string
  trackOpened?: boolean
}

export const useCalendarAnalytics = (
  props: CalendarAnalyticsProps,
): CalendarAnalytics => {
  const { granularity, channelsCount, organizationId, trackOpened } = props

  // track the opening of the calendar
  // TODO: create a more sophisticated tracking for filters and tab switches
  // See: https://linear.app/buffer/issue/CONT-161
  useEffect(() => {
    if (trackOpened !== false) {
      BufferTracker.calendarOpened({
        organizationId,
        clientName: 'publishWeb',
        product: 'publish',
        calendarVersion: 'new',
        view: granularity,
        channelCount: channelsCount,
        source: 'sidebar',
      })
    }
  }, [organizationId, granularity, channelsCount, trackOpened])

  const dispatch = useDispatch()
  return {
    onCalendarChangeTrack: (previousView: string, changeType: string): void => {
      const metadata = {
        view: previousView,
        dateChangeType: changeType,
      }
      dispatch(analyticsActions.trackEvent('Calendar Dates Changed', metadata))
    },
    onWeekClick: (isWeekly: boolean): void => {
      const metadata = {
        view: 'week',
        previousView: isWeekly ? 'week' : 'month',
      }
      dispatch(actions.shouldRefetch())
      dispatch(analyticsActions.trackEvent('Calendar View Switched', metadata))
    },
    onMonthClick: (isWeekly: boolean): void => {
      const metadata = {
        view: 'month',
        previousView: isWeekly ? 'week' : 'month',
      }
      dispatch(actions.shouldRefetch())
      dispatch(analyticsActions.trackEvent('Calendar View Switched', metadata))
    },
    onDropPostSuccess: ({
      dueAt,
      isDraft,
    }: {
      dueAt: string
      isDraft: boolean
    }): void => {
      const newDate = new Date(dueAt)
      const formattedDate = format(newDate, "eee, MMMM d 'at' HH:mm")
      toast.success(
        `Great! We’ve rescheduled your ${
          isDraft ? 'draft' : 'post'
        } for ${formattedDate}`,
      )
    },
    onDropPostError: (error?: { message: string } | Error | null): void => {
      const maybeErrorMessage =
        error && 'message' in error ? error.message : undefined
      const fallbackErrorMessage =
        'Whoops, we had some problems updating the date for that post!'
      toast.error(maybeErrorMessage || fallbackErrorMessage)
    },
  }
}
