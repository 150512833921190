/* eslint-disable @typescript-eslint/naming-convention */
import { z } from 'zod'

export const csvLineSchema = z.object({
  text: z
    .string({
      required_error: 'Text is required',
      invalid_type_error: 'Text must be a string',
    })
    .trim()
    .min(1, 'Text cannot be empty')
    .max(500, 'Text must not exceed 2200 characters'),

  image: z
    .string({
      invalid_type_error: 'Image URL must be a string if provided',
    })
    .trim()
    .url('Invalid image URL format')
    .nullish() // Accepts undefined or null
    .optional(), // Makes the field optional
})

export type CSVLine = z.infer<typeof csvLineSchema>
