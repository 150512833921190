import React from 'react'
import { Badge, DraftIcon, type BadgeProps } from '@buffer-mono/popcorn'
import styles from './PostStatusBadge.module.css'

export const DraftBadge = (props: Pick<BadgeProps, 'size'>): JSX.Element => {
  return (
    <Badge className={styles.draft} size="medium" {...props}>
      <DraftIcon />
      Draft
    </Badge>
  )
}
