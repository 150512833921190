import { useEffect, useMemo, useState } from 'react'

import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

import { useChannels } from '../PageSidebar/useChannels'
import {
  getTimezonesMap,
  type TimezoneInfo,
  type TimezoneMap,
} from './timezone-filter'

/**
 * Custom hook to manage and cache the timezone map state.
 * Fetches and stores the timezone mapping data on component mount.
 *
 * @returns {TimezoneMap} An object mapping timezone IDs to their corresponding TimezoneInfo objects
 *
 * @example
 * const timezones = useTimezoneMap()
 * console.log(timezones['America/New_York']) // { id: 'America/New_York', city: 'New York', ... }
 */
export function useTimezoneMap(): TimezoneMap {
  const [timezones, setTimezones] = useState<TimezoneMap>({})

  useEffect(() => {
    getTimezonesMap().then(setTimezones)
  }, [])

  return timezones
}

/**
 * Custom hook to manage and cache the timezone map state.
 * Fetches and stores the timezone mapping data on component mount.
 *
 * @returns {TimezoneMap} An object mapping timezone IDs to their corresponding TimezoneInfo objects
 *
 * @example
 * const timezones = useTimezoneMap()
 * console.log(timezones['America/New_York']) // { id: 'America/New_York', city: 'New York', ... }
 */
function useTimezoneList(): string[] {
  const timezones = useTimezoneMap()

  return useMemo(
    () =>
      Object.values(timezones)
        .sort((a, b) => a.city.localeCompare(b.city))
        .map(({ id }) => id),
    [timezones],
  )
}

/**
 * Custom hook to get compatible timezone objects.
 * It converts deprecated timezones to their current equivalent using a mapping table.
 *
 * @param {string | undefined} timezone - The timezone identifier to check and potentially convert
 * @returns {TimezoneInfo | undefined} The compatible timezone info object, or undefined if not found
 *
 * @example
 * const compatibleTimezone = useCompatibleTimezone('EDT')
 * console.log(compatibleTimezone?.id) // 'America/New_York'
 */
export function useCompatibleTimezone(
  timezone: string | undefined,
): TimezoneInfo | undefined {
  const timezones = useTimezoneMap()
  const timezoneList = useTimezoneList()
  const [compatibleTimezone, setCompatibleTimezone] = useState(timezone)

  useEffect(() => {
    if (!timezone || timezoneList.length === 0) return
    if (!timezoneList.includes(timezone)) {
      import('./backwards-timezones').then(({ BACKWARDS_TIMEZONES }) => {
        setCompatibleTimezone(BACKWARDS_TIMEZONES[timezone])
      })
    } else {
      setCompatibleTimezone(timezone)
    }
  }, [timezone, timezoneList])

  return compatibleTimezone &&
    timezoneList.length > 0 &&
    timezoneList.includes(compatibleTimezone)
    ? timezones[compatibleTimezone]
    : undefined
}

/**
 * Custom hook to get timezone suggestions based on user input and channel data.
 * When there's no input, it returns the browser's timezone and timezones from active channels.
 * When there's input, it returns an empty array (filtering is handled elsewhere).
 *
 * @param {string} inputValue - The current search input value
 * @returns {string[]} Array of suggested timezone IDs
 *
 * @example
 * const suggestions = useTimezoneSuggestions('')
 * console.log(suggestions) // ['America/New_York', 'Europe/London', ...] (browser + channel timezones)
 */
export function useTimezoneSuggestions(inputValue: string): string[] {
  const { channels } = useChannels()
  const timezoneList = useTimezoneList()
  const timezones = useTimezoneMap()

  return useMemo(() => {
    if (inputValue) return []

    return [
      getCurrentTimeZone(),
      ...(channels ?? [])
        .map(({ timezone }) => timezone)
        .filter((timezone) => timezoneList.includes(timezone))
        .sort((a, b) => timezones[a].city.localeCompare(timezones[b].city)),
    ].filter((timezone, index, self) => self.indexOf(timezone) === index)
  }, [channels, inputValue, timezoneList, timezones])
}

/**
 * Custom hook to filter timezones by excluding suggested timezones from the complete list.
 * Used to separate suggested timezones from the main timezone list in the UI.
 *
 * @param {string[]} suggestions - Array of timezone IDs to exclude from the complete list
 * @returns {string[]} Array of filtered timezone IDs
 *
 * @example
 * const suggestions = useTimezoneSuggestions('')
 * const filteredTimezones = useFilteredTimezones(suggestions)
 * console.log(filteredTimezones) // All timezones except those in suggestions
 */
export function useFilteredTimezones(suggestions: string[]): string[] {
  const timezoneList = useTimezoneList()

  return useMemo(() => {
    return timezoneList.filter((id) => !suggestions.includes(id))
  }, [suggestions, timezoneList])
}
