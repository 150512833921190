import { useQuery } from '@apollo/client'
import { graphql } from '~publish/gql'

import type {
  GetPostForCalendarPostCardQuery,
  GetPostForCalendarPostCardQueryVariables,
} from '~publish/gql/graphql'

export const GetPostForCalendarPostCard = graphql(/* GraphQL */ `
  query GetPostForCalendarPostCard($id: PostId!) {
    post(input: { id: $id }) {
      ...PostCard_Post
    }
  }
`)

type UseQueryResponse = ReturnType<
  typeof useQuery<
    GetPostForCalendarPostCardQuery,
    GetPostForCalendarPostCardQueryVariables
  >
>
export const useLoadCalendarPostCard = (
  id: string,
  { skip = false } = {},
): UseQueryResponse =>
  useQuery(GetPostForCalendarPostCard, {
    variables: { id },
    skip,
  })
