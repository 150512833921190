import { useEffect, useState } from 'react'
import { parseOpml, type OPML } from '../helpers/parseOPML'

type RecommendationList = {
  name: string
  path: string
  sha: string
  size: number
  url: string
  html_url: string
  git_url: string
  download_url: string
  type: string
  _links: {
    self: string
    git: string
    html: string
  }
}

type AsyncState<T> = {
  loading: boolean
  data: T | null
  error?: Error | null
}

export const useRecommendationList = (): AsyncState<RecommendationList[]> => {
  const [recommendations, setRecommendations] = useState<
    AsyncState<RecommendationList[]>
  >({
    loading: true,
    data: null,
  })

  useEffect(() => {
    fetch(
      `https://api.github.com/repos/plenaryapp/awesome-rss-feeds/contents/recommended/with_category`,
    )
      .then((res) => res.json())
      .then((data: RecommendationList[]) => {
        setRecommendations({ loading: false, error: null, data })
      })
      .catch((err) =>
        setRecommendations({ loading: false, error: err, data: null }),
      )
  }, [])

  return recommendations
}

export const useFeedOPML = (url: string): AsyncState<OPML> => {
  const [opml, setOpml] = useState<AsyncState<OPML>>({
    loading: true,
    data: null,
  })

  useEffect(() => {
    fetch(url)
      .then((res) => res.text())
      .then((data: string) => {
        const opml = parseOpml(data)

        opml.feeds = opml.feeds
          // there's a dead link to an old buffer feed
          .filter(
            (feed) => feed.url !== 'https://buffer.com/resources/android/rss/',
          )
          // reddit feeds don't work anymore
          .filter((feed) => !feed.url.startsWith('https://www.reddit.com'))
        setOpml({ loading: false, error: null, data: opml })
      })
      .catch((err) => setOpml({ loading: false, error: err, data: null }))
  }, [url])

  return opml
}
