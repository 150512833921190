import React from 'react'
import clsx from 'clsx'

import { PostCardActions } from '../PostCard/PostCardActions'
import { usePostData } from '../PostCard/PostCardContext'
import { PostedFrom } from './PostedFrom'
import { PostCardAuthor } from './PostCardAuthor'
import styles from './PostCardFooter.module.css'

export const PostCardFooter = ({
  condensed = false,
}: {
  condensed?: boolean
}): JSX.Element | null => {
  const { via } = usePostData()
  const isApiPost = via === 'api' || via === 'buffer'
  return (
    <footer
      className={clsx(styles.footer, condensed && styles.footerCondensed)}
    >
      {!isApiPost && !condensed && <PostedFrom />}
      {isApiPost && !condensed && <PostCardAuthor />}
      <PostCardActions />
    </footer>
  )
}

PostCardFooter.displayName = 'PostCardFooter'
