import React from 'react'

import { FeedGroupSettings } from './FeedGroupSettings'
import { useFeedGroup } from '../../hooks'

type Props = {
  feedGroupId: string
}

export const EditFeedGroup = (props: Props): JSX.Element => {
  const { feedGroupId } = props
  const feedGroup = useFeedGroup(feedGroupId)
  if (feedGroup.loading) {
    return <div>Loading...</div>
  }
  if (feedGroup.error) {
    return <div>Error: {feedGroup.error.message}</div>
  }

  if (!feedGroup.feedGroup) {
    return <div>Feed Group not found</div>
  }

  return <FeedGroupSettings feedGroup={feedGroup.feedGroup} />
}
