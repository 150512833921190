import { graphql } from '~publish/gql'
import { useMutation } from '@apollo/client'
import type {
  CreateNewRssFeedGroupMutationVariables,
  CreateNewRssFeedGroupMutation,
} from '~publish/gql/graphql'
import { useTriggerFeedUpdate } from './useFeedUpdates'

const CreateNewRSSFeedGroup = graphql(/* GraphQL */ `
  mutation CreateNewRSSFeedGroup($input: CreateRSSFeedGroupInput!) {
    createRSSFeedGroup(input: $input) {
      __typename
      ... on RSSFeedGroupActionSuccess {
        feedGroup {
          id
        }
      }
    }
  }
`)

export const useCreateNewFeedGroup = (): ((
  input: CreateNewRssFeedGroupMutationVariables['input'],
) => Promise<
  CreateNewRssFeedGroupMutation['createRSSFeedGroup'] | undefined | null
>) => {
  const [create] = useMutation(CreateNewRSSFeedGroup)

  const triggerCreate = useTriggerFeedUpdate('feedGroupCreated')

  return async (input: CreateNewRssFeedGroupMutationVariables['input']) => {
    const data = await create({ variables: { input } })
    if (
      data.data?.createRSSFeedGroup?.__typename === 'RSSFeedGroupActionSuccess'
    ) {
      triggerCreate({
        kind: 'feedGroupCreated',
        feedGroup: data.data.createRSSFeedGroup.feedGroup,
      })
    }
    return data.data?.createRSSFeedGroup
  }
}
