import React, { useState } from 'react'
import { LoaderCircleIcon, Popover } from '@buffer-mono/popcorn'

import { useLoadCalendarPostCard } from '../hooks/useLoadCalendarPostCard'
import { CalendarPostCard } from '../CalendarPostCard'
import styles from './CalendarPostPopover.module.css'

type CalendarPostPopoverProps = {
  children: React.ReactNode
  postId: string
}

export const CalendarPostPopover = ({
  children,
  postId,
}: CalendarPostPopoverProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const { data, loading } = useLoadCalendarPostCard(postId, { skip: !open })

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content
        side="right"
        align="start"
        className={styles.popoverContent}
      >
        {loading ? (
          <div className={styles.loading}>
            {/** TODO: improve loading state */}
            <LoaderCircleIcon />
          </div>
        ) : data?.post ? (
          <CalendarPostCard post={data.post} />
        ) : null}
      </Popover.Content>
    </Popover>
  )
}
