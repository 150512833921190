/**
 * Logs provided value only in development mode and skips in production mode
 */

declare global {
  interface Window {
    DEBUG?: boolean
  }
}

/**
 * Conditionally logs debug messages based on environment and debug settings.
 *
 * Debug mode is enabled under these conditions:
 * - Development environment:
 *   - Both window.DEBUG and localStorage.debug must not be false
 * - Production environment:
 *   - Either window.DEBUG or localStorage.debug must be true
 *
 * @param values - Values to be logged to the console
 * @example
 * debugLog('User data:', userData); // Logs if debug conditions are met
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debugLog(...values: any[]): void {
  const isDev = process.env.NODE_ENV === 'development'
  const windowDebug = (window as any).DEBUG
  const localStorageDebug = localStorage.getItem('debug')

  const isEnabled = isDev
    ? windowDebug !== false && localStorageDebug !== 'false'
    : windowDebug === true || localStorageDebug === 'true'

  if (isEnabled) {
    console.log(...values)
  }
}
