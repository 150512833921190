
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AccountCompleteEmailVerification": [
      "AccountCompleteEmailVerificationErrorJWTExpired",
      "AccountCompleteEmailVerificationResponse",
      "Error"
    ],
    "AccountInitiateEmailVerificationPayload": [
      "AccountInitiateEmailVerificationError",
      "AccountInitiateEmailVerificationResponse"
    ],
    "AccountRemovePayload": [
      "AccountRemoveError",
      "AccountRemoveResponse"
    ],
    "AccountSuspendResponse": [
      "Error",
      "Response"
    ],
    "AccountUnsuspendResponse": [
      "Error",
      "Response"
    ],
    "AccountUpdateRoleResponse": [
      "Error",
      "Response"
    ],
    "AddEntityNotePayload": [
      "AddEntityNoteResponse",
      "CoreWebAppCommonError"
    ],
    "AddNoteToPostPayload": [
      "AddNoteToPostSuccess",
      "InvalidInputError",
      "LimitReachedError",
      "NotFoundError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "AddOrUpdateDismissedObjectPayload": [
      "AddOrUpdateDismissedObjectError",
      "AddOrUpdateDismissedObjectResponse"
    ],
    "AddPostToQueuePayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "ApprovePostPayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "Asset": [
      "DocumentAsset",
      "ImageAsset",
      "VideoAsset"
    ],
    "AuthorizeChannelsPayload": [
      "AuthorizeChannelsError",
      "AuthorizeChannelsResponse"
    ],
    "BackfillChannelPostsAndAnalyticsPayload": [
      "BasicError",
      "MutationBasicResponse"
    ],
    "BackfillChannelUpdatesResponse": [
      "Error",
      "Response"
    ],
    "BaseResponse": [
      "Error",
      "Response"
    ],
    "Billing": [
      "MPBilling",
      "OBBilling"
    ],
    "BillingCreateCheckoutSessionPayload": [
      "BillingCreateCheckoutSessionResponse",
      "BillingError"
    ],
    "BillingCreateCustomerPortalSessionPayload": [
      "BillingCreateCustomerPortalSessionResponse",
      "BillingError"
    ],
    "BillingCreatedSetupIntentPayload": [
      "BillingCreateSetupIntentResponse",
      "BillingError"
    ],
    "BillingIncreaseSubscriptionQuantityByChannelIdsPayload": [
      "BillingError",
      "BillingResponse"
    ],
    "BillingMigrateToOneBufferPayload": [
      "BillingError",
      "BillingMigrateToOneBufferResponse"
    ],
    "BillingStartTrialPayload": [
      "BillingError",
      "BillingResponse"
    ],
    "BillingUpdateCustomerPaymentMethodPayload": [
      "BillingError",
      "BillingResponse"
    ],
    "BillingUpdateSubscriptionPlanPayload": [
      "BillingError",
      "BillingUpdateSubscriptionPlanResponse"
    ],
    "BillingUpdateSubscriptionQuantityPayload": [
      "BillingError",
      "BillingUpdateSubscriptionPlanResponse"
    ],
    "BlockMutationResponse": [
      "BlockMutationSuccess",
      "CommonError"
    ],
    "BlocksMutationResponse": [
      "BlocksMutationSuccess",
      "CommonError"
    ],
    "BroadcasterResponse": [
      "BlueskyBroadcasterResponse",
      "FacebookBroadcasterResponse",
      "GoogleBusinessBroadcasterResponse",
      "InstagramBroadcasterResponse",
      "LinkedinBroadcasterResponse",
      "MastodonBroadcasterResponse",
      "PinterestBroadcasterResponse",
      "StartPageBroadcasterResponse",
      "ThreadsBroadcasterResponse",
      "TiktokBroadcasterResponse",
      "TwitterBroadcasterResponse",
      "YoutubeBroadcasterResponse"
    ],
    "CalendarsPayload": [
      "Calendar",
      "PublishingError"
    ],
    "CancelTrialPayload": [
      "CancelTrialResponse",
      "NotFoundError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "ChannelAnalyticsLastUpdateAtPayload": [
      "Error",
      "Response"
    ],
    "ChannelAuthorizationPayload": [
      "ChannelAuthorizationError",
      "ChannelAuthorizationResponse"
    ],
    "ChannelAuthorizationUrlPayload": [
      "ChannelAuthorizationUrlError",
      "ChannelAuthorizationUrlResponse"
    ],
    "ChannelConnectionListPayload": [
      "ChannelConnectionListResponse",
      "ChannelConnectionNoChannelsError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "ChannelConnectionRefreshPayload": [
      "ChannelConnectionCannotStealChannelError",
      "ChannelConnectionNoChannelsError",
      "ChannelConnectionRefreshResponse",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "ChannelMetadata": [
      "FacebookMetadata",
      "GoogleBusinessMetadata",
      "InstagramMetadata",
      "LinkedInMetadata",
      "MastodonMetadata",
      "PinterestMetadata",
      "TwitterMetadata"
    ],
    "ChannelMutationResponse": [
      "ChannelResponse",
      "Error"
    ],
    "ChannelStoreTemporaryCredentialsResponse": [
      "Error",
      "Response"
    ],
    "ChannelUpdateLockedStatusResponse": [
      "ChannelLimitError",
      "Error",
      "Response"
    ],
    "ChannelsConnectPayload": [
      "ChannelsConnectError",
      "ChannelsConnectResponse"
    ],
    "ChannelsSetRecommendationsPayload": [
      "ChannelsSetRecommendationsError",
      "ChannelsSetRecommendationsResponse"
    ],
    "ChannelsUpdateCredentialsResponse": [
      "Error",
      "Response"
    ],
    "ChecklistsPayload": [
      "ChecklistsResponse",
      "CoreWebAppCommonError"
    ],
    "ClientPayload": [
      "Client",
      "ClientError"
    ],
    "CommonPostMetadata": [
      "BlueskyPostMetadata",
      "FacebookPostMetadata",
      "GoogleBusinessPostMetadata",
      "InstagramPostMetadata",
      "LinkedInPostMetadata",
      "MastodonPostMetadata",
      "PinterestPostMetadata",
      "StartPagePostMetadata",
      "ThreadsPostMetadata",
      "TiktokPostMetadata",
      "TwitterPostMetadata",
      "YoutubePostMetadata"
    ],
    "CompleteEmailVerificationPayload": [
      "CompleteEmailVerificationSuccess",
      "UnexpectedError"
    ],
    "ConnectableServicesPayload": [
      "ConnectableServicesError",
      "ConnectableServicesResponse"
    ],
    "ConvertCustomChannelPayload": [
      "ConvertCustomChannelResponse",
      "CustomChannelError"
    ],
    "CoreWebAppError": [
      "CoreWebAppCommonError"
    ],
    "CoreWebAppMutationResponse": [
      "IdeaMutationSuccess",
      "IdeasDeletionSuccess",
      "PlaceholderIdeasMutationSuccess",
      "PostNotFound",
      "PostNotUpdated",
      "PostUpdated",
      "TagActionInIdeasSuccess"
    ],
    "CreateCustomChannelsPayload": [
      "CreateCustomChannelsResponse",
      "CustomChannelError"
    ],
    "CreateIdeaGroupPayload": [
      "IdeaGroup",
      "InvalidInputError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "CreateIdeaPayload": [
      "Idea",
      "IdeaResponse",
      "InvalidInputError",
      "LimitReachedError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "CreateOrUpdateRSSFeedGroupPayload": [
      "NotFoundError",
      "RSSFeedGroupActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "CreateTagPayload": [
      "DuplicateError",
      "InvalidInputError",
      "LimitReachedError",
      "NotFoundError",
      "RestProxyError",
      "TagActionSuccess",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "CredentialsForChannelsPayload": [
      "CredentialsForChannelsError",
      "CredentialsForChannelsResponse"
    ],
    "CustomChannelUpdatePayload": [
      "CustomChannelUpdateResponse",
      "CustomChannelsError"
    ],
    "CustomChannelsCreatePayload": [
      "CustomChannelsCreateResponse",
      "CustomChannelsError"
    ],
    "Day": [
      "CalendarDay"
    ],
    "DeleteAccountPayload": [
      "DeleteAccountSuccess",
      "InvalidInputError",
      "NotFoundError",
      "UnexpectedError"
    ],
    "DeleteEntityNotePayload": [
      "CoreWebAppCommonError",
      "DeleteEntityNoteResponse"
    ],
    "DeleteIdeaGroupPayload": [
      "DeleteIdeaGroupResponse",
      "InvalidInputError",
      "NotFoundError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "DeleteNoteFromPostPayload": [
      "DeleteNoteFromPostSuccess",
      "InvalidInputError",
      "LimitReachedError",
      "NotFoundError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "DeletePostResult": [
      "DeletePostSuccess",
      "NotFoundError",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "DeleteRSSFeedGroupsPayload": [
      "NotFoundError",
      "RSSFeedGroupsDeleteSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "DeleteTagPayload": [
      "DeleteTagActionSuccess",
      "InvalidInputError",
      "NotFoundError",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "DismissBannerPayload": [
      "DismissBannerError",
      "DismissBannerResponse"
    ],
    "DropPostPayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "EditEntityNotePayload": [
      "CoreWebAppCommonError",
      "EditEntityNoteResponse"
    ],
    "EmailNotificationPreferencePayload": [
      "EmailNotificationPreferenceError",
      "EmailNotificationPreferencesResponse"
    ],
    "FeedConnectionMetadata": [
      "RSSFeedConnectionMetadata"
    ],
    "FeedItemContent": [
      "RSSFeedItemContent"
    ],
    "GenericSubscription": [
      "MPSubscription",
      "OBSubscription"
    ],
    "GetAccessTokensForChannelPayload": [
      "AccessTokens",
      "NotFoundError",
      "UnauthorizedError",
      "UnexpectedError",
      "UnsupportedChannelError"
    ],
    "GoogleBusinessPostDetails": [
      "GoogleBusinessEventMetaData",
      "GoogleBusinessOfferMetaData",
      "GoogleBusinessWhatsNewMetaData"
    ],
    "HellonextSsoMutationResponse": [
      "Error",
      "HellonextSsoResponse",
      "UnverifiedEmailAddressError"
    ],
    "IdeaMutationPayload": [
      "CoreWebAppCommonError",
      "IdeaMutationSuccess"
    ],
    "IdeaPayload": [
      "CoreWebAppCommonError",
      "Idea"
    ],
    "IdeasCountByTagsPayload": [
      "CoreWebAppCommonError",
      "IdeasCountByTags"
    ],
    "IdeasCountPayload": [
      "CoreWebAppCommonError",
      "IdeasCount"
    ],
    "IdeasDeletionPayload": [
      "CoreWebAppCommonError",
      "IdeasDeletionSuccess"
    ],
    "ImageSearchPayload": [
      "ImageSearchError",
      "ImageSearchResponse"
    ],
    "ImpersonationResponse": [
      "Error",
      "Response"
    ],
    "IncrementPostingStreakPayload": [
      "IncrementPostingStreakResponse",
      "NotFoundError",
      "StreakUpdateError",
      "UnexpectedError"
    ],
    "InitiateEmailUpdateRequestPayload": [
      "InitiateEmailUpdateRequestSuccess",
      "InvalidInputError",
      "NotFoundError",
      "UnexpectedError"
    ],
    "InitiateUpdateEmailPayload": [
      "EmptySuccess",
      "InvalidInputError",
      "NotFoundError",
      "UnexpectedError"
    ],
    "LoginVerificationResponsePayload": [
      "EmptySuccess",
      "UnexpectedError"
    ],
    "MarkNoteAuthorAsDeletedForOrganizationResponse": [
      "MarkNoteAuthorAsDeletedForOrganizationError",
      "MarkNoteAuthorAsDeletedForOrganizationSuccessResponse"
    ],
    "MoveIdeaGroupPayload": [
      "InvalidInputError",
      "MoveIdeaGroupResponse",
      "NotFoundError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "MoveIdeaPayload": [
      "MoveIdeaResponse",
      "UnexpectedError"
    ],
    "MovePostToDraftsResult": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "MutationBasicError": [
      "BasicError",
      "ImageSearchError"
    ],
    "MutationBasicPayload": [
      "BasicError",
      "MutationBasicResponse"
    ],
    "MutationError": [
      "ChannelConnectionCannotStealChannelError",
      "ChannelConnectionNoChannelsError",
      "CustomChannelError",
      "DuplicateError",
      "InvalidInputError",
      "LimitReachedError",
      "NotFoundError",
      "ProfileNotFoundError",
      "RemoveSubscriptionScheduleError",
      "RestProxyError",
      "StreakUpdateError",
      "UnauthorizedError",
      "UnexpectedError",
      "UnsupportedChannelError",
      "VideoProcessingError"
    ],
    "MutationResponse": [
      "BlockMutationSuccess",
      "BlocksMutationSuccess",
      "CommonMutationError",
      "CommonMutationResponse",
      "EngagementMutationResponse",
      "StartPageMutationSuccess"
    ],
    "MutationSuccess": [
      "CancelTrialResponse",
      "DeleteAccountSuccess",
      "EmptySuccess",
      "InitiateEmailUpdateRequestSuccess",
      "RemoveAccountSuccess",
      "RemoveLegacyGlobalFeatureFlipSuccess",
      "SetPasswordSuccess",
      "UpdateLastActivityAtForChannelSuccess"
    ],
    "OrganizationFeatureUsageUpdateResponse": [
      "Error",
      "Response"
    ],
    "PaginatedIdeasPayload": [
      "CoreWebAppCommonError",
      "PaginatedIdeasList"
    ],
    "PlaceholderIdeasCreatedPayload": [
      "CoreWebAppCommonError",
      "PlaceholderIdeasMutationSuccess"
    ],
    "PostDeletionPayload": [
      "PostDeletionResponse",
      "PublishingError"
    ],
    "PostMetadata": [
      "BlueskyPostMetadata",
      "FacebookPostMetadata",
      "GoogleBusinessPostMetadata",
      "InstagramPostMetadata",
      "LinkedInPostMetadata",
      "MastodonPostMetadata",
      "PinterestPostMetadata",
      "StartPagePostMetadata",
      "ThreadsPostMetadata",
      "TiktokPostMetadata",
      "TwitterPostMetadata",
      "YoutubePostMetadata"
    ],
    "PostStatisticsV2": [
      "PostMetricLegacy",
      "PostMetricObject"
    ],
    "PostUpdatedPayload": [
      "CoreWebAppCommonError",
      "PostNotFound",
      "PostNotUpdated",
      "PostUpdated"
    ],
    "PublishPostNowPayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError",
      "VideoProcessingError"
    ],
    "PublishingBroadcastUpdateResponse": [
      "BlueskyBroadcasterResponse",
      "BroadcasterError",
      "Error",
      "FacebookBroadcasterResponse",
      "GoogleBusinessBroadcasterResponse",
      "InstagramBroadcasterResponse",
      "LinkedinBroadcasterResponse",
      "MastodonBroadcasterResponse",
      "PinterestBroadcasterResponse",
      "StartPageBroadcasterResponse",
      "ThreadsBroadcasterResponse",
      "TiktokBroadcasterResponse",
      "TwitterBroadcasterResponse",
      "YoutubeBroadcasterResponse"
    ],
    "PublishingCreateMediaContainerResponse": [
      "CreateMediaContainerResponse",
      "MediaContainerCreationError"
    ],
    "PublishingPostAttachment": [
      "PublishingPostDestinationUrl",
      "PublishingPostFirstComment",
      "PublishingPostLink",
      "PublishingPostRetweet"
    ],
    "RejectPostPayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "RemoveAccountPayload": [
      "InvalidInputError",
      "NotFoundError",
      "RemoveAccountSuccess",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "RemoveLegacyGlobalFeatureFlipPayload": [
      "InvalidInputError",
      "NotFoundError",
      "RemoveLegacyGlobalFeatureFlipSuccess",
      "UnexpectedError"
    ],
    "RemovePostScheduledTimePayload": [
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "RemoveSubscriptionSchedulePayload": [
      "RemoveSubscriptionScheduleError",
      "RemoveSubscriptionScheduleResponse"
    ],
    "RequestPostApprovalPayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "RevertPostApprovalRequestPayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "SchedulePostPayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "SchedulesPayload": [
      "SchedulesError",
      "SchedulesResponse"
    ],
    "ScrapedLink": [
      "LinkAttachment",
      "RetweetMetadata"
    ],
    "SendTestNotificationPayload": [
      "SendTestNotificationError",
      "SendTestNotificationSuccess"
    ],
    "SetPasswordForAccountPayload": [
      "InvalidInputError",
      "NotFoundError",
      "SetPasswordSuccess",
      "UnexpectedError"
    ],
    "SetPostingScheduleForChannelPayload": [
      "ProfileNotFoundError",
      "SetPostingScheduleForChannelResponse",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "StartPageBlock": [
      "StartPageBlockButtonLink",
      "StartPageBlockFeed",
      "StartPageBlockFeedPost",
      "StartPageBlockHeader",
      "StartPageBlockIconLinks",
      "StartPageBlockImageLink",
      "StartPageBlockImageLinksGrid",
      "StartPageBlockInstagramLinksGrid",
      "StartPageBlockMailchimp",
      "StartPageBlockSpotify",
      "StartPageBlockSubHeading",
      "StartPageBlockText",
      "StartPageBlockVideo"
    ],
    "StartPageMutationResponse": [
      "CommonError",
      "StartPageMutationSuccess"
    ],
    "StartPageResponse": [
      "CommonError",
      "StartPage"
    ],
    "StartPagesResponse": [
      "CommonError",
      "StartPages"
    ],
    "SwapPostsInQueuePayload": [
      "NotFoundError",
      "RestProxyError",
      "SwapPostsInQueueSuccess",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "TagActionInIdeasPayload": [
      "CoreWebAppCommonError",
      "TagActionInIdeasSuccess"
    ],
    "TextGenerationResponse": [
      "CommonError",
      "TextGenerated"
    ],
    "ThreadedPostMetadata": [
      "BlueskyPostMetadata",
      "MastodonPostMetadata",
      "ThreadsPostMetadata",
      "TwitterPostMetadata"
    ],
    "TrackExternalServiceUsageResponse": [
      "Error",
      "Response"
    ],
    "UpdateAccountTimezonePayload": [
      "InvalidInputError",
      "NotFoundError",
      "UnexpectedError",
      "UpdateAccountTimezoneSuccess"
    ],
    "UpdateCustomChannelPayload": [
      "CustomChannelError",
      "UpdateCustomChannelResponse"
    ],
    "UpdateIdeaGroupPayload": [
      "IdeaGroup",
      "InvalidInputError",
      "NotFoundError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "UpdateIdeaPayload": [
      "IdeaResponse",
      "InvalidInputError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "UpdateLastActivityAtForChannelPayload": [
      "UnauthorizedError",
      "UnexpectedError",
      "UpdateLastActivityAtForChannelSuccess"
    ],
    "UpdateNoteOnPostPayload": [
      "InvalidInputError",
      "LimitReachedError",
      "NotFoundError",
      "UnauthorizedError",
      "UnexpectedError",
      "UpdateNoteOnPostSuccess"
    ],
    "UpdatePasswordPayload": [
      "EmptySuccess",
      "InvalidInputError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "UpdatePostDueAtMutationResponse": [
      "CoreWebAppCommonError",
      "PostNotFound",
      "PostNotUpdated",
      "PostUpdated"
    ],
    "UpdatePostTagsPayload": [
      "NotFoundError",
      "PostActionSuccess",
      "RestProxyError",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "UpdateTagPayload": [
      "DuplicateError",
      "InvalidInputError",
      "NotFoundError",
      "RestProxyError",
      "TagActionSuccess",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "UploadPayload": [
      "CoreWebAppCommonError",
      "UploadDetails"
    ],
    "VerifyTfaCodePayload": [
      "InvalidInputError",
      "MutationBasicResponse",
      "NotFoundError",
      "UnexpectedError"
    ],
    "accountSetSettingsPayload": [
      "AccountSetSettingsError",
      "AccountSetSettingsResponse"
    ],
    "trackActionOnboardingSurveyPayload": [
      "InvalidInputError",
      "TrackActionOnboardingSurveySuccess",
      "UnauthorizedError",
      "UnexpectedError"
    ],
    "voteForFeatureMutationResponse": [
      "BasicError",
      "MutationBasicResponse"
    ]
  }
};
      export default result;
    