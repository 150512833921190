import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  Combobox,
  type ComboboxContentProps,
  EmptyState,
  Flex,
  IconButton,
  LockIcon,
  SearchIcon,
  SettingsIcon,
  Tag as TagComponent,
  TagIcon,
  toast,
  type ComboboxAnchorProps,
} from '@buffer-mono/popcorn'

import { useTags } from '~publish/hooks/useTags'

import { TagEditDialog } from '../TagEditDialog'

import styles from './TagsCombobox.module.css'

interface TagsComboboxProps {
  selectedTags: string[]
  onChange: (tags: string[]) => void
  open?: boolean
  onOpenChange?: (open: boolean) => void
  children?: React.ReactNode
  limit?: number
  align?: ComboboxContentProps['align']
  side?: ComboboxContentProps['side']
  anchor?: ComboboxAnchorProps['virtualRef']
}

export function TagsCombobox({
  open,
  selectedTags,
  onChange: setSelectedTags,
  onOpenChange,
  children,
  limit = 10,
  align,
  side,
  anchor,
}: TagsComboboxProps): JSX.Element {
  const { tags, hasCreatePermission, createTag } = useTags()
  const [openNewTagModal, setOpenNewTagModal] = useState(false)
  const [tagInfo, setTagInfo] = useState<{ name: string } | undefined>()

  const handleCreateTag = (name: string): void => {
    setTagInfo({ name })
    setOpenNewTagModal(true)
  }

  const handleSaveTag = useCallback(
    async (tag: { name: string; color: string }): Promise<void> => {
      try {
        const { id } = await createTag(tag)

        setSelectedTags([...selectedTags, id])
        setOpenNewTagModal(false)
      } catch (error) {
        toast.error((error as Error).message)
      }
    },
    [createTag, selectedTags, setSelectedTags],
  )

  const limitReached = selectedTags.length >= limit

  return (
    <>
      <Combobox
        open={open}
        value={selectedTags}
        onChange={setSelectedTags}
        multiple
        onOpenChange={onOpenChange}
      >
        {anchor && <Combobox.Anchor virtualRef={anchor} />}
        <Combobox.Trigger>{children}</Combobox.Trigger>
        <Combobox.Content align={align} side={side}>
          <Combobox.Input placeholder="Search tags" />
          {!!tags.length && (
            <>
              <Combobox.List>
                <Combobox.Empty>
                  <EmptyState size="small">
                    <EmptyState.Icon>
                      <SearchIcon />
                    </EmptyState.Icon>
                    <EmptyState.Description>
                      No tags found
                    </EmptyState.Description>
                  </EmptyState>
                </Combobox.Empty>
                <Combobox.Group>
                  {tags.map((tag) => {
                    const disabled =
                      tag.isLocked ||
                      (limitReached && !selectedTags.includes(tag.id))
                    return (
                      <Combobox.Item
                        key={tag.id}
                        value={tag.id}
                        keywords={[tag.name]}
                        disabled={disabled}
                      >
                        <Flex justify="between" className={styles.tagItem}>
                          <TagComponent key={tag.id} color={tag.color}>
                            {tag.name}
                          </TagComponent>
                          {tag.isLocked && <LockIcon />}
                        </Flex>
                      </Combobox.Item>
                    )
                  })}
                </Combobox.Group>
                {hasCreatePermission && (
                  <Combobox.Create onSelect={handleCreateTag} />
                )}
              </Combobox.List>

              <Combobox.Footer>
                <Combobox.Clear>
                  <Button variant="tertiary">Clear All</Button>
                </Combobox.Clear>
                <IconButton
                  label="Manage tags"
                  tooltip="Manage tags"
                  as={Link}
                  to="/tags"
                  variant="tertiary"
                >
                  <SettingsIcon />
                </IconButton>
              </Combobox.Footer>
            </>
          )}
          {!tags.length && (
            <Combobox.List>
              <EmptyState size="small">
                <EmptyState.Icon>
                  <TagIcon />
                </EmptyState.Icon>
                <EmptyState.Description>
                  There are no tags yet.
                </EmptyState.Description>
                {hasCreatePermission && (
                  <EmptyState.Actions>
                    <Button onClick={(): void => setOpenNewTagModal(true)}>
                      New tag
                    </Button>
                  </EmptyState.Actions>
                )}
              </EmptyState>
            </Combobox.List>
          )}
        </Combobox.Content>
      </Combobox>

      <TagEditDialog
        onOpenChange={setOpenNewTagModal}
        open={openNewTagModal}
        tag={tagInfo}
        onSubmit={handleSaveTag}
      />
    </>
  )
}
