import React from 'react'
import { Badge, NotifyMeIcon, type BadgeProps } from '@buffer-mono/popcorn'
import styles from './PostNotifyBadge.module.css'

export const NotifiedBadge = (
  props: Pick<BadgeProps, 'size'>,
): JSX.Element | null => {
  return (
    <Badge className={styles.notify} size="medium" {...props}>
      <NotifyMeIcon />
      Notified
    </Badge>
  )
}
