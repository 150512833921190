import React, { useCallback } from 'react'

import {
  Button,
  Flex,
  Text,
  BookmarkIcon,
  Card,
  Heading,
} from '@buffer-mono/popcorn'
import { useUrlOrigin } from '../../hooks'
import styles from './FeedGroupSettings.module.css'

type FeedTileProps = {
  id?: string
  isSubscribed: boolean
  name: string
  url: string
  onUnsubscribe?: (feedId: string) => void
  onSubscribe?: (url: string) => void
}

export const FeedTile = (props: FeedTileProps): JSX.Element => {
  const { id, name, url, onUnsubscribe, onSubscribe, isSubscribed } = props
  const [origin] = useUrlOrigin(url)

  const handleAction = useCallback(() => {
    if (isSubscribed) {
      onUnsubscribe?.(id ?? '')
    } else {
      onSubscribe?.(url)
    }
  }, [id, isSubscribed, onSubscribe, onUnsubscribe, url])

  return (
    <Card className={styles.feedTile}>
      <Flex gap="xs" align="center" direction="row" justify="start">
        {origin && (
          <img
            src={`http://www.google.com/s2/favicons?domain=${encodeURIComponent(
              origin,
            )}`}
            alt={name}
            className={styles.favicon}
          />
        )}
        <Heading size="small">{name}</Heading>
      </Flex>
      <Text size="sm" color="success">
        {url}
      </Text>
      <Flex
        gap="xs"
        align="center"
        direction="row"
        justify="end"
        className={styles.feedTileActionsBar}
      >
        <Button variant="tertiary" onClick={handleAction}>
          <BookmarkIcon />
          {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
        </Button>
      </Flex>
    </Card>
  )
}
