import React from 'react'
import type { BadgeProps } from '@buffer-mono/popcorn'
import { usePostData } from '../PostCard/PostCardContext'
import { NotifiedBadge } from './NotifiedBadge'
import { NotifyBadge } from './NotifyBadge'

export const PostNotifyBadge = (
  props: Pick<BadgeProps, 'size'>,
): JSX.Element | null => {
  const { schedulingType, status } = usePostData()
  if (schedulingType !== 'notification') {
    return null
  }
  if (status === 'sent') {
    return <NotifiedBadge {...props} />
  }
  return <NotifyBadge {...props} />
}
