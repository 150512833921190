import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import {
  Badge,
  ClockIcon,
  CloseIcon,
  CommentRoundIcon,
  CommentSquare1StIcon,
  Dialog,
  Flex,
  IconButton,
  MapPinIcon,
  Text,
  ThreadIcon,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { PortalContainerProvider } from '@buffer-mono/popcorn/src/helpers/usePortalContainer'

import { getFragmentData, graphql } from '~publish/gql'

import { useSplitEnabled } from '@buffer-mono/features'
import { PostCardActions } from '~publish/components/PostCard/PostCardActions'
import {
  useMetadataFirstComment,
  useMetadataGeolocation,
  useMetadataThread,
  useMetadataThreadCount,
  usePostData,
} from '~publish/components/PostCard/PostCardContext'
import { PostCardErrorNotice } from '~publish/components/PostCard/PostCardErrorNotice'
import { PostCardMetrics } from '~publish/components/PostCard/PostCardMetrics'
import { PostCardProcessingNotice } from '~publish/components/PostCard/PostCardProcessingNotice'
import { PostCardSlabs } from '~publish/components/PostCard/PostCardSlabs'
import { PostMediaBentoBoxPreview } from '~publish/components/PostCard/PostMedia'
import { PostCardContent } from '~publish/components/PostCardContent'
import { PostCardAuthor } from '~publish/components/PostCardFooter/PostCardAuthor'
import { PostedFrom } from '~publish/components/PostCardFooter/PostedFrom'
import { PostCardHeader } from '~publish/components/PostCardHeader'
import { PostCardTags } from '~publish/components/PostCardTags'
import { getReadablePostStatus } from '~publish/helpers/posts/getReadablePostStatus'
import { useDateTimeFormatter } from '~publish/hooks/useDateTimeFormatter'
import { PostCard, PostCard_Post, PostCardProvider } from '../../PostCard'
import { PostErrorDialog } from '../PostErrorDialog'
import styles from './PostDetailsDialog.module.css'

export const GetPost = graphql(/* GraphQL */ `
  query GetPost($id: PostId!) {
    post(input: { id: $id }) {
      id
      ...PostCard_Post # TODO: Update this once the PostDetailsDialog has his own implementation
    }
  }
`)

// TODO: we have this same component in PostCardHeader, we should move it to a shared component
const TextSeparator = (): JSX.Element => (
  <Text color="subtle" aria-hidden={true}>
    {' • '}
  </Text>
)

const CustomScheduleLabel = (): JSX.Element => {
  return (
    <Text size="sm" color="subtle" className={styles.customLabel}>
      <TextSeparator />
      <Flex gap="2xs" align="center">
        <ClockIcon size="xsmall" />
        <span>Custom</span>
      </Flex>
    </Text>
  )
}

const ExpandedPostCardHeader = (): JSX.Element => {
  const { dueAt, isCustomScheduled, sentAt } = usePostData()
  const dateTimeFormatter = useDateTimeFormatter()

  const date = sentAt ?? dueAt

  return (
    <Dialog.Header className={styles.header}>
      <Flex gap="2xs" align="center">
        {date && (
          <Text>
            {sentAt ? 'Published on' : 'Scheduled for'}&nbsp;
            <Text size="md" weight="medium">
              {dateTimeFormatter(date, { includeTimeZone: false })}
            </Text>
            {isCustomScheduled && <CustomScheduleLabel />}
          </Text>
        )}
      </Flex>
      <Flex gap="xs">
        <IconButton
          label="Notes"
          onClick={undefined}
          size="small"
          variant="tertiary"
        >
          <CommentRoundIcon />
        </IconButton>
        <Dialog.Close asChild>
          <IconButton
            label="Expand"
            onClick={undefined}
            size="small"
            variant="tertiary"
          >
            <CloseIcon />
          </IconButton>
        </Dialog.Close>
      </Flex>
    </Dialog.Header>
  )
}

export const ExtendedPostCardAddons = (): JSX.Element | null => {
  const { metadata } = usePostData()

  const threadCount = useMetadataThreadCount(metadata)
  const thread = useMetadataThread(metadata)
  const firstComment = useMetadataFirstComment(metadata)
  const geolocation = useMetadataGeolocation(metadata)

  return (
    <Flex direction="column" gap="xs">
      {threadCount > 1 && thread?.[1]?.text && (
        <Flex data-testid="addon-icons-x" gap="2xs">
          <ThreadIcon />
          <Badge size="xsmall">
            {threadCount}
            <VisuallyHidden>thread posts</VisuallyHidden>
          </Badge>
          <Text>{`Thread (${threadCount} posts) ${thread[1].text}`}</Text>
        </Flex>
      )}
      {geolocation && (
        <Flex gap="md">
          <MapPinIcon
            data-testid="map-icon"
            aria-label={`Location: ${geolocation?.text}`}
          />
          <Text>{geolocation?.text}</Text>
        </Flex>
      )}
      {firstComment && (
        <Flex gap="md">
          <CommentSquare1StIcon
            data-testid="first-comment-icon"
            aria-label={`First comment: ${firstComment}`}
          />
          <Text>{firstComment}</Text>
        </Flex>
      )}
    </Flex>
  )
}

const ExtendedPostCardBody = (): JSX.Element => {
  const post = usePostData()
  const isSentPost = post.status === 'sent' || !!post.sentAt

  return (
    <>
      <Dialog.Body className={styles.body}>
        <Flex justify="between" gap="md">
          <Flex
            direction="column"
            align="stretch"
            className={styles.postContent}
          >
            <PostCardHeader />
            <PostCardContent lineClamp={7} />
          </Flex>
          <PostMediaBentoBoxPreview assets={post.assets} />
        </Flex>
      </Dialog.Body>
      <Dialog.Separator />
      {/* <PostCardAttachments /> */}
      <Dialog.Body className={styles.body}>
        <ExtendedPostCardAddons />
      </Dialog.Body>
      <Dialog.Separator />
      <Dialog.Body className={styles.body}>
        <PostCardTags />
      </Dialog.Body>
      {isSentPost && (
        <>
          <Dialog.Separator />
          <Dialog.Body className={styles.body}>
            <PostCardMetrics />
          </Dialog.Body>
        </>
      )}
    </>
  )
}

const ExtendedPostCardFooter = (): JSX.Element => {
  const { via } = usePostData()
  const isApiPost = via === 'api' || via === 'buffer'
  return (
    <Dialog.Footer className={styles.footer}>
      {!isApiPost && <PostedFrom />}
      {isApiPost && <PostCardAuthor />}
      <PostCardActions />
    </Dialog.Footer>
  )
}

export function PostDetailsDialog({
  parent,
}: {
  parent: string
}): JSX.Element | null {
  const match = useRouteMatch()
  const history = useHistory()
  // This Split does not restrict this Dialog from being rendered,
  // but rather controls which version of the PostCard is rendered in the Dialog.
  const { isEnabled: isPostDeepLinkingEnabled } =
    useSplitEnabled('post-deep-linking')
  const { postId, id: channelId } = useParams<{ postId: string; id: string }>()
  const [open, setOpen] = useState(true)
  const dateTimeFormatter = useDateTimeFormatter()
  const { data, loading, error } = useQuery(GetPost, {
    variables: {
      id: postId,
    },
  })
  const portalContainerId = `portal-container-${postId}`

  const [container, setContainer] = React.useState<HTMLElement | null>(null)

  useEffect(
    function setInnerPortalContainer() {
      if (portalContainerId) {
        setContainer(document.getElementById(portalContainerId))
      }
      // We don't want to set the container until we have the data because it will not be present yet
    },
    [data, portalContainerId],
  )

  const closeDialogWhenComposerOpens = (
    evt: React.MouseEvent<HTMLElement>,
  ): void => {
    // Look for the closest ancestor that has the aria-controls attribute set to "composer-root"
    const openComposerTrigger = (evt.target as Element)?.closest(
      '[aria-controls="composer-root"]',
    )
    if (openComposerTrigger) {
      setOpen(false)
      history.push(parent)
    }
  }

  const onOpenChange = (open: boolean): void => {
    setOpen(open)
    if (!open) {
      history.push(parent)
    }
  }

  if (loading && !data) {
    return null
  }

  const rawPost = data?.post
  const post = getFragmentData(PostCard_Post, rawPost)
  if (channelId && post?.channel?.id && channelId !== post.channel.id) {
    history.push(match.url.replace(channelId, post.channel.id))
  }

  if (error || !post || !rawPost) {
    return (
      <PostErrorDialog
        error={error ?? { message: 'Bad Request' }}
        parent={parent}
      />
    )
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        size={error ? 'medium' : 'large'}
        style={{ overflow: 'visible', padding: 0 }}
        onClick={closeDialogWhenComposerOpens}
      >
        <PortalContainerProvider container={container ?? document.body}>
          {/*
          This is the new version. The 'post-deep-linking' Split is being used here
          because it hasn't been released yet. While it might seem like this entire 
          Dialog should be behind the split, it was technically released under very
          specific conditions. See below message.
         */}
          {isPostDeepLinkingEnabled && (
            <PostCardProvider post={rawPost}>
              <Flex
                as="article"
                className={styles.card}
                data-post-id={post.id}
                data-testid="expanded-post-card"
                direction="column"
                align="stretch"
              >
                <VisuallyHidden>
                  <Dialog.Title>View Post details</Dialog.Title>
                  <Dialog.Description>
                    {`Post ${getReadablePostStatus(post.status)} for ${
                      post.channel.name
                    } ${post.channel.service} channel ${
                      post.dueAt ? `on ${dateTimeFormatter(post.dueAt)}` : ''
                    }`}
                  </Dialog.Description>
                </VisuallyHidden>
                <PostCardSlabs />
                <PostCardErrorNotice />
                <PostCardProcessingNotice />
                <ExpandedPostCardHeader />
                <Dialog.Separator />
                <ExtendedPostCardBody />
                <ExtendedPostCardFooter />
              </Flex>
            </PostCardProvider>
          )}
          {/* 
          This is the Queue PostCard that's available in this Dialog in production
          under very specific conditions. If you create a single post for a single
          channel and the resulting post is not visible due to filters, we'll show
          a success toast with a link to this view.
         */}
          {!isPostDeepLinkingEnabled && data?.post && (
            <PostCard post={data.post} />
          )}
          {/* We need to render any portal from PostCard into the Dialog.Content to avoid the dialog to close when interacting with the portal */}
          <div id={portalContainerId} style={{ position: 'absolute' }} />
        </PortalContainerProvider>
      </Dialog.Content>
    </Dialog>
  )
}
