import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  Sidebar,
  // Icons
  AllChannelsIcon,
  AudienceIcon,
  BellIcon,
  BufferIcon,
  BuildingIcon,
  DollarIcon,
  FlaskConicalIcon,
  GripIcon,
  MessageCircleHeartIcon,
  SlidersVerticalIcon,
  Text,
  UserIcon,
} from '@buffer-mono/popcorn'

import style from './SettingsSidebar.module.css'

const SettingsSidebar = (): JSX.Element => {
  return (
    <Sidebar>
      <Sidebar.Content className={style.sidebar}>
        {/* Account Section */}
        <Sidebar.Header className={style.sidebarHeader}>
          <Text as="h2" size="lg" weight="bold">
            Settings
          </Text>
        </Sidebar.Header>
        <Sidebar.Group>
          <Sidebar.GroupLabel>Account</Sidebar.GroupLabel>
          <Sidebar.GroupContent>
            <Sidebar.List>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<UserIcon />}
                  as={NavLink}
                  exact
                  to="/settings"
                >
                  Profile
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<SlidersVerticalIcon />}
                  as={NavLink}
                  to="/settings/preferences"
                >
                  Preferences
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<BellIcon />}
                  as={NavLink}
                  to="/settings/notifications"
                >
                  Notifications
                </Sidebar.Button>
              </Sidebar.ListItem>
            </Sidebar.List>
          </Sidebar.GroupContent>
        </Sidebar.Group>

        {/* Organization Section */}
        <Sidebar.Group>
          <Sidebar.GroupLabel>Organization</Sidebar.GroupLabel>
          <Sidebar.GroupContent>
            <Sidebar.List>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<BuildingIcon />}
                  as={NavLink}
                  to="/settings/general"
                >
                  General
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<AllChannelsIcon />}
                  as={NavLink}
                  to="/settings/channels"
                  suffix="5"
                >
                  Channels
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<AudienceIcon />}
                  as={NavLink}
                  to="/settings/team"
                >
                  Team
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<DollarIcon />}
                  as={NavLink}
                  to="/settings/billing"
                >
                  Billing
                </Sidebar.Button>
              </Sidebar.ListItem>
            </Sidebar.List>
          </Sidebar.GroupContent>
        </Sidebar.Group>

        {/* Other Section */}
        <Sidebar.Group>
          <Sidebar.GroupLabel>Other</Sidebar.GroupLabel>
          <Sidebar.GroupContent>
            <Sidebar.List>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<GripIcon />}
                  as={NavLink}
                  to="/settings/apps-extras"
                >
                  Apps & Extras
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<FlaskConicalIcon />}
                  as={NavLink}
                  to="/settings/beta-features"
                >
                  Beta Features
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<BufferIcon />}
                  as={NavLink}
                  to="/settings/alpha-features"
                >
                  Alpha Features
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<MessageCircleHeartIcon />}
                  as={NavLink}
                  to="/settings/refer-a-friend"
                >
                  Refer a Friend
                </Sidebar.Button>
              </Sidebar.ListItem>
            </Sidebar.List>
          </Sidebar.GroupContent>
        </Sidebar.Group>
      </Sidebar.Content>
    </Sidebar>
  )
}

export default SettingsSidebar
