import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@bufferapp/ui/Button'
import { ButtonsQueuingTypesMap, SaveButtonTypes } from '../../AppConstants'
import AppActionCreators from '../../action-creators/AppActionCreators'
import { ScheduleTime } from './DraftSaveButtons'
import { profilesPropType } from '../ComposerPropTypes'
import type { BeforeFirstActionReturnType } from './hooks/useBeforeFirstAction'

export const StackedSaveButtons = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSavingPossible' implicitly has ... Remove this comment to see the full error message
  isSavingPossible,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSavingDraftPossible' implicitly... Remove this comment to see the full error message
  isSavingDraftPossible,
  // @ts-expect-error TS(7031) FIXME: Binding element 'draftMode' implicitly has an 'any... Remove this comment to see the full error message
  draftMode,
  // @ts-expect-error TS(7031) FIXME: Binding element 'previewMode' implicitly has an 'a... Remove this comment to see the full error message
  previewMode,
  // @ts-expect-error TS(7031) FIXME: Binding element 'timezone' implicitly has an 'any'... Remove this comment to see the full error message
  timezone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedProfiles' implicitly has ... Remove this comment to see the full error message
  selectedProfiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'firstStackedButtonLabel' implicit... Remove this comment to see the full error message
  firstStackedButtonLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'firstStackedButtonType' implicitl... Remove this comment to see the full error message
  firstStackedButtonType,
  // @ts-expect-error TS(7031) FIXME: Binding element 'otherStackedButtonsTypes' implici... Remove this comment to see the full error message
  otherStackedButtonsTypes,
  beforeFirstAction,
  saveButtonsCopy,
}: {
  beforeFirstAction?: BeforeFirstActionReturnType
  saveButtonsCopy: Map<string, string>
}): JSX.Element => {
  const stackedSafeButtonRef = useRef<HTMLDivElement | null>(null)
  const [showScheduleTimePicker, setShowScheduleTimePicker] = useState(false)
  const [buttonAction, setButtonAction] = useState(null)
  const isSaveDisabled = draftMode ? !isSavingDraftPossible : !isSavingPossible
  const showDropDown = otherStackedButtonsTypes.length > 0
  // @ts-expect-error TS(7006) FIXME: Parameter 'btnType' implicitly has an 'any' type.
  const dropDownItems = otherStackedButtonsTypes.map((btnType) => ({
    id: btnType,
    title: saveButtonsCopy.get(btnType),
  }))
  const [popoverPosition, setPopoverPosition] = useState<'top' | 'bottom'>(
    'bottom',
  )

  const handleClickOutsideScheduleTime = () => {
    setShowScheduleTimePicker(false)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'buttonType' implicitly has an 'any' typ... Remove this comment to see the full error message
  const handleClickAction = (buttonType, isDropdownItem) => {
    const showDateTimePicker =
      (buttonType === SaveButtonTypes.SCHEDULE_DRAFT ||
        buttonType === SaveButtonTypes.SCHEDULE_POST) &&
      isDropdownItem
    if (showDateTimePicker) {
      setShowScheduleTimePicker(true)
      setButtonAction(buttonType)
    } else {
      const queuingType = ButtonsQueuingTypesMap.get(buttonType)

      AppActionCreators.saveDrafts(queuingType, {
        shouldSkipEmptyTextAlert: false,
        hasCampaignsToTagsFeature: true,
      })
    }
  }

  const onFirstButtonClick = () => {
    if (beforeFirstAction?.stopDefaultAction && beforeFirstAction?.newAction) {
      beforeFirstAction.newAction()
      return
    }

    handleClickAction(firstStackedButtonType, false)
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
  const onStackedButtonClick = ({ id }) => {
    if (beforeFirstAction?.stopDefaultAction && beforeFirstAction?.newAction) {
      beforeFirstAction.newAction()
      return
    }

    handleClickAction(id, true)
  }

  const handleOpenDropdown = (): void => {
    if (stackedSafeButtonRef.current) {
      const popover =
        stackedSafeButtonRef.current.parentElement?.querySelector(
          'ul',
        )?.parentElement // Find the popover element
      if (popover) {
        const buttonRect = stackedSafeButtonRef.current.getBoundingClientRect()
        const popoverRect = popover.getBoundingClientRect()

        const isOutsideWindow =
          buttonRect.bottom + popoverRect.height > window.innerHeight

        setPopoverPosition(isOutsideWindow ? 'top' : 'bottom')
      }
    }
  }

  return (
    <>
      {showScheduleTimePicker && (
        <ScheduleTime
          timezone={timezone}
          selectedProfiles={selectedProfiles}
          draftMode={draftMode}
          // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type '"ADD_TO_QUE... Remove this comment to see the full error message
          buttonAction={buttonAction}
          onClickOutside={handleClickOutsideScheduleTime}
        />
      )}
      {/* TODO: this should be migrated to popcorn */}
      <div ref={stackedSafeButtonRef}>
        <Button
          data-testid="stacked-save-buttons"
          disabled={isSaveDisabled || previewMode}
          type="primary"
          isSplit={showDropDown}
          items={dropDownItems}
          label={firstStackedButtonLabel}
          onClick={onFirstButtonClick}
          onSelectClick={onStackedButtonClick}
          selectPosition={popoverPosition}
          onOpen={handleOpenDropdown}
        />
      </div>
    </>
  )
}

StackedSaveButtons.propTypes = {
  isSavingPossible: PropTypes.bool.isRequired,
  isSavingDraftPossible: PropTypes.bool.isRequired,
  timezone: PropTypes.string,
  selectedProfiles: profilesPropType.isRequired,
  draftMode: PropTypes.bool,
  previewMode: PropTypes.bool,
  firstStackedButtonLabel: PropTypes.string.isRequired,
  firstStackedButtonType: PropTypes.string.isRequired,
  otherStackedButtonsTypes: PropTypes.arrayOf(PropTypes.string),
  saveButtonsCopy: PropTypes.instanceOf(Map).isRequired,
}

StackedSaveButtons.defaultProps = {
  draftMode: false,
  previewMode: false,
  otherStackedButtonsTypes: [],
  timezone: null,
}
