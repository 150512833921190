import React, { useCallback } from 'react'
import { Link as RRLink } from 'react-router-dom'

import {
  toast,
  Heading,
  Flex,
  IconButton,
  SettingsIcon,
  Link,
  CheckCircleIcon,
  SimpleSpinner,
} from '@buffer-mono/popcorn'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useCreateIdea } from '~publish/components/IdeaComposer/useCreateIdea'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { feedGroupSettingsPage } from '~publish/legacy/routes'

import { useFeedItems, type FeedItem } from '../../hooks/useFeedItems'
import { useFeedGroup } from '../../hooks/useFeedGroup'
import { FeedName } from './FeedName'
import { ContentFeedItem } from './FeedItem'
import styles from './ContentFeed.module.css'
import { usePageTitle } from '~publish/hooks/usePageTitle'

type ContentFeedProps = {
  feedGroupId: string
}

export const ContentFeed = (props: ContentFeedProps): JSX.Element => {
  const organizationId = useOrganizationId()
  const feedGroup = useFeedGroup(props.feedGroupId)
  const { lastElementRef, items, loading, fetchingMore } = useFeedItems(
    props.feedGroupId,
    organizationId,
  )
  const lastItemId = items?.at(-1)?.id
  const feedName = feedGroup.feedGroup?.name
  usePageTitle(feedName ? `${feedGroup.feedGroup?.name} (Feed)` : 'Feeds')

  const { createIdea } = useCreateIdea({
    onCompleted: (data) => {
      const idea = data?.createIdea
      if (idea && 'id' in idea) {
        toast.success(
          <>
            <div>Your idea has been saved.</div>
            <Link as={RRLink} to={`/create/ideas/${idea.id}/edit`}>
              View idea
            </Link>
          </>,
        )
      } else {
        toast.error('Something went wrong creating the idea')
      }
    },
    onError: (): void => {
      toast.error('Something went wrong creating the idea')
    },
  })
  const { createNewPostInComposer } = usePostComposer()

  const handleCreatePost = useCallback(
    (item: FeedItem): void => {
      createNewPostInComposer({
        cta: 'publish-composer-createPost-default-1',
        channels: 'all',
        prefillPostData: {
          text: item.content.contentTextPreview + ' ' + item.content.articleUrl,
        },
      })
    },
    [createNewPostInComposer],
  )

  const handleSaveAsIdea = useCallback(
    (item: FeedItem): void => {
      // TODO: add media
      createIdea({
        content: {
          title: item.content.title,
          text: `${item.content.contentTextPreview}\n\n${item.content.articleUrl}`,
          aiAssisted: false,
          tags: [],
        },
      })
    },
    [createIdea],
  )

  if (!feedGroup.feedGroup) {
    return (
      <div className={styles.container}>
        <div className={styles.scrollContainer}>
          {feedGroup.loading ? 'Loading feed...' : 'Feed not found'}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <Flex gap="md" align="center">
          <Heading size="large">{feedGroup.feedGroup.name}</Heading>
          <IconButton
            as={RRLink}
            variant="tertiary"
            label="Settings"
            tooltip="Settings"
            to={feedGroupSettingsPage.linkTo(feedGroup.feedGroup.id)}
          >
            <SettingsIcon />
          </IconButton>
        </Flex>
        <Flex wrap="wrap" gap="md" align="center" className={styles.feedList}>
          {feedGroup.feedGroup.feeds.map((feed) => (
            <FeedName key={feed.id} feed={feed} />
          ))}
        </Flex>
        <ul className={styles.contentList} role="feed">
          {items?.map((item, index) => (
            <ContentFeedItem
              key={item.id}
              ref={item.id === lastItemId ? lastElementRef : null}
              lastDate={index > 0 ? items?.[index - 1]?.createdAt : undefined}
              item={item}
              feed={feedGroup.feedGroup?.feeds.find(
                (feed) => feed.id === item.feed.id,
              )}
              handleCreatePost={handleCreatePost}
              handleSaveAsIdea={handleSaveAsIdea}
            />
          ))}
        </ul>
        {fetchingMore && (
          <div className={styles.caughtUp}>
            <SimpleSpinner size="medium" />
          </div>
        )}
        {!loading && !fetchingMore && items?.length !== 0 && (
          <div className={styles.caughtUp}>
            <div className={styles.caughtUpBody}>
              <CheckCircleIcon />
              <span>You’re all caught up!</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
