import type { History } from 'history'
import React, { useEffect } from 'react'
import { replace } from 'redux-first-history'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  type RouteProps,
} from 'react-router-dom'

import PagesWithSidebar from '~publish/legacy/app-pages/components/PagesWithSidebar'
import ProfilePages from '~publish/legacy/app-pages/components/ProfilePages'
import { CALENDAR_PAGE, QUEUE_PAGE } from '~publish/legacy/constants'
import { Preferences } from '~publish/legacy/preferences'
import { filterProfilesByOrg } from '~publish/legacy/profile-sidebar/utils'
import {
  allChannels,
  blueprintsPage,
  calendarMonth,
  calendarPage,
  calendarWeek,
  campaignsPage,
  channel,
  channelGroups,
  channelSettings,
  channelShopGrid,
  compose,
  createPage,
  feedGroupPage,
  feedGroupSettingsPage,
  ideaNewRoute,
  linkToPost,
  newCalendarAllChannels,
  newCalendarSingleChannel,
  newPost,
  preferencesPage,
  profileChildTabPages,
  profilePages,
  profileTabPages,
  tagsPage,
  settings,
} from '~publish/legacy/routes'
import {
  getLastVisitedPage,
  setLastVisitedPage,
} from '~publish/legacy/utils/page-visits'
import CreatePage, { FeedsPage, FeedSettingsPage } from '~publish/pages/Create'
import { useConnectChannelModal } from '~publish/hooks/useConnectChannelModal'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'
import {
  type CreateNewPostInComposerData,
  type OpenComposerCommonOptions,
  usePostComposer,
} from '~publish/hooks/usePostComposer'
import { selectProfiles } from '~publish/legacy/profile-sidebar/selectors'
import { useAppSelector, useAppDispatch } from '~publish/legacy/store'
import { selectCalendarAsHomepage } from '~publish/legacy/user/selectors'
import { NewPostPage } from '~publish/pages/NewPost'

import {
  useAccount,
  useChannelsConnected,
  usePageLoad,
} from '../../../accountContext'
import ComposePage from '../../../compose-page'
import { PostDeepLinkResolver } from '../PostDeepLinkResolver/PostDeepLinkResolver'

import {
  getChannelLocationFromProfile,
  getProfileLocationFromChannel,
  getProfileSettingsLocationFromChannel,
} from './redirectionUtils'
import {
  DEFAULT_POST_LIST_VIEW_MODE,
  LAST_POST_LIST_VIEW_MODE_LOCAL_STORAGE_KEY,
  type ListOrCalendarViewMode,
} from '../../../../components/PostListOrCalendarViewToggle'
import {
  LAST_VIEW_MODE_LOCAL_STORAGE_KEY,
  DEFAULT_VIEW_MODE,
} from '../../../../components/Calendar/hooks/useCalendar'
import type { CalendarDateRangeViewMode } from '../../../../components/Calendar/hooks/useCalendarDateRangeViewMode'
import { useChannelGroupsAccess } from '~publish/pages/ChannelGroups/hooks/useChannelGroupAccess'
import {
  IdeaManagementRouter,
  useIdeaComposer,
} from '~publish/components/IdeaManagementRouter'
import type { CreateIdeaWithComposer } from '~publish/components/IdeaManagementRouter/hooks'
import { useLocalStorage } from '@buffer-mono/popcorn'
import Settings from '~publish/pages/Settings/Settings'

declare global {
  // window.__history is used to share the history object with the app shell to have smooth navigations
  interface Window {
    __history: History<unknown> | undefined
    __openComposer: (
      data: CreateNewPostInComposerData,
      options?: OpenComposerCommonOptions,
    ) => Promise<void>
    __openIdeaComposer: CreateIdeaWithComposer
    __isComposerOpen?: boolean
  }
}

export const AppPages = (): JSX.Element => {
  // Get current selected org from account
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type 'Initial... Remove this comment to see the full error message
  const { account, loading } = useAccount()
  const { createNewPostInComposer, isOpen: isComposerOpen } = usePostComposer()
  const allProfiles = useAppSelector(selectProfiles)
  const connectedChannelsOnOrganization = useChannelsConnected()
  const showCalendarAsHomepage = useAppSelector(selectCalendarAsHomepage)
  const dispatch = useAppDispatch()
  const { createIdeaWithComposer } = useIdeaComposer()

  const history = useHistory()
  useEffect(() => {
    // Make history object available globally so that it can be used in the app shell
    window.__history = history
  }, [history])

  useEffect(() => {
    // Make post composer and idea composer available globally so that it can be used in the app shell
    window.__openComposer = createNewPostInComposer
    window.__openIdeaComposer = createIdeaWithComposer
    window.__isComposerOpen = isComposerOpen
  }, [createNewPostInComposer, createIdeaWithComposer, isComposerOpen])

  const { initialPageLoad, updateInitialPageLoad } = usePageLoad()
  const selectedOrg = account?.currentOrganization?.id

  const profiles = filterProfilesByOrg(allProfiles, {
    id: selectedOrg,
  })

  const hasProfiles = profiles.length > 0

  /**
   * We're deprecating the `awaitingApproval` and `pendingApproval` routes
   * in favour of the `approvals` route. This is a temporary redirect to
   * ensure that users are redirected to the correct page.
   */
  const approvalsRoute = profileTabPages.getRoute({
    profileId: profiles[0]?.id,
    tabId: 'approvals',
  })
  const deprecatedAwaitingApprovalRoute = profileTabPages.getRoute({
    profileId: profiles[0]?.id,
    tabId: 'awaitingApproval',
  })
  const deprecatedPendingApprovalRoute = profileTabPages.getRoute({
    profileId: profiles[0]?.id,
    tabId: 'pendingApproval',
  })
  const RedirectToApprovals = ({ location }: RouteProps): JSX.Element => (
    <Redirect
      to={{
        ...location,
        pathname: approvalsRoute,
      }}
    />
  )

  useEffect(() => {
    updateInitialPageLoad()
  })
  useConnectChannelModal()
  // New Single channel
  const [isPublishRevamp, { isReady: isPublishRevampReady }] =
    usePublishRevamp()

  const { canAccessChannelGroups, isGroupsReady } = useChannelGroupsAccess()

  const [listOrCalendarViewMode] = useLocalStorage<ListOrCalendarViewMode>(
    LAST_POST_LIST_VIEW_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_POST_LIST_VIEW_MODE,
  )
  const [calendarDateRangeViewMode] =
    useLocalStorage<CalendarDateRangeViewMode>(
      LAST_VIEW_MODE_LOCAL_STORAGE_KEY,
      DEFAULT_VIEW_MODE,
    )

  const location = history.location
  const channelSelectedThroughRoute = (location.state as { channel?: string })
    ?.channel

  useEffect(() => {
    // Reset channel to select state included in some location changes
    if (channelSelectedThroughRoute) {
      dispatch(replace(location.pathname, {}))
    }

    /* TODO: we could include here others setLastVisitedPage calls, as the one for queue, for example */
    if (location.pathname.includes('calendar')) {
      setLastVisitedPage(CALENDAR_PAGE)
    }
  }, [location, channelSelectedThroughRoute])

  const defaultPath = (): string => {
    // NEW
    /**
     * Calculate the default path based on the following rules:
     * - If user has only one connected channel -> Redirect to connected channel
     * - If user has zero or multiple connected channels -> Redirect to all channels
     * - In either view, load calendar/list view based on local storage
     * - Otherwise redirect to all channels queue
     */
    const hasOnlyOneConnectedChannel =
      connectedChannelsOnOrganization.length === 1
    const shouldRedirectToCalendar = listOrCalendarViewMode === 'calendar'

    if (hasOnlyOneConnectedChannel) {
      const channelId = connectedChannelsOnOrganization[0].id
      return shouldRedirectToCalendar
        ? newCalendarSingleChannel.buildPathname({
            id: channelId,
            granularity: calendarDateRangeViewMode,
          })
        : channel.getRoute(channelId)
    }

    return shouldRedirectToCalendar
      ? newCalendarAllChannels.buildPathname({
          granularity: calendarDateRangeViewMode,
        })
      : allChannels.route
  }

  const legacyDefaultPath = (): string => {
    // LEGACY
    /**
     * Calculate the default path based on the following rules:
     * - Redirect to calendar if user has no profiles
     * - Always redirects to queue for old users
     * - Redirect to queue only if last visited page is the queue for new users
     */
    const lastVisitedPage = getLastVisitedPage()

    if (!hasProfiles && !loading) {
      return calendarPage.route
    }

    const queueIsLastVisitedPage =
      lastVisitedPage === QUEUE_PAGE && !initialPageLoad

    const shouldRedirectToQueue =
      !showCalendarAsHomepage || queueIsLastVisitedPage

    const selectedProfileId = profiles[0].id
    const queuePath = profileTabPages.getRoute({
      profileId: selectedProfileId,
      tabId: 'queue',
    })

    return shouldRedirectToQueue ? queuePath : calendarPage.route
  }

  //  Do not render until split is ready to avoid invalid redirections
  if (!isPublishRevampReady || !isGroupsReady) {
    return <></>
  }

  return (
    <Switch>
      <Route path={compose.route}>
        <ComposePage />
      </Route>
      <Route path={preferencesPage.route}>
        <Preferences />
      </Route>
      <Route
        path={[
          campaignsPage.route,
          tagsPage.route,
          ...(canAccessChannelGroups ? [channelGroups.route] : []),
          ...(isPublishRevamp
            ? [
                newCalendarAllChannels.route,
                newCalendarSingleChannel.route,
                allChannels.route,
                channel.route,
                channelSettings.route,
                channelShopGrid.route,
              ]
            : [calendarWeek.route, calendarMonth.route]),
          blueprintsPage.route,
        ]}
      >
        <PagesWithSidebar />
      </Route>
      {/* Legacy all-posts route */}
      <Route
        path="/all-posts"
        render={({ location }): JSX.Element => (
          <Redirect to={{ ...location, pathname: allChannels.route }} />
        )}
      />
      {!isPublishRevamp && (
        <Route
          path={channelSettings.route}
          render={({ location, match }): JSX.Element => (
            <Redirect
              to={getProfileSettingsLocationFromChannel({
                location,
                match,
              })}
            />
          )}
        />
      )}
      {!isPublishRevamp && (
        <Route
          path={channel.route}
          render={({ location, match }): JSX.Element => (
            <Redirect to={getProfileLocationFromChannel({ location, match })} />
          )}
        />
      )}
      {isPublishRevamp && (
        <Route
          path={[
            profileChildTabPages.route,
            profileTabPages.route,
            profilePages.route,
          ]}
          render={({ location, match }): JSX.Element => (
            <Redirect to={getChannelLocationFromProfile({ location, match })} />
          )}
        />
      )}
      {!isPublishRevamp && (
        <Route
          path={calendarPage.route}
          render={(): JSX.Element => <Redirect to={calendarWeek.route} />}
        />
      )}
      {isPublishRevamp && (
        <Route
          path={calendarMonth.route}
          render={({ location }): JSX.Element => {
            return (
              <Redirect
                to={{
                  ...location,
                  pathname: newCalendarAllChannels.getRoute('month'),
                  search: location.search,
                }}
              />
            )
          }}
        />
      )}
      {isPublishRevamp && (
        <Route
          path={[calendarWeek.route, calendarPage.route]}
          render={({ location }): JSX.Element => {
            return (
              <Redirect
                to={{
                  ...location,
                  pathname: newCalendarAllChannels.getRoute('week'),
                  search: location.search,
                }}
              />
            )
          }}
        />
      )}
      <Route
        path={[deprecatedAwaitingApprovalRoute, deprecatedPendingApprovalRoute]}
      >
        <RedirectToApprovals />
      </Route>
      <Route path={linkToPost.route}>
        <PostDeepLinkResolver />
      </Route>

      {!isPublishRevamp && (
        <Route path={profilePages.route}>
          <ProfilePages profiles={profiles} />
        </Route>
      )}
      {/* Create Routes */}
      {/* Redirect old /content paths for backwards compatibility */}
      <Route
        path="/content/ideas/new" // Because of some old routing, it's possible to end up here
        key="content-ideas-redirect-new"
        render={({ location }): JSX.Element => (
          <Redirect
            to={{
              ...location,
              // The ideaNewRoute is a generic subroute, so it's combined with the createPage route
              pathname: createPage.route + ideaNewRoute.route,
            }}
          />
        )}
      />
      <Route
        path="/content/new"
        key="content-redirect-new"
        render={({ location }): JSX.Element => (
          <Redirect
            to={{
              ...location,
              // The ideaNewRoute is a generic subroute, so it's combined with the createPage route
              pathname: createPage.route + ideaNewRoute.route,
            }}
          />
        )}
      />
      <Route
        path="/content"
        key="content-redirect-create"
        render={(): JSX.Element => <Redirect to={createPage.route} />}
      />
      <Route path={feedGroupSettingsPage.route} key="feed-group-settings-route">
        <FeedSettingsPage />
      </Route>
      <Route path={feedGroupPage.route} key="feed-group-route">
        <FeedsPage />
      </Route>
      <Route path={createPage.route} key="create-route">
        <CreatePage />
        <IdeaManagementRouter parent={createPage.route} />
      </Route>

      <Route path={newPost.route}>
        <NewPostPage />
      </Route>

      <Route path={settings.route}>
        <Settings />
      </Route>

      {/* Default / catch-all redirect */}
      <Route
        path="*"
        render={(): JSX.Element => (
          <Redirect
            to={isPublishRevamp ? defaultPath() : legacyDefaultPath()}
          />
        )}
      />
    </Switch>
  )
}
