import { graphql } from '~publish/gql'
import { useMutation } from '@apollo/client'
import type { DeleteRssFeedGroupsInput } from '~publish/gql/graphql'
import { useTriggerFeedUpdate } from './useFeedUpdates'

type FeedIds = DeleteRssFeedGroupsInput['feedGroupIds']

const DeleteFeedGroups = graphql(/* GraphQL */ `
  mutation DeleteFeedGroups($input: DeleteRSSFeedGroupsInput!) {
    deleteRSSFeedGroups(input: $input) {
      __typename
    }
  }
`)

export const useDeleteFeedGroups = (): ((
  feedGroupIds: FeedIds,
) => Promise<void>) => {
  const [create] = useMutation(DeleteFeedGroups)

  const triggerDelete = useTriggerFeedUpdate('feedGroupDeleted')

  return async (feedGroupIds: FeedIds) => {
    await create({ variables: { input: { feedGroupIds } } })
    for (const id of feedGroupIds) {
      triggerDelete({ kind: 'feedGroupDeleted', feedGroup: { id } })
    }
  }
}
