/* eslint-disable no-use-before-define */
import { EventEmitter } from 'events'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import findLastIndex from 'lodash/findLastIndex'
import uniqBy from 'lodash/uniqBy'
import twitterText from 'twitter-text'
import { selectSplits } from '~publish/legacy/composer-popover/selectors'
import {
  type ChannelDataProperties,
  type Draft,
  DraftMethods,
  getNewDraft,
} from '~publish/legacy/composer/composer/entities/Draft'
import {
  MediaAttachmentTypes,
  MediaTypes,
  SERVICE_GOOGLEBUSINESS,
  SERVICE_INSTAGRAM,
  SERVICE_LINKEDIN,
  SERVICE_MASTODON,
  SERVICE_STARTPAGE,
  SERVICE_TIKTOK,
  SERVICE_YOUTUBE,
} from '~publish/legacy/constants'
import { LinkedInAnnotation } from '~publish/legacy/editor/plugins/linkedin-annotations/LinkedInAnnotations'
import { getVideoRestrictionsForDraft } from '~publish/legacy/media/config/posts-media-restrictions'
import { validateMedia } from '~publish/legacy/media/validation/validateMedia'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import AppActionCreators from '../action-creators/AppActionCreators'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import NotificationActionCreators from '../action-creators/NotificationActionCreators'
import { shortenDraftLink } from '../action-creators/shortenDraftLink'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import {
  AttachmentTypes,
  ComposerInitiators,
  ErrorTypes,
  InstagramAspectRatioLimits,
  NotificationScopes,
  Services,
} from '../AppConstants'
import AppDispatcher from '../dispatcher'
import { EditorStateProxy } from '../entities/EditorStateProxy'
import {
  getNewAvailableImage,
  getNewGif,
  getNewImage,
  getNewInstagramFeedbackObj,
  getNewLink,
  getNewRetweet,
  getNewSourceLink,
  getNewVideo,
} from '../entities/factories'
import type { Document } from '../entities/Document'
import { MastodonParser } from '../lib/parsers/MastodonParser'
import { validateVideo } from '../lib/validation/ValidateVideo'
import { ActionTypes } from '../state/ActionTypes'
import events from '../utils/Events'
import { isUrlOnBlocklist } from '../utils/StringUtils'
import AppStore from './AppStore'
import { NotificationStore } from './NotificationStore'
import type { ValidationFeedback } from './types'
import { getDraftsFeedback } from './utils'
import {
  PostTypeCarousel,
  PostTypePost,
  PostTypeReel,
} from '~publish/legacy/post/constants'
import Shortener from '~publish/legacy/composer/composer/utils/Shortener'
import type { PostFields } from '@buffer-mono/reminders-config'
import { getStickersToDisplay } from '~publish/legacy/reminders/components/new-reminders/components/channel-fields/helpers'
import type { Post, Thread } from '../entities/Draft/Draft'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'
import { getWiredComposerStore } from '../state/getWiredComposerStore'
import { selectPendingCount } from '~publish/legacy/uploads/state/selectors'
import type { SelectedTag } from '~publish/legacy/campaign/types'

// import { registerStore, sendToMonitor } from '../utils/devtools';

const CHANGE_EVENT = 'change'

const store = getWiredComposerStore()

export const getInitialState = () => {
  return {
    get drafts(): Draft[] {
      const drafts = Services.map((service) => getNewDraft(service))
      /*
       * SWC does not have a great support for Circular Dependencies
       * in order to be able to use ComposerStore inside plugins of editor we need to use a getter
       * so that the store.drafts is initialized after the modules initialization
       * This code should be refactored to avoid circular dependencies and to not initialize all drafts at once
       */
      Object.defineProperty(this, 'drafts', {
        value: drafts,
        writable: true,
        enumerable: true,
        configurable: true,
      })

      return drafts
    },
    draftsSharedData: {
      uploadedImages: [],
      uploadedGifs: [],
      processingVideos: new Map(), // uploadId <> [drafId]
      uploadedVideos: [],
    },
    meta: {
      lastInteractedWithComposerId: null,
      forceEditorFocus: false,
      activeThreadId: 0,
      isSuggestedMediaHidden: false,
      // used for Mastodon notices
      hasMentionsWithoutServer: false,
      // When the update type is changed automatically.
      // Example: When an IG post is converted into a Reel when it only contains a video.
      updateTypeChanged: false,
    },
  }
}

let state = getInitialState()

// Register with Redux DevTools (uncomment to enable)
// registerStore('composer', state);

const eventShowSwitchPlanModal = () => {
  events.trigger('show-switch-plan-modal')
}

const ComposerStore = {
  ...EventEmitter.prototype,
  emitChange: () => ComposerStore.emit(CHANGE_EVENT),
  // @ts-expect-error TS(7006) FIXME: Parameter 'callback' implicitly has an 'any' type.
  addChangeListener: (callback) => ComposerStore.on(CHANGE_EVENT, callback),
  // @ts-expect-error TS(7006) FIXME: Parameter 'callback' implicitly has an 'any' type.
  removeChangeListener: (callback) =>
    ComposerStore.removeListener(CHANGE_EVENT, callback),

  getEnabledDrafts: (): Draft[] =>
    state.drafts.filter((draft) => draft.isEnabled),
  getDrafts: (): Draft[] => state.drafts,

  getInvalidEnabledDraftsFeedback: ({
    isDraft = false,
  }: {
    isDraft: boolean
  }): ValidationFeedback[] => {
    const drafts = ComposerStore.getEnabledDrafts()
    const activeThreadId = ComposerStore.getActiveThreadId()

    return getDraftsFeedback({ drafts, activeThreadId, isDraft })
  },

  getIsSuggestedMediaBoxHidden: () => state.meta.isSuggestedMediaHidden,
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  getDraft: (id) => state.drafts.find((draft) => draft.id === id),

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  getDraftLinkUrl: (id) => {
    // @ts-expect-error TS(2339) FIXME: Property 'link' does not exist on type 'Draft | un... Remove this comment to see the full error message
    const { link } = ComposerStore.getDraft(id)
    return link ? link.url : null
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  doesDraftHaveLinkAttachment: (id) => ComposerStore.getDraft(id).link !== null,
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  doesDraftHaveRetweetAttachment: (id) =>
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    ComposerStore.getDraft(id).retweet !== null,
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  doesDraftHaveLinkAttachmentEnabled: (id) =>
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    ComposerStore.getDraft(id).enabledAttachmentType === AttachmentTypes.LINK,
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  doesDraftHaveSourceLink: (id) =>
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    ComposerStore.getDraft(id).sourceLink !== null,

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  isDraftEnabled: (id) => ComposerStore.getDraft(id).isEnabled,
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  isDraftLocked: (id) => ComposerStore.getDraft(id).isSaved,

  getDraftsSharedData: () => state.draftsSharedData,

  areAllDraftsSaved: () => {
    const enabledDrafts = ComposerStore.getEnabledDrafts()
    return (
      enabledDrafts.length > 0 && enabledDrafts.every((draft) => draft.isSaved)
    )
  },

  // Retrieve long link from shortLinkLongLinkMap (if url is long link already, return url)
  getCanonicalUrl: (url: string): string => {
    const matchingDraft = state.drafts.find((draft) =>
      draft.shortLinkLongLinkMap.has(url),
    )

    if (matchingDraft) {
      return matchingDraft.shortLinkLongLinkMap.get(url)
    }

    return url
  },

  // scheduledAt and isPinnedToSlot are the same across all drafts
  getScheduledAt: () => state.drafts[0].scheduledAt,
  getEnabledDraftScheduledAt: () => {
    const enabledDrafts = ComposerStore.getEnabledDrafts()
    return enabledDrafts[0]?.scheduledAt
  },
  isSentPost: () => {
    const { sentPost } = AppStore.getOptions()
    return sentPost
  },
  isPinnedToSlot: () => state.drafts[0].isPinnedToSlot,

  getMeta: () => state.meta,

  // @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
  getSuggestedMediaForDraft: (draft, shouldFilterOutAttachedMedia) => {
    if (!DraftMethods.shouldShowSuggestedMedia(draft)) {
      return []
    }

    const enabledDrafts = ComposerStore.getEnabledDrafts()
    const draftsSharedData = ComposerStore.getDraftsSharedData()

    const otherEnabledDrafts = enabledDrafts.filter(
      (enabledDraft) => enabledDraft.id !== draft.id,
    )
    const { availableImages } = draft
    const availableLinkThumbnails =
      draft.link !== null && draft.link.availableThumbnails !== null
        ? draft.link.availableThumbnails
        : []
    const availableSourceLinkImages =
      draft.sourceLink !== null ? draft.sourceLink.availableImages : []

    let suggestedMedia = Array.prototype.concat.call(
      // Other enabled drafts' attached videos
      otherEnabledDrafts.reduce(
        (attachedVideos, otherDraft) =>
          otherDraft.video !== null
            ? // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              attachedVideos.concat(otherDraft.video)
            : attachedVideos,
        [],
      ),
      // Other enabled drafts' attached images
      ...otherEnabledDrafts.map((otherDraft) => otherDraft.images),
      // Other enabled drafts' attached gifs (if service supports gifs)

      draft.service.canHaveMediaAttachmentType(MediaTypes.GIF)
        ? otherEnabledDrafts.reduce(
            (attachedGifs, otherDraft) =>
              otherDraft.gif !== null
                ? // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  attachedGifs.concat(otherDraft.gif)
                : attachedGifs,
            [],
          )
        : [],
      draftsSharedData.uploadedVideos, // All uploaded videos
      draftsSharedData.uploadedImages, // All uploaded images
      // All uploaded gifs (if service supports gifs)
      draft.service.canHaveMediaAttachmentType(MediaTypes.GIF)
        ? draftsSharedData.uploadedGifs
        : [],
      availableImages, // This draft's available images
      availableLinkThumbnails, // This draft's link attachment's available thumbnails
      availableSourceLinkImages, // Images found on this draft's source url page
    )

    if (shouldFilterOutAttachedMedia) {
      suggestedMedia = suggestedMedia.filter(
        (suggestedItem) =>
          // @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
          !draft.images.find((image) => suggestedItem.url === image.url) &&
          !(draft.video && draft.video.url === suggestedItem.url) &&
          !(draft.gif && draft.gif.url === suggestedItem.url),
      )

      if (draft.hasThread()) {
        const allThreadedMedia = draft.thread.reduce(
          // @ts-expect-error TS(7006) FIXME: Parameter 'threadedMedia' implicitly has an 'any' ... Remove this comment to see the full error message
          (threadedMedia, threadedDraft) => {
            if (threadedDraft.video) {
              threadedMedia.push(threadedDraft.video.url)
            }
            if (threadedDraft.gif) {
              threadedMedia.push(threadedDraft.gif.url)
            }
            if (
              Array.isArray(threadedDraft.images) &&
              threadedDraft.images.length > 0
            ) {
              // @ts-expect-error TS(7006) FIXME: Parameter 'img' implicitly has an 'any' type.
              threadedMedia.push(...threadedDraft.images.map((img) => img.url))
            }

            return threadedMedia
          },
          [],
        )
        suggestedMedia = suggestedMedia.filter(
          (suggestedItem) =>
            // @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
            !allThreadedMedia.find((url) => suggestedItem.url === url),
        )
      }
    }

    // Deduplicate suggested media
    return uniqBy(suggestedMedia, (e) => e.url)
  },

  /**
   * Get the first selected profile for link shortenig config
   *  If omni, use the first selected profile
   *  or first connected profile if no profiles are selected
   *
   * @param { string } draftId
   * @returns {Profile | undefined}
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
  getFirstSelectedProfile: (draftId) => {
    const draft = ComposerStore.getDraft(draftId)
    let profiles = []
    if (draft?.service.isOmni) {
      const enabledDrafts = ComposerStore.getEnabledDrafts()
      profiles =
        enabledDrafts.length > 0
          ? AppStore.getSelectedProfilesForService(
              enabledDrafts[0].service.name,
            )
          : AppStore.getProfiles()
    } else {
      profiles = draft?.isEnabled
        ? AppStore.getSelectedProfilesForService(draft.service.name)
        : []
    }

    return profiles.length === 0 ? undefined : profiles[0]
  },

  getActiveThreadId: (): number => state.meta.activeThreadId,
  getNumberOfThreads: (draftId: string): number | undefined => {
    const draft = ComposerStore.getDraft(draftId)

    return draft?.thread?.length
  },

  getPendingUploadsCount: (draft: Draft): number => {
    const state = store.getState()
    const uploaderId = DraftMethods.getUploaderId(draft)
    return selectPendingCount(state, uploaderId)
  },

  /**
   * Do not use: synchronous "dispatches" go against Flux's ideas, this method
   * is only used in a single place to prevent a race condition.
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  // eslint-disable-next-line no-use-before-define
  _syncDispatch: (payload) => onDispatchedPayload(payload),
}

// Should be used for composition with functions that are passed a draft id as first argument.
// It would have been awesome to compose this as a decorator on top of non-class methods,
// alas this isn't part of the spec yet.
const monitorComposerLastInteractedWith =
  // @ts-expect-error TS(7006) FIXME: Parameter 'fn' implicitly has an 'any' type.


    (fn) =>
    // @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
    (draftId, ...restArgs) => {
      if (
        draftId === 'omni' ||
        draftId === AppStore.getAppState().expandedComposerId
      ) {
        state.meta.lastInteractedWithComposerId = draftId
      }

      return fn(draftId, ...restArgs)
    }

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateDraftHasSavingError = (id, hasSavingError) => {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  ComposerStore.getDraft(id).hasSavingError = hasSavingError
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const clearDraftInlineErrors = (id) => {
  const notifications = NotificationStore.getVisibleNotifications().filter(
    (notif) =>
      notif.scope ===
        `${NotificationScopes.UPDATE_SAVING}-${ErrorTypes.INLINE}-${id}` ||
      notif.scope === `${NotificationScopes.PROFILE_QUEUE_LIMIT}-${id}`,
  )
  notifications.forEach((notif) =>
    NotificationActionCreators.removeNotification(notif.id),
  )
}

const enableDraft = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, markAppAsLoadedWhenDone) => {
    let shouldPreventRerender = false

    if (ComposerStore.isDraftLocked(id)) return !shouldPreventRerender

    /**
     * Dashboard-specific rules:
     * - If omnibox is disabled, and there's only a single and empty composer currently
     *   enabled, then enabling a new draft will enable the omnibox, effectively keeping
     *   a single composer visible (the omnibox's)
     * - If omnibox is disabled, and there are multiple or non-empty composers currently
     *   enabled, then enabling a new draft will show an additional composer, which will
     *   be prefilled with the previous composer's contents
     */
    if (!AppStore.getIsOmniboxEnabled()) {
      const enabledDrafts = ComposerStore.getEnabledDrafts()
      const hasOneEmptyEnabledDraft =
        enabledDrafts.length === 1 && enabledDrafts[0].isEmpty()

      if (hasOneEmptyEnabledDraft) {
        AppActionCreators.updateOmniboxState(true)
        shouldPreventRerender = true
      } else if (enabledDrafts.length >= 1) {
        const draftFrom = ComposerStore.getDraft(
          state.meta.lastInteractedWithComposerId,
        )
        // if it's a Thread, we want to preserve editor state to thread structure
        if (draftFrom && draftFrom.hasThread()) {
          draftFrom.thread[state.meta.activeThreadId] =
            getThreadedDraftData(draftFrom)
        }
        copyDraftContents({
          draftFrom,
          // @ts-expect-error TS(2322) FIXME: Type 'Draft | undefined' is not assignable to type... Remove this comment to see the full error message
          draftsTo: [ComposerStore.getDraft(id)],
        })
      }
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    ComposerStore.getDraft(id).isEnabled = true
    ComposerActionCreators.updateInstagramState()
    if (markAppAsLoadedWhenDone) AppActionCreators.markAppAsLoaded()

    setValidUpdateType(id)

    return !shouldPreventRerender
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const disableDraft = (id) => {
  let shouldPreventRerender = false

  if (ComposerStore.isDraftLocked(id)) return !shouldPreventRerender
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  ComposerStore.getDraft(id).isEnabled = false

  // Disable omnibox mode if needed
  const enabledDrafts = ComposerStore.getEnabledDrafts()
  const hasOneEnabledDraft = enabledDrafts.length === 1

  if (AppStore.getIsOmniboxEnabled() && hasOneEnabledDraft) {
    AppActionCreators.updateOmniboxState(null)
    copyDraftContents({ draftsTo: enabledDrafts })
    shouldPreventRerender = true
  }

  NotificationActionCreators.removeComposerOmniboxNotices(id)

  return !shouldPreventRerender
}

// @ts-expect-error TS(7019) FIXME: Rest parameter 'values' implicitly has an 'any[]' ... Remove this comment to see the full error message
const getFirstNonNullOrUndefined = (...values) =>
  values.find((v) => typeof v !== 'undefined' && v !== null)

const setDraftInitialText = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
  id,
  // @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
  text,
  // @ts-expect-error TS(7031) FIXME: Binding element 'url' implicitly has an 'any' type... Remove this comment to see the full error message
  url,
  // @ts-expect-error TS(7031) FIXME: Binding element 'facebookMentionEntities' implicit... Remove this comment to see the full error message
  facebookMentionEntities,
  // @ts-expect-error TS(7031) FIXME: Binding element 'via' implicitly has an 'any' type... Remove this comment to see the full error message
  via,
  // @ts-expect-error TS(7031) FIXME: Binding element 'composerInitiator' implicitly has... Remove this comment to see the full error message
  composerInitiator,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isEditing' implicitly has an 'any... Remove this comment to see the full error message
  isEditing,
  // @ts-expect-error TS(7031) FIXME: Binding element 'annotations' implicitly has an 'a... Remove this comment to see the full error message
  annotations,
}) => {
  const draft = ComposerStore.getDraft(id)

  if (!draft) {
    return
  }

  if (draft.enabledAttachmentType === AttachmentTypes.RETWEET) {
    const retweetMark = 'RT @'

    if (text.includes(retweetMark)) {
      text = text.substring(0, text.indexOf(` ${retweetMark}`))
      const hasRetweetComment = text?.length !== 0
      if (!hasRetweetComment) return
    }
  }

  const shouldAppendUrl =
    draft.service.name !== 'pinterest' &&
    draft.service.name !== 'mastodon' &&
    (draft.service.name !== 'instagram' ||
      !ComposerInitiators.ImageBufferButtons.includes(composerInitiator))

  let extraText = null
  let shouldMoveCursorToStart = false

  if (
    id === 'youtube' &&
    !isEditing &&
    AppStore.getOrganizationsData()?.selected?.plan === 'free'
  ) {
    shouldMoveCursorToStart = true
    extraText = '\n#PoweredByBuffer #shorts'
  }

  let initialText = [
    text,
    shouldAppendUrl ? url : null,
    via ? `via @${via}` : null,
  ]
    .filter((val) => val)
    .join(' ')

  // Text from ideas will be inserted in title field no text field
  // when the channel is YouTube
  if (draft.service.name === 'youtube' && !isEditing && initialText !== '') {
    // update field for youtube
    ComposerActionCreators.updateDraftYoutubeData({ title: initialText })
    initialText = ''
  }

  if (draft.service.name === 'youtube' && !isEditing) {
    initialText = [initialText, extraText].filter((val) => val).join(' ')
  }

  draft.text = initialText
  draft.editorState = EditorStateProxy.createStateFromText(
    draft.editorState,
    initialText,
    {
      annotations,
      facebookMentionEntities,
    },
  )

  if (shouldMoveCursorToStart) {
    EditorStateProxy.moveCursorToStart(draft.editorState)
  }

  if (draft.service.isInstagram()) {
    ComposerActionCreators.updateDraftCommentCharacterCount(id)
  }

  if (draft.service.isPinterest()) {
    ComposerActionCreators.updateDraftTitleCharacterCount(id)
  }

  ComposerActionCreators.draftUpdated()
}

const setDraftsInitialText = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
  text,
  // @ts-expect-error TS(7031) FIXME: Binding element 'url' implicitly has an 'any' type... Remove this comment to see the full error message
  url,
  // @ts-expect-error TS(7031) FIXME: Binding element 'facebookMentionEntities' implicit... Remove this comment to see the full error message
  facebookMentionEntities,
  // @ts-expect-error TS(7031) FIXME: Binding element 'via' implicitly has an 'any' type... Remove this comment to see the full error message
  via,
  // @ts-expect-error TS(7031) FIXME: Binding element 'composerInitiator' implicitly has... Remove this comment to see the full error message
  composerInitiator,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isEditing' implicitly has an 'any... Remove this comment to see the full error message
  isEditing,
  // @ts-expect-error TS(7031) FIXME: Binding element 'annotations' implicitly has an 'a... Remove this comment to see the full error message
  annotations,
}) => {
  state.drafts.forEach((draft) =>
    setDraftInitialText({
      id: draft.id,
      text,
      url,
      facebookMentionEntities,
      via,
      composerInitiator,
      isEditing,
      annotations,
    }),
  )
}

const setDraftEditorState = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, editorState) => {
    const draft = ComposerStore.getDraft(id)

    if (draft) {
      draft.editorState = editorState
      draft.text = EditorStateProxy.getPlainText(editorState)
    }

    ComposerActionCreators.updateDraftCommentCharacterCount(id)
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateDraftIsSaved = (id) => {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  ComposerStore.getDraft(id).isSaved = true
}

const debouncedUpdateDraftSourceLinkDataActionCreator = debounce((id, url) => {
  ComposerActionCreators.updateDraftSourceLinkData(id, url)
}, 250)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateDraftSourceLink = monitorComposerLastInteractedWith((id, url) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!draft.service.canHaveSourceUrl) return

  if (url === '' || url === null) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.sourceLink = null
  } else {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.sourceLink = getNewSourceLink(url)

    const isValidUrl = twitterText.isValidUrl(url, true, false)
    if (isValidUrl) debouncedUpdateDraftSourceLinkDataActionCreator(id, url)
  }
})

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateDraftSourceLinkData = (id, { url, images = [] }) => {
  if (!ComposerStore.doesDraftHaveSourceLink(id)) {
    return
  }

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const draftSourceLink = ComposerStore.getDraft(id).sourceLink

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  Object.assign(draftSourceLink, {
    url,
    availableImages: images.map((image) =>
      getNewImage({
        // @ts-expect-error TS(2339) FIXME: Property 'url' does not exist on type 'never'.
        url: image.url,
        // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type 'never'.
        width: image.width,
        // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type 'never'.
        height: image.height,
      }),
    ),
  })
}

const updateDraftImageUserTags = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, userTags, mediaUrl) => {
    const draft = ComposerStore.getDraft(id)

    if (!draft) {
      return
    }

    draft.images?.forEach((image, i) => {
      if (image.url === mediaUrl) {
        draft.images[i].userTags = userTags
      }
    })
  },
)

const updateDraftCampaignId = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, campaignId) => {
    const draft = ComposerStore.getDraft(id)
    if (draft) {
      // @ts-expect-error TS(2339) FIXME: Property 'campaignId' does not exist on type 'Draf... Remove this comment to see the full error message
      draft.campaignId = campaignId
    }
  },
)

const updateDraftTags = monitorComposerLastInteractedWith(
  (id: string, tags: SelectedTag[]) => {
    const draft = ComposerStore.getDraft(id)
    if (draft) {
      draft.tags = tags
    }
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateDraftTitle = monitorComposerLastInteractedWith((id, title) => {
  const draft = ComposerStore.getDraft(id)
  if (draft) {
    draft.title = title
  }
})

const updateDraftScheduledAt = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, timestamp, isPinnedToSlot = false) => {
    const draft = ComposerStore.getDraft(id)
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.scheduledAt = timestamp
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.isPinnedToSlot = isPinnedToSlot
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateDraftListPlaces = (id, places) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.places = places
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateInstagramDraftThumbnail = (id, thumbOffset, thumbnail) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (draft.video) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.video.thumbnail = thumbnail
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.video.thumbOffset = thumbOffset
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
const updateDraftThumbnailGenerated = (draftId, thumbnailURL) => {
  const draft = ComposerStore.getDraft(draftId)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (draft.video) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.video.thumbnail = thumbnailURL
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.video.thumbOffset = 0
  }
}

const updateToggleSidebarVisibility = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, composerSidebarVisible) => {
    let draft
    if (id) {
      draft = ComposerStore.getDraft(id)
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      draft.composerSidebarVisible = composerSidebarVisible
    } else {
      const enabledDrafts = ComposerStore.getEnabledDrafts()

      enabledDrafts.forEach((enabledDraft) => {
        enabledDraft.composerSidebarVisible = composerSidebarVisible
      })
    }
  },
)

const updateTogglePostPreviewVisibility = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, composerPostPreviewVisible) => {
    let draft
    if (id) {
      draft = ComposerStore.getDraft(id)
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      draft.composerPostPreviewVisible = composerPostPreviewVisible
    } else {
      const enabledDrafts = ComposerStore.getEnabledDrafts()

      enabledDrafts.forEach((enabledDraft) => {
        // @ts-expect-error TS(2339) FIXME: Property 'composerPostPreviewVisible' does not exi... Remove this comment to see the full error message
        enabledDraft.composerPostPreviewVisible = composerPostPreviewVisible
      })
    }
  },
)

const updateShouldShortenLinks = monitorComposerLastInteractedWith(
  (id: string, shouldShortenLinks: boolean) => {
    let draft: Draft | undefined
    if (id) {
      draft = ComposerStore.getDraft(id)
      if (!draft) {
        return
      }

      draft.shortenLinksToggle = shouldShortenLinks

      if (shouldShortenLinks) {
        handleNewDraftLinksAndReshorten(id, draft.urls)
      } else {
        unshortenDraftLinks(id, draft)
      }
    } else {
      const enabledDrafts = ComposerStore.getEnabledDrafts()

      enabledDrafts.forEach((enabledDraft) => {
        enabledDraft.shortenLinksToggle = shouldShortenLinks
      })
    }
  },
)

const hideSuggestedMedia = () => {
  state.meta.isSuggestedMediaHidden = true
}

const updateDraftChannelData = monitorComposerLastInteractedWith(
  (id: string, channelData: ChannelDataProperties) => {
    const draft = ComposerStore.getDraft(id)

    if (!draft) {
      return
    }

    draft.setChannelData(channelData)
  },
)

const updateDraftUpdateType = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, updateType) => {
    const draft = ComposerStore.getDraft(id)
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.updateType = updateType
  },
)

const updateDraftInitialStickers = monitorComposerLastInteractedWith(
  (id: string) => {
    const draft = ComposerStore.getDraft(id)

    if (!draft) {
      return
    }

    draft.selectedStickers = getStickersToDisplay({
      service: draft.id,
      updateType: draft.updateType ?? 'post',
    }).filter((sticker) =>
      Object.keys(draft?.channelData?.[draft.id] ?? {}).includes(sticker),
    )
  },
)

const updateDraftSelectedStickers = monitorComposerLastInteractedWith(
  (
    id: string,
    selectedStickers: PostFields[],
    didEditorStateChange: boolean,
  ) => {
    const draft = ComposerStore.getDraft(id)

    if (!draft) {
      return
    }

    draft.selectedStickers = selectedStickers
  },
)

const updateDraftIsReminder = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, isReminder) => {
    const draft = ComposerStore.getDraft(id)
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (draft.service.supportsMobileReminders) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      draft.isReminder = isReminder
    }
  },
)

const updateDraftCommentCharacterCount = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id) => {
    let draft
    if (id) {
      draft = ComposerStore.getDraft(id)

      if (
        !draft ||
        (draft.service.name !== 'instagram' &&
          draft.service.name !== 'linkedin') ||
        draft.service.commentCharLimit === null
      ) {
        return
      }

      draft.characterCommentCount = DraftMethods.getFullCharacterCount(
        draft,
        draft.getCommentText(),
      )
    } else {
      const enabledDrafts = ComposerStore.getEnabledDrafts()

      enabledDrafts.forEach((enabledDraft) => {
        if (enabledDraft.service.commentCharLimit === null) {
          return
        }

        enabledDraft.characterCommentCount = DraftMethods.getFullCharacterCount(
          enabledDraft,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
          enabledDraft.channelData[enabledDraft.service].comment_text,
        )
      })
    }
  },
)

const updateDraftTitleCharacterCount = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id) => {
    let draft
    if (id) {
      draft = ComposerStore.getDraft(id)
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (draft.service.name !== 'pinterest') return
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (draft.service.titleCharLimit === null) return

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      draft.characterTitleCount = DraftMethods.getFullCharacterCount(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Draft | undefined' is not assign... Remove this comment to see the full error message
        draft,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        draft.title,
      )
    } else {
      const enabledDrafts = ComposerStore.getEnabledDrafts()

      enabledDrafts.forEach((enabledDraft) => {
        if (enabledDraft.service.titleCharLimit === null) {
          return
        }

        enabledDraft.characterTitleCount = DraftMethods.getFullCharacterCount(
          enabledDraft,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
          enabledDraft.title,
        )
      })
    }
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const mapShortLinkWithLongLink = (id, shortLink, longLink) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.shortLinkLongLinkMap.set(shortLink, longLink)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const unmapShortLinkWithLongLink = (id, link) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.shortLinkLongLinkMap.delete(link)
}

/**
 * Handle all new LinkedIn entity urls that should be converted
 * to annotations.
 *
 * If multiple LinkedIn profiles are selected, we use the id from
 * the first one for the LinkedIn lookup requests.
 *
 * This function is run async as not to block the rest of the editor,
 * but inside it we wait for all lookup requests to complete
 * before starting to edit the draft editor state.
 *
 * We do this because if we start the editing flow while the
 * requests aren't completed yet, we risk ending up with a stale
 * state by the time we push our updates, which would overwrite
 * any changes made to the editor state while we were waiting
 * for the requests to complete.
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'newUrls' implicitly has an 'any' type.
const handleNewLinkedInEntityUrls = async (newUrls) => {
  const draftId = Service.Linkedin
  const [profile] = AppStore.getSelectedProfilesForService(draftId)

  if (profile) {
    const entityUrls = await LinkedInAnnotation.parseLinkedInEntitiesUrlInText(
      newUrls,
      profile.id,
    )

    let newEditorState = ComposerStore.getDraft(draftId)?.editorState
    if (entityUrls.length !== 0) {
      entityUrls.forEach((entityDetails) => {
        newEditorState = EditorStateProxy.insertLinkedInAnnotation(
          newEditorState,
          entityDetails,
        )
      })
      // At the end, run setDraftEditorState to perform other actions
      setDraftEditorState(draftId, newEditorState)
    }
  }
}

/**
 * Loop over each block of text in the editor, and update the list of all urls
 * within the composer + detect new and removed ones
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const parseDraftTextLinks = (id) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const parsedUrls = EditorStateProxy.parseLinks(draft.editorState)

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const newUrls = parsedUrls.filter((url) => !draft.urls.includes(url))
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const removedUrls = draft.urls.filter((url) => !parsedUrls.includes(url))

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.urls = parsedUrls

  if (newUrls.length) {
    handleNewDraftLinks(id, newUrls)
  }

  if (removedUrls.length) {
    handleRemovedDraftLinks(id, removedUrls)
  }

  if (newUrls.length || removedUrls.length) {
    ComposerActionCreators.draftUpdated()
  }
}

/**
 * Used to display notices for links and mentions. We should remove it when we implement
 * autocomplete plugins for Mastodon.
 */
const parseMastodonText = () => {
  const draft = ComposerStore.getDraft('mastodon')
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const text = draft.text
  const hasMentionsWithoutServer = MastodonParser.hasMentionsWithoutServer(text)
  state.meta.hasMentionsWithoutServer = hasMentionsWithoutServer
}

const unshortenDraftLinks = (id: string, draft: Draft): void => {
  draft.shortLinkLongLinkMap.forEach((unshortenLink: string) => {
    ComposerActionCreators.draftTextLinkUnshortened(id, unshortenLink)
  })

  const unshortenedUrls: string[] = []

  draft.urls.forEach((url) => {
    replaceDraftLinkWithUnshortenedLink(
      id,
      ComposerStore.getCanonicalUrl(url),
      url,
    )
    unshortenedUrls.push(ComposerStore.getCanonicalUrl(url))
  })

  draft.urls = unshortenedUrls
  draft.unshortenedUrls = unshortenedUrls
}

const handleNewDraftLinksAndReshorten = (
  id: string,
  newUrls: string[],
): void => {
  handleNewDraftLinks(id, newUrls, true)
}

const handleNewDraftLinks = (
  id: string,
  newUrls: string[],
  reShortenLinks = false,
): void => {
  const draft = ComposerStore.getDraft(id)

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const isLinkedInDraft = draft.service.name === Service.Linkedin
  if (isLinkedInDraft) {
    handleNewLinkedInEntityUrls(newUrls)
    // LinkedIn entity urls are already processed
    newUrls = newUrls.filter(
      (url) => !LinkedInAnnotation.isLinkedInEntityUrl(url),
    )
  }

  if (newUrls.length === 0) return

  // If link attachment is disabled update it with first newly-added url
  // (As long as it's not on the blocklist)
  const isBlocked = isUrlOnBlocklist(ComposerStore.getCanonicalUrl(newUrls[0]))
  if (!isBlocked) {
    // If draft does not have any active link attachments
    // process links for attachments.
    if (!ComposerStore.doesDraftHaveLinkAttachmentEnabled(id)) {
      ComposerActionCreators.updateDraftLink(id, newUrls[0])

      // If no attachment is currently added, bring link attachment up with new link
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (!draft.hasAttachment()) {
        ComposerActionCreators.toggleAttachment(id, AttachmentTypes.LINK)
      }
    }
  } else {
    // If the first URL is blocked, show a notice about blocked URLs.
    NotificationActionCreators.queueInfo({
      scope: NotificationScopes.FB_IG_URL_NO_LINK_PREVIEW,
      message: `Due to recent changes with Facebook’s Data Policy, we can no longer generate link previews for <br />
      Facebook and Instagram links.
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://support.buffer.com/article/619-sharing-facebook-and-instagram-links-through-buffer?${HC_UTM_PARAMS}">
        Learn more
      </a>.`,
      isUnique: true,
      onlyCloseOnce: true,
    })
  }

  newUrls.forEach((newUrl) => {
    // Shorten newly-added links if not unshortened before
    if (!draft) {
      return
    }

    const wasUnshortenedBefore = draft.unshortenedUrls.includes(newUrl)
    if (!wasUnshortenedBefore || reShortenLinks) {
      if (
        draft?.shouldShortenLinks() &&
        Shortener.shouldShorten({
          service: draft?.service,
          url: newUrl,
        })
      ) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'ComposerProfile | undefined' is ... Remove this comment to see the full error message
        shortenDraftLink(id, newUrl, ComposerStore.getFirstSelectedProfile(id))
        updateShouldShowShortLinkMessage(id, false)
      } else {
        if (draft?.shouldShortenLinks() && Shortener.isLinkTooShort(newUrl)) {
          // If the link is too short, we don't want to shorten it, and we want to
          // show a message instead
          updateShouldShowShortLinkMessage(id, true)
        }
      }
    }
    // Add images scraped from new links to list of available images
    ComposerActionCreators.updateDraftLinkAvailableImages(id, newUrl)
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const handleRemovedDraftLinks = (id, removedUrls) => {
  const draft = ComposerStore.getDraft(id)

  // @ts-expect-error TS(7006) FIXME: Parameter 'removedUrl' implicitly has an 'any' typ... Remove this comment to see the full error message
  removedUrls.forEach((removedUrl) => {
    // Remove from available images those whose source link has been removed
    ComposerActionCreators.removeDraftLinkAvailableImages(id, removedUrl)

    // Forget this link was possibly unshortened before to allow shortening it
    // again if re-added
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const unshortenedUrlIndex = draft.unshortenedUrls.indexOf(removedUrl)
    if (unshortenedUrlIndex !== -1)
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      draft.unshortenedUrls.splice(unshortenedUrlIndex, 1)

    unmapShortLinkWithLongLink(id, removedUrl)
  })
}

/**
 * One-stop shop for updating draft link data. When a new link attachment is created,
 * if a piece of link attachment data that could be retrieved from the scraper is
 * missing, the scraper will be automatically invoked to retrieve more data.
 *
 * @param {string} id - Draft id
 * @param {object} linkData - Contains any number of link data properties
 * @param {object} meta
 * @param {boolean} meta.isNewLinkAttachment - Whether a new link is being attached,
 *                                             or an existing one being updated
 * @param {boolean} meta.comesFromDirectUserAction - Whether e.g. a keypress led to
 *                                                   linkData being updated
 *
 * The values from both meta.isNewLinkAttachment and meta.comesFromDirectUserAction
 * will determine how linkData is used. If meta.isNewLinkAttachment is true, linkData
 * will replace all previous values, and missing data will be reset to null. If it's
 * false, then linkData will be composed onto the existing link attachment's data.
 * Some properties can be customized by users and are valuable to keep around when new
 * link attachement data is set (e.g. after scraping a link). That's the case of
 * link.title, link.description, and link.thumbnail. When meta.comesFromDirectUserAction
 * is false, these properties will never be overridden by new linkData. When it's true,
 * these properties will always take new values from new linkData (if provided).
 *
 * Example usage:
 *
 * 1. Creating a new empty link attachment:
 *
 *    updateDraftLinkData(id, { url }, {
 *      isNewLinkAttachment: true,
 *      comesFromDirectUserAction: false,
 *    })
 *
 * 2. Updating a link attachment's title when users manually edit it:
 *
 *    updateDraftLinkData(id, { title }, {
 *      isNewLinkAttachment: false,
 *      comesFromDirectUserAction: true,
 *    })
 *
 * 3. Update a link attachment's data after scraping the page:
 *
 *    updateDraftLinkData(id, scrapedData, {
 *      isNewLinkAttachment: false,
 *      comesFromDirectUserAction: false,
 *    })
 */
const updateDraftLinkData = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, linkData, meta) => {
    const { isNewLinkAttachment, comesFromDirectUserAction } = meta
    const draft = ComposerStore.getDraft(id)

    if (!draft) {
      return
    }

    const {
      url = null,
      expandedUrl = null,
      title = null,
      description = null,
      thumbnail = null,
      availableThumbnails = null,
      originalThumbnailUrl = null,
    } = linkData

    if (!draft.service.canHaveAttachmentType(AttachmentTypes.LINK)) {
      return
    }

    /**
     * Facebook Groups are reminders-only, so they don't support link attachments
     */
    const hasFacebookGroupSelected =
      AppStore.getSelectedProfilesForService('facebook').filter(
        (service) => service.serviceType === 'group',
      ).length > 0
    if (hasFacebookGroupSelected) {
      return
    }

    const hasLinkAttachment = draft.link !== null
    const hasAvailableThumbnails =
      availableThumbnails !== null && availableThumbnails.length > 0
    const fallBackToLinkAttachmentData =
      !isNewLinkAttachment && hasLinkAttachment
    const prioritizeLinkAttachmentData =
      fallBackToLinkAttachmentData && !comesFromDirectUserAction

    // In some situations (e.g. editing an update that has a custom thumbnail in the dashboard),
    // an attached thumbnail won't be found among the scraped availableThumbnails: this flag is used
    // to know when to insert such an attached thumbnail into the collection of availableThumbnails
    // so that navigating between available thumbnails doesn't lose this initial thumbnail.
    const hasUnavailableThumbnailAttached =
      draft.link !== null &&
      draft.link.thumbnail !== null &&
      availableThumbnails !== null &&
      !availableThumbnails.some(
        (thumb: { url: string }) => thumb.url === draft.link?.thumbnail?.url,
      )

    let linkThumbnail = null
    if (draft.link !== null && draft.link.thumbnail !== null) {
      linkThumbnail = originalThumbnailUrl
        ? { ...draft.link.thumbnail, originalUrl: originalThumbnailUrl }
        : draft.link.thumbnail
    }

    draft.link = getNewLink({
      url: getFirstNonNullOrUndefined(
        url,
        fallBackToLinkAttachmentData ? draft.link?.url : null,
      ),
      expandedUrl: getFirstNonNullOrUndefined(
        expandedUrl,
        fallBackToLinkAttachmentData ? draft.link?.expandedUrl : null,
      ),
      title: getFirstNonNullOrUndefined(
        prioritizeLinkAttachmentData ? draft.link?.title : null,
        title !== null ? title.replace(/\n/g, '') : null, // Strip out line breaks
        fallBackToLinkAttachmentData ? draft.link?.title : null,
      ),
      description: getFirstNonNullOrUndefined(
        prioritizeLinkAttachmentData ? draft.link?.description : null,
        description,
        fallBackToLinkAttachmentData ? draft.link?.description : null,
      ),
      thumbnail: getFirstNonNullOrUndefined(
        thumbnail !== null
          ? getNewImage({
              url: thumbnail,
              originalUrl: thumbnail.originalUrl,
            })
          : null,
        fallBackToLinkAttachmentData ? linkThumbnail : null,
        hasAvailableThumbnails
          ? getNewImage({
              url: availableThumbnails[0].url,
              originalUrl: availableThumbnails[0].url,
              width: availableThumbnails[0].width,
              height: availableThumbnails[0].height,
            })
          : null,
      ),
      thumbnailHttps: null,
      availableThumbnails: getFirstNonNullOrUndefined(
        availableThumbnails !== null
          ? [
              ...(hasUnavailableThumbnailAttached
                ? [draft.link?.thumbnail]
                : []),
              ...availableThumbnails.map(
                (thumb: { url: string; width: number; height: number }) =>
                  getNewImage({
                    url: thumb.url,
                    width: thumb.width,
                    height: thumb.height,
                  }),
              ),
            ]
          : null,
        fallBackToLinkAttachmentData ? draft.link?.availableThumbnails : null,
      ),
      wasEdited: isNewLinkAttachment
        ? false
        : comesFromDirectUserAction || !!(draft.link && draft.link.wasEdited),
    })

    // If the link is missing some data that we could retrieve by scraping it, do so
    const scrapableProps = ['title', 'description', 'availableThumbnails']
    const isAnyScrapablePropMissing = scrapableProps.some(
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      (prop) => draft.link?.[prop] === null,
    )
    if (isAnyScrapablePropMissing)
      ComposerActionCreators.scrapeDraftLinkData(id, url, originalThumbnailUrl)

    if (isNewLinkAttachment)
      AppActionCreators.refreshFacebookDomainOwnershipData()
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const addDraftImage = monitorComposerLastInteractedWith((id: string, image) => {
  const draft = ComposerStore.getDraft(id)
  if (!draft) {
    return
  }
  const hasAttachedVideo = draft.video !== null
  const hasAttachedGif = draft.gif != null

  const newImagesOverflow =
    DraftMethods.getImageCount(draft) +
    ComposerStore.getPendingUploadsCount(draft) -
    draft.service.maxAttachableImagesCount(draft)
  const needsToMakeRoomForNewImages = newImagesOverflow > 0

  if (!draft.service.canHaveAttachmentType(AttachmentTypes.MEDIA)) return
  if (!draft.service.canHaveMediaAttachmentType(MediaTypes.IMAGE)) return

  if (needsToMakeRoomForNewImages)
    draft.images.splice(-newImagesOverflow, newImagesOverflow)
  if (hasAttachedVideo) draft.video = null // Override video
  if (hasAttachedGif) draft.gif = null // Override gif

  // If the image comes from Canva we are replacing any existing image
  // so that you get the feeling of editing the image
  const prevImage = shouldReplaceCanvaImage(image, draft.images)
  if (prevImage) {
    const index = draft.images.indexOf(prevImage)
    draft.images[index] = image
  } else {
    draft.images.push(image)
  }

  ComposerActionCreators.draftImageAdded(id, image.url)

  if (draft.id === 'instagram') {
    ComposerActionCreators.updateInstagramState()
    // if story, and more than 1 image, change scheduling type
    if (draft.isStoryPost() && draft.images.length > 1) {
      ComposerActionCreators.updateDraftIsReminder(draft.id, true)
    }
  }
})

const updateDraftVideoThumbnail = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, thumbnail) => {
    const draft = ComposerStore.getDraft(id)
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (!draft.service.canEditVideoAttachment) return

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.video.thumbnail = thumbnail
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.video.wasEdited = true
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateDraftVideoTitle = monitorComposerLastInteractedWith((id, title) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!draft.service.canEditVideoAttachment && !draft.service.canEditVideoTitle)
    return

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.video.name = title
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.video.wasEdited = true
})

const updateDraftLinkThumbnail = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, thumbnail) => {
    const draft = ComposerStore.getDraft(id)
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.link.thumbnail = thumbnail
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.link.thumbnailHttps = thumbnail.url

    // TODO: merge updateDraftLinkThumbnail() into updateDraftLinkData()
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.link.wasEdited = true
  },
)

const updateShouldShowShortLinkMessage = monitorComposerLastInteractedWith(
  (id: string, shouldShowShortLinkMessage: boolean) => {
    const draft = ComposerStore.getDraft(id)
    if (!draft) {
      return
    }
    draft.showShortLinkMessage = shouldShowShortLinkMessage
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
const selectNextLinkThumbnail = monitorComposerLastInteractedWith((draftId) => {
  const draft = ComposerStore.getDraft(draftId)
  // @ts-expect-error TS(2339) FIXME: Property 'availableThumbnails' does not exist on t... Remove this comment to see the full error message
  const { availableThumbnails } = draft.link
  const currThumbnailIndex = findLastIndex(
    availableThumbnails,
    // @ts-expect-error TS(7006) FIXME: Parameter 'thumbnail' implicitly has an 'any' type... Remove this comment to see the full error message
    (thumbnail) => thumbnail.url === draft.link.thumbnail.url,
  )
  let nextThumbnail

  if (currThumbnailIndex === availableThumbnails.length - 1) {
    // eslint-disable-next-line prefer-destructuring
    nextThumbnail = availableThumbnails[0]
  } else {
    nextThumbnail = availableThumbnails[currThumbnailIndex + 1]
  }

  ComposerActionCreators.updateDraftLinkThumbnail(draftId, nextThumbnail)
})

const selectPreviousLinkThumbnail = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
  (draftId) => {
    const draft = ComposerStore.getDraft(draftId)
    // @ts-expect-error TS(2339) FIXME: Property 'availableThumbnails' does not exist on t... Remove this comment to see the full error message
    const { availableThumbnails } = draft.link
    const currThumbnailIndex = availableThumbnails.findIndex(
      // @ts-expect-error TS(7006) FIXME: Parameter 'thumbnail' implicitly has an 'any' type... Remove this comment to see the full error message
      (thumbnail) => thumbnail.url === draft.link.thumbnail.url,
    )
    let prevThumbnail

    if (currThumbnailIndex === 0) {
      prevThumbnail = availableThumbnails[availableThumbnails.length - 1]
    } else {
      prevThumbnail = availableThumbnails[currThumbnailIndex - 1]
    }

    ComposerActionCreators.updateDraftLinkThumbnail(draftId, prevThumbnail)
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const addDraftLinkAvailableThumbnail = (id, thumbnail) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (draft.link.availableThumbnails === null)
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.link.availableThumbnails = []
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.link.availableThumbnails.unshift(thumbnail)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const addDraftUploadedLinkThumbnail = (id, url, width, height, source) => {
  const draftsSharedData = ComposerStore.getDraftsSharedData()
  const formattedImage = getNewImage({ url, width, height, source })

  /**
   * It's important for the three collections below to share the same formattedImage
   * reference, so that a change made in one location propagates to all other places
   * seamlessly.
   * TODO: Re-implement this logic in an immutable fashion: it'll be a bit more work
   * to manually search and update all places where an image can be stored, but it'll
   * be much more solid.
   */
  updateDraftLinkThumbnail(id, formattedImage)
  addDraftLinkAvailableThumbnail(id, formattedImage)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Image' is not assignable to para... Remove this comment to see the full error message
  draftsSharedData.uploadedImages.push(formattedImage)
  state.meta.isSuggestedMediaHidden = false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
const shouldReplaceCanvaImage = (image, collection) => {
  if (image.source?.name === 'canva') {
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    return collection.find((item) => item.source?.id === image.source.id)
  }
  return false
}

// @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
const addDraftUploadedImage = (draftId, url, width, height, source) => {
  const draftsSharedData = ComposerStore.getDraftsSharedData()
  const formattedImage = getNewImage({ url, width, height, source })

  /**
   * It's important for the two collections below to share the same formattedImage
   * reference, so that a change made in one location propagates to all other places
   * seamlessly.
   * TODO: Re-implement this logic in an immutable fashion: it'll be a bit more work
   * to manually search and update all places where an image can be stored, but it'll
   * be much more solid.
   */

  // If the image comes from Canva we are replacing any existing image
  // so that you get the feeling of editing the image
  const prevImage = shouldReplaceCanvaImage(
    formattedImage,
    draftsSharedData.uploadedImages,
  )
  if (prevImage) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    const index = draftsSharedData.uploadedImages.indexOf(prevImage)
    // @ts-expect-error TS(2322) FIXME: Type 'Image' is not assignable to type 'never'.
    draftsSharedData.uploadedImages[index] = formattedImage
  } else {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Image' is not assignable to para... Remove this comment to see the full error message
    draftsSharedData.uploadedImages.push(formattedImage)
  }
  addDraftImage(draftId, formattedImage)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
const addAutoUploadedImage = (url, altText, source, height, width) => {
  const draftsSharedData = ComposerStore.getDraftsSharedData()
  const formattedImage = getNewImage({ url, altText, source, height, width })

  /**
   * It's important for the two collections below to share the same formattedImage
   * reference, so that a change made in one location propagates to all other places
   * seamlessly.
   * TODO: Re-implement this logic in an immutable fashion: it'll be a bit more work
   * to manually search and update all places where an image can be stored, but it'll
   * be much more solid.
   */
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Image' is not assignable to para... Remove this comment to see the full error message
  draftsSharedData.uploadedImages.push(formattedImage)
  state.drafts.forEach((draft) => addDraftImage(draft.id, formattedImage))
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const removeDraftImage = monitorComposerLastInteractedWith((id, image) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const imageIndex = draft.images.indexOf(image)

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.images.splice(imageIndex, 1)

  ComposerActionCreators.draftUpdated()

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (draft.id === 'instagram') ComposerActionCreators.updateInstagramState()
})

// TODO: Refactor to not rely on reference mutations
// @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
const updateImageAltText = (image, altText) => {
  image.altText = altText
}

/**
 * TODO: Refactor to not rely on reference mutations
 * This method is currently only called when auto-uploading images (i.e. auto-attached
 * images on app load), so the two collections where we're sure to be able to find
 * them at any point in time are draftsSharedData.uploadedImages/uploadedGifs.
 * Find the image there and mutate it. This is quite brittle, hence the todo above.
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
const updateUploadedImageDimensions = (url, width, height) => {
  const draftsSharedData = ComposerStore.getDraftsSharedData()
  const collections = [
    draftsSharedData.uploadedImages,
    draftsSharedData.uploadedGifs,
  ]
  let image

  collections.some((imageCollection) => {
    // @ts-expect-error TS(2339) FIXME: Property 'url' does not exist on type 'never'.
    image = imageCollection.find((uploadedImage) => uploadedImage.url === url)
    return !!image
  })

  if (image) {
    // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type 'never'.
    image.width = width
    // @ts-expect-error TS(2339) FIXME: Property 'height' does not exist on type 'never'.
    image.height = height
  }
}

/**
 * Note: When adding to the draft a video that already exists within the app, it's
 * important to pass the video object's reference around rather than creating a new
 * equivalent video object, since those references are compared in different places
 * using === to establish if we're looking at the same video or not.
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const addDraftVideo = monitorComposerLastInteractedWith((id, video) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const hasAttachedImages = draft.images?.length > 0
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const hasAttachedVideo = draft.video !== null
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const hasAttachedGif = draft.gif !== null

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!draft.service.canHaveAttachmentType(AttachmentTypes.MEDIA)) return
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!draft.service.canHaveMediaAttachmentType(MediaTypes.VIDEO)) return

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (hasAttachedImages) draft.images.splice(0) // Override images
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (hasAttachedVideo) draft.video = null // Override video
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (hasAttachedGif) draft.gif = null

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.video = cloneDeep(video)

  ComposerActionCreators.draftVideoAdded(id, video)

  if (draft?.id === 'instagram') {
    ComposerActionCreators.updateInstagramState()
  }

  setValidUpdateType(id)
})

/**
 * Update the update type if not valid.
 */
const setValidUpdateType = (draftId: string): void => {
  const draft = ComposerStore.getDraft(draftId)
  if (!draft) return

  const hasVideoAttachment = draft.hasVideoAttachment()
  const isInstagramPost = draft.service?.isInstagram()
  const isFeedPost = draft.updateType === PostTypePost

  if (isInstagramPost && hasVideoAttachment && isFeedPost) {
    ComposerActionCreators.updateDraftUpdateType(draftId, PostTypeReel)
    state.meta.updateTypeChanged = true
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'videoData' implicitly has an 'any' type... Remove this comment to see the full error message
const addSharedUploadedVideo = (videoData) => {
  const draftsSharedData = ComposerStore.getDraftsSharedData()
  // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
  draftsSharedData.uploadedVideos.push(videoData)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const removeDraftVideo = monitorComposerLastInteractedWith((id) => {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  ComposerStore.getDraft(id).video = null

  ComposerActionCreators.draftUpdated()

  if (id === 'instagram') {
    ComposerActionCreators.updateInstagramState()
  }
})

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const removeDraftDocument = monitorComposerLastInteractedWith((id) => {
  const draft = ComposerStore.getDraft(id)
  if (!draft) return

  draft.document = null
  if (draft?.service.isLinkedin()) {
    DraftMethods.setUpdateType(draft, PostTypePost)
  }
})

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const addDraftGif = monitorComposerLastInteractedWith((id, gif) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const hasAttachedImages = draft.images?.length > 0
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const hasAttachedVideo = draft.video !== null

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!draft.service.canHaveAttachmentType(AttachmentTypes.MEDIA)) return
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!draft.service.canHaveMediaAttachmentType(MediaTypes.GIF)) return

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (hasAttachedImages) draft.images.splice(0) // Override images
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (hasAttachedVideo) draft.video = null // Override video
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.gif = gif

  ComposerActionCreators.draftGifAdded(id, gif.url)

  setValidUpdateType(id)
})

const enableLinkedInDocument = (draft: Draft): void => {
  if (!draft.service.isLinkedin()) {
    return
  }

  // ensure the document object exists
  if (!draft.document)
    draft.document = { mediaType: MediaTypes.DOCUMENT, title: '' }

  // remove any other media
  if (draft.images?.length) draft.images = []
  if (draft.video) draft.video = null

  DraftMethods.setUpdateType(draft, PostTypeCarousel)
}

const addDraftDocument = monitorComposerLastInteractedWith(
  (id: string, document: Document) => {
    const draft = ComposerStore.getDraft(id)

    if (!draft?.service.canHaveMediaAttachmentType(MediaAttachmentTypes.PDF)) {
      return
    }

    enableDraftAttachment(id, AttachmentTypes.MEDIA)
    enableLinkedInDocument(draft)

    // preserve title
    if (draft.document?.title) document.title = draft.document.title

    draft.document = document
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
const addDraftUploadedGif = (draftId, url, stillGifUrl, width, height) => {
  const draftsSharedData = ComposerStore.getDraftsSharedData()
  const formattedGif = getNewGif(url, stillGifUrl, width, height)

  /**
   * It's important for the two collections below to share the same formattedGif
   * reference, so that a change made in one location propagates to all other places
   * seamlessly.
   * TODO: Re-implement this logic in an immutable fashion: it'll be a bit more work
   * to manually search and update all places where an image can be stored, but it'll
   * be much more solid.
   */
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Gif' is not assignable to parame... Remove this comment to see the full error message
  draftsSharedData.uploadedGifs.push(formattedGif)
  addDraftGif(draftId, formattedGif)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
const addAutoUploadedGif = (url, stillGifUrl) => {
  const draftsSharedData = ComposerStore.getDraftsSharedData()
  const formattedGif = getNewGif(url, stillGifUrl)

  /**
   * It's important for the two collections below to share the same formattedGif
   * reference, so that a change made in one location propagates to all other places
   * seamlessly.
   * TODO: Re-implement this logic in an immutable fashion: it'll be a bit more work
   * to manually search and update all places where an image can be stored, but it'll
   * be much more solid.
   */
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Gif' is not assignable to parame... Remove this comment to see the full error message
  draftsSharedData.uploadedGifs.push(formattedGif)
  state.drafts.forEach((draft) => addDraftGif(draft.id, formattedGif))
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const removeDraftGif = monitorComposerLastInteractedWith((id) => {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  ComposerStore.getDraft(id).gif = null
  ComposerActionCreators.draftUpdated()
})

const getThreadedDraftData = (draft: Draft, text?: string): Post => {
  return {
    text: text || draft.text,
    characterCount: draft.characterCount,
    images: [...draft.images],
    video: draft.video,
    gif: draft.gif,
    link: draft.link,
    retweet: draft.retweet,
    urls: draft.urls,
    unshortenedUrls: draft.unshortenedUrls,
    enabledAttachmentType: draft.enabledAttachmentType,
  }
}

const getNewThreadedDraftData = (text = ''): Post => {
  return {
    text,
    images: [],
    video: null,
    gif: null,
    link: null,
    retweet: null,
    enabledAttachmentType: null,
    urls: [],
    unshortenedUrls: [],
  }
}

const updateDraftPostText = (id: string, text: string): void => {
  const draft = ComposerStore.getDraft(id)

  if (draft) {
    draft.text = text
  }
}

const updateDraftAddPostToThread = monitorComposerLastInteractedWith(
  (id: string, offset: number) => {
    const draft = ComposerStore.getDraft(id)

    if (!draft) {
      return
    }

    if (selectShouldShowNBMigration(store.getState())) {
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      ModalActionCreators.openModal('ThreadsMPPaywall')
      return
    }

    const { hasErrors, shouldJumpToNextThreadedPost } = draft.canAddToThread(
      state.meta.activeThreadId,
    )

    if (shouldJumpToNextThreadedPost) {
      ComposerActionCreators.switchActiveThreadEditor(
        draft.id,
        state.meta.activeThreadId + 1,
      )

      return
    }

    if (hasErrors) {
      return
    }

    if (draft.updateType !== 'thread') {
      draft.updateType = 'thread'
    }

    if (!Array.isArray(draft.thread)) {
      draft.thread = []
    } else {
      if (
        draft.service.maxThreads &&
        draft.thread.length >= draft.service.maxThreads
      ) {
        return
      }
    }

    const text = draft.text
    const postOneText = offset ? text.slice(0, offset).trim() : text
    const postTwoText = offset ? text.slice(offset).trim() : ''

    // preserve last active draft
    draft.thread[state.meta.activeThreadId] = getThreadedDraftData(
      draft,
      postOneText,
    )

    // create a new draft entity
    const indexToInsert = state.meta.activeThreadId + 1
    const newDraft = getNewThreadedDraftData(postTwoText)

    draft.thread.splice(indexToInsert, 0, newDraft)

    // remove currently uploaded media
    draft.text = ''
    draft.images = []
    draft.video = null
    draft.gif = null
    draft.link = null
    draft.retweet = null
    draft.urls = []
    draft.unshortenedUrls = []
    draft.enabledAttachmentType = AttachmentTypes.MEDIA

    // replace editor with empty text
    setDraftEditorState(
      id,
      EditorStateProxy.createStateFromText(draft.editorState, newDraft.text, {
        annotations: [],
        facebookMentionEntities: [],
      }),
    )

    // set active thread id
    setActiveThreadId(indexToInsert)
    AppActionCreators.forceEditorFocus()
  },
)

const updateAiAssisted = monitorComposerLastInteractedWith((id: string) => {
  const draft = ComposerStore.getDraft(id)
  if (draft) {
    draft.aiAssisted = true
  }
})

const updateDraftThread = monitorComposerLastInteractedWith(
  (id: string, thread: Thread): void => {
    const draft = ComposerStore.getDraft(id)

    if (draft) {
      draft.thread = thread
    }
  },
)

const updateDraftMediaOrder = (
  id: string,
  dragIndex: number,
  hoverIndex: number,
): void => {
  const draft = ComposerStore.getDraft(id)
  if (!draft || !draft.images) {
    return
  }

  // Clone the images array to avoid mutating the state directly
  const newImages = [...draft.images]

  // Remove the dragged item from the array
  const [draggedImage] = newImages.splice(dragIndex, 1)

  // Insert the dragged item at its new position
  newImages.splice(hoverIndex, 0, draggedImage)

  // Update the draft with the new images array
  draft.images = newImages
}

const deleteThreadedDraft = (
  id: string,
  threadId?: number,
  mergeThreads?: boolean,
): void => {
  const draft = ComposerStore.getDraft(id)

  if (!draft || !Array.isArray(draft.thread)) {
    return
  }

  const { activeThreadId } = state.meta
  const threadToDelete = threadId || activeThreadId

  if (mergeThreads) {
    draft.thread[activeThreadId] = getThreadedDraftData(draft)

    if (threadToDelete > 0 && draft.thread[threadToDelete].text) {
      draft.thread[threadToDelete - 1].text = `${
        draft.thread[threadToDelete - 1].text
      }
${draft.thread[threadToDelete].text}`
    }
  }

  draft.thread.splice(threadToDelete, 1)

  replaceEditorContents(
    id,
    draft,
    threadToDelete === 0 ? 0 : threadToDelete - 1,
  )

  if (draft.thread.length === 1) {
    draft.thread = null
    draft.updateType = null
  }
}

const setActiveThreadId = (threadId: number): void => {
  state.meta.activeThreadId = threadId
}

const replaceEditorContents = (
  id: string,
  draft: Draft,
  threadId: number,
): void => {
  if (Array.isArray(draft.thread) && draft.thread.length > 0) {
    // reset media available in editor
    draft.images = []
    draft.link = null
    draft.gif = null
    draft.video = null
    draft.retweet = null
    draft.urls = []
    draft.unshortenedUrls = []

    // fetch draft we switch to
    const newDraft = draft.thread[threadId]

    draft.text = newDraft.text

    // replace editor text
    setDraftEditorState(
      id,
      EditorStateProxy.createStateFromText(draft.editorState, newDraft.text, {
        annotations: [],
        facebookMentionEntities: [],
      }),
    )

    // add images if available
    if (Array.isArray(newDraft.images) && newDraft.images.length > 0) {
      newDraft.images.forEach((image) => {
        const formattedImage = getNewImage({
          url: image.url,
          source: image.source,
          altText: image.altText,
        })
        addDraftImage(id, formattedImage)
      })
    }
    // add video if available
    if (newDraft.video) {
      addDraftVideo(id, newDraft.video)
    }
    // add gif if available
    if (newDraft.gif) {
      addDraftGif(id, newDraft.gif)
    }
    // add link if available
    if (newDraft.link) {
      draft.link = newDraft.link
    }
    if (newDraft.retweet) {
      draft.retweet = newDraft.retweet
    }
    draft.enabledAttachmentType = newDraft.enabledAttachmentType
    setActiveThreadId(threadId)
    ComposerActionCreators.draftUpdated()

    AppActionCreators.forceEditorFocus()
  }
}

const switchActiveThreadEditor = (id: string, threadId: number): void => {
  const draft = ComposerStore.getDraft(id)
  const currentlyActiveThreadId = state.meta.activeThreadId

  if (draft?.hasThread()) {
    // preserve current composer data in thread structure
    draft.thread[currentlyActiveThreadId] = getThreadedDraftData(draft)
    replaceEditorContents(id, draft, threadId)
  }
}

// This method can be called very often (onMouseMove), so make sure the store
// only emits a change if a change actually happened to prevent over-rendering
// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const updateDraftTempImage = (id, url) => {
  const draft = ComposerStore.getDraft(id)

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (draft.tempImage !== url) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.tempImage = url
    return true
  }

  return false
}

// Remove draft temp image in all cases if no image is specified, otherwise only
// remove the passed draft temp image if it's specified
// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const removeDraftTempImage = (id, url = null) => {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (url === null || ComposerStore.getDraft(id).tempImage === url) {
    updateDraftTempImage(id, null)
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const addDraftRetweet = (id, retweetData) => {
  const draft = ComposerStore.getDraft(id)

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!draft.service.canHaveAttachmentType(AttachmentTypes.RETWEET)) return

  const retweet = getNewRetweet(retweetData)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.retweet = retweet

  ComposerActionCreators.draftUpdated()
}

const disableDraftsAttachment = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, attachmentType) => {
    const draft = ComposerStore.getDraft(id)
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.enabledAttachmentType = null

    if (attachmentType === AttachmentTypes.LINK) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      draft.link = null
    }

    ComposerActionCreators.attachmentToggled(id)
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const disableDraftAttachment = (id) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (draft.service.canHaveMediaAttachmentType(AttachmentTypes.MEDIA)) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.enabledAttachmentType = AttachmentTypes.MEDIA
  } else {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    draft.enabledAttachmentType = null
  }

  ComposerActionCreators.attachmentToggled(id)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const enableDraftAttachment = (id, attachmentType) => {
  const draft = ComposerStore.getDraft(id)

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (!draft.service.canHaveAttachmentType(attachmentType)) return

  // Link Attachment doesn't have a suggestion mode: don't enable it if no link attached
  if (
    attachmentType === AttachmentTypes.LINK &&
    !ComposerStore.doesDraftHaveLinkAttachment(id)
  ) {
    return
  }

  // Retweet Attachment doesn't have a suggestion mode: don't enable it if no retweet attached
  if (
    attachmentType === AttachmentTypes.RETWEET &&
    !ComposerStore.doesDraftHaveRetweetAttachment(id)
  ) {
    return
  }

  // TikTok doesn't have a suggestion mode: don't enable it if service is TikTok
  if (id === 'tiktok') {
    return
  }

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.enabledAttachmentType = attachmentType

  ComposerActionCreators.attachmentToggled(id)

  if (id === 'instagram') {
    ComposerActionCreators.updateInstagramState()
  }
}

const toggleDraftAttachment = monitorComposerLastInteractedWith(
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  (id, attachmentType) => {
    const draft = ComposerStore.getDraft(id)
    const shouldDisableAttachment =
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      draft.enabledAttachmentType === attachmentType

    if (shouldDisableAttachment) {
      disableDraftAttachment(id)
    } else {
      enableDraftAttachment(id, attachmentType)
    }

    if (id === 'instagram') ComposerActionCreators.updateInstagramState()
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const replaceDraftLinkWithShortlink = (id, link, shortLink) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2339) FIXME: Property 'editorState' does not exist on type 'Dra... Remove this comment to see the full error message
  const { editorState } = draft

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.editorState = EditorStateProxy.replaceLinkWithShortLink(
    editorState,
    link,
    shortLink,
  )

  ComposerActionCreators.parseDraftTextLinks(id)
}

const addDraftUnshortenedLink = (id: string, unshortenedLink: string): void => {
  const draft = ComposerStore.getDraft(id)
  if (!draft) {
    return
  }

  if (!draft.unshortenedUrls.includes(unshortenedLink)) {
    draft.unshortenedUrls.push(unshortenedLink)
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const addDraftAvailableImages = (id, images, sourceLink) => {
  const draft = ComposerStore.getDraft(id)

  // @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
  const newAvailableImages = images.map((image) => {
    const formattedImage = getNewImage({
      url: image.url,
      width: image.width,
      height: image.height,
    })

    return getNewAvailableImage(formattedImage, sourceLink)
  })

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.availableImages = draft.availableImages.concat(newAvailableImages)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const removeDraftAvailableImages = (id, sourceLink) => {
  const draft = ComposerStore.getDraft(id)

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.availableImages = draft.availableImages.filter(
    (availableImage) => availableImage.sourceLink !== sourceLink,
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
const addOmniNotice = (message, draftId) => {
  NotificationActionCreators.queueInfo({
    scope: NotificationScopes.MC_OMNIBOX_EDIT_NOTICE,
    message,
    onlyCloseOnce: false,
    data: { id: draftId, type: 'notice' },
  })
}

const getEditedImagesMessage = (draft: Draft): string => {
  let message
  const maxAttachableImagesCount = draft.service.maxAttachableImagesCount(draft)
  if (maxAttachableImagesCount === 1) {
    message = `${draft.service.formattedName} does not support multiple images, so we’ve used your first image`
  } else {
    message = `${draft.service.formattedName} allows ${maxAttachableImagesCount}
    images, so we kept the first ${maxAttachableImagesCount} images`
  }
  return message
}

/**
 * Copies draft media
 * @param draftFromMedia -> simplified Draft entity containing only media:
 * {
 *   enabledAttachmentType: string
 *   retweet: Retweet,
 *   video: Video,
 *   gif: Gif,
 *   images: Image[],
 * }
 * @param draftTo -> full entity from Draft.ts
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'draftFromMedia' implicitly has an 'any'... Remove this comment to see the full error message
const copyDraftMedia = (draftFromMedia, draftTo) => {
  if (draftFromMedia.images.length > 0) {
    if (!draftTo.service.canHaveMediaAttachmentType(MediaTypes.IMAGE)) {
      const message = `We can't publish images to ${draftTo.service.formattedName}, so we've removed it.`
      addOmniNotice(message, draftTo.id)
      return
    }

    let imagesToAttach = draftFromMedia.images
    if (
      draftFromMedia.images.length >
      draftTo.service.maxAttachableImagesCount(draftTo)
    ) {
      imagesToAttach = draftFromMedia.images.slice(
        0,
        draftTo.service.maxAttachableImagesCount(draftTo),
      )
      const message = getEditedImagesMessage(draftTo)
      addOmniNotice(message, draftTo.id)
    }
    // @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
    imagesToAttach.forEach((image) => addDraftImage(draftTo.id, image))
    enableDraftAttachment(draftTo.id, AttachmentTypes.MEDIA)
  } else if (draftFromMedia.gif !== null) {
    if (!draftTo.service.canHaveMediaAttachmentType(MediaTypes.GIF)) {
      const message = `We can't publish GIFs to ${draftTo.service.formattedName}, so we've removed it.`
      addOmniNotice(message, draftTo.id)
      return
    }
    addDraftGif(draftTo.id, draftFromMedia.gif)
    enableDraftAttachment(draftTo.id, AttachmentTypes.MEDIA)
  } else if (draftFromMedia.video !== null) {
    if (!draftTo.service.canHaveMediaAttachmentType(MediaTypes.VIDEO)) {
      const message = `We can't publish videos to ${draftTo.service.formattedName}, so we've removed it.`
      addOmniNotice(message, draftTo.id)
      return
    }

    // Re-validate TikTok videos before attaching
    if (draftTo.service.name === 'tiktok') {
      if (draftFromMedia.video.url) {
        const extension = draftFromMedia.video.url
          .split(/[#?]/)[0]
          .split('.')
          .pop()
          .trim()
          .toUpperCase()

        if (!draftTo.service.canHaveMediaAttachmentType(extension)) {
          const message = `We can't publish videos of this file type to ${draftTo.service.formattedName}, so we've removed it.`
          addOmniNotice(message, draftTo.id)
          return
        }
      }

      const validationResultVideo = validateVideo(
        draftFromMedia.video,
        draftTo.service,
      )

      if (validationResultVideo.isValidationFail()) {
        let { message } = validationResultVideo
        message += ", so we've removed it."
        addOmniNotice(message, draftTo.id)
        return
      }
    }

    addDraftVideo(draftTo.id, draftFromMedia.video)
    enableDraftAttachment(draftTo.id, AttachmentTypes.MEDIA)
  } else if (draftFromMedia.document !== null) {
    if (!draftTo.service.canHaveMediaAttachmentType(MediaAttachmentTypes.PDF)) {
      const message = `We can't publish documents to ${draftTo.service.formattedName}, so we've removed it.`
      addOmniNotice(message, draftTo.id)
      return
    }
    addDraftDocument(draftTo.id, draftFromMedia.document)
  }
}

const replaceDraftLinkWithUnshortenedLink = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  id,
  // @ts-expect-error TS(7006) FIXME: Parameter 'unshortenedLink' implicitly has an 'any... Remove this comment to see the full error message
  unshortenedLink,
  // @ts-expect-error TS(7006) FIXME: Parameter 'shortLink' implicitly has an 'any' type... Remove this comment to see the full error message
  shortLink,
) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2339) FIXME: Property 'editorState' does not exist on type 'Dra... Remove this comment to see the full error message
  const { editorState } = draft

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  draft.editorState = EditorStateProxy.replaceLinkWithUnshortenedLink(
    editorState,
    unshortenedLink,
    shortLink,
  )

  ComposerActionCreators.parseDraftTextLinks(id)
}

const copyDraftTextData = (draftFrom: Draft, draftTo: Draft) => {
  let newEditorState = EditorStateProxy.copyDraftEditorState(draftFrom, draftTo)

  if (
    draftFrom.retweet &&
    draftFrom.retweet.text &&
    draftFrom.retweet.text.trim() !== ''
  ) {
    newEditorState = EditorStateProxy.createStateFromText(
      newEditorState,
      `${draftFrom.text}\n${draftFrom.retweet.text}`,
    )
  }

  if (draftFrom.hasThread()) {
    if (draftTo.service.supportsThread) {
      newEditorState = EditorStateProxy.createStateFromText(
        newEditorState,
        draftFrom.thread[0].text,
      )
      draftTo.thread = cloneDeep(draftFrom.thread)
      draftTo.updateType = 'thread'
    } else {
      // If we copy from Thread to non-Thread, we want to join all threaded updates
      const joinedThreadsText = DraftMethods.getJoinedThreadsText(draftFrom)
      newEditorState = EditorStateProxy.createStateFromText(
        newEditorState,
        joinedThreadsText,
      )
    }
  }

  setDraftEditorState(draftTo.id, newEditorState)

  // @ts-expect-error TS(7034) FIXME: Variable 'unshortenedUrls' implicitly has type 'an... Remove this comment to see the full error message
  const unshortenedUrls = []
  if (
    (draftTo.service.isPinterest() || draftTo.service.isMastodon()) &&
    draftFrom?.urls.length > 0
  ) {
    draftFrom.urls.forEach((url) => {
      replaceDraftLinkWithUnshortenedLink(
        draftTo.id,
        ComposerStore.getCanonicalUrl(url),
        url,
      )
      unshortenedUrls.push(ComposerStore.getCanonicalUrl(url))
    })
    // @ts-expect-error TS(7005) FIXME: Variable 'unshortenedUrls' implicitly has an 'any[... Remove this comment to see the full error message
    draftFrom.urls = unshortenedUrls
    // @ts-expect-error TS(7005) FIXME: Variable 'unshortenedUrls' implicitly has an 'any[... Remove this comment to see the full error message
    draftFrom.unshortenedUrls = unshortenedUrls
  }

  draftTo.urls = draftFrom.urls
  draftTo.unshortenedUrls = draftFrom.unshortenedUrls
  draftTo.shortLinkLongLinkMap = draftFrom.shortLinkLongLinkMap
}

const canEnableLinkAttachmentOnDraftCopy = (
  draftFrom: Draft,
  draftTo: Draft,
): boolean => {
  if (!draftTo.urls || draftTo.urls.length === 0 || !draftTo.urls[0]) {
    return false
  }

  const draftFromCanHaveLink = draftFrom.service.canHaveAttachmentType(
    AttachmentTypes.LINK,
  )

  const draftToCanHaveLink = draftTo.service.canHaveAttachmentType(
    AttachmentTypes.LINK,
  )

  return !draftFromCanHaveLink && draftToCanHaveLink
}

const getEnabledAttachmentTypeFromDraft = (
  draftFrom?: Draft,
  draftTo?: Draft,
): string | null => {
  if (!draftFrom || !draftTo) return null

  if (draftFrom.hasThread()) {
    return draftFrom.thread?.[0]?.enabledAttachmentType ?? null
  }

  if (canEnableLinkAttachmentOnDraftCopy(draftFrom, draftTo)) {
    return AttachmentTypes.LINK
  }

  return draftFrom.enabledAttachmentType
}

const copyDraftContents = ({
  draftFrom = ComposerStore.getDraft('omni'),
  draftsTo = state.drafts,
} = {}) => {
  draftsTo.forEach((draft) => {
    const { service } = draft
    if (service.isOmni || draft === draftFrom) return

    if (!draftFrom) {
      return
    }

    // eslint-disable-next-line no-use-before-define
    clearDraft(draft.id, { preserveEnabledState: true })

    // Add text and data related to text
    copyDraftTextData(draftFrom, draft)

    draft.tags = draftFrom.tags
    draft.sourceLink = draftFrom.sourceLink
    draft.availableImages = draftFrom.availableImages
    draft.shortenLinksToggle = draftFrom.shortenLinksToggle

    /**
     * Below we add attachments. draftFromMedia is a structure created from the draftFrom,
     * or its active threaded draft (in case of Thread updates)
     * @type {{images: Image[], retweet: Retweet, gif: Gif, link: Link, enabledAttachmentType: string, video: Video}}
     */
    const draftFromMedia = {
      enabledAttachmentType: getEnabledAttachmentTypeFromDraft(
        draftFrom,
        draft,
      ),

      link: draftFrom.hasThread() ? draftFrom.thread[0].link : draftFrom.link,
      retweet: draftFrom.hasThread()
        ? draftFrom.thread[0].retweet
        : draftFrom.retweet,
      video: draftFrom.hasThread()
        ? draftFrom.thread[0].video
        : draftFrom.video,
      gif: draftFrom.hasThread() ? draftFrom.thread[0].gif : draftFrom.gif,
      images: draftFrom.hasThread()
        ? draftFrom.thread.reduce(
            (allImages, threadedUpdate) =>
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              allImages.concat(threadedUpdate.images),
            [],
          )
        : draftFrom.images,
      document: draftFrom?.document ? draftFrom.document : null,
    }

    if (draftFromMedia.enabledAttachmentType) {
      const draftUrl = draft.urls[0]
      switch (draftFromMedia.enabledAttachmentType) {
        case AttachmentTypes.LINK: {
          if (
            !service.canHaveAttachmentType(draftFromMedia.enabledAttachmentType)
          ) {
            const message = `${draft.service.formattedName} doesn't allow link attachments, so we removed it.`
            addOmniNotice(message, draft.id)
            return
          }

          if (
            !service.canEditLinkAttachment &&
            draftFromMedia.link?.wasEdited
          ) {
            const message = `${draft.service.formattedName} doesn't allow editing links, so we kept the original link attachment.`
            addOmniNotice(message, draft.id)
          }

          if (draftFromMedia.link !== null) {
            const linkDataToReuse = service.canEditLinkAttachment
              ? {
                  url: draftFromMedia.link.url,
                  title: draftFromMedia.link.title,
                  description: draftFromMedia.link.description,
                  thumbnail:
                    draftFromMedia.link.thumbnail !== null
                      ? draftFromMedia.link.thumbnail.url
                      : null,
                }
              : {
                  url: draftFromMedia.link.url,
                }

            updateDraftLinkData(draft.id, linkDataToReuse, {
              isNewLinkAttachment: true,
              comesFromDirectUserAction: false,
            })
            enableDraftAttachment(draft.id, AttachmentTypes.LINK)
          } else if (draftUrl) {
            ComposerActionCreators.scrapeDraftLinkData(draft.id, draftUrl)
            enableDraftAttachment(draft.id, AttachmentTypes.LINK)
            ComposerActionCreators.toggleAttachment(
              draft.id,
              AttachmentTypes.LINK,
            )
          }

          break
        }

        case AttachmentTypes.MEDIA: {
          if (
            !service.canHaveAttachmentType(draftFromMedia.enabledAttachmentType)
          ) {
            const message = `${draft.service.formattedName} doesn't allow image attachments, so we removed it.`
            addOmniNotice(message, draft.id)
            return
          }

          if (
            draftFromMedia.video !== null &&
            service.videoMaxSize &&
            draftFromMedia.video.size > service.videoMaxSize
          ) {
            const message = `${
              draft.service.formattedName
            } allows video size to be up to ${Math.round(
              service.videoMaxSize / 1024 / 1024,
            )}MB.`
            addOmniNotice(message, draft.id)
          }

          copyDraftMedia(draftFromMedia, draft)
          break
        }

        case AttachmentTypes.RETWEET: {
          if (
            !service.canHaveAttachmentType(draftFromMedia.enabledAttachmentType)
          ) {
            const message = `${draft.service.formattedName} doesn't allow retweet attachments, so we removed it.`
            addOmniNotice(message, draft.id)
            return
          }

          draft.retweet = draftFromMedia.retweet
          break
        }

        default:
          break
      }
    }
  })

  // Let draftFrom remain the last active draft, so that networks that are added
  // immediately after still use the same draft as reference (instead of a
  // possibly more restricted other network, e.g. Twitter)
  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'null'.
  state.meta.lastInteractedWithComposerId = draftFrom.id
  ComposerActionCreators.updateInstagramState()

  // we need to set isTwitterPremium flag on twitter drafts for increased char limit
  // to work, as it checks draft.isTwitterPremium to determine it
  const twitterDraft = draftsTo?.find((draft) => draft.id === 'twitter')
  if (twitterDraft) {
    const isTwitterPremium = AppStore.getSelectedProfilesForService(
      'twitter',
    ).reduce((acc, profile) => acc && !!profile.isTwitterPremium, true)
    ComposerActionCreators.setIsTwitterPremium('twitter', isTwitterPremium)
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const clearDraft = (id, { preserveEnabledState = false } = {}) => {
  const draft = ComposerStore.getDraft(id)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const emptyDraft = getNewDraft(draft.service)

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (preserveEnabledState) emptyDraft.isEnabled = draft.isEnabled

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  Object.assign(draft, emptyDraft, { scheduledAt: draft.scheduledAt })
}

const parseDraftsTextLinks = () => {
  const enabledDrafts = ComposerStore.getEnabledDrafts()
  enabledDrafts.forEach((draft) => parseDraftTextLinks(draft.id))
}

// @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
const insertHashtag = ({ draft, text }) => {
  const { id, editorState } = draft
  const newEditorState = EditorStateProxy.insertSnippet(editorState, text)
  setDraftEditorState(id, newEditorState)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
const insertHashtagInCaption = (text) => {
  const { lastInteractedWithComposerId } = ComposerStore.getMeta()
  const draft = ComposerStore.getDraft(lastInteractedWithComposerId)

  if (draft) {
    insertHashtag({ draft, text })
  } else {
    const enabledDrafts = ComposerStore.getEnabledDrafts()
    enabledDrafts.forEach((enabledDraft) => {
      insertHashtag({ draft: enabledDraft, text })
    })
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
const passesImageAspectRatioTest = (image) => {
  if (!image.width || !image.height) return true
  const ratio = image.width / image.height
  return (
    ratio >= InstagramAspectRatioLimits.min &&
    ratio <= InstagramAspectRatioLimits.max
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'images' implicitly has an 'any' type.
const passesImagesAspectRatioTest = (images) => {
  return images.every(passesImageAspectRatioTest)
}

const isInstagramVideoAspectRatioOk = (draft: Draft): boolean => {
  const { video, service, updateType } = draft
  const aspectRatioRules = getVideoRestrictionsForDraft(
    service.name,
    updateType,
    // TODO: this function does not return any rools right now, however,
    // if it is fixed then validateDraft funtion fails
    // See more: https://buffer.slack.com/archives/C05N3DJ5K7G/p1718877098223399
    // eslint-disable-next-line array-callback-return
  )?.filter((rule) => {
    rule.ruleName.startsWith('aspectRatio')
  })

  return validateMedia(aspectRatioRules || [], video) === null
}

const getUsernamesOfProfilesWithoutPushNotifications = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedIgProfiles' implicitly ha... Remove this comment to see the full error message
  selectedIgProfiles,
}) => {
  const usernamesOfProfilesWithoutPushNotifications = selectedIgProfiles
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    .filter((profile) => !profile.hasPushNotifications)
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    .map((profile) => profile.service && `@${profile.service.username}`)

  return usernamesOfProfilesWithoutPushNotifications.join(', ')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'selectedIgProfiles' implicitly has an '... Remove this comment to see the full error message
const getReminderMessage = (selectedIgProfiles, mediaType) => {
  const draftsMode = AppStore.getMetaData().tabId === 'drafts'

  const hasSomeProfilesWithoutPushNotifications = selectedIgProfiles.some(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => !profile.hasPushNotifications,
  )
  const hasSomeProfilesWithPushNotifications = selectedIgProfiles.some(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => profile.hasPushNotifications,
  )

  const usernameList =
    hasSomeProfilesWithoutPushNotifications &&
    getUsernamesOfProfilesWithoutPushNotifications({ selectedIgProfiles })

  // Only IG profiles with Push Notifications enabled
  const hasAllProfilesWithPushNotifications =
    hasSomeProfilesWithPushNotifications &&
    !hasSomeProfilesWithoutPushNotifications

  // Only IG profiles with Push Notifications disabled
  const hasAllProfilesWithoutPushNotifications =
    hasSomeProfilesWithoutPushNotifications &&
    !hasSomeProfilesWithPushNotifications

  // Has both IG Profiles with and without Push Notifications enabled
  const hasProfilesWithAndWithoutPushNotifications =
    hasSomeProfilesWithPushNotifications &&
    hasSomeProfilesWithoutPushNotifications

  const messageMap = {
    imageRatio: {
      onlyWith: {
        message: `Due to Instagram limitations, we can't post images directly to Instagram with aspect
          ratios outside the range 4:5 to 1.91:1. You will receive a Reminder to post manually when the time comes!`,
        composerId: 'instagram',
        code: 'ASPECT_RATIO',
      },
      onlyWithout: {
        message: `To post images to Instagram with aspect ratio outside the 4:5 to 1:91:1 range, you’ll need
          to set up Reminders. Reminders aren’t set up for ${usernameList}. Finish scheduling your post, then
          visit the queue for ${usernameList} to set up Reminders!`,
        composerId: 'instagram',
        code: 'ASPECT_RATIO',
      },
      mixed: {
        message: `Reminders aren’t set up for ${usernameList}. To post an image outside the range 4:5 to 1.91:1
          to Instagram, you’ll need to set up Reminders. Finish scheduling your post, then visit the queue for
          ${usernameList} to set up Reminders!`,
        composerId: 'instagram',
        code: 'ASPECT_RATIO',
      },
    },
    videoRatio: {
      onlyWith: {
        message: `Due to Instagram limitations, we can't post videos directly to Instagram with aspect
        ratios outside the range 4:5 to 16:9. You will receive a Reminder to post manually when the time comes!`,
        composerId: 'instagram',
        code: 'ASPECT_RATIO',
      },
      onlyWithout: {
        message: `To post videos to Instagram with aspect ratio outside the 4:5 to 16:9 range, you’ll need
          to set up Reminders. Reminders aren’t set up for ${usernameList}. Finish scheduling your post,
          then visit the queue for ${usernameList} to set up Reminders!`,
        composerId: 'instagram',
        code: 'ASPECT_RATIO',
      },
      mixed: {
        message: `Reminders aren’t set up for ${usernameList}. To post a video outside the range 4:5 to 16:9 to Instagram,
          you’ll need to set up Reminders. Finish scheduling your post, then visit the queue for ${usernameList} to set up Reminders!`,
        composerId: 'instagram',
        code: 'ASPECT_RATIO',
      },
    },
    mixScheduling: {
      onlyWith: {
        message: `Some of your channels aren't enabled for Direct Scheduling, we'll send out Reminders
          for those accounts. Not all features are supported for reminders.`,
        composerId: 'instagram',
        code: 'NOT_ENABLED',
      },
      onlyWithout: {
        message: `Some of your channels aren't enabled for Direct Scheduling, you'll need to set up
          Reminders for those accounts. Reminders aren’t set up for ${usernameList}. Finish scheduling your post,
          then visit the queue for ${usernameList} to set up Reminders!`,
        composerId: 'instagram',
        code: 'NOT_ENABLED',
      },
      mixed: {
        message: `Some of your channels aren't enabled for Direct Scheduling, we'll send out Reminders for those accounts.
          Reminders aren’t set up for ${usernameList}. Finish scheduling your post, then visit the queue for ${usernameList} to set up Reminders!`,
        composerId: 'instagram',
        code: 'NOT_ENABLED',
      },
    },
    remindersOnly: {
      onlyWith: {
        message: `Your channel isn't enabled for Direct Scheduling, we'll send out Reminders. Not all features are supported for reminders.`,
        composerId: 'instagram',
        code: 'NOT_ENABLED',
      },
      onlyWithout: {
        message: `Reminders aren’t set up for ${usernameList}. Finish scheduling your post,
          then visit the queue for ${usernameList} to set up Reminders!`,
        composerId: 'instagram',
        code: 'NOT_ENABLED',
      },
      mixed: {
        message: `Some of your channels aren't enabled for Direct Scheduling, we'll send out Reminders for those channels.
          Reminders aren’t set up for ${usernameList}. Finish scheduling your post, then visit the queue for ${usernameList} to set up Reminders!`,
        composerId: 'instagram',
        code: 'NOT_ENABLED',
      },
    },
  }
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const messagesByMediaType = messageMap[mediaType] || {}
  let feedbackMessage

  if (hasAllProfilesWithPushNotifications) {
    feedbackMessage = messagesByMediaType.onlyWith
  }

  if (hasAllProfilesWithoutPushNotifications && !draftsMode) {
    feedbackMessage = messagesByMediaType.onlyWithout
  } else if (hasAllProfilesWithoutPushNotifications) {
    feedbackMessage = messagesByMediaType.onlyWith
  }

  if (hasProfilesWithAndWithoutPushNotifications && !draftsMode) {
    feedbackMessage = messagesByMediaType.mixed
  } else if (hasProfilesWithAndWithoutPushNotifications) {
    feedbackMessage = messagesByMediaType.onlyWith
  }

  if (feedbackMessage) {
    return getNewInstagramFeedbackObj(feedbackMessage)
  }
}

const updateInstagramDraftsFeedback = () => {
  const reduxState = store.getState()
  const isRemindersEnabled = selectSplits(reduxState)?.isRemindersEnabled
  if (isRemindersEnabled) return

  const instagramDraft = ComposerStore.getEnabledDrafts().filter(
    (draft) => draft.id === 'instagram',
  )[0]
  if (!instagramDraft) return
  instagramDraft.instagramFeedback = []

  const selectedIgProfiles = AppStore.getSelectedProfilesForService('instagram')
  const hasSomeEnabledProfiles = selectedIgProfiles.some(
    (profile) => profile.instagramDirectEnabled,
  )
  const hasSomeDisabledProfiles = selectedIgProfiles.some(
    (profile) => !profile.instagramDirectEnabled,
  )

  const isMediaError =
    hasSomeEnabledProfiles &&
    instagramDraft.enabledAttachmentType === AttachmentTypes.MEDIA
  const isImageOrGalleryError =
    isMediaError && instagramDraft.images?.length > 0
  const isImageRatioError =
    isImageOrGalleryError &&
    passesImagesAspectRatioTest(instagramDraft.images) === false
  const isVideoRatioError =
    isMediaError &&
    instagramDraft.video &&
    isInstagramVideoAspectRatioOk(instagramDraft) === false
  const hasMixSchedulingError =
    hasSomeEnabledProfiles && hasSomeDisabledProfiles

  const hasInstagramProfileWithReminders = hasSomeDisabledProfiles

  let feedbackObject

  if (isImageRatioError) {
    if (instagramDraft.isStoryPost()) {
      // We don't support reminders for Stories at the moment
      return
    }
    feedbackObject = getReminderMessage(selectedIgProfiles, 'imageRatio')
  } else if (isVideoRatioError) {
    if (instagramDraft.isReelsPost() || instagramDraft.isStoryPost()) {
      // We don't support reminders for Reels and Stories at the moment
      return
    }
    feedbackObject = getReminderMessage(selectedIgProfiles, 'videoRatio')
  } else if (hasMixSchedulingError) {
    feedbackObject = getReminderMessage(selectedIgProfiles, 'mixScheduling')
  } else if (hasInstagramProfileWithReminders) {
    feedbackObject = getReminderMessage(selectedIgProfiles, 'remindersOnly')
  }

  if (feedbackObject) {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ message: any; composerId: stri... Remove this comment to see the full error message
    instagramDraft.instagramFeedback.push(feedbackObject)
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
const onDispatchedPayload = (payload) => {
  const { action } = payload
  let shouldEmitChange = true
  // @ts-expect-error TS(7034) FIXME: Variable 'video' implicitly has type 'any' in some... Remove this comment to see the full error message
  let video

  switch (action.actionType) {
    case ActionTypes.COMPOSER_SET_DRAFTS_INITIAL_TEXT:
      setDraftsInitialText(action)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_EDITOR_STATE:
      setDraftEditorState(action.id, action.editorState)
      break

    case ActionTypes.UPDATE_DRAFT_IS_SAVED:
      updateDraftIsSaved(action.id)
      break

    case ActionTypes.COMPOSER_HIDE_SUGGESTED_MEDIA:
      hideSuggestedMedia()
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_UPDATE_TYPE:
      state.drafts.forEach((draft) =>
        updateDraftUpdateType(draft.id, action.updateType),
      )
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_IS_REMINDER:
      updateDraftIsReminder(action.draftId, action.isReminder)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_IS_REMINDER:
      state.drafts.forEach((draft) =>
        ComposerActionCreators.updateDraftIsReminder(
          draft.id,
          action.isReminder,
        ),
      )
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_UPDATE_TYPE:
      updateDraftUpdateType(action.id, action.updateType)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_SELECTED_STICKERS:
      updateDraftSelectedStickers(action.id, action.selectedStickers)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_INITIAL_STICKERS:
      state.drafts.forEach((draft) => updateDraftInitialStickers(draft.id))
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_GOOGLE_BUSINESS_DATA:
      updateDraftChannelData(SERVICE_GOOGLEBUSINESS, action.googleBusinessData)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_MASTODON_DATA:
      updateDraftChannelData(SERVICE_MASTODON, action.mastodonData)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_START_PAGE_DATA:
      updateDraftChannelData(SERVICE_STARTPAGE, action.startPageData)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_INSTAGRAM_DATA:
      updateDraftChannelData(SERVICE_INSTAGRAM, action.instagramData)
      updateInstagramDraftsFeedback()
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_LINKEDIN_DATA:
      updateDraftChannelData(SERVICE_LINKEDIN, action.linkedinData)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_YOUTUBE_DATA:
      updateDraftChannelData(SERVICE_YOUTUBE, action.youtubeData)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_TIKTOK_DATA:
      updateDraftChannelData(SERVICE_TIKTOK, action.tiktokData)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_IMAGE_USER_TAGS:
      // need to add timeout because draft images doesn't load right away in edit mode
      setTimeout(() => {
        state.drafts.forEach((draft) =>
          updateDraftImageUserTags(draft.id, action && action.userTags),
        )
      })
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_CAMPAIGN_ID:
      state.drafts.forEach((draft) =>
        updateDraftCampaignId(draft.id, action?.campaignId),
      )
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_TAGS:
      state.drafts.forEach((draft) => updateDraftTags(draft.id, action?.tags))
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_TITLE:
      state.drafts.forEach((draft) => updateDraftTitle(draft.id, action?.title))
      break

    case ActionTypes.COMPOSER_UPDATE_TOGGLE_SIDEBAR:
      updateToggleSidebarVisibility(action.id, action.composerSidebarVisible)
      break

    case ActionTypes.UPDATE_CONNECT_CHANNEL_POPOVER_VISIBLE:
      updateToggleSidebarVisibility(
        action.id,
        action.connectChannelPopoverVisible,
      )
      break

    case ActionTypes.COMPOSER_UPDATE_TOGGLE_POST_PREVIEW:
      updateTogglePostPreviewVisibility(
        action.id,
        action.composerPostPreviewVisible,
      )
      break

    case ActionTypes.COMPOSER_UPDATE_SHOULD_SHORTEN_LINKS:
      updateShouldShortenLinks(action.id, action.shouldShortenLinks)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_COMMENT_CHARACTER_COUNT:
      updateDraftCommentCharacterCount(action.id, action.didEditorStateChange)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_TITLE_CHARACTER_COUNT:
      updateDraftTitleCharacterCount(action.id, action.didEditorStateChange)
      break

    case ActionTypes.COMPOSER_PARSE_DRAFT_TEXT_LINKS:
      parseDraftTextLinks(action.id)
      break

    case ActionTypes.COMPOSER_PARSE_MASTODON_TEXT:
      parseMastodonText()
      break

    case ActionTypes.COMPOSER_PARSE_DRAFTS_TEXT_LINKS:
      parseDraftsTextLinks()
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_SCHEDULED_AT:
      state.drafts.forEach((draft) => {
        updateDraftScheduledAt(
          draft.id,
          action.scheduledAt,
          action.isPinnedToSlot,
        )
      })
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_LINK_DATA:
      updateDraftLinkData(action.id, action.linkData, action.meta)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_LINK_DATA:
      state.drafts.forEach((draft) =>
        updateDraftLinkData(draft.id, action.linkData, action.meta),
      )
      break

    case ActionTypes.COMPOSER_ADD_DRAFT_IMAGE:
      addDraftImage(action.id, action.image)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_LINK_THUMBNAIL:
      updateDraftLinkThumbnail(action.id, action.thumbnail)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_VIDEO_THUMBNAIL:
      updateDraftVideoThumbnail(action.id, action.thumbnail)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_VIDEO_TITLE:
      updateDraftVideoTitle(action.id, action.title)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_SOURCE_LINK:
      updateDraftSourceLink(action.id, action.url)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_SOURCE_LINK:
      state.drafts.forEach((draft) =>
        updateDraftSourceLink(draft.id, action.url),
      )
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_SOURCE_LINK_DATA:
      updateDraftSourceLinkData(action.id, action.linkData)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_LIST_PLACES:
      updateDraftListPlaces(action.id, action.places)
      break

    case ActionTypes.COMPOSER_UPDATE_INSTAGRAM_DRAFT_THUMBNAIL:
      updateInstagramDraftThumbnail(
        action.id,
        action.thumbOffset,
        action.thumbnail,
      )
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_THUMBNAIL_GENERATED: {
      updateDraftThumbnailGenerated(action.draftId, action.thumbnailURL)

      const { videoMetadata } = action

      const processedVideoData = {
        uploadId: action.uploadId,
        name: action.name,
        duration: videoMetadata.duration,
        durationMs: videoMetadata.durationMs,
        frameRate: videoMetadata.frameRate,
        size: videoMetadata.size,
        width: videoMetadata.width,
        height: videoMetadata.height,
        url: videoMetadata.url,
        originalUrl: videoMetadata.url,
        thumbnail: action.thumbnailURL,
        availableThumbnails: [action.thumbnailURL],
        thumbOffset: 0,
        mediaType: MediaTypes.VIDEO,
      }

      addDraftVideo(action.draftId, processedVideoData)
      addSharedUploadedVideo(processedVideoData)

      break
    }

    case ActionTypes.COMPOSER_DRAFT_IMAGE_ADDED:
      removeDraftTempImage(action.id, action.url)
      break

    case ActionTypes.COMPOSER_REMOVE_DRAFT_IMAGE:
      removeDraftImage(action.id, action.image)
      break

    case ActionTypes.COMPOSER_REMOVE_DRAFT_DOCUMENT:
      removeDraftDocument(action.id)
      break

    case ActionTypes.COMPOSER_ADD_DRAFT_VIDEO:
      addDraftVideo(action.id, action.video)
      break

    case ActionTypes.COMPOSER_ADD_DRAFT_GIF:
      addDraftGif(action.id, action.gif)
      break

    case ActionTypes.COMPOSER_DRAFT_VIDEO_ADDED:
      removeDraftTempImage(action.id, action.video.thumbnail)
      break

    case ActionTypes.COMPOSER_DRAFT_GIF_ADDED:
      removeDraftTempImage(action.id, action.url)
      break

    case ActionTypes.COMPOSER_REMOVE_DRAFT_VIDEO:
      removeDraftVideo(action.id, action.videoUrl)
      break

    case ActionTypes.COMPOSER_REMOVE_DRAFT_GIF:
      removeDraftGif(action.id, action.gifUrl)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_IMAGE_USER_TAGS:
      updateDraftImageUserTags(
        action.id,
        action && action.userTags,
        action.mediaUrl,
      )
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_IMAGE_USER_TAGS_ALL_DRAFTS:
      state.drafts.forEach((draft) =>
        updateDraftImageUserTags(
          draft.id,
          action && action.userTags,
          action.mediaUrl,
        ),
      )
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_CAMPAIGN_ID:
      updateDraftCampaignId(action.id, action?.campaignId)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_TITLE:
      updateDraftTitle(action.id, action?.title)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_TEMP_IMAGE:
      shouldEmitChange = updateDraftTempImage(action.id, action.url)
      break

    case ActionTypes.COMPOSER_REMOVE_DRAFT_TEMP_IMAGE:
      removeDraftTempImage(action.id)
      break

    case ActionTypes.COMPOSER_ADD_DRAFTS_RETWEET:
      state.drafts.forEach((draft) =>
        addDraftRetweet(draft.id, action.retweetData),
      )
      break

    case ActionTypes.COMPOSER_TOGGLE_DRAFT_ATTACHMENT:
      toggleDraftAttachment(action.id, action.attachmentType)
      break

    case ActionTypes.COMPOSER_DISABLE_DRAFT_ATTACHMENT:
      disableDraftsAttachment(
        action.id,
        action.attachmentType,
        action.didEditorStateChange,
      )
      break

    case ActionTypes.COMPOSER_ENABLE_DRAFTS_ATTACHMENT:
      state.drafts.forEach((draft) =>
        enableDraftAttachment(draft.id, action.attachmentType),
      )
      break

    case ActionTypes.COMPOSER_ENABLE:
      shouldEmitChange = enableDraft(action.id, action.markAppAsLoadedWhenDone)
      break

    case ActionTypes.COMPOSER_DISABLE:
      shouldEmitChange = disableDraft(action.id)
      break

    case ActionTypes.UPDATE_DRAFT_HAS_SAVING_ERROR:
      updateDraftHasSavingError(action.id, action.hasSavingError)
      break

    case ActionTypes.COMPOSER_CLEAR_INLINE_ERRORS:
      clearDraftInlineErrors(action.id)
      break

    case ActionTypes.COMPOSER_DRAFT_LINK_SHORTENED:
      // @ts-expect-error TS(2555) FIXME: Expected at least 1 arguments, but got 0.
      monitorComposerLastInteractedWith(() => {
        mapShortLinkWithLongLink(action.id, action.shortLink, action.link)
        replaceDraftLinkWithShortlink(action.id, action.link, action.shortLink)
      })()
      break

    case ActionTypes.COMPOSER_DRAFT_LINK_UNSHORTENED:
      // @ts-expect-error TS(2555) FIXME: Expected at least 1 arguments, but got 0.
      monitorComposerLastInteractedWith(() => {
        addDraftUnshortenedLink(action.id, action.unshortenedLink)
        parseDraftTextLinks(action.id)
        unmapShortLinkWithLongLink(action.id, action.shortLink)
      })()
      break

    case ActionTypes.COMPOSER_DRAFT_LINK_RESHORTENED:
      // @ts-expect-error TS(2555) FIXME: Expected at least 1 arguments, but got 0.
      monitorComposerLastInteractedWith(() => {
        parseDraftTextLinks(action.id)
        mapShortLinkWithLongLink(action.id, action.shortLink, action.link)
      })()
      break

    case ActionTypes.COMPOSER_ADD_DRAFT_AVAILABLE_IMAGES:
      addDraftAvailableImages(action.id, action.images, action.sourceLink)
      break

    case ActionTypes.COMPOSER_REMOVE_DRAFT_AVAILABLE_IMAGES:
      removeDraftAvailableImages(action.id, action.sourceLink)
      break

    case ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_IMAGE:
      // Order matters, this should remain last
      addDraftUploadedImage(
        action.id,
        action.url,
        action.width,
        action.height,
        action.source,
      )
      break

    case ActionTypes.UPLOADED_LINK_THUMBNAIL:
      // @ts-expect-error TS(2554) FIXME: Expected 5 arguments, but got 4.
      addDraftUploadedLinkThumbnail(
        action.id,
        action.url,
        action.width,
        action.height,
      )
      break

    case ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_LINK_THUMBNAIL:
      // @ts-expect-error TS(2554) FIXME: Expected 5 arguments, but got 4.
      addDraftUploadedLinkThumbnail(
        action.id,
        action.url,
        action.width,
        action.height,
      )
      break

    case ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_VIDEO:
      state.draftsSharedData.processingVideos.set(action.uploadId, [action.id])
      break

    case ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_GIF:
      addDraftUploadedGif(
        action.id,
        action.url,
        action.stillGifUrl,
        action.width,
        action.height,
      )
      break

    case ActionTypes.COMPOSER_SET_LINKEDIN_CAROUSEL_TITLE:
      const draft = ComposerStore.getDraft(SERVICE_LINKEDIN)

      if (!draft) return

      enableLinkedInDocument(draft)
      draft.document && (draft.document.title = action.title)

      break

    case ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_DOCUMENT:
      const draftIds = action.draftId
        ? [action.draftId]
        : state.drafts.map((draft) => draft.id)

      draftIds.forEach((draftId) => {
        addDraftDocument(draftId, action.document)
      })

      break

    case ActionTypes.COMPOSER_VIDEO_PROCESSED: {
      const videoData = action.processedVideoData
      const { processingVideos } = state.draftsSharedData

      if (!processingVideos.has(videoData.uploadId)) return

      const [draftId] = processingVideos.get(videoData.uploadId)
      const newVideo = getNewVideo(videoData)

      addDraftVideo(draftId, newVideo)
      addSharedUploadedVideo(newVideo)

      break
    }

    case ActionTypes.COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_IMAGE:
      addAutoUploadedImage(
        action.url,
        action.altText,
        action.source,
        action.height,
        action.width,
      )
      break

    case ActionTypes.COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_GIF:
      addAutoUploadedGif(action.url, action.stillGifUrl)
      break

    case ActionTypes.COMPOSER_UPDATE_NEXT_LINK_THUMBNAIL:
      selectNextLinkThumbnail(action.draftId)
      break

    case ActionTypes.COMPOSER_UPDATE_PREVIOUS_LINK_THUMBNAIL:
      selectPreviousLinkThumbnail(action.draftId)
      break

    case ActionTypes.COMPOSER_UPDATE_IMAGE_ALT_TEXT:
      updateImageAltText(action.image, action.altText)
      break

    case ActionTypes.COMPOSER_UPDATE_UPLOADED_IMAGE_DIMENSIONS:
      updateUploadedImageDimensions(action.url, action.width, action.height)
      updateInstagramDraftsFeedback()
      break

    case ActionTypes.COMPOSER_APPLY_OMNI_UPDATE:
      copyDraftContents()
      AppActionCreators.updateOmniboxState(false)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFTS_THREAD:
      state.drafts.forEach((draft) =>
        updateDraftThread(draft.id, action.thread),
      )
      break

    case ActionTypes.COMPOSER_UPDATE_AI_ASSISTED:
      state.drafts.forEach((draft) => updateAiAssisted(draft.id))
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_ADD_POST_TO_THREAD:
      updateDraftAddPostToThread(action.id, action.offset)
      break

    case ActionTypes.COMPOSER_UPDATE_POST_TEXT:
      updateDraftPostText(action.id, action.text)
      break

    case ActionTypes.COMPOSER_SET_ACTIVE_THREAD_ID:
      setActiveThreadId(action.threadId)
      break

    case ActionTypes.COMPOSER_SWITCH_ACTIVE_THREAD_EDITOR:
      switchActiveThreadEditor(action.id, action.threadId)
      break

    case ActionTypes.COMPOSER_DELETE_THREADED_DRAFT:
      deleteThreadedDraft(action.id, action.threadID, action.mergeThreads)
      break

    case ActionTypes.COMPOSER_UPDATE_DRAFT_MEDIA_ORDER:
      updateDraftMediaOrder(action.id, action.dragIndex, action.hoverIndex)
      break

    case ActionTypes.COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_VIDEO:
      video = getNewVideo(action.video)
      addSharedUploadedVideo(video)
      // @ts-expect-error TS(7005) FIXME: Variable 'video' implicitly has an 'any' type.
      state.drafts.forEach((draft) => addDraftVideo(draft.id, video))
      break

    case ActionTypes.COMPOSER_DRAFTS_PREVENT_AUTO_ATTACHING_URLS:
      state.drafts.forEach((draft) => {
        draft.urls = action.urls
      })
      break

    case ActionTypes.COMPOSER_FORCE_FOCUS:
      state.meta.forceEditorFocus = true
      break

    case ActionTypes.COMPOSER_STOP_FORCE_FOCUS:
      state.meta.forceEditorFocus = false
      break

    case ActionTypes.APP_RESET:
      state = getInitialState()
      break

    case ActionTypes.COMPOSER_UPDATE_INSTAGRAM_STATE:
      updateInstagramDraftsFeedback()
      break

    case ActionTypes.EVENT_SHOW_SWITCH_PLAN_MODAL:
      eventShowSwitchPlanModal()
      break

    case ActionTypes.COMPOSER_INSERT_HASHTAG_TO_CAPTION:
      insertHashtagInCaption(action.text)
      break

    case ActionTypes.COMPOSER_UPDATE_INSTAGRAM_DRAFTS_FEEDBACK:
      updateInstagramDraftsFeedback()
      break

    case ActionTypes.COMPOSER_SET_IS_TWITTER_PREMIUM:
      const twitterDraft = ComposerStore.getDraft(action.id)
      if (!twitterDraft) return
      twitterDraft.isTwitterPremium = action.isTwitterPremium
      break

    default:
      shouldEmitChange = false
      break
  }

  if (shouldEmitChange) ComposerStore.emitChange()

  // (uncomment to enable Redux DevTools)
  // sendToMonitor('composer', action, state);
}

AppDispatcher.register(onDispatchedPayload)

export default ComposerStore
