import { useState } from 'react'

import Scraper from '~publish/legacy/composer/composer/utils/Scraper'
import ComposerStore from '~publish/legacy/composer/composer/stores/ComposerStore'
import type { Link } from '~publish/legacy/composer/composer/entities/factories'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'

export const useLinkPreview = (
  isTwitterPreview: boolean,
): [
  Link | null,
  (link: string | null) => void,
  (linkPreview: Link | null) => void,
  (isDataLoaded: boolean) => void,
  boolean,
  Link | null,
] => {
  const { environment } = AppStore.getMetaData()
  const [linkPreview, setLinkPreview] = useState<Link | null>(null)
  const [scrapedResult, setScrapedData] = useState<Link | null>(null)
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)

  const scrapeUrl = (link: string | null): void => {
    if (link && link !== '') {
      const canonicalUrl = ComposerStore.getCanonicalUrl(link)

      Scraper.scrape(canonicalUrl, environment, isTwitterPreview)
        .then(
          (scrapedData): Link => ({
            url: canonicalUrl,
            title: scrapedData.title || '',
            description: scrapedData.description || '',
            thumbnail:
              Array.isArray(scrapedData.images) && scrapedData.images.length > 0
                ? {
                    url: scrapedData.images[0].url,
                    width: scrapedData.images[0].width,
                    height: scrapedData.images[0].height,
                    mediaType: scrapedData.images[0].type,
                  }
                : null,
            expandedUrl: scrapedData.canonicalUrl,
            thumbnailHttps: null,
            availableThumbnails: scrapedData.images?.map((image) => image.url),
            wasEdited: false,
          }),
        )
        .then((scrapedData) => {
          if (
            scrapedData.title ||
            scrapedData.description ||
            scrapedData.thumbnail
          ) {
            setLinkPreview(scrapedData)
            setScrapedData(scrapedData)
            setIsDataLoaded(true)
          }
        })
    }
  }

  return [
    linkPreview,
    scrapeUrl,
    setLinkPreview,
    setIsDataLoaded,
    isDataLoaded,
    scrapedResult,
  ] as const
}
