import { useSplitEnabled } from '@buffer-mono/features'
import React from 'react'

import PlayIcon from '@bufferapp/ui/Icon/Icons/Play'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import { MediaTypes } from '~publish/legacy/constants'
import type { Draft } from '../../entities/Draft'
import { Document } from '../../entities/Document'
import {
  isMediaImage,
  isMediaVideo,
  type Media,
} from '../../entities/factories'
import { escapeParens } from '../../utils/StringUtils'
import Button from '../shared/Button'
import CloseButton from '../shared/CloseButton'
import styles from '../css/MediaAttachmentThumbnail.module.css'
import {
  getTags,
  renderCanvaEditOption,
  renderEditOption,
  renderAltOption,
  renderHoverEditOption,
  renderTagOption,
} from './helpers'
import {
  useMediaAttachmentFeedback,
  useMediaAttachmentThumbnailHandlers,
} from './hooks'
import {
  ThumbnailOverlay,
  TooltipContent,
  ValidationWarningIcon,
  ValidationWarningWrapper,
  VideoTag,
} from './styles'
import * as Tooltip from '@radix-ui/react-tooltip'
import { TempTooltip } from '~publish/legacy/shared-components/TempTooltip/TempTooltip'
import { getQueueResizedImageUrl } from '~publish/legacy/utils/bufferImages'
import type { Uploader } from '@buffer-mono/uploader'

export interface MediaAttachmentThumbnailProps {
  media: Media
  className: string
  showImageDescription: boolean
  draft: Draft
  canAddUserTag?: boolean
  uploader: Uploader
}

const MediaAttachmentThumbnail = ({
  media,
  className,
  showImageDescription,
  draft,
  canAddUserTag = false,
  uploader,
}: MediaAttachmentThumbnailProps): JSX.Element => {
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')
  const isRegularImage = media.mediaType === MediaTypes.IMAGE
  const shouldEditOnClick =
    isRemindersEnabled && isRegularImage && draft.service.isInstagram()

  const {
    onClick,
    onCloseButtonClick,
    onAddTagClick,
    onCanvaEditButtonClick,
    onEditButtonClick,
    onMouseEnter,
    onMouseLeave,
    isHovering,
  } = useMediaAttachmentThumbnailHandlers({
    media,
    draft,
    canAddUserTag,
    shouldEditOnClick,
    showImageDescription,
    uploader,
  })

  const mediaFeedback = useMediaAttachmentFeedback(media.url, draft.id)

  const feedbackMessages = isRemindersEnabled
    ? mediaFeedback
        .map((validation) => validation?.messageOptions?.specific)
        .join('\n')
    : []

  const thumbnailClassName = [styles.thumbnail, className].join(' ')
  const isVideo = isMediaVideo(media)
  const thumbnail = Document.isDocument(media)
    ? media.thumbnailUrl
    : isVideo
    ? media?.thumbnail
    : media.url
  const tags = getTags(draft, media.url)
  const userTagCount = tags ? tags.length : null

  const tooltipCopy =
    isRegularImage && showImageDescription
      ? 'Click to expand & add description'
      : 'Click to expand'
  const ariaLabel =
    isRegularImage && showImageDescription
      ? 'Select to expand image and add image description'
      : `Select to expand ${isVideo ? 'video' : 'image'}`

  const buttonWrapperClassName = [
    mediaFeedback.length && isRemindersEnabled ? styles.warningBorder : '',
    styles.buttonWrapper,
  ].join(' ')

  const canEditCanvaImage =
    isMediaImage(media) && media?.source?.name === 'canva'

  const showEditButtonIcon =
    (!isRemindersEnabled || !draft.service.isInstagram()) && isRegularImage
  const showInstagramHoverEditButton = shouldEditOnClick && isHovering

  return (
    <div className={thumbnailClassName}>
      <div
        className={buttonWrapperClassName}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used */}
        <TempTooltip content={!shouldEditOnClick ? tooltipCopy : null} arrow>
          <Button
            className={styles.imageContainer}
            aria-label={ariaLabel}
            onClick={onClick}
            data-testid="media-attachment-thumbnail"
          >
            {isVideo && <ThumbnailOverlay />}
            <img
              className={styles.thumbnailImage}
              alt=""
              src={getQueueResizedImageUrl(escapeParens(thumbnail))}
              data-testid="media-attachment-thumbnail-image"
              crossOrigin="anonymous"
            />
            {isVideo && (
              <VideoTag aria-label="video attachment">
                <PlayIcon />
              </VideoTag>
            )}
          </Button>
        </TempTooltip>
        {showInstagramHoverEditButton &&
          renderHoverEditOption(onEditButtonClick)}
      </div>
      <div className={styles.actionButtonWrapper}>
        {canAddUserTag && renderTagOption(userTagCount, onAddTagClick)}
        {canEditCanvaImage && renderCanvaEditOption(onCanvaEditButtonClick)}
        {showImageDescription && renderAltOption(onClick)}
        {showEditButtonIcon && renderEditOption(onEditButtonClick)}
      </div>
      {!!mediaFeedback.length && (
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <ValidationWarningWrapper>
              <ValidationWarningIcon>
                <WarningIcon />
              </ValidationWarningIcon>
            </ValidationWarningWrapper>
          </Tooltip.Trigger>
          <TooltipContent>
            <span>{feedbackMessages}</span>
          </TooltipContent>
        </Tooltip.Root>
      )}
      <CloseButton
        className={styles.closeButton}
        onClick={onCloseButtonClick}
        label="Remove media"
      />
    </div>
  )
}

export default MediaAttachmentThumbnail
