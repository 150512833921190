import { graphql } from '~publish/gql'
import { useMutation } from '@apollo/client'
import type { UpdateNewRssFeedGroupMutationVariables } from '~publish/gql/graphql'
import { useTriggerFeedUpdate } from './useFeedUpdates'

const UpdateNewRSSFeedGroup = graphql(/* GraphQL */ `
  mutation UpdateNewRSSFeedGroup($input: UpdateRSSFeedGroupInput!) {
    updateRSSFeedGroup(input: $input) {
      __typename
    }
  }
`)

export const useUpdateFeedGroup = (): ((
  input: UpdateNewRssFeedGroupMutationVariables['input'],
) => Promise<void>) => {
  const [updateFeedGroup] = useMutation(UpdateNewRSSFeedGroup)

  const triggerUpdate = useTriggerFeedUpdate('feedGroupUpdated')

  return async (input: UpdateNewRssFeedGroupMutationVariables['input']) => {
    await updateFeedGroup({ variables: { input } })
    triggerUpdate({
      kind: 'feedGroupUpdated',
      feedGroup: { id: input.feedGroupId },
    })
  }
}
