import { type Upload, MediaType } from '@buffer-mono/uploader'
import { SERVICE_LINKEDIN } from '~publish/legacy/constants'
import ComposerActionCreators from './ComposerActionCreators'

export const handleUploadStarted = ({
  upload,
  draftId,
}: {
  upload: Upload
  draftId: string
}): void => {
  if (draftId === SERVICE_LINKEDIN && upload.mediaType === MediaType.document) {
    ComposerActionCreators.setLinkedInCarouselTitle()
  }
}
