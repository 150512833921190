import React, { useState } from 'react'
import { Sidebar, PlusIcon, HashIcon } from '@buffer-mono/popcorn'
import { useParams } from 'react-router-dom'

import { useFeedGroups } from '../../hooks/useFeedGroups'
import { SidebarLink } from '../../../components/SidebarLink/SidebarLink'
import { feedGroupPage } from '~publish/legacy/routes'
import { NewFeedName } from './NewFeedName'

export function FeedGroupsNav(): JSX.Element {
  const { feedGroupId } = useParams<{ feedGroupId?: string }>()
  const [isFeedNameOpen, setIsFeedNameOpen] = useState(false)
  const groups = useFeedGroups()

  return (
    <>
      {isFeedNameOpen && (
        <NewFeedName open={isFeedNameOpen} onOpenChange={setIsFeedNameOpen} />
      )}
      <Sidebar.Button
        prefix={<PlusIcon />}
        onClick={(): void => setIsFeedNameOpen(true)}
      >
        Create Feed Group
      </Sidebar.Button>
      {groups.items.map((group) => (
        <SidebarLink
          key={group.id}
          icon={<HashIcon />}
          selected={feedGroupId === group.id}
          to={feedGroupPage.linkTo(group.id)}
        >
          {group.name}
        </SidebarLink>
      ))}
    </>
  )
}
