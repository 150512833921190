import React from 'react'
import { Button, Dialog } from '@buffer-mono/popcorn'

export const ConfirmDialog = ({
  onConfirm,
  children,
}: {
  onConfirm: () => void
  children: React.ReactNode
}): JSX.Element => {
  return (
    <Dialog>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content size="medium">
        <Dialog.Header>
          <Dialog.Title>Are you sure?</Dialog.Title>
          <Dialog.Description>
            Clicking &quot;Confirm&quot; will create scheduled posts that will
            be published.
          </Dialog.Description>
        </Dialog.Header>
        <Dialog.Footer>
          <Dialog.Close>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button variant="primary" onClick={onConfirm}>
              Confirm
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
