import React from 'react'
import { DraftBadge } from './DraftBadge'
import { ApprovalBadge } from './ApprovalBadge'
import { usePostData } from '../PostCard/PostCardContext'
import type { BadgeProps } from '@buffer-mono/popcorn'

/**
 * Display the appropriate badge based on the post status.
 */
export const PostStatusBadge = (
  props: Pick<BadgeProps, 'size'>,
): JSX.Element | null => {
  const { status } = usePostData()
  if (status === 'needs_approval') {
    return <ApprovalBadge {...props} />
  }

  if (status === 'draft') {
    return <DraftBadge {...props} />
  }

  return null
}
