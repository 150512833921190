/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/ban-ts-comment */
import React from 'react'
import {
  Text,
  Link,
  borderWidth,
} from '@buffer-mono/legacy-bufferapp-components'
import { Tooltip, Text as UiText } from '@bufferapp/ui'
import Button from '@bufferapp/ui/Button'
import AnalyzeIcon from '@bufferapp/ui/Icon/Icons/Analyze'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'

import { abbreviateNumber } from '~publish/legacy/duplicate-server/formatters'
import styled from 'styled-components'
import {
  gray,
  grayDark,
  grayLight,
  grayLighter,
} from '@bufferapp/ui/style/colors'
import { useAccount, useHasEntitlement } from '~publish/legacy/accountContext'

const StatsTitle = styled(Text)`
  span {
    color: ${grayDark};
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
`

const StatsValue = styled(Text)`
  span {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
`

const StatsContainer = styled.div`
  display: flex;
  background-color: ${grayLighter};
  border-top: ${borderWidth} solid ${grayLight};
  box-sizing: border-box;
  padding: 0px 16px;
`

const StatsContainerStyled = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  padding: 8px;
  align-items: left;
  flex-direction: column;

  &:first-child {
    padding-left: 0px;
  }
`

const TooltipWrapper = styled.div`
  display: inline-block;
  padding: 0 4px;
  bottom: -4px;
  position: relative;
`

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const TooltipMessage = ({ label }: { label: string | null }) => {
  return label ? (
    <TooltipWrapper>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message */}
      <Tooltip label={label} position="right">
        <InfoIcon color={gray} />
      </Tooltip>
    </TooltipWrapper>
  ) : null
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const ExternalLink = ({ link }: { link: string | null }) => {
  return link ? (
    // @ts-expect-error
    <Link href={link} unstyled>
      *
    </Link>
  ) : null
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const StatisticElement = ({
  value = null,
  title = '',
  tooltip = null,
  link = null,
}: {
  value?: string | number | null
  title?: string
  tooltip?: string | null
  link?: string | null
}) => {
  if (typeof value === 'undefined' || value === null) {
    return null
  }

  return (
    <StatsContainerStyled>
      <StatsTitle>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
        <UiText>{title}</UiText>
        {tooltip && <TooltipMessage label={tooltip} />}
        {link && <ExternalLink link={link} />}
      </StatsTitle>
      <StatsValue>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
        <UiText>{abbreviateNumber(value, 1)}</UiText>
      </StatsValue>
    </StatsContainerStyled>
  )
}

StatisticElement.defaultProps = {
  value: null,
  title: '',
  tooltip: null,
  link: null,
}

const RedirectToAnalyzeWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 495px;
  right: 16px;
  bottom: 6px;
  align-items: center;
  justify-content: center;

  button {
    font-weight: 600;
    height: 28px;
    font-size: 12px;
  }
`

const RedirectToAnalyze: React.FC<{
  profileId: string
  profileService: string
}> = ({ profileId, profileService }) => {
  return (
    <RedirectToAnalyzeWrapper>
      <Button
        fullWidth
        type="secondary"
        icon={<AnalyzeIcon />}
        label="Open Advanced Analytics for these metrics"
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onClick={() => {
          window.location.href = `https://analyze.buffer.com/${profileService}/posts/${profileId}`
        }}
      />
    </RedirectToAnalyzeWrapper>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
const PostStats = ({
  statistics = {},
  profileService,
  profileId,
  profileServiceType = null,
  hasAnalyticsOnPosts,
}: {
  statistics: {
    [key: string]: number | string
  }
  profileService: string
  profileId: string
  profileServiceType: string | null
  hasAnalyticsOnPosts: boolean
}) => {
  const { account } = useAccount()
  const isOneBufferOrg = account.currentOrganization?.isOneBufferOrganization
  const isEntitledToAnalytics = useHasEntitlement('analytics')

  const titles = {
    views: 'View',
    comments: 'Comment',
    likes: 'Like',
    favorites: 'Like',
    mentions: 'Mention',
    clicks: 'Click',
    reach: 'Reach',
    shares: 'Share',
    reshares: 'Reshare',
    repins: 'Save',
    reactions: 'Reaction',
    impressions: 'Impression',
    plusOne: '+1',
    linkClicks: 'Link Clicks',
    reposts: 'Repost',
    quotes: 'Quote',
    replies: 'Replies',
  }

  // The order on the following list will be the order of the stats displayed
  const titlesPerNetwork = {
    youtube: {
      views: 'View',
      likes: 'Like',
      comments: 'Comment',
    },
    mastodon: {
      comments: 'Comment',
      shares: 'Share',
      favorites: 'Favorite',
    },
    twitter: isEntitledToAnalytics
      ? {
          likes: 'Like',
          retweets: 'Retweet',
          clicks: 'Click',
          reach: 'Impression',
        }
      : {
          likes: 'Like',
          retweets: 'Retweet',
          clicks: 'Click',
        },
    tiktok: isEntitledToAnalytics
      ? {
          likes: 'Like',
          comments: 'Comment',
          views: 'View',
          shares: 'Share',
          reach: 'Reach',
        }
      : {
          likes: 'Like',
          comments: 'Comment',
          views: 'View',
        },
    pinterest: isEntitledToAnalytics
      ? {
          repins: 'Save',
          comments: 'Comment',
          clicks: 'Click',
          reactions: 'Reaction',
          impressions: 'Impression',
          views: 'View',
        }
      : {
          repins: 'Save',
          comments: 'Comment',
          clicks: 'Click',
        },
    linkedin: isEntitledToAnalytics
      ? {
          likes: 'Like',
          comments: 'Comment',
          reach: 'Impression',
          shares: 'Share',
          engagement_rate: 'Eng. Rate',
        }
      : {
          likes: 'Like',
          comments: 'Comment',
          reach: 'Impression',
        },
    facebook: isEntitledToAnalytics
      ? // Group Posts don't have reach
        profileServiceType === 'group'
        ? {
            likes: 'Like',
            comments: 'Comment',
            shares: 'Share',
            clicks: 'Click',
          }
        : {
            likes: 'Like',
            comments: 'Comment',
            shares: 'Share',
            clicks: 'Click',
            reach: 'Reach',
          }
      : {
          likes: 'Like',
          comments: 'Comment',
          shares: 'Share',
        },
    threads: isEntitledToAnalytics
      ? {
          likes: 'Like',
          replies: 'Reply',
          views: 'View',
          quotes: 'Quote',
          reposts: 'Repost',
        }
      : {
          likes: 'Like',
          replies: 'Reply',
          views: 'View',
        },
  }

  const advancedStatistics = {
    instagram: [
      {
        title: 'Likes',
        key: 'likes',
      },
      {
        title: 'Comments',
        key: 'comments',
      },
      {
        title: 'Impressions',
        key: 'impressions',
      },
      {
        title: 'Reach',
        key: 'reach',
      },
      {
        title: 'Eng. Rate',
        key: 'engagement_rate',
      },
    ],
    startPage: [
      {
        title: 'Link Clicks',
        key: 'linkClicks',
      },
    ],
  }

  const titlesWithComplexPlurals: Record<string, string> = {
    replies: 'Replies',
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function getMetrics() {
    if (
      isOneBufferOrg &&
      Object.keys(advancedStatistics).includes(profileService)
    ) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return advancedStatistics[profileService].map((metric) => {
        let value: string | number = statistics[metric.key] || '-'
        if (metric.key === 'engagement_rate' && typeof value === 'number') {
          value = `${value.toFixed(2)}%`
        }

        return {
          ...metric,
          value,
          tooltip:
            profileService === 'instagram' && profileServiceType === 'profile'
              ? `Instagram has deprecated statistics for Personal accounts. Set up direct scheduling to start seeing these statistics for Business or Creator accounts.`
              : null,
        }
      })
    }

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const metricTitles = titlesPerNetwork[profileService] || titles

    return Object.keys(metricTitles)
      .map((typeStat) => {
        return [typeStat, metricTitles[typeStat]]
      })
      .map(([typeStat, title]) => {
        const value = statistics[typeStat]
        const nonPluralTitles = ['engagement_rate', 'replies']

        let titleValue = title

        if (profileService !== 'twitter' && profileService !== 'linkedin') {
          nonPluralTitles.push('reach')
        }

        if (value !== 1 && nonPluralTitles.indexOf(typeStat) < 0) {
          titleValue += 's'
        }

        if (value !== 1 && titlesWithComplexPlurals[typeStat]) {
          titleValue = titlesWithComplexPlurals[typeStat]
        }

        if (typeof value === 'undefined') {
          return null
        }

        return {
          key: typeStat,
          value: typeStat === 'engagement_rate' ? `${value}%` : value,
          title: titleValue,
          tooltip:
            profileService === 'instagram' && profileServiceType === 'profile'
              ? `Instagram has deprecated statistics for Personal accounts. Set up direct scheduling to start seeing these statistics for Business or Creator accounts.`
              : null,
        }
      })
  }

  function hasFreePlan(): boolean {
    const { currentOrganization } = account || {}
    const { billing } = currentOrganization || {}
    return billing?.subscription?.plan?.id === 'free'
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'stat' implicitly has an 'any' type.
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function shouldShowRedirect(stat) {
    return (
      hasFreePlan() &&
      hasAnalyticsOnPosts &&
      stat.value === '-' &&
      stat.key === 'engagement_rate'
    )
  }

  const metrics = getMetrics()
  return (
    <StatsContainer>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'stat' implicitly has an 'any' type. */}
      {metrics.map((stat) => {
        if (stat === null) {
          return null
        }
        return (
          <React.Fragment key={stat.key}>
            {shouldShowRedirect(stat) && (
              <RedirectToAnalyze
                profileId={profileId}
                profileService={profileService}
              />
            )}
            <StatisticElement
              key={stat.key}
              value={!hasAnalyticsOnPosts ? '-' : stat.value}
              title={stat.title}
              tooltip={stat.tooltip}
              link={stat.link}
            />
          </React.Fragment>
        )
      })}
    </StatsContainer>
  )
}

export default PostStats
