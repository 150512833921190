import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import React, { useEffect, useState } from 'react'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useAnimatedExpandCollapse } from '~publish/legacy/hooks/useAnimatedExpandCollapse'
import { MediaType } from '@buffer-mono/uploader'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import AppStore from '../../stores/AppStore'
import type { ValidationCode } from '../../stores/types'
import {
  HeightRefContainer,
  WarningIconWrapper,
  WarningWrapper,
} from './styles'

const IMAGE_ERROR_SUFFIX =
  'Click on the image to edit it or switch your posting from Automatic to Notify Me.'
const VIDEO_ERROR_SUFFIX = 'Switch your posting from Automatic to Notify Me.'

interface MediaValidationWarningProps {
  visible: boolean
  codes?: Array<ValidationCode>
}

/**
 * If any of the validations that failed can be bypassed
 * with Reminders, show a message explaining the validation
 * error and prompt the user to either fix it, or save
 * the post as a reminder.
 */
export const BypassValidationWithReminderMessage = ({
  visible,
  codes = [VALIDATION_CODE.ASPECT_RATIO, VALIDATION_CODE.VIDEO_DURATION],
}: MediaValidationWarningProps): JSX.Element => {
  const [warningMessage, setWarningMessage] = useState<string>('')

  const isVisible = visible && !!warningMessage

  // Find the first validation that can be bypassed
  // Fixing the first error will result in the second one
  // showing next if there are more
  const firstMediaValidation = AppStore.whatPreventsSaving().find(
    (validation) =>
      codes.includes(validation.code) && validation.composerId === 'instagram',
  )

  useEffect(() => {
    const mediaType = firstMediaValidation?.metadata?.mediaType
    if (!firstMediaValidation || !mediaType) {
      setWarningMessage('')
      return
    }

    // Use the generic validation message here
    const message =
      firstMediaValidation.messageOptions?.generic ??
      firstMediaValidation.message
    // Add the relevant suffix based on the validation code

    const suffix =
      mediaType === MediaType.image ? IMAGE_ERROR_SUFFIX : VIDEO_ERROR_SUFFIX

    const messageWithSuffix = `${message}. ${suffix}`
    setWarningMessage(messageWithSuffix)

    if (messageWithSuffix !== '') {
      BufferTracker.mediaValidationWarningMessageViewed({
        channel: 'instagram',
        message: messageWithSuffix,
        clientName: 'core',
      })
    }
  }, [firstMediaValidation])

  const { collapseElementRef, height } = useAnimatedExpandCollapse(isVisible)

  return (
    <HeightRefContainer $maxHeight={height} $shouldBeVisible={isVisible}>
      <WarningWrapper
        ref={collapseElementRef}
        data-testid="bypass-validation-warning"
      >
        <WarningIconWrapper>
          <WarningIcon color="yellowDarker" />
        </WarningIconWrapper>
        <span>{warningMessage}</span>
      </WarningWrapper>
    </HeightRefContainer>
  )
}
