import { generateThumbnailFromGif } from '~publish/legacy/utils/image/getStillDataUriFromGif'
import { generateThumbnailFromVideo } from '~publish/legacy/composer/composer/utils/DOMUtils'
import { generateThumbnailFromPDF } from '~publish/legacy/composer/composer/utils/generateThumbnailFromPDF'

import { getMediaTypeFromMime, MediaType } from '@buffer-mono/uploader'

export async function generateThumbnail(file: {
  type?: string
  data: Blob | File
}): Promise<File> {
  const mediaType = getMediaTypeFromMime(file.type)

  if (mediaType === MediaType.gif) {
    return generateThumbnailFromGif(file.data)
  }

  if (mediaType === MediaType.video) {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    return (await generateThumbnailFromVideo(file.data)).imageFile
  }

  if (mediaType === MediaType.document) {
    return await generateThumbnailFromPDF(file.data)
  }

  throw new Error(`Cannot generate thumbnail for file type: ${file.type}`)
}
