import React from 'react'
import partition from 'lodash/partition'
import Tag from '@bufferapp/ui/Tag'
import { Flash } from '@bufferapp/ui/Icon'

import {
  QueueingTypes,
  SaveButtonTypes,
  InlineSaveButtonTypes,
  PaywallButtonTypes,
} from '../../../AppConstants'
import type {
  ComposerInlineSaveButton,
  ComposerNonInlineSaveButton,
  ComposerQueueingType,
  ComposerSaveButton,
} from '../../../stores/types'

const isInlineButton = (
  button: ComposerSaveButton,
): button is ComposerInlineSaveButton => {
  return InlineSaveButtonTypes.includes(button)
}

export const getSaveButton = (
  saveButtons: ComposerSaveButton[],
): [ComposerInlineSaveButton[], ComposerNonInlineSaveButton[]] => {
  return partition(saveButtons, isInlineButton)
}

export const getSaveButtonsCopy = ({
  moreThanOneProfileSelected,
  draftMode = false,
}: {
  moreThanOneProfileSelected: boolean
  draftMode?: boolean
}): Map<ComposerSaveButton, string> =>
  new Map([
    [SaveButtonTypes.ADD_TO_QUEUE, 'Add to Queue'],
    [SaveButtonTypes.SHARE_NEXT, 'Share Next'],
    [SaveButtonTypes.SHARE_NOW, 'Share Now'],
    [
      SaveButtonTypes.SCHEDULE_POST,
      moreThanOneProfileSelected ? 'Schedule Posts' : 'Schedule Post',
    ],
    [SaveButtonTypes.SAVE, draftMode ? 'Save Draft' : 'Save'],
    [SaveButtonTypes.SAVE_AND_APPROVE, 'Save & Approve'],
    [
      SaveButtonTypes.ADD_TO_DRAFTS,
      moreThanOneProfileSelected ? 'Save Drafts' : 'Save Draft',
    ],
    [
      SaveButtonTypes.SCHEDULE_DRAFT,
      moreThanOneProfileSelected ? 'Schedule Drafts' : 'Schedule Draft',
    ],
  ])

export const addingToQueueCopyMap = new Map<ComposerQueueingType, string>([
  [QueueingTypes.QUEUE, 'Adding to queue…'],
  [QueueingTypes.NEXT, 'Adding to queue…'],
  [QueueingTypes.NOW, 'Sharing…'],
  [QueueingTypes.CUSTOM, 'Scheduling…'],
  [QueueingTypes.SAVE, 'Saving…'],
  [QueueingTypes.SAVE_AND_APPROVE, 'Saving and approving…'],
  [QueueingTypes.ADD_DRAFT, 'Adding to drafts…'],
  [QueueingTypes.NEXT_DRAFT, 'Adding to drafts…'],
  [QueueingTypes.CUSTOM_DRAFT, 'Adding to drafts…'],
])

export const addedToQueueCopyMap = new Map<ComposerQueueingType, string>([
  [QueueingTypes.QUEUE, 'Added to queue!'],
  [QueueingTypes.NEXT, 'Added to queue!'],
  [QueueingTypes.NOW, 'Shared!'],
  [QueueingTypes.CUSTOM, 'Scheduled!'],
  [QueueingTypes.SAVE, 'Saved!'],
  [QueueingTypes.SAVE_AND_APPROVE, 'Saved and approved!'],
  [QueueingTypes.ADD_DRAFT, 'Added to drafts!'],
  [QueueingTypes.NEXT_DRAFT, 'Added to drafts!'],
  [QueueingTypes.CUSTOM_DRAFT, 'Added to drafts!'],
])

// @ts-expect-error TS(7031) FIXME: Binding element 'hasInitialDate' implicitly has an... Remove this comment to see the full error message
export const getDraftButtons = ({ hasInitialDate, showApprovalPaywall }) => {
  const items = []
  if (hasInitialDate) {
    items.push(
      { id: SaveButtonTypes.SCHEDULE_DRAFT, title: 'Schedule Draft' },
      { id: SaveButtonTypes.ADD_TO_DRAFTS, title: 'Save as Draft' },
    )
  } else {
    items.push(
      { id: SaveButtonTypes.ADD_TO_DRAFTS, title: 'Save as Draft' },
      {
        id: SaveButtonTypes.SCHEDULE_DRAFT,
        title: 'Schedule Draft',
        tooltip: 'Save a draft with a custom date',
      },
    )
  }

  if (showApprovalPaywall) {
    items.push({
      id: PaywallButtonTypes.REQUEST_APPROVAL,
      title: 'Request Approval',
      icon: (
        <Tag color="purpleLighter">
          <Flash color="purple" />
        </Tag>
      ),
      iconEnd: true,
    })
  }

  return items
}
