const STORAGE_KEYS = {
  ABOUT: 'generate-ideas-about',
  TARGET: 'generate-ideas-target',
  IDEA: 'generate-ideas-idea',
}

interface CustomWindow extends Window {
  __userData: { data: { account: { currentOrganization: { id: string } } } }
}

/**
 *
 */
const getPromptSettings = () => {
  const organizationId = (window as CustomWindow)?.__userData?.data?.account
    ?.currentOrganization?.id
  return {
    about:
      window.localStorage.getItem(`${organizationId}-${STORAGE_KEYS.ABOUT}`) ||
      null,
    targetAudience:
      window.localStorage.getItem(`${organizationId}-${STORAGE_KEYS.TARGET}`) ||
      null,
  }
}

/**
 *
 */
const setPromptSettings = ({
  about,
  targetAudience,
}: {
  about: string
  targetAudience: string
}) => {
  const organizationId = (window as CustomWindow)?.__userData?.data?.account
    ?.currentOrganization?.id
  window.localStorage.setItem(`${organizationId}-${STORAGE_KEYS.ABOUT}`, about)
  window.localStorage.setItem(
    `${organizationId}-${STORAGE_KEYS.TARGET}`,
    targetAudience,
  )
}

/**
 *
 */
const getPrompt = () => {
  const { about, targetAudience } = getPromptSettings()

  return `
    Generate one post idea (not content) for social media for
    a business that is about ${about}, and whose target audience is ${targetAudience}.
    Write the idea only, no formating, no double quotes, no hashtags.
  `
}

/**
 *
 */
const getIdea = () => {
  const organizationId = (window as CustomWindow)?.__userData?.data?.account
    ?.currentOrganization?.id
  return (
    window.localStorage.getItem(`${organizationId}-${STORAGE_KEYS.IDEA}`) ||
    null
  )
}

/**
 *
 */
const setIdea = ({ idea }: { idea: string }) => {
  const organizationId = (window as CustomWindow)?.__userData?.data?.account
    ?.currentOrganization?.id
  window.localStorage.setItem(`${organizationId}-${STORAGE_KEYS.IDEA}`, idea)
}

/**
 *
 */
const deleteIdea = () => {
  const organizationId = (window as CustomWindow)?.__userData?.data?.account
    ?.currentOrganization?.id
  window.localStorage.removeItem(`${organizationId}-${STORAGE_KEYS.IDEA}`)
}

export {
  getPromptSettings,
  setPromptSettings,
  getPrompt,
  getIdea,
  setIdea,
  deleteIdea,
}
