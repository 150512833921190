import { env } from '~publish/env'
import { useEffect, useState, useMemo } from 'react'

// use production scraper if running in production or local prod, which uses split env = production
const scraperUrl =
  env.VITE_NODE_ENV === 'production' || env.VITE_SPLIT_ENV === 'production'
    ? 'https://scraper.buffer.com'
    : 'https://scraper.local.buffer.com'

type ScrapedData = {
  kind: 'success'
  url: string
  title?: string
  description?: string
  cannonicalUrl?: string
  images?: {
    url: string
    width: number
    height: number
    type: string
    opengraph: boolean
  }[]
}

type ScrapedDataErrorType = {
  kind: 'error'
  message: string
}

type ScrapedDataLoading = {
  kind: 'loading'
}

type ScrapedDataResultType =
  | ScrapedData
  | ScrapedDataErrorType
  | ScrapedDataLoading

const getScrapedData = async (url: string): Promise<ScrapedDataResultType> => {
  const response = await fetch(`${scraperUrl}?url=${encodeURIComponent(url)}`)

  if (response.ok) {
    const data = await response.json()
    return data
  }

  return {
    kind: 'error',
    message: 'Failed to fetch scraped data',
  }
}

const globalCache = new Map<string, Promise<ScrapedDataResultType>>()

type ReturnType = {
  data?: Omit<ScrapedData, 'kind'>
  error?: string
  loading: boolean
}

export const useScraperData = (url: string): ReturnType => {
  const [scrapedData, setScrapedData] = useState<ScrapedDataResultType>({
    kind: 'loading',
  })

  useEffect(() => {
    let cachedPromise = globalCache.get(url)
    if (!cachedPromise) {
      cachedPromise = getScrapedData(url)
      globalCache.set(url, cachedPromise)
    }

    cachedPromise.then(setScrapedData)
  }, [url, setScrapedData])

  return useMemo(() => {
    if (scrapedData.kind === 'error') {
      return {
        error: scrapedData.message,
        loading: false,
      }
    }
    if (scrapedData.kind === 'loading') {
      return {
        loading: true,
      }
    }

    return {
      loading: false,
      data: scrapedData,
    }
  }, [scrapedData])
}
