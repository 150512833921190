import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  Button,
  Flex,
  IconButton,
  Tag as TagComponent,
  TagPlusIcon,
  toast,
} from '@buffer-mono/popcorn'
import { useMutation } from '@apollo/client'

import { graphql } from '~publish/gql'
import type { MutationError, Tag } from '~publish/gql/graphql'
import { TagsCombobox } from '~publish/components/TagsCombobox'
import { usePostData } from '~publish/components/PostCard/PostCardContext'
import { useAccount } from '~publish/legacy/accountContext'
import UserEntity from '~publish/legacy/user/UserEntity'
import { client } from '~publish/legacy/apollo-client'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { usePostNavigation } from '~publish/hooks/usePostNavigation'

import styles from './PostCardTags.module.css'

type TagId = Tag['id']

const MAX_TAGS_PER_POST = 10

export const UPDATE_POST_TAGS = graphql(/* GraphQL */ `
  mutation UpdatePostTags($input: UpdatePostTagsInput!) {
    updatePostTags(input: $input) {
      __typename
      ... on PostActionSuccess {
        post {
          id
          tags {
            id
            name
            color
          }
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

function useUpdatePostTags(postId: string): (tagIds: TagId[]) => Promise<{
  success: boolean
  error: MutationError | null
}> {
  const [updatePostTags] = useMutation(UPDATE_POST_TAGS)
  const [selectedTagIds] = useQueryParam<string[]>('tags')

  return async (updatedTagIds: TagId[]) => {
    const existingTags = updatedTagIds.map((id) => {
      const tag = client.cache.readFragment({
        id: client.cache.identify({ __typename: 'Tag', id }),
        fragment: graphql(/* GraphQL */ `
          fragment TagFields on Tag {
            id
            name
            color
          }
        `),
      })
      return tag
    })

    const optimisticTags = existingTags.filter(Boolean) as Tag[]

    const { data, errors } = await updatePostTags({
      variables: {
        input: {
          id: postId,
          tags: updatedTagIds,
        },
      },
      optimisticResponse: {
        updatePostTags: {
          __typename: 'PostActionSuccess',
          post: {
            __typename: 'Post',
            id: postId,
            tags: optimisticTags,
          },
        },
      },
      update(cache, { data }) {
        if (data?.updatePostTags.__typename === 'PostActionSuccess') {
          const updatedPost = data.updatePostTags.post

          if (selectedTagIds) {
            // Check if updated tags match the selected tags filter
            const matchesFilter = selectedTagIds.some((tagId) =>
              updatedPost.tags.some((tag) => tag.id === tagId),
            )

            if (!matchesFilter) {
              // Remove the post from the list if it no longer matches the filter
              cache.modify({
                fields: {
                  posts(existingPostsRefs = {}, { readField }) {
                    return {
                      ...existingPostsRefs,
                      edges: existingPostsRefs.edges.filter(
                        (postRef: any) =>
                          readField('id', postRef.node) !== postId,
                      ),
                    }
                  },
                },
              })

              return
            }
          }

          // Otherwise, update the post's tags in the cache
          cache.modify({
            id: cache.identify({ __typename: 'Post', id: postId }),
            fields: {
              tags() {
                return updatedPost.tags
              },
            },
          })
        }
      },
    })

    if (errors || !data) {
      return {
        success: false,
        error: {
          message: errors?.[0]?.message ?? 'Failed to update post tags',
        },
      }
    }

    const result = data.updatePostTags
    if (result.__typename !== 'PostActionSuccess') {
      return {
        success: false,
        error: { message: result.message ?? 'Failed to update post tags' },
      }
    }

    return { success: true, error: null }
  }
}

export function PostCardTags({
  maxVisibleTags = Infinity,
}: {
  maxVisibleTags?: number
}): JSX.Element {
  const account = useAccount()
  const isAdmin = UserEntity.isAdmin(account)
  const { tags: postTags, id: postId } = usePostData()
  const [selectedTags, setSelectedTags] = useState<TagId[]>([])
  const updateTags = useUpdatePostTags(postId)
  const [open, setOpen] = useState(false)
  const listRef = useRef(null)
  const expandPostCard = usePostNavigation(postId)

  useEffect(() => {
    setSelectedTags(postTags?.map((tag) => tag.id) || [])
  }, [postTags])

  const selectTags = useCallback(
    async (tags: TagId[]): Promise<void> => {
      setSelectedTags(tags)
      const { success, error } = await updateTags(tags)

      if (!success && error) {
        toast.error(`Failed to edit tags on post`)
      }
    },
    [updateTags],
  )

  // We have optimistic updates so we have to keep sorting the tags because the
  // last added item would be in the last position.
  const sortedTags = [...postTags]?.sort((a, b) =>
    a.name.localeCompare(b.name, 'en'),
  )
  const visibleTags =
    maxVisibleTags < sortedTags.length
      ? sortedTags.slice(0, maxVisibleTags)
      : sortedTags
  const remainingTagsCount = sortedTags.length - visibleTags.length

  const tooltipMessage = !isAdmin
    ? 'Only Admins can add and edit tags.'
    : postTags.length === 0
    ? 'Add tags'
    : 'Add and edit tags'

  const label = postTags.length === 0 ? 'Add tags' : 'Add and edit tags'
  return (
    <Flex
      as="ul"
      gap="xs"
      wrap="wrap"
      align="center"
      className={styles.tags}
      ref={listRef}
      aria-label="Tags"
    >
      {visibleTags?.map((tag) => {
        if (!tag) return null
        return (
          <li key={tag.id} className={styles.tagItem}>
            <TagComponent color={tag.color}>{tag.name}</TagComponent>
          </li>
        )
      })}
      {maxVisibleTags && remainingTagsCount > 0 && (
        <li className={styles.tagItem}>
          <Button onClick={expandPostCard} variant="secondary" size="small">
            +{remainingTagsCount} More...
          </Button>
        </li>
      )}
      <TagsCombobox
        open={open}
        selectedTags={selectedTags}
        onChange={selectTags}
        onOpenChange={setOpen}
        limit={MAX_TAGS_PER_POST}
        align="start"
        anchor={listRef}
      >
        <IconButton
          label={label}
          tooltip={tooltipMessage}
          size="small"
          disabled={!isAdmin}
          className={styles.addTagButton}
        >
          <TagPlusIcon />
        </IconButton>
      </TagsCombobox>
    </Flex>
  )
}
