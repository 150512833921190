import React from 'react'
import { Badge, NotifyMeIcon, type BadgeProps } from '@buffer-mono/popcorn'
import { usePostData } from '../PostCard/PostCardContext'
import styles from './PostNotifyBadge.module.css'
export const NotifyBadge = (
  props: Pick<BadgeProps, 'size'>,
): JSX.Element | null => {
  const { schedulingType } = usePostData()
  if (schedulingType !== 'notification') return null

  return (
    <Badge className={styles.notify} size="medium" {...props}>
      <NotifyMeIcon />
      Notify
    </Badge>
  )
}
