import React, { useState } from 'react'
import Text from '@bufferapp/ui/Text'
import Modal from '@bufferapp/ui/Modal'
import Button from '@bufferapp/ui/Button'
import type { Blueprint } from '~publish/legacy/blueprints/types'
import { ChannelIcon } from '~publish/legacy/blueprints/components/Blueprint/ChannelIcon'
import styled from 'styled-components'
import Loader from '@bufferapp/ui/Loader'
import Notice from '@bufferapp/ui/Notice'
import { createDrafts } from '~publish/legacy/blueprints/utils/createDrafts'
import { greenLight } from '@bufferapp/ui/style/colors'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 16px 0;
  width: 100%;
  position: relative;
`

const StyledButton = styled(Button)`
  margin-top: 8px;
`

const StyledNotice = styled(Notice)`
  margin: 16px;
  background: ${greenLight};
`

export const BlueprintModal: React.FC<{
  blueprint: Blueprint
  closeModal: () => void
  isOpen: boolean
  channels: { name: string; service: string; id: string }[]
}> = ({ blueprint, closeModal, isOpen, channels }) => {
  const [isCreating, setIsCreating] = useState(false)
  const [notice, setNotice] = useState('')

  return (
    <>
      {isOpen && (
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; closeButton: { callback... Remove this comment to see the full error message
        <Modal closeButton={{ callback: () => closeModal() }}>
          <div
            style={{ padding: '0 16px', maxHeight: '700px', overflowY: 'auto' }}
          >
            <div>
              <Text type="h2">{blueprint.title}</Text>
            </div>
            {blueprint.description.map((el, id) => {
              if (el.type === 'text') {
                return (
                  <Text type="p" key={id}>
                    {el.content}
                  </Text>
                )
              }

              return (
                <img
                  style={{ maxWidth: '100%' }}
                  src={el.content}
                  alt={blueprint.title}
                  key={id}
                />
              )
            })}
            {notice !== '' && (
              <StyledNotice dismiss={() => ({})} type="note">
                <Text>
                  <span dangerouslySetInnerHTML={{ __html: notice }} />
                </Text>
              </StyledNotice>
            )}
            <Buttons>
              {!isCreating &&
                blueprint?.badge !== 'coming_soon' &&
                channels.map((channel) => (
                  <StyledButton
                    key={channel.id}
                    type="secondary"
                    icon={ChannelIcon(channel.service)}
                    onClick={() => {
                      setIsCreating(true)
                      createDrafts({
                        channelId: channel.id,
                        channelName: channel.name,
                        blueprint,
                        draftCreationCallback: (response) => {
                          setIsCreating(false)
                          if (response.success) {
                            setNotice(
                              `Drafts for your channel were created! Check them out <a href="/calendar/month">here</a>`,
                            )
                          } else {
                            setNotice(
                              `There was some problem creating your drafts: ${response.message}`,
                            )
                          }
                        },
                      })
                    }}
                    label={`Create for ${channel.name}`}
                    fullWidth
                  />
                ))}
              {isCreating && <Loader />}
              {channels.length === 0 && (
                <Text>You don&apos;t have any matching channels connected</Text>
              )}
            </Buttons>
          </div>
        </Modal>
      )}
    </>
  )
}
