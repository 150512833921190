import {
  type UploadedUppyFile,
  UppyFile,
  BasePlugin,
  type Uppy,
  type PluginOptions,
  type BaseUppyFile,
  type SuccessResponse,
  type Upload,
} from '@buffer-mono/uploader'
import { generateThumbnail } from '../lib/generateThumbnail'

export interface GenerateThumbnailsUppyPluginOptions extends PluginOptions {
  onError: (file: Upload, error: Error) => void
}

export default class GenerateThumbnailsUppyPlugin extends BasePlugin<GenerateThumbnailsUppyPluginOptions> {
  private onError: ((file: Upload, error: Error) => void) | undefined

  constructor(uppy: Uppy, opts?: GenerateThumbnailsUppyPluginOptions) {
    super(uppy, opts)

    this.id = 'GenerateThumbnailsUppyPlugin'
    this.type = 'preprocess'

    this.onError = opts?.onError

    this.generateThumbnails = this.generateThumbnails.bind(this)
    this.onUploadSuccess = this.onUploadSuccess.bind(this)
  }

  async generateThumbnails(fileIds: string[]): Promise<void> {
    for (const fileId of fileIds) {
      const file = this.uppy.getFile(fileId) as UploadedUppyFile

      if (UppyFile.shouldGenerateThumbnail(file)) {
        let thumbnailUploadId: string | undefined

        try {
          const thumbnail = await generateThumbnail(file)

          thumbnailUploadId = this.uppy.addFile({
            name: thumbnail.name,
            type: thumbnail.type,
            data: thumbnail,
            meta: {
              parentUploadId: file.id,
            },
          })
        } catch (error) {
          if (error instanceof Error) {
            error.message = `Thumbnail generation failed for ${file.name}. ${error.message}`

            this.onError?.(UppyFile.toFailedUpload(file, error.message), error)
          }

          // do not try to upload if thumbnail failed
          this.uppy.removeFile(file.id)
          if (thumbnailUploadId) {
            this.uppy.removeFile(thumbnailUploadId)
          }
        }
      }
    }
  }

  private onUploadSuccess(
    file: BaseUppyFile<{
      parentUploadId?: string
    }>,
    response: SuccessResponse,
  ): void {
    if (file.meta.parentUploadId) {
      this.uppy.setFileState(file.meta.parentUploadId, {
        preview: response.uploadURL,
      })
    }
  }

  install(): void {
    this.uppy.addPreProcessor(this.generateThumbnails)

    this.uppy.on('upload-success', this.onUploadSuccess)
  }

  uninstall(): void {
    this.uppy.removePreProcessor(this.generateThumbnails)

    this.uppy.off('upload-success', this.onUploadSuccess)
  }
}
