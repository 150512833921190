import {
  Button,
  ChannelIcon,
  type ChannelType,
  DropdownMenu,
} from '@buffer-mono/popcorn'
import React from 'react'
import { Services } from '../../AppConstants'
import AppStore from '../../stores/AppStore'
import type { ComposerProfile } from '../../stores/types'
import styles from './PreviewConnectChannel.module.css'
import ComposerStore from '../../stores/ComposerStore'
import { saveDraftDataInLocalStorage } from '../../utils/PreviewUtils'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'

export const PreviewConnectChannel = (): JSX.Element => {
  const selectedChannels = AppStore.getSelectedProfiles().length
  const singleButton = selectedChannels <= 1
  const singleProfileSelected = selectedChannels === 1

  // Handling Button Copy
  let buttonCopy = 'Connect a Channel to Post'
  if (singleProfileSelected) {
    const selectedChannel = AppStore.getSelectedProfiles()[0]
    const config = Services.get(selectedChannel.service.name)
    buttonCopy = `Connect ${config?.formattedName} to Post`
  } else if (!singleButton) buttonCopy = 'Connect Channels to Post'

  // Handling actions
  const onConnectChannelClick = (profile: ComposerProfile): void => {
    const draft = ComposerStore.getDraft(profile?.service?.name || 'omni')
    if (!draft) return

    // Store the draft in local storage
    if (draft.hasThread())
      ComposerActionCreators.switchActiveThreadEditor(profile.service.name, 0)
    saveDraftDataInLocalStorage(draft)

    // Open the connect channel process
    if (profile) {
      window.appshell.actions.connectChannel({
        selectedService: profile.service.name as Parameters<
          typeof window.appshell.actions.connectChannel
        >[0]['selectedService'],
        skipCelebrationModal: true,
        prefillDataAfterConnection: true,
      })
    } else {
      window.appshell.actions.openChannelStorefront({
        cta: `publish-composer-preview-${buttonCopy}-1`,
        skipCelebrationModal: true,
        prefillDataAfterConnection: true,
      })
    }
  }

  return (
    <section className={styles.previewConnectChannelWrapper}>
      {singleButton && (
        <Button
          size="large"
          onClick={(): void => {
            return onConnectChannelClick(AppStore.getSelectedProfiles()[0])
          }}
        >
          {buttonCopy}
        </Button>
      )}
      {!singleButton && (
        <DropdownMenu trigger={<Button size="large">{buttonCopy}</Button>}>
          {AppStore.getSelectedProfiles().map((profile) => {
            const config = Services.get(profile.service.name)
            return (
              <DropdownMenu.Item
                onClick={(e): void => {
                  e.preventDefault()
                  onConnectChannelClick(profile)
                }}
                key={profile.service.name}
              >
                <ChannelIcon
                  color="subtle"
                  type={profile.service.name as ChannelType}
                />
                Connect {config?.formattedName}
              </DropdownMenu.Item>
            )
          })}
        </DropdownMenu>
      )}
    </section>
  )
}
