import React from 'react'

import {
  Avatar,
  Flex,
  Image,
  Link,
  Paragraph,
  Text,
  XRetweetIcon,
} from '@buffer-mono/popcorn'
import { isOfType } from '~publish/helpers/typeGuards'

import { usePostData } from '../PostCardContext'
import styles from './PostCardAttachments.module.css'
import { clsx } from 'clsx'

const Separator = (): JSX.Element => <span aria-hidden>&middot;</span>

const SubText = ({ children }: React.PropsWithChildren): JSX.Element => (
  <Text color="subtle" className={styles.retweetUsername}>
    {children}
  </Text>
)

type RetweetAttachmentProps = {
  retweet: {
    text: string
    user: {
      name: string
      username: string
      avatar: string
    }
  }
  condensed?: boolean
}

const RetweetAttachment = ({
  retweet,
  condensed,
}: RetweetAttachmentProps): JSX.Element => (
  <section
    className={clsx(styles.retweet, condensed && styles.retweetCondensed)}
  >
    <Avatar
      size={condensed ? 'small' : 'medium'}
      src={retweet.user?.avatar ?? undefined}
      alt={retweet.user?.name || ''}
      className={styles.retweetAvatar}
    />
    <div className={styles.retweetContent}>
      <Flex gap="2xs" className={styles.retweetAuthor}>
        <div className={styles.retweetAuthorName}>
          <XRetweetIcon className={styles.retweetIcon} color="subtle" />
          <Text weight="bold" className={styles.retweetUsername}>
            {retweet.user?.name}
          </Text>
          <Separator />
        </div>
        <SubText>{'@' + retweet.user?.username}</SubText>
      </Flex>
      <Text className={clsx(condensed && styles.condensedText)}>
        {retweet.text}
      </Text>
    </div>
  </section>
)

type LinkAttachmentProps = {
  linkAttachment: {
    title: string
    url: string
    text: string
    expandedUrl?: string | null
    thumbnail?: string | null
  }
  condensed?: boolean
}

const LinkAttachment = ({
  linkAttachment,
  condensed,
}: LinkAttachmentProps): JSX.Element => (
  <section className={clsx(styles.link, condensed && styles.linkCondensed)}>
    {linkAttachment.thumbnail && (
      <Image
        className={clsx(
          styles.linkImage,
          condensed && styles.linkImageCondensed,
        )}
        src={linkAttachment.thumbnail}
        alt={linkAttachment.title}
        loading="lazy"
      />
    )}
    <div className={styles.linkContent}>
      <div className={styles.linkHeader}>
        <Text weight="bold">{linkAttachment.title}</Text>
        <Link
          className={styles.linkUrl}
          href={linkAttachment.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text
            size="sm"
            color="subtle"
            truncate
            className={styles.linkUrlText}
          >
            {linkAttachment.expandedUrl || linkAttachment.url}
          </Text>
        </Link>
      </div>
      <Paragraph className={clsx(condensed && styles.linkTextCondensed)}>
        {linkAttachment.text}
      </Paragraph>
    </div>
  </section>
)

export const PostCardAttachments = ({
  condensed,
}: {
  condensed?: boolean
}): JSX.Element | null => {
  const { metadata } = usePostData()

  const hasRetweet =
    isOfType(metadata, 'TwitterPostMetadata') && metadata.retweet
  const hasLinkAttachment =
    isOfType(metadata, 'FacebookPostMetadata') ||
    isOfType(metadata, 'LinkedInPostMetadata') ||
    (isOfType(metadata, 'BlueskyPostMetadata') && metadata.linkAttachment)

  const retweet = hasRetweet ? metadata.retweet : null
  const linkAttachment = hasLinkAttachment ? metadata.linkAttachment : null

  return (
    <>
      {retweet && <RetweetAttachment retweet={retweet} condensed={condensed} />}
      {linkAttachment && (
        <LinkAttachment linkAttachment={linkAttachment} condensed={condensed} />
      )}
    </>
  )
}
