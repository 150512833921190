type OPMLFeed = {
  title: string
  url: string
}

export type OPML = {
  feeds: OPMLFeed[]
}

/**
 * Temporary parser for OPML files, which is the common format for collections of rss feeds.
 *
 * Uses regex to avoid some parsing failures in https://github.com/plenaryapp/awesome-rss-feeds/tree/master
 *
 * @param {string} opmlString - The OPML string to parse
 * @return {OPML} - An object with a single property, `feeds`, which is an array
 * of objects with `title` and `url` properties.
 */
export const parseOpml = (opmlString: string): OPML => {
  const feeds = [
    ...Array.from(
      opmlString.matchAll(/title="([^"]*)".*xmlUrl="([^"]*)"/g),
    ).map(([, title, url]) => ({ title, url })),
    ...Array.from(
      opmlString.matchAll(/xmlUrl="([^"]*)".*title="([^"]*)"/g),
    ).map(([, url, title]) => ({ title, url })),
  ]

  return { feeds }
}
