import React, { useRef, useImperativeHandle } from 'react'
import { Button, Text } from '@bufferapp/ui'
import Input from '@bufferapp/ui/Input'

import { ButtonWrapper, MaxCount, MaxCountText } from './style'

/**
 * Remove unnecessary characters from the input.
 * - @ sign
 * - all whitespace
 * @param {String} value
 */
const filterInput = (value: string): string =>
  value.replace(/@/g, '').replace(/\s/g, '')

interface TagInputProps {
  translations: {
    placeholder?: string
    inputLabel?: string
    inputBtnAddTag?: string
    inputBtnCancel?: string
    maxLimitText?: string
    usernameError?: string
  }
  reachedMaxLimit: boolean
  inputValue: string
  setInputValue: (value: string) => void
  cancel: (e: any) => void
  addTag: (e: any) => void
  disabled: boolean
  inputDisabled: boolean
  error: boolean
}

const TagInput = React.forwardRef<
  React.ElementRef<typeof Input>,
  TagInputProps
>(
  (
    {
      translations,
      inputValue,
      setInputValue,
      disabled,
      inputDisabled,
      cancel,
      addTag,
      reachedMaxLimit,
      error,
    },
    ref,
  ) => {
    /**
     * This strange looking `ref / useImperativeHandle` stuff just makes it so that the
     * parent component can get a ref on this TagInput component and call `ref.current.focus()` on it.
     * https://reactjs.org/docs/hooks-reference.html#useimperativehandle
     */
    const inputEl = useRef(null)
    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputEl.current) {
          // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type 'never'.
          inputEl.current.focus()
        }
      },
      blur: () => {
        if (inputEl.current) {
          // @ts-expect-error TS(2339) FIXME: Property 'blur' does not exist on type 'never'.
          inputEl.current.blur()
        }
      },
    }))

    if (reachedMaxLimit) {
      return (
        <MaxCount>
          <MaxCountText>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
            <Text>{translations.maxLimitText}</Text>
          </MaxCountText>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; onClick: any; ... Remove this comment to see the full error message */}
          <Button
            type="secondary"
            size="small"
            onClick={cancel}
            label={translations.inputBtnCancel}
          />
        </MaxCount>
      )
    }
    return (
      <>
        <Input
          type="input"
          // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
          onChange={(e) => {
            setInputValue(filterInput(e.target.value))
          }}
          value={inputValue}
          name="tagInput"
          label={translations.inputLabel}
          prefix={{ text: '@', paddingLeft: '25px' }}
          ref={inputEl}
          disabled={inputDisabled}
          help={error && translations.usernameError}
          hasError={error}
        />
        <ButtonWrapper>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; onClick: any; ... Remove this comment to see the full error message */}
          <Button
            type="primary"
            size="small"
            onClick={addTag}
            label={translations.inputBtnAddTag}
            disabled={disabled}
          />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; onClick: any; ... Remove this comment to see the full error message */}
          <Button
            type="text"
            size="small"
            onClick={cancel}
            label={translations.inputBtnCancel}
          />
        </ButtonWrapper>
      </>
    )
  },
)

TagInput.displayName = 'TagInput'

export default TagInput
