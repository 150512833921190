/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, {
  css,
  type FlattenSimpleInterpolation,
  type SimpleInterpolation,
} from 'styled-components'
import { Text } from '@bufferapp/ui'
import { Image } from '@bufferapp/ui/Icon'
import { blue, grayDark, white } from '@bufferapp/ui/style/colors'

import CircularUploadIndicator from '../../composer/composer/components/progress-indicators/CircularUploadIndicator'
import {
  type Uploader,
  getFilesFromClipboardItems,
} from '@buffer-mono/uploader'
import { useDropTarget } from '../hooks/useDropTarget'
import { UploadSource } from '@buffer-mono/uploader'

type UploadDropzoneProps = {
  isDragging?: boolean
  isHovering?: boolean
  isUploading?: boolean
  withBorder?: boolean
}

const UploadDropzone = styled.button<UploadDropzoneProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props): SimpleInterpolation => !props.isUploading && 'pointer'};
  overflow: hidden;
  border: none;
  background-color: transparent;

  ${(props): FlattenSimpleInterpolation => {
    if (props.withBorder) {
      return css`
        position: relative;
        width: 103px;
        height: 103px;
        border-width: 1px;
        border-radius: 3px;
        border-style: dashed;
        &:hover {
          border-color: ${blue};
        }
      `
    }

    return css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `
  }}

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  ${(props): FlattenSimpleInterpolation | undefined => {
    if (props.isDragging || props.isHovering || props.isUploading) {
      return css`
        background: ${white};
        opacity: 0.9;
        border-color: ${blue};
      `
    }
  }};
`

const UploadsCount = styled.span`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${blue};
  color: ${white};
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
  text-indent: 1px;
`

const Input = styled.input`
  display: none;
`

const Message = styled.div<{ isDragging: boolean }>`
  position: absolute;
  display: flex;
  pointer-events: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${grayDark};
  text-align: center;
  width: 100%;
  height: 100%;
  svg {
    margin-bottom: 4px;
  }
  p {
    margin: 4px 0;
    line-height: 16px;
    max-width: 80px;
  }
  span {
    color: ${blue};
  }
  &:hover {
    svg {
      fill: ${blue};
    }
  }
  ${(props): FlattenSimpleInterpolation | undefined => {
    if (props.isDragging) {
      return css`
        opacity: 0.2;
      `
    }
  }};
`

const SmallerText = styled(Text)`
  font-size: 12px;
`

const UploadMessage = ({
  onlyAllowsVerticalVideo = false,
  isDragging = false,
}: {
  onlyAllowsVerticalVideo: boolean
  isDragging: boolean
}): JSX.Element => {
  const attachmentText = onlyAllowsVerticalVideo ? 'video' : 'file'
  return (
    <Message isDragging={isDragging}>
      <Image size="large" />

      <SmallerText type="p">
        Drag & drop or <Text type="span">select a {attachmentText}</Text>
      </SmallerText>
    </Message>
  )
}

// TODO: Remove in favor of UploadDropzone component - https://buffer.atlassian.net/browse/CT-501

/**
 *
 * @deprecated Please use UploadDropzone component instead
 */
export const UploaderDropzone = ({
  uploader,
  uploadProgress,
  activeUploadsCount,
  isUploading,
  withBorder = false,
  multiple = true,
  disabled = false,
  showMessageOnHover = false,
  onlyAllowsVerticalVideo = false,
}: {
  uploader: Uploader
  uploadProgress: number
  activeUploadsCount: number
  isUploading: boolean
  withBorder?: boolean
  multiple?: boolean
  disabled?: boolean
  showMessageOnHover?: boolean
  onlyAllowsVerticalVideo?: boolean
}): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false)
  const {
    isDragging,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleClick,
  } = useDropTarget({ uploader })

  const showMessage = useMemo(() => {
    if (!showMessageOnHover) return !isUploading

    return (isHovering || isDragging) && !isUploading
  }, [showMessageOnHover, isUploading, isHovering, isDragging])

  const renderProgress = isUploading
  const renderCount = activeUploadsCount > 1 && isUploading
  const fileInput = useRef<HTMLInputElement>(null)

  const openFileInput = (): void => {
    if (fileInput.current !== null) {
      fileInput.current.click()
      setIsHovering(false)
    }
  }

  // Used to paste image/videos from clipboard
  useEffect(() => {
    const handlePaste = async (event: ClipboardEvent): Promise<void> => {
      // prevent pasting if already uploading as it might lead to adding a file when it should not be allowed
      if (isUploading || !event.clipboardData) {
        return
      }

      // Get the data of clipboard
      const clipboardItems = event.clipboardData?.items
      if (!clipboardItems) {
        return
      }
      const files = getFilesFromClipboardItems(Array.from(clipboardItems))

      if (files) {
        await uploader.addFiles(files, {
          source: UploadSource.dragAndDrop(),
        })
      }
    }
    document.addEventListener('paste', handlePaste, true)

    return (): void => {
      document.removeEventListener('paste', handlePaste, true)
    }
  }, [isUploading, uploader])

  return (
    <UploadDropzone
      withBorder={withBorder}
      isUploading={isUploading}
      isDragging={isDragging}
      isHovering={isHovering}
      onMouseEnter={(): void => setIsHovering(true)}
      onMouseLeave={(): void => setIsHovering(false)}
      onDrop={handleDrop}
      onClick={openFileInput}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      data-testid="composer-uploader-dropzone"
      aria-label="Attach file"
    >
      {showMessage && (
        <UploadMessage
          isDragging={isDragging}
          onlyAllowsVerticalVideo={onlyAllowsVerticalVideo}
        />
      )}
      {/* TODO UPLOADS use accept attribute to filter types */}
      <Input
        data-testid="uploads-dropzone-input"
        ref={fileInput}
        type="file"
        multiple={multiple}
        disabled={disabled}
        onChange={handleClick}
      />
      {renderProgress && (
        <CircularUploadIndicator
          data-testid="uploads-dropzone-progress"
          size={54}
          progress={uploadProgress || 0}
          showText
        />
      )}
      {renderCount && <UploadsCount>{activeUploadsCount}</UploadsCount>}
    </UploadDropzone>
  )
}
