import React from 'react'

import { useParams } from 'react-router-dom'
import { ContentFeed } from './components/ContentFeed/ContentFeed'

export const Feeds = (): JSX.Element => {
  const { feedGroupId } = useParams<{ feedGroupId: string }>()
  if (!feedGroupId) {
    return <div>Feed Group Id not found</div>
  }
  return <ContentFeed feedGroupId={feedGroupId} />
}
