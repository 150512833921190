import styled, { css } from 'styled-components'

const imageFirstLayoutStyles = css`
  order: 2;
  position: relative;
  width: 100%;
`

const expandedTextZoneStyles = css`
  border-radius: 4px 4px 0 0;
  min-height: 92px;
`

const collapsedTextZoneStyles = css`
  padding: 0;
  max-width: 608px;
  overflow: hidden;
  white-space: nowrap;
  line-height: 38px;
  color: var(--color-text-subtle);
`

export const ComposerEditorWrapper = styled.div<{
  $showImageFirstLayout: boolean
}>`
  position: relative;
  ${(props) =>
    props.$showImageFirstLayout
      ? css`
          order: 2;
          width: 100%;
        `
      : css`
          flex-basis: 100%;
          z-index: 1;
        `}
`

export const ComposerTextArea = styled.div<{
  $isComposerExpanded: boolean
  $hasLinkAttachment: boolean
  $showImageFirstLayout: boolean
}>`
  padding: 16px 32px 8px 16px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-neutral);
  background-color: var(--color-bg-neutral);
  resize: none;
  word-break: break-word;
  border: 0;
  height: ${(props) => (props.$isComposerExpanded ? '100%' : 'auto')};

  ${(props) => props.$showImageFirstLayout && imageFirstLayoutStyles}
  ${(props) => props.$isComposerExpanded && expandedTextZoneStyles}
  ${(props) =>
    props.$isComposerExpanded &&
    props.$hasLinkAttachment &&
    `border-bottom: none;`}

  ${(props) => !props.$isComposerExpanded && collapsedTextZoneStyles}
`
