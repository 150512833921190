import type { ChannelGroup } from '~publish/pages/ChannelGroups'
import type { RootState } from '../store'

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectUserId = (state) => state.user.id

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectFeatureFlip = (state, featureFlip) =>
  state?.user?.features?.includes(featureFlip)

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectWeekStartsMonday = (state) =>
  !!state.user?.week_starts_monday

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectHasTwentyFourHourTimeFormat = (state) =>
  state.user?.hasTwentyFourHourTimeFormat

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectUserHasFeature = (state, feature) =>
  state?.user?.features?.includes(feature)

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectUserIdAndEmail = (state) => {
  return {
    id: state.user.id,
    email: state.user.email,
  }
}

export const selectUserChannelGroups = (state: RootState): ChannelGroup[] =>
  state.user?.profile_groups

export const selectCalendarAsHomepage = (state: RootState): boolean =>
  !!state.user?.showCalendarAsHomepage
