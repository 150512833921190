import React, { useMemo, forwardRef } from 'react'

import {
  Heading,
  Text,
  Flex,
  Image,
  IconButton,
  ExternalLinkIcon,
  IdeasIcon,
  FileTextIcon,
} from '@buffer-mono/popcorn'
import { useScraperData } from '~publish/hooks/resources/useScraperData'

import { formatDistance } from 'date-fns'
import type { FeedItem } from '../../hooks/useFeedItems'
import type { FeedGroupForDetails } from '../../hooks/useFeedGroup'
import styles from './ContentFeed.module.css'

const isSame = (
  a: Date,
  b: Date,
): {
  day: boolean
  week: boolean
  month: boolean
  year: boolean
} => {
  const year = a.getFullYear() === b.getFullYear()
  const month = year && a.getMonth() === b.getMonth()
  const [newer, older] = a.getDate() > b.getDate() ? [a, b] : [b, a]
  const week = month && newer.getDate() - older.getDate() < 7 && a.getDay() > 0
  const day = month && a.getDate() === b.getDate()
  return {
    day,
    week,
    month,
    year,
  }
}

type ContentFeedItemProps = {
  item: FeedItem
  lastDate?: string
  feed?: FeedGroupForDetails['feeds'][number]
  handleCreatePost: (item: FeedItem) => void
  handleSaveAsIdea: (item: FeedItem) => void
}

export const ContentFeedItem = forwardRef<HTMLLIElement, ContentFeedItemProps>(
  (props, ref) => {
    const { lastDate, item, handleCreatePost, handleSaveAsIdea, feed } = props
    const rootUrl = useMemo(
      () =>
        feed?.connectionMetadata.url
          ? new URL(feed?.connectionMetadata.url).origin
          : null,
      [feed?.connectionMetadata.url],
    )
    const { data } = useScraperData(item.content.articleUrl)
    const firstImage = data?.images?.[0]
    const isNewDay = useMemo(() => {
      if (!lastDate) {
        return true
      }

      return !isSame(new Date(item.createdAt), new Date(lastDate)).day
    }, [item.createdAt, lastDate])

    const newDay = useMemo(() => {
      const now = new Date()
      const yesterday = new Date(now.getTime() - 1000 * 60 * 60 * 24)
      const postDate = new Date(item.createdAt)
      const same = isSame(postDate, now)
      if (same.day) {
        return 'Today'
      }
      if (isSame(postDate, yesterday).day) {
        return 'Yesterday'
      }
      if (same.week) {
        return postDate.toLocaleDateString('en-US', {
          weekday: 'long',
        })
      }
      if (same.year) {
        return postDate.toLocaleDateString('en-US', {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
        })
      }
      return postDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      })
    }, [item.createdAt])

    return (
      <li ref={ref}>
        {isNewDay && (
          <div className={styles.dateSegment}>
            <time dateTime={new Date(item.createdAt).toISOString()}>
              {newDay}
            </time>
          </div>
        )}
        <article className={styles.articleLayout}>
          <Image
            className={styles.articleThumbnail}
            src={
              item.content.thumbnail ??
              firstImage?.url ??
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
            }
            alt={item.content.title}
          />
          <div className={styles.articleHeader}>
            <div className={styles.headerContainer}>
              <header className={styles.articleHeader}>
                <Heading size="small">{item.content.title}</Heading>
                {/* todo: add source image via feed */}
                <Text size="sm">
                  <Flex gap="xs" align="center">
                    {rootUrl && (
                      <img
                        className={styles.feedNameFavicon}
                        src={`http://www.google.com/s2/favicons?domain=${rootUrl}`}
                        alt={item.feed.name}
                      />
                    )}
                    <span>{item.feed.name}</span>
                    <span>|</span>
                    <time dateTime={new Date(item.createdAt).toISOString()}>
                      {formatDistance(new Date(item.createdAt), new Date(), {
                        addSuffix: true,
                      }).replace('about ', '')}
                    </time>
                  </Flex>
                </Text>
              </header>
              <Flex gap="xs" align="center">
                <IconButton
                  tooltip="Open Link"
                  label="Open Link"
                  variant="tertiary"
                  as="a"
                  href={item.content.articleUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ExternalLinkIcon />
                </IconButton>
                <IconButton
                  tooltip="Create New Post"
                  label="Create New Post"
                  variant="tertiary"
                  onClick={(): void => handleCreatePost(item)}
                >
                  <FileTextIcon />
                </IconButton>
                <IconButton
                  tooltip="Save as Idea"
                  label="Save as Idea"
                  variant="tertiary"
                  onClick={(): void => handleSaveAsIdea(item)}
                >
                  <IdeasIcon />
                </IconButton>
              </Flex>
            </div>
            <Text className={styles.articleDescription}>
              {item.content.contentTextPreview}
            </Text>
          </div>
        </article>
      </li>
    )
  },
)

ContentFeedItem.displayName = 'ContentFeedItem'
