import type { PostCard_PostFragment } from '~publish/gql/graphql'

const readablePostStatus: Record<PostCard_PostFragment['status'], string> = {
  draft: 'draft',
  needs_approval: 'needs approval',
  sent: 'sent',
  error: 'errored',
  scheduled: 'scheduled',
  sending: 'being sent',
} as const

export const getReadablePostStatus = (
  status: PostCard_PostFragment['status'],
): string => {
  return readablePostStatus[status]
}
